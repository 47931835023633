import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
const NewUserFallback = (): React.JSX.Element => {
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    return (
        <>
            <Paper sx={{ height: '100vh' }}>
                <Stack flexDirection={'row'} alignItems={'center'} height={'100%'} justifyContent={'center'}>
                    <Typography>
                        User <b>{profileProps?.email || ''}</b> is not authorized for any configured adopter.
                    </Typography>
                </Stack>
            </Paper>
        </>
    );
};

export default NewUserFallback;
