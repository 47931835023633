import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'auth',

    initialState: { token: null } as {
        token: null | string;
    },

    reducers: {
        setCredentials: (state, { payload: { token } }: PayloadAction<{ token: string }>) => {
            state.token = token;
        },

        appLogout: () => {},
    },

    extraReducers: () => {},
});

export const { setCredentials, appLogout } = slice.actions;

export default slice;
