import {
    Stack,
    Box,
    Button,
    Typography,
    Menu,
    TextField,
    Divider,
    FormControlLabel,
    PopoverVirtualElement,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

type Props = {
    handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    open: boolean;
    handleClose: ((event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
    handleSearch: (arg0: React.ChangeEvent<HTMLInputElement>, arg1: any, arg2: string) => void;
    sitesData: { data: { site: string[] | undefined } };
    searchValue: unknown;
    dataToShow: any[];
    additionalFilters: { siteId?: string[]; connectionState?: string[] };
    handleSiteFilter: (arg0: any, arg1: any) => void;
    filteredSearchData: string | any[];
    isFetchingSites: boolean;
    handleLoadMore: () => void;
    paginationPayload: { isAduEnabled: boolean | undefined };
    handleGroupFilter: (arg0: boolean) => void;
    isMenuOpen: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
};

const DeviceTableCustomFilters = ({
    handleClick,
    open,
    handleClose,
    handleSearch,
    sitesData,
    searchValue,
    dataToShow,
    additionalFilters,
    handleSiteFilter,
    filteredSearchData,
    isFetchingSites,
    handleLoadMore,
    paginationPayload,
    handleGroupFilter,
    isMenuOpen,
}: Props): React.JSX.Element => (
    <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} px={2} py={1}>
        <Box>
            <Button
                variant="text"
                disableElevation
                disableRipple
                onClick={handleClick}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                data-cy="site-id-filter-dropdown"
            >
                <Typography fontSize={16} fontWeight={600} color={'#424e54'}>
                    Site
                </Typography>
                <ArrowDropDownIcon sx={{ color: '#424e54' }} />
            </Button>
            <Menu open={open} onClose={handleClose} id="basic-menu" className="site-menu" anchorEl={isMenuOpen}>
                <Box className="target-devices-pos-relative table-header-search-box">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search.."
                        variant="outlined"
                        className="w-100"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleSearch(event, sitesData?.data?.site, 'label')
                        }
                        value={searchValue}
                    />
                    <SearchIcon className="search-icon-wrap" />
                </Box>
                <Divider />
                <Box className="site-menu-body">
                    {dataToShow?.length ? (
                        dataToShow?.map((site: any) => (
                            <Tooltip title={site.label} placement="right-end" key={site?.id}>
                                <FormControlLabel
                                    key={site?.id}
                                    className="form-label-ml-0 form-site-label"
                                    control={
                                        <Checkbox
                                            checked={(additionalFilters.siteId ?? []).includes(site.id)}
                                            onChange={(e: any): void => {
                                                e.stopPropagation();
                                                handleSiteFilter(site?.id, e);
                                            }}
                                        />
                                    }
                                    label={site.label}
                                ></FormControlLabel>
                            </Tooltip>
                        ))
                    ) : (
                        <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
                            {isFetchingSites ? 'Loading...' : 'No Result Found.'}
                        </Typography>
                    )}
                    {filteredSearchData?.length > dataToShow?.length && (
                        <Button
                            fullWidth
                            variant="text"
                            // disabled={getDeviceDetailsByGroupName?.length < 3}
                            size="small"
                            color="primary"
                            data-cy="view-full-list-button"
                            sx={{
                                cursor: 'pointer',
                                background: 'none',
                                '&:hover': { backgroundColor: 'none', textDecoration: 'underline' },
                                textDecoration: 'underline',
                                marginTop: 1,
                            }}
                            onClick={(): void => handleLoadMore()}
                        >
                            Click to load more...
                        </Button>
                    )}
                </Box>
                <Divider />
                <Box className="site-menu-footer">
                    <Typography></Typography>
                </Box>
            </Menu>
        </Box>
        <Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={paginationPayload?.isAduEnabled}
                        onChange={(): void => handleGroupFilter(!paginationPayload.isAduEnabled)}
                        size="small"
                    />
                }
                label={<Typography variant="body2">Show only ADU Devices</Typography>}
            />
        </Box>
    </Stack>
);

export default DeviceTableCustomFilters;
