import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, Card, Chip, CardContent, Button, IconButton, Stack, Divider, Toolbar } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { AppBar, ListItemTag } from '@brightlayer-ui/react-components';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGetDeviceByGroupNameQuery, useLazyDownloadDeviceCSVByGroupNameQuery } from './groupApi';
import { DeviceDetailsByGroupName } from '../../types';
import CustomTable from '../../components/CustomTable';
import { downloadFileFromLink } from '../../commonUtils';
import { CustomWaitingState } from '../../components/CustomWaitingState';
import { useSelector } from 'react-redux';
import { ChevronRight, Devices } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
    installedUpdateDetailsCell,
    lastAttemptedUpdateDetailsCell,
} from '../../components/InstalledUpdateDetailsCell';

export const OverviewList = (): React.JSX.Element => {
    /*Additional hooks */
    const tableRef = useRef<any>();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);
    const navigate = useNavigate();
    const { groupName, container } = useParams();

    /*States */
    const [paginationPayload, setPaginationPayload] = useState({ page: 0, size: 10, filters: {} });
    const [deviceListByGroupName, setDeviceListByGroupName] = useState([]);
    const [customWaitingState, setCustomWaitingState] = useState<boolean>(false);

    /*API calls */
    const {
        data: deviceListData,
        isFetching: isDevicesFetched,
        isError: isDevicesFetchedError,
    } = useGetDeviceByGroupNameQuery(groupName, {
        refetchOnMountOrArgChange: true,
    });

    const [downloadDeviceCSV, { currentData: deviceCSVData, isSuccess, isError }] =
        useLazyDownloadDeviceCSVByGroupNameQuery();

    /*Custom width tooltip style*/
    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    /*Functions for table cell */

    const groupNameDetailsCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
        <Typography
            variant="body2"
            className="text-primary text-clickable"
            onClick={(): void => navigate(`/${container}/groupOverviewDevice/${groupName}/${groups?.deviceId}`)}
        >
            {groups?.deviceId}
        </Typography>
    );

    const deploymentStatusDetailsCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
        <ListItemTag
            className={classHandlerForDeploymentStatus(groups?.deploymentStatus)}
            label={groups?.deploymentStatus === null ? '--' : `${groups?.deploymentStatus}`}
        />
    );

    const onLatestUpdateDetailsCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
        <ListItemTag
            label={groups?.onLatestUpdate ? 'Yes' : 'No'}
            className={groups?.onLatestUpdate ? 'succeeded-tag' : 'cancel-chip'}
        />
    );

    /*Table columns */

    const deviceDetailsByGroupNameColumns = React.useMemo(
        () => [
            {
                header: 'Device Id',
                accessor: 'deviceId',
                cell: groupNameDetailsCell,
                isFilterable: true,
                isDebounce: true,
                width: '20%',
            },
            {
                header: 'Deployment status',
                isDebounce: true,
                cell: deploymentStatusDetailsCell,
                width: '20%',
            },
            {
                header: 'Installed update',
                isDebounce: true,
                cell: installedUpdateDetailsCell,
                width: '20%',
            },
            {
                header: 'Last attempted update',
                isDebounce: true,
                cell: lastAttemptedUpdateDetailsCell,
                width: '20%',
            },
            {
                header: 'On latest update',
                isDebounce: true,
                cell: onLatestUpdateDetailsCell,
                width: '20%',
            },
        ],
        [deviceListByGroupName]
    );

    /*Table Handlers */

    const handleFilterChange = (filters: any): void => {
        let filteredDevices;
        if (Object.keys(filters).length === 0) {
            // If filters object is empty, show whole data
            setDeviceListByGroupName(deviceListData?.data);
        } else {
            filteredDevices = deviceListData?.data?.filter((device: any) =>
                Object.keys(filters).every((key) => {
                    const filterValue = filters?.[key];
                    const deviceValue = device?.[key];
                    if (deviceValue) return deviceValue.includes(filterValue);
                })
            );

            setDeviceListByGroupName(filteredDevices);
        }
        if (JSON.stringify(filters) !== JSON.stringify(paginationPayload.filters)) {
            setPaginationPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: filters,
            }));
        }
    };

    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const getFilterLabel = (key: string): string | undefined => {
        if (key === 'siteId') return 'Site';
        const filteredColumn = deviceDetailsByGroupNameColumns.find((obj) => obj.accessor === key);
        return filteredColumn?.header;
    };

    const handleHeaderTooltip = (): React.JSX.Element => (
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body2">{profileProps?.adopterName || '--'}</Typography>
            <ChevronRight />
            <Typography variant="body2">{profileProps?.iotHubName || '--'}</Typography>
            <ChevronRight />
            <Typography variant="body2">Groups</Typography>
            <ChevronRight />
            <Typography variant="body2">{groupName}</Typography>
            <ChevronRight />
            <Typography variant="body2">Device List ({deviceListByGroupName?.length || '--'})</Typography>
        </Stack>
    );

    const classHandlerForDeploymentStatus = (deployStatus: string | undefined): string => {
        switch (deployStatus) {
            case 'Succeeded':
                return 'succeeded-tag';
            case 'Canceled':
            case 'Failed':
                return 'cancel-chip';
            case 'InProgress':
                return 'in-progress-chip';
            default:
                return 'bg-white text-black';
        }
    };

    const getWrapperClassForTable = (): string => {
        switch (true) {
            case deviceListByGroupName?.length === 0 || isError:
                return 'device-table-white-space custom-overview-list-table-responsive no-device-found-center';
            case deviceListByGroupName?.length < 3:
                return 'device-table-white-space custom-overview-list-table-responsive';
            default:
                return 'device-table-white-space custom-overview-list-table-responsive';
        }
    };

    /*Download CSV Handler */

    const downloadCSVHandler = async (): Promise<void> => {
        setCustomWaitingState(true);
        await downloadDeviceCSV({ groupId: groupName });
    };

    /*Effects */

    /* The above code is using the `useEffect` hook in a React component to handle the logic for
    downloading a file and updating the waiting state based on the success or error status of the
    operation. */
    useEffect(() => {
        if (isSuccess && deviceCSVData?.data) {
            downloadFileFromLink(deviceCSVData?.data, 'deviceCSV');
            setCustomWaitingState(false);
        } else if (isError) {
            setCustomWaitingState(false);
        }
    }, [isSuccess, isError, deviceCSVData]);

    useEffect(() => {
        if (deviceListData?.data) setDeviceListByGroupName(deviceListData?.data);
    }, [deviceListData]);

    return (
        <>
            <Box className="content-container detail-page-container" sx={{ height: 'auto', pt: '90px !important' }}>
                <AppBar className="detail-page-header appbar-index6">
                    <Toolbar>
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            className="card-header"
                            sx={{ borderBottom: '0 !important' }}
                        >
                            <IconButton
                                onClick={(): void => navigate(`/${container}/groupsTab/${groupName}/overview`)}
                                data-cy="back-icon-for-overview-list"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box>
                                <Typography variant={'h6'} fontSize={'20px'} fontWeight={600} className="text-content">
                                    Group Devices
                                </Typography>
                                <CustomWidthTooltip placement="bottom-start" title={<>{handleHeaderTooltip()}</>}>
                                    <Typography
                                        variant={'body1'}
                                        fontSize={'16px'}
                                        fontWeight={400}
                                        className="text-content"
                                    >
                                        ... / Groups / {groupName} / Device List ({deviceListByGroupName?.length})
                                    </Typography>
                                </CustomWidthTooltip>
                            </Box>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box p={'0 24px 24px 114px'}>
                <Card sx={{ m: 0 }} className="custom-card">
                    <CardContent className="card-content">
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            sx={{ p: 2 }}
                            data-cy="overview-list-header"
                        >
                            <Box sx={{ borderBottom: '0 !important' }}>
                                <Typography variant="h6" fontSize={'16px'} fontWeight={600} className="text-primary">
                                    {groupName}: Device List ({deviceListByGroupName?.length ?? 0})
                                </Typography>
                            </Box>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <Button
                                    variant="text"
                                    size="medium"
                                    disabled={isDevicesFetched}
                                    onClick={(): void => navigate(`/${container}/groupsTab/${groupName}/overview`)}
                                >
                                    View less
                                </Button>
                                <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    disabled={!deviceListByGroupName?.length || isDevicesFetched}
                                    onClick={downloadCSVHandler}
                                >
                                    Export as CSV
                                </Button>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack direction="row" className="chip-spacing">
                            {Object.keys(paginationPayload?.filters).map((key: string) => (
                                <Chip
                                    key={uuidv4()}
                                    label={getFilterLabel(key)}
                                    onDelete={(): void => tableRef?.current?.resetFilters(key, true)}
                                    data-cy="filter-device-id-chip"
                                />
                            ))}
                        </Stack>
                        <Divider />
                        <CustomTable
                            ref={tableRef}
                            isPagination={true}
                            controlledPageSize={paginationPayload?.page}
                            total={deviceListByGroupName?.length}
                            keyToTraverse="deviceId"
                            handleFilterChange={handleFilterChange}
                            handlePageChange={handlePaginationChange}
                            data={deviceListByGroupName}
                            headers={deviceDetailsByGroupNameColumns}
                            containerClass="custom-data-table"
                            wrapperClass={getWrapperClassForTable()}
                            isLoading={isDevicesFetched}
                            noDataFoundTitle={
                                !deviceListByGroupName || isDevicesFetchedError
                                    ? 'Something went wrong !! No device(s) found'
                                    : 'No device(s) found'
                            }
                            noDataFoundIcon={<Devices fontSize="inherit" />}
                        />
                    </CardContent>
                </Card>
            </Box>
            {customWaitingState && <CustomWaitingState />}
        </>
    );
};
