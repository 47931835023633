import React, { useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import FirmwareOverview from './FirmwareOverview';
import FirmwareHistory from './FirmwareHistory';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate, useParams } from 'react-router-dom';

export const FirmwareTabs = (): React.JSX.Element => {
    /*Additional hooks*/
    const navigate = useNavigate();
    const { selectedTab } = useParams();

    useEffect(() => {
        if (selectedTab && !['overview', 'history'].includes(selectedTab)) {
            navigate('/PageNotFound');
        }
    }, [selectedTab]);

    /*Variables*/
    const isDefaultTab = selectedTab === 'overview';
    const tabsProps = [
        {
            id: 'default',
            navigatePath: '/firmwareTabs/overview',
            icon: <InsertChartIcon />,
            label: 'Overview',
            className: `tab-item ${isDefaultTab && 'active'}`,
        },
        {
            id: 'secondary',
            navigatePath: '/firmwareTabs/history',
            icon: <HistoryIcon />,
            label: 'Import History',
            className: `tab-item ${!isDefaultTab && 'active'}`,
        },
    ];

    // required to maintain state of active tab.
    const handleTabValue = (): number => (isDefaultTab ? 0 : 1);

    return (
        <>
            <Box
                className="detail-page-header"
                sx={{ zIndex: '0', height: '58px !important', position: 'fixed', top: '60px' }}
            >
                <Tabs className="custom-tab custom-tabs-style" value={handleTabValue()}>
                    {tabsProps.map(({ id, navigatePath, icon, label, className }) => (
                        <Tab
                            key={id}
                            onClick={(): void => navigate(navigatePath)}
                            icon={icon}
                            iconPosition="start"
                            label={label}
                            className={className}
                            sx={{ py: 0, px: 4 }}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box marginTop={'52px !important'}>{isDefaultTab ? <FirmwareOverview /> : <FirmwareHistory />}</Box>
        </>
    );
};
