import {
    Dialog,
    DialogTitle,
    Stack,
    Typography,
    Button,
    DialogContent,
    Box,
    CircularProgress,
    Paper,
    List,
    ListItem,
    ListItemText,
    DialogContentText,
    TextField,
    Divider,
    DialogActions,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import React from 'react';

type Props = {
    data: any;
    isLoading: boolean;
    handleCloseModal: () => void;
    isModalOpen: boolean;
    refetchData: () => void;
    deleteKey: string;
    setDeleteKey: (value: string) => void;
    cancelDeployment: () => void;
    isSendingResponse?: boolean;
};

const CancelDeploymentModal = ({
    data,
    isLoading,
    handleCloseModal,
    isModalOpen,
    refetchData,
    deleteKey,
    setDeleteKey,
    cancelDeployment,
    isSendingResponse,
}: Props): React.JSX.Element => (
    <div>
        <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="modal-wrapper common-modal-view"
        >
            <DialogTitle id="alert-dialog-title" className="modal-header">
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Typography variant="h6" data-cy="cancel-modalText">
                            Cancel Deployment
                        </Typography>
                    </Stack>
                    <Button variant="text" data-cy="refresh-modalText" startIcon={<ReplayIcon />} onClick={refetchData}>
                        Refresh
                    </Button>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent className="modal-body">
                <Box className="cancel-deployment-modal-height">
                    {isLoading ? (
                        <CircularProgress thickness={5} size={50} />
                    ) : (
                        <>
                            <Typography
                                variant="body2"
                                fontSize={'16px'}
                                fontWeight={600}
                                className="text-content"
                                data-cy="cancel-modelContext"
                            >
                                Current status of deployment on devices are below
                            </Typography>
                            <Paper sx={{ marginY: '16px' }}>
                                <List>
                                    <ListItem secondaryAction={data?.data?.totalDeviceCount}>
                                        <ListItemText primary="Total devices" />
                                    </ListItem>
                                    <ListItem secondaryAction={data?.data?.devicesInProgressCount}>
                                        <ListItemText primary="In progress" />
                                    </ListItem>
                                    <ListItem secondaryAction={data?.data?.devicesCanceledCount}>
                                        <ListItemText primary="Cancelled" />
                                    </ListItem>
                                    <ListItem secondaryAction={data?.data?.devicesCompletedSucceededCount}>
                                        <ListItemText primary="Succeeded" />
                                    </ListItem>
                                    <ListItem secondaryAction={data?.data?.devicesCompletedFailedCount}>
                                        <ListItemText primary="Failed" />
                                    </ListItem>
                                </List>
                            </Paper>
                            <Typography
                                variant="body2"
                                mb={2}
                                fontSize={'16px'}
                                fontWeight={600}
                                className="text-content"
                                data-cy="modal-cancel-message"
                            >
                                Do you really want to cancel the deployment?
                            </Typography>
                            <DialogContentText id="alert-dialog-description" mb={2}>
                                If Yes, Please Enter <b>cancel deployment</b> in below text box to cancel it.
                            </DialogContentText>
                            <TextField
                                id={'typecancel deployment'}
                                label={'Type "cancel deployment" to confirm'}
                                value={deleteKey}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                    setDeleteKey(e.target.value);
                                }}
                                fullWidth
                                variant={'filled'}
                                data-cy="type-cancel-deployment"
                            />
                        </>
                    )}
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions className="modal-footer">
                <Button onClick={handleCloseModal} variant="outlined">
                    Close
                </Button>
                <Button
                    onClick={cancelDeployment}
                    disabled={deleteKey !== 'cancel deployment' || isLoading || isSendingResponse}
                    className="error-btn"
                    variant="contained"
                    autoFocus
                    startIcon={
                        isSendingResponse && <CircularProgress className="circular-upload-btn" sx={{ color: '#fff' }} />
                    }
                >
                    Cancel Deployment
                </Button>
            </DialogActions>
        </Dialog>
    </div>
);

export default CancelDeploymentModal;
