import { Paper, Stack, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useAppDispatch } from '../redux-config/store';
import { setRecallProfile } from '../features/common/commonSlice';
export const NoProfileData = (): React.JSX.Element => {
    /*Additional hooks*/
    const location = useLocation();
    const dispatch = useAppDispatch();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    /*Variables*/
    const state = location?.state;
    const errorMessage = state?.errorToDisplay?.data?.errorMessage?.[0];
    localStorage.setItem('errorMessage', errorMessage);
    const storedErrorMessage = localStorage.getItem('errorMessage');
    const value = state?.value;
    const errorStateForProfile = state?.errorState;
    localStorage.setItem('errorStateForProfile', errorStateForProfile);
    const storedErrorStateForProfile = localStorage.getItem('errorStateForProfile');

    //Variables to store data if switch adopter/iotHub API call fails.
    const errorMessageForSwitch = state?.errorMessageForSwitch?.error?.data?.errorMessage;
    const isErrorStateActive = state?.errorForSwitchCall;

    const storedIsErrorStateActive = localStorage.getItem('errorState');
    const storedErrorMessageForSwitch = localStorage.getItem('errorMessageForSwitch');

    /*Functions */
    /* The `errorMessageHandling` function in the code snippet is responsible for rendering a specific JSX
    element based on certain conditions. */
    const errorMessageHandling = (): React.JSX.Element => {
        if (isErrorStateActive || storedIsErrorStateActive) {
            return (
                <Typography variant="h6">
                    {errorMessageForSwitch ?? storedErrorMessageForSwitch ?? 'Fetching Error Type..'}
                </Typography>
            );
        }
        return (
            <Typography variant="h6">
                {storedErrorMessage !== 'undefined' ? storedErrorMessage : 'Fetching Error Type...'}
            </Typography>
        );
    };

    /**
     * The `refreshProfileCall` function dispatches an action to set a flag for recalling a profile.
     */
    const refreshProfileCall = (): void => {
        dispatch(setRecallProfile(true));
    };

    /* The `useEffect` hook in the code snippet is used to perform side effects in function components. In
    this specific case: */
    useEffect(() => {
        if (isErrorStateActive || errorMessageForSwitch) {
            localStorage.setItem('errorState', isErrorStateActive);
            localStorage.setItem('errorMessageForSwitch', errorMessageForSwitch);
        }
        return () => {
            localStorage.removeItem('errorState');
            localStorage.removeItem('errorMessageForSwitch');
            localStorage.removeItem('errorMessage');
            localStorage.removeItem('errorStateForProfile');
        };
    }, []);

    return (
        <Paper sx={{ height: '100vh' }}>
            <Stack alignItems={'center'} height={'100%'} justifyContent={'center'}>
                {storedErrorStateForProfile || isErrorStateActive || storedIsErrorStateActive ? (
                    errorMessageHandling()
                ) : (
                    <Typography>
                        User <b>{profileProps?.email || ''}</b> is not authorized for any configured{' '}
                        {value || 'adopter'}.
                    </Typography>
                )}
                <Button
                    startIcon={<RefreshIcon />}
                    color="primary"
                    className="mt-16"
                    variant="contained"
                    onClick={refreshProfileCall}
                >
                    Refresh
                </Button>
            </Stack>
        </Paper>
    );
};
