import { Autocomplete, Box, Divider, FormHelperText } from '@mui/material';
import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useGetGroupDetailsQuery } from '../../pages/Summary/groupApi';
import CustomTextField from '../../components/CustomTextField';

type GroupId = {
    groupId: string;
};

export const AddGroupModal1 = React.memo(
    ({
        setDeviceUploadType,
        setGroupNameForDevices,
        groupNameForDevices,
        deviceUploadType,
        groupOperationType,
        setGroupOperationType,
        errorMessage,
        setErrorMessage,
    }: any): React.JSX.Element => {
        const handleChange = (event: any): void => {
            setDeviceUploadType((event.target as HTMLInputElement).value);
        };

        //API call to get groups list
        const { data } = useGetGroupDetailsQuery(undefined);
        const groupList: string[] = data?.data
            ?.filter((item: GroupId) => item?.groupId !== '$default')
            .map((item: GroupId) => item?.groupId);

        //Function to handle selected  group.
        const onChangeEventHandler = (_event: React.ChangeEvent<any>, newValue: string | null): void => {
            if (newValue !== null) setGroupNameForDevices(newValue);
        };

        //Function to handle add or delete group
        const handleAddDeleteGroup = (event: any, label: string): void => {
            setGroupOperationType({ id: (event.target as HTMLInputElement).value, label });
            setGroupNameForDevices('');
            setErrorMessage(null);
        };
        return (
            <>
                <Divider />
                <DialogContent className="modal-body">
                    <Box px={3} py={2}>
                        <FormControl className="w-100">
                            <FormLabel required className="custom-form-label text-secondary">
                                Select
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                // className="justify-content-between"
                                defaultValue={groupOperationType.id}
                            >
                                <FormControlLabel
                                    value="addNewGroup"
                                    control={<Radio />}
                                    label="Add/Update Group"
                                    onChange={(e): void => handleAddDeleteGroup(e, 'Add/Update Group')}
                                    data-cy="add-to-group"
                                />
                                <FormControlLabel
                                    sx={{ mr: 0 }}
                                    value="deleteFromGroup"
                                    control={<Radio />}
                                    label="Delete from Group"
                                    onChange={(e): void => handleAddDeleteGroup(e, 'Delete from Group')}
                                    data-cy="delete-from-group"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    {groupOperationType.id === 'addNewGroup' && (
                        <Box px={3}>
                            {groupNameForDevices?.length < 1 && (
                                <FormHelperText sx={{ color: '#ca3c3d', mb: 1 }} data-cy="warning-text-for-modal1">
                                    *Please enter/select the group name.
                                </FormHelperText>
                            )}
                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                value={groupNameForDevices}
                                onChange={onChangeEventHandler}
                                options={groupList?.map((option: any) => option) || []}
                                renderInput={(params): React.JSX.Element => (
                                    <CustomTextField
                                        params={params}
                                        setSelectedAssigneeValue={setGroupNameForDevices}
                                        selectedAssigneeValue={groupNameForDevices}
                                        setErrorMessage={setErrorMessage}
                                        errorMessage={errorMessage}
                                    />
                                )}
                            />
                        </Box>
                    )}
                    <FormControl sx={{ paddingLeft: '24px' }} className="p-24">
                        <FormLabel className="custom-form-label" required>
                            Create new group
                        </FormLabel>
                        <RadioGroup defaultValue={deviceUploadType} name="radio-buttons-group">
                            <FormControlLabel
                                value="selectDevice"
                                control={<Radio />}
                                label="Select devices from existing list"
                                onChange={handleChange}
                            />
                            <FormControlLabel
                                value="uploadDevice"
                                control={<Radio />}
                                label={
                                    groupOperationType?.id === 'deleteFromGroup'
                                        ? 'Upload device list csv/excel file to remove devices from group'
                                        : 'Upload device list csv/excel file to assign a device group'
                                }
                                onChange={handleChange}
                                data-cy="upload-file-as-CSV"
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </>
        );
    }
);
