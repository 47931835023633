import { api } from '../../redux-config/api/baseApi';

const profileApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProfileDetails: builder.query<any, any>({
            query: () => ({
                url: 'du/v1/users/me/profile',
            }),
            providesTags: ['Profile'],
        }),
        getAdopterIotHubList: builder.query<any, any>({
            query: () => ({
                url: 'du/v1/adopter/iothub',
            }),
        }),
        updateAdopterIotHubData: builder.mutation<any, any>({
            query: (data) => ({
                url: 'du/v1/adopter/switch',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error): any => (!error ? ['Profile', 'SummaryGroup'] : []),
        }),
    }),
});

export const { useGetProfileDetailsQuery, useLazyGetAdopterIotHubListQuery, useUpdateAdopterIotHubDataMutation } =
    profileApi;
