/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { AppBar, Avatar, Box, Divider, IconButton, Paper, Stack, Toolbar, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { UserMenu } from '@brightlayer-ui/react-components';
import { ExitToApp, Person } from '@mui/icons-material';
import { initials } from '../commonUtils';
import { useSelector } from 'react-redux';

export const MyProfile = (): React.JSX.Element => {
    /*Additional hooks*/
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    /*Helper-Utility functions*/
    const handleUserLogout = React.useCallback(async (): Promise<void> => {
        await oktaAuth.signOut();
    }, []);

    const name = profileProps?.firstName?.concat(profileProps?.lastName);
    const nameCredentials = initials(profileProps?.email);

    return (
        <>
            <AppBar position="fixed" className="bg-white appbar-index6">
                <Toolbar>
                    <IconButton
                        onClick={(): void => navigate('/summary')}
                        size="medium"
                        edge="start"
                        aria-label="back-btn"
                        sx={{ mr: 2 }}
                    >
                        <ArrowBackIcon className="text-content" />
                    </IconButton>
                    <Typography
                        variant="body2"
                        fontSize={'20px'}
                        fontWeight={600}
                        className="text-content"
                        sx={{ flexGrow: 1 }}
                    >
                        Profile
                    </Typography>
                    <UserMenu
                        avatar={nameCredentials ? <Avatar>{nameCredentials?.toUpperCase()}</Avatar> : <Avatar />}
                        menuGroups={[
                            {
                                items: [
                                    {
                                        itemID: '0',
                                        title: 'My Profile',
                                        icon: <Person />,
                                        onClick: () => navigate('/profile'),
                                    },
                                    {
                                        itemID: '1',
                                        title: 'Log Out',
                                        icon: <ExitToApp />,
                                        onClick: handleUserLogout as never,
                                    },
                                ],
                            },
                        ]}
                    />
                </Toolbar>
            </AppBar>
            <Paper sx={{ m: '96px 30px 30px 120px' }}>
                <Stack flexDirection={'row'} alignItems={'center'}>
                    <Stack flexBasis={'20%'} alignItems={'center'} p={3}>
                        {nameCredentials ? (
                            <Avatar sx={{ className: 'custom-myprofile-avatar-icon' }}>
                                {nameCredentials?.toUpperCase()}
                            </Avatar>
                        ) : (
                            <Avatar sx={{ className: 'custom-myprofile-avatar-icon' }} />
                        )}
                    </Stack>
                    <Stack flexBasis={'80%'} sx={{ borderLeft: '1px solid rgba(66, 78, 84, 0.12)' }}>
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            padding={'16px'}
                        >
                            <Box>
                                <Typography variant="body2" fontSize={'16px'} fontWeight={600} className="text-content">
                                    Name
                                </Typography>
                                <Typography variant="body2" fontSize={'14px'} fontWeight={400}>
                                    {name ?? 'N/A'}
                                </Typography>
                            </Box>

                            {/* <Box>
                            <EditIcon />
                        </Box> */}
                        </Stack>
                        <Divider />
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            padding={'16px'}
                        >
                            <Box>
                                <Typography variant="body2" fontSize={'16px'} fontWeight={600} className="text-content">
                                    Email
                                </Typography>
                                <Typography variant="body2" fontSize={'14px'} fontWeight={600} className="text-primary">
                                    {profileProps?.email || 'N/A'}
                                </Typography>
                            </Box>
                            {/* <Box>
                            <EditIcon />
                        </Box> */}
                        </Stack>
                        <Divider />
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            padding={'16px'}
                        >
                            <Box>
                                <Typography variant="body2" fontSize={'16px'} fontWeight={600} className="text-content">
                                    Phone Number
                                </Typography>
                                <Typography variant="body2" fontSize={'14px'} fontWeight={400}>
                                    {profileProps?.phoneNumber || 'N/A'}
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider />
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            padding={'16px'}
                        >
                            <Box>
                                <Typography
                                    variant="body2"
                                    fontSize={'16px'}
                                    fontWeight={600}
                                    className="f-16 fw-600 text-content"
                                >
                                    Country
                                </Typography>
                                <Typography variant="body2" fontSize={'14px'} fontWeight={400}>
                                    {'N/A'}
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider />
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
};
