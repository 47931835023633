import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
    Typography,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Avatar,
    Tooltip,
    Divider,
    Stack,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AssignmentReturned } from '@mui/icons-material';
import CustomTable from '../../components/CustomTable';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useGetFirmwareHistoryQuery, useLazyDownloadFirmwareImportHistoryCSVQuery } from './firmwareApis';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomWaitingState } from '../../components/CustomWaitingState';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import { getLocalTimeStamp, downloadFileFromLink, handleClientSidePaginationSorting } from '../../commonUtils';
import { SEARCH_PARAMS } from '../../components/Constant/FirmwareConstants';
import { PaginationPayload, EmptyObj } from '../../types/Common';
import { HistoryDetails } from '../../types';
import { GlobalSearch } from '../../components/GlobalSearch';

const FirmwareHistory = (): React.JSX.Element => {
    /*Additional Hooks*/
    const tableRef = useRef<any>();
    const dispatch = useAppDispatch();

    /*States*/
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
    const [detailsModal, setDetailsModal] = useState<boolean>(false);
    const [historyDetails, setHistoryDetails] = useState<HistoryDetails | EmptyObj>({});
    const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
    const [filteredHistoryData, setFilteredHistoryData] = useState<any[]>([]);
    const [customWaitingState, setCustomWaitingState] = React.useState<boolean>(false);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [paginationPayload, setPaginationPayload] = useState<PaginationPayload>({
        page: 0,
        size: 10,
        filters: {},
        sort: { key: '', sortType: '' },
    });

    /*API Calls*/
    const { data: allFirmwareHistory, isLoading } = useGetFirmwareHistoryQuery<{
        data: any;
        refetch: RefetchConfigOptions;
        isLoading: boolean;
        isError: boolean;
    }>(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        pollingInterval: autoRefresh ? 120000 : 0,
    });

    const [downloadFirmwareImportHistoryCSV, { currentData: firmwareImportHistoryCSVData, isSuccess, isError }] =
        useLazyDownloadFirmwareImportHistoryCSVQuery();

    const downloadCSVHandler = async (): Promise<void> => {
        setCustomWaitingState(true);
        await downloadFirmwareImportHistoryCSV({});
    };

    /*Table Cells*/
    const getStatusCell = (history: any): React.JSX.Element | undefined => {
        switch (history.status) {
            case 'Succeeded':
                return (
                    <Avatar className="table-avatar-icon success-avatar">
                        <DoneIcon />
                    </Avatar>
                );
            case 'Failed':
                return (
                    <Avatar className="table-avatar-icon error-avatar">
                        <CloseIcon />
                    </Avatar>
                );
            case 'Running':
                return (
                    <Avatar className="table-avatar-icon progress-avatar">
                        <Tooltip title="Firmware import in progress" placement="top-end">
                            <CircularProgress color="primary" />
                        </Tooltip>
                    </Avatar>
                );
            default:
                return;
        }
    };

    const getInvokedAtCell = (history: any): React.JSX.Element => (
        <Typography variant={'body2'}>{getLocalTimeStamp(history?.createdDateTime) || 'N/A'}</Typography>
    );

    const getProviderCell = (history: any): React.JSX.Element => (
        <Typography>{history?.updateId?.provider || '--'}</Typography>
    );

    const getFirmwareCell = (history: any): React.JSX.Element => (
        <Typography>{history?.updateId?.name || '--'}</Typography>
    );

    const getVersionCell = (history: any): React.JSX.Element => (
        <Typography>{history?.updateId?.version || '--'}</Typography>
    );

    const getDetailsCell = (history: any): React.JSX.Element => (
        <Button
            variant={'outlined'}
            onClick={(): void => {
                setDetailsModal(!detailsModal);
                setHistoryDetails(history);
            }}
            disabled={history.status === 'Running'}
        >
            Details
        </Button>
    );

    /*Table Columns*/
    const columns = useMemo(
        () => [
            {
                header: 'Status',
                cell: getStatusCell,
                width: '10%',
            },
            {
                header: 'Invoked At',
                accessor: 'createdDateTime',
                cell: getInvokedAtCell,
                isSortable: true,
                width: '20%',
            },
            {
                header: 'Provider',
                cell: getProviderCell,
                width: '20%',
            },
            {
                header: 'Firmware Name',
                cell: getFirmwareCell,
                width: '20%',
            },
            {
                header: 'Version',
                cell: getVersionCell,
                width: '20%',
            },
            {
                header: 'Actions',
                cell: getDetailsCell,
                width: '10%',
            },
        ],
        []
    );

    /*Table Handlers*/
    /**
     * The function `handleGlobalSearch` updates the search key and pagination payload when the input
     * value changes.
     * @param e - React.ChangeEvent<HTMLInputElement> - This is the type of the event object that is
     * passed to the function when the input value changes. It is a generic type provided by React and
     * represents a change event on an HTML input element.
     */
    const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchKey(e.target.value);
        setPaginationPayload({
            ...paginationPayload,
            page: 0,
        });
    };

    /**
     * The below code defines two functions, handleFilterChange and handlePaginationChange, which
     * update the paginationPayload state based on the provided filters and pagination values.
     * @param {any} filters - The `filters` parameter is an object that represents the filters applied
     * to the data. It can contain various properties depending on the specific filtering requirements,
     * such as search keywords, date ranges, or any other criteria used to filter the data.
     */

    const handleFilterChange = (filters: any, sortedData: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(paginationPayload.filters)) {
            setPaginationPayload((prev: PaginationPayload) => ({
                ...prev,
                page: 0,
                filters: filters,
            }));
        } else if (Object.keys(sortedData)?.length) {
            setPaginationPayload((prev: PaginationPayload) => ({
                ...prev,
                page: 0,
                sort: sortedData,
            }));
        }
    };

    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: PaginationPayload) => ({ ...prev, page: page, size: size }));
    };

    /*Effects*/
    useEffect(() => {
        if (isSuccess && firmwareImportHistoryCSVData?.data) {
            downloadFileFromLink(firmwareImportHistoryCSVData?.data, 'firmwareImportHistoryCSV');
            setCustomWaitingState(false);
        } else if (isSuccess && firmwareImportHistoryCSVData?.data === null) {
            setCustomWaitingState(false);
            dispatch(setSnackbarState({ open: true, message: 'Something went wrong, please try again later!' }));
        } else if (isError) {
            setCustomWaitingState(false);
        }
    }, [isSuccess, isError, firmwareImportHistoryCSVData]);

    useEffect(() => {
        if (!isLoading) {
            const clonedData = allFirmwareHistory?.data?.records?.slice(
                paginationPayload.page * paginationPayload.size,
                paginationPayload.page * paginationPayload.size + paginationPayload.size
            );
            setFilteredHistoryData(clonedData);
            setTotalRecords(allFirmwareHistory?.data?.records?.length);
        }
        if (allFirmwareHistory?.data?.records?.length) {
            const enableAutoRefresh = allFirmwareHistory?.data?.records?.some((el: any) => el.status === 'Running');
            setAutoRefresh(enableAutoRefresh);
        }
    }, [allFirmwareHistory]);

    useEffect(() => {
        if (allFirmwareHistory?.data?.records?.length) {
            const [paginatedData, filteredTotal] = handleClientSidePaginationSorting(
                allFirmwareHistory?.data?.records,
                paginationPayload,
                searchKey,
                SEARCH_PARAMS
            );
            setFilteredHistoryData(paginatedData);
            setTotalRecords(filteredTotal);
        }
    }, [paginationPayload, searchKey]);

    return (
        <>
            <Box className="main-content-wrapper">
                <Card sx={{ m: 0 }} className="custom-card">
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="card-header"
                    >
                        <Typography variant="h6">Firmware Import History</Typography>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={!allFirmwareHistory?.data?.total}
                            onClick={downloadCSVHandler}
                        >
                            Export as CSV
                        </Button>
                    </Stack>
                    <CardContent className="card-content" sx={{ paddingBottom: '0 !important' }}>
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            className="pos-relative table-header-search-box"
                        >
                            <GlobalSearch
                                handleGlobalSearch={handleGlobalSearch}
                                searchKey={searchKey}
                                setSearchKey={setSearchKey}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <InfoOutlinedIcon sx={{ marginRight: 1 }} />
                                <Typography variant="body2">
                                    View firmware import history for past seven days.
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider />
                        <CustomTable
                            ref={tableRef}
                            isPagination={true}
                            controlledPageSize={paginationPayload?.page}
                            total={totalRecords}
                            keyToTraverse="id"
                            handleFilterChange={handleFilterChange}
                            handlePageChange={handlePaginationChange}
                            data={filteredHistoryData}
                            headers={columns}
                            containerClass="custom-data-table"
                            wrapperClass={
                                'device-table-white-space firmware-import-history-table-responsive-height no-device-found-center'
                            }
                            isLoading={isLoading}
                            noDataFoundTitle={
                                !filteredHistoryData ? 'Something went wrong !! No logs found' : 'No Logs found'
                            }
                            customClass="firmware-history-emptyStateWrapper"
                            noDataFoundIcon={<AssignmentReturned fontSize="inherit" />}
                        />
                    </CardContent>
                </Card>
            </Box>

            <Dialog
                open={detailsModal}
                onClose={(): void => setDetailsModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="modal-wrapper common-modal-view"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className={`modal-header ${
                        historyDetails.status === 'Succeeded' ? 'custom-success-icon' : 'custom-error-icon'
                    }`}
                >
                    {historyDetails.status === 'Succeeded' ? 'Success Details' : 'Error Details'}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ p: '16px 24px' }}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Typography variant="h6" color={'#424e54'} className="f-14 fw-600 w-30">
                            Message : &nbsp;
                        </Typography>
                        <Typography variant="body2" color={'#424e54'} className="f-14 fw-300 w-70">
                            {historyDetails.status === 'Succeeded'
                                ? 'Request Completed Successfully'
                                : historyDetails?.error?.message}
                        </Typography>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'} paddingY={2}>
                        <Typography variant="h6" color={'#424e54'} className="f-14 fw-600 w-30">
                            Raised : &nbsp;
                        </Typography>
                        <Typography variant="body2" color={'#424e54'} className="f-14 fw-300 w-70">
                            {getLocalTimeStamp(historyDetails?.lastActionDateTime)}
                        </Typography>
                    </Stack>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Typography variant="h6" color={'#424e54'} className="f-14 fw-600 w-30">
                            Trace Id : &nbsp;
                        </Typography>
                        <Typography variant="body2" color={'#424e54'} className="f-14 fw-300 w-70">
                            {historyDetails?.traceId}
                        </Typography>
                    </Stack>
                    {historyDetails?.error?.innerError && (
                        <>
                            <Stack flexDirection={'row'} alignItems={'center'} paddingY={2}>
                                <Typography variant="h6" color={'#424e54'} className="f-14 fw-600 w-30">
                                    Code : &nbsp;
                                </Typography>
                                <Typography variant="body2" color={'#424e54'} className="f-14 fw-300 w-70">
                                    {historyDetails?.error?.innerError?.code}
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <Typography variant="h6" color={'#424e54'} className="f-14 fw-600 w-30">
                                    Detailed Message : &nbsp;
                                </Typography>
                                <Typography variant="body2" color={'#424e54'} className="f-14 fw-300 w-70">
                                    {historyDetails?.error?.innerError?.message}
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} pt={2}>
                                <Typography variant="h6" color={'#424e54'} className="f-14 fw-600 w-30">
                                    Error Detail : &nbsp;
                                </Typography>
                                <Typography variant="body2" color={'#424e54'} className="f-14 fw-300 w-70">
                                    {historyDetails?.error?.innerError?.errorDetail}
                                </Typography>
                            </Stack>
                        </>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions className="modal-footer">
                    <Button onClick={(): void => setDetailsModal(false)} variant="outlined" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {customWaitingState && <CustomWaitingState />}
        </>
    );
};

export default FirmwareHistory;
