import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux-config/store';
import { setSnackbarState } from '../../features/common/commonSlice';

//MUI imports
import {
    Box,
    Card,
    CardContent,
    Divider,
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ListItemTag } from '@brightlayer-ui/react-components';

//APIs imports
import {
    useGetCurrentDeploymentListQuery,
    useGetCurrentDeploymentDevicesQuery,
    useGetCurrentDeploymentStatisticsQuery,
} from '../Summary/groupApi';
import { useCancelDeploymentMutation, useRetryDeploymentMutation } from '../../features/common/commonApis';
import CancelDeploymentModal from '../../features/modal/CancelDeploymentModal';
import { getLocalTimeStamp, getTimeDifference, openInNewTab } from '../../commonUtils';

import { DonutChart } from '../../components/DonutChart';
import Fallback from '../../components/Fallback';
import { DonutChartSkeleton, LegendOptions, Deployment, Device } from '../../types';

/*Utility Functions-Component*/

/* The code below is defining a custom legend options object. The `customLegendOptions` object contains a `labelFormatter` function 
that is used to format the legend labels. */
const customLegendOptions: LegendOptions = {
    labelFormatter: function () {
        // If legendTooltip is set, put it into title attr
        if (this?.options?.legendTooltip) {
            return `<div title="${this.options.legendTooltip}">${this.name} ${
                this.options.y || this.options.y === 0 ? `: <strong>${this.options.y}</strong>` : ''
            } </div>`;
        }
        return `<div>${this.name}</div>`;
    },
};

const StatisticsChartComponent = ({
    data,
    legendOptions,
}: {
    data: DonutChartSkeleton;
    legendOptions: LegendOptions;
}): React.JSX.Element => <DonutChart data={data} size={'90%'} additionalLegendOptions={legendOptions} />;

const DeploymentsListComponent = ({
    deploymentData,
    selectedDeployment,
    setSelectedDeployment,
}: {
    deploymentData: any;
    selectedDeployment: Deployment | undefined;
    setSelectedDeployment: (selectedDeployment: Deployment) => void;
}): React.JSX.Element =>
    deploymentData?.data?.map((deployment: Deployment) => (
        <Box
            key={deployment.deploymentId}
            onClick={(): void => setSelectedDeployment(deployment)}
            className={`custom-list-border p-16 cursor-pointer mt-0 ${
                selectedDeployment?.deploymentId === deployment.deploymentId ? 'current-update-select-box' : ''
            }`}
        >
            <Stack flexDirection={'row'} justifyContent={'space-between'} key={deployment.deploymentId}>
                <Stack flexDirection={'row'}>
                    <Typography variant="h6" className="left-text">
                        Provider:{' '}
                    </Typography>
                    <Typography variant="h6" fontSize={'16px'} fontWeight={400} className="text-content">
                        &nbsp; {deployment?.updateId?.provider}
                    </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                <Box>
                    <ListItemTag label={`v ${deployment?.updateId?.version}`} />
                </Box>
            </Stack>
            <Stack flexDirection={'row'}>
                <Typography variant="h6" className="left-text">
                    Name:{' '}
                </Typography>
                <Typography variant="h6" fontSize={'16px'} fontWeight={400} className="text-content">
                    &nbsp; {deployment?.updateId?.name}
                </Typography>
            </Stack>
        </Box>
    ));

const SelectedDeploymentDetails = ({
    selectedDeployment,
}: {
    selectedDeployment: Deployment | undefined;
}): React.JSX.Element => (
    <List disablePadding>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Provider</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {selectedDeployment?.updateId?.provider ?? selectedDeployment?.update?.updateId?.provider}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Name</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {selectedDeployment?.updateId?.name ?? selectedDeployment?.update?.updateId?.name ?? '--'}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Version</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {selectedDeployment?.updateId?.version ?? selectedDeployment?.update?.updateId?.version ?? '--'}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Descriptive label</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {selectedDeployment?.friendlyName ?? selectedDeployment?.update?.friendlyName ?? '--'}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Start time</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {getLocalTimeStamp(selectedDeployment?.startDateTime)}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Cloud rollback</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {selectedDeployment?.isCloudInitiatedRollback ? 'Yes' : 'No'}
            </ListItemText>
        </ListItem>
    </List>
);

const TargetDeviceList = ({
    targetDeviceList,
    groupId,
}: {
    targetDeviceList: { data: [] };
    groupId?: string;
}): React.JSX.Element => {
    const { container } = useParams();
    return (
        <List>
            {targetDeviceList?.data?.slice(0, 3).map((device: Device) => (
                <ListItem key={device.deviceId} className="border" sx={{ width: 'auto', mb: 1 }}>
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="w-100"
                    >
                        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <ListItemText className="f-14 fw-400">{device.deviceId}</ListItemText>
                        </Stack>
                        <Button
                            variant="text"
                            size="medium"
                            onClick={(): void =>
                                openInNewTab(`/${container}/deviceTabs/${groupId}/${device?.deviceId}`)
                            }
                        >
                            View
                        </Button>
                    </Stack>
                </ListItem>
            ))}
        </List>
    );
};

/*Main Component*/
export const CurrentUpdates = (props: {
    groupId?: string;
    profileData?: { iotHubName: string };
}): React.JSX.Element => {
    /*Additional Hooks*/
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    /*States*/
    const [selectedDeployment, setSelectedDeployment] = useState<Deployment | undefined>();
    const [referenceTime, setReferenceTime] = useState<number | undefined>();
    const [lastUpdated, setLastUpdated] = useState('');
    const [isDeleteBtnOpen, setIsDeleteBtnOpen] = useState<boolean>(false);
    const [deleteKey, setDeleteKey] = useState<string>('');
    // available update modal

    /*APIs Call*/
    const [cancelDeployment, { isLoading: cancellingDeployment }] = useCancelDeploymentMutation();
    const {
        currentData: currentDeployments,
        isFetching: isFetchingDeployments,
        refetch: refetchCurrentDeploymentList,
    } = useGetCurrentDeploymentListQuery(
        { groupId: props?.groupId },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const {
        data: targetDeviceList,
        isFetching: isFetchingDevices,
        refetch: refetchDeploymentDevicesList,
    } = useGetCurrentDeploymentDevicesQuery(
        {
            groupId: props?.groupId,
            deviceClassId: selectedDeployment?.deviceClassId,
            deploymentId: selectedDeployment?.deploymentId,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !(selectedDeployment?.deviceClassId && selectedDeployment?.deploymentId),
        }
    );

    const {
        data: statisticsData,
        isFetching: isFetchingStatistics,
        refetch: refetchStatisticsData,
    } = useGetCurrentDeploymentStatisticsQuery(
        {
            groupId: props?.groupId,
            deviceClassId: selectedDeployment?.deviceClassId,
            deploymentId: selectedDeployment?.deploymentId,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !(selectedDeployment?.deviceClassId && selectedDeployment?.deploymentId),
        }
    );

    const [retryDeployment, { isLoading: isFetchingRetryDeployment }] = useRetryDeploymentMutation();

    /*Variables*/

    const statisticsChartData: DonutChartSkeleton = {
        name: 'Data',
        totalDeviceCount: statisticsData?.data?.totalDeviceCount,
        data: [
            {
                name: 'In Progress',
                y: statisticsData?.data?.devicesInProgressCount,
                color: '#F2D34E',
                legendTooltip: 'In Progress',
            },
            {
                name: 'Failed',
                y: statisticsData?.data?.devicesCompletedFailedCount,
                color: '#f24822',
                legendTooltip: 'Failed',
            },
            {
                name: 'Succeeded',
                y: statisticsData?.data?.devicesCompletedSucceededCount,
                color: '#57C141',
                legendTooltip: 'Succeeded',
            },
            {
                name: 'Cancelled',
                y: statisticsData?.data?.devicesCanceledCount,
                color: '#7b8387',
                legendTooltip: 'Cancelled',
            },
        ],
    };

    /*Helper-Utility Func*/
    const getLastUpdatedAt = (): void => {
        const timeDifference = getTimeDifference(referenceTime);
        setLastUpdated(timeDifference);
    };

    /*Handlers*/
    const handleSelectedDeployment = (): void => {
        const predefinedDeployment = currentDeployments?.data?.find(
            (dep: Deployment) => dep.deviceClassId === state?.subGroupDeviceClassId
        );
        setSelectedDeployment(predefinedDeployment ?? currentDeployments?.data[0]);
    };

    const handleDeleteBtnOpen = (): void => {
        setIsDeleteBtnOpen(true);
    };

    const handleDeleteBtnClose = (): void => {
        setIsDeleteBtnOpen(false);
    };

    /**
     * The function `handleManualRefresh` asynchronously fetches data and updates various lists and
     * statistics in a TypeScript React application.
     */
    const handleManualRefresh = async (): Promise<void> => {
        await refetchCurrentDeploymentList().then((res: any) => {
            if (!res?.error) {
                if (!currentDeployments?.data?.length) {
                    return;
                }
                return Promise.allSettled([refetchStatisticsData(), refetchDeploymentDevicesList()]);
            }
            console.error(res?.error?.data?.error);
        });
    };

    const cancelDeploymentPayload = (): object => ({
        groupId: props?.groupId,
        deviceClassId: selectedDeployment?.deviceClassId,
        deploymentId: selectedDeployment?.deploymentId,
    });
    const handleCancelDeployment = (): void => {
        if (deleteKey) {
            cancelDeployment(cancelDeploymentPayload())
                .then((res: any) => {
                    if (!res?.error) {
                        dispatch(setSnackbarState({ open: true, message: 'Deployment cancelled successfully!' }));
                        setIsDeleteBtnOpen(false);
                    }
                })
                .catch((res) => {
                    console.error(res?.error?.data?.errorMessage?.[0]);
                    setIsDeleteBtnOpen(false);
                });
        }
    };

    const handleRetryDeploymentHandler = async (): Promise<void> => {
        await retryDeployment(cancelDeploymentPayload())
            .then((res: any) => {
                if (!res?.error) {
                    dispatch(setSnackbarState({ open: true, message: 'Retry Deployment Successful' }));
                }
            })
            .catch((res) => {
                console.error(res?.error?.data?.errorMessage?.[0]);
            });
    };
    /*Effects*/

    /* The code below is using React's `useEffect` hook to perform certain actions based on the
     dependencies provided. */
    useEffect(() => {
        if (!isFetchingDeployments) {
            handleSelectedDeployment();
            const currentTime = Date.now();
            setReferenceTime(currentTime);
        }
    }, [isFetchingDeployments]);

    /* The below code is a React useEffect hook that runs a function `getLastUpdatedAt()` at an interval of
      every 5 seconds (5000 milliseconds) when the `referenceTime` dependency changes. It sets up a
      setInterval to call `getLastUpdatedAt()` every 5 seconds and clears the interval when the component
      unmounts or when `referenceTime` changes. */
    useEffect(() => {
        if (referenceTime) {
            getLastUpdatedAt();
            const interval = setInterval(() => {
                getLastUpdatedAt();
            }, 5000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [referenceTime]);

    return (
        <Box p={'24px 24px 22px 114px'}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Stack flexBasis={'30%'} className="grid-pt-0">
                    <Card className="current-update-left-card-height">
                        <CardContent sx={{ p: 2, height: '100%' }} data-cy="updates-selection-content">
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                pb={1}
                                height={'54px'}
                            >
                                <Typography color={'#424e54'} fontSize={'16px'} fontWeight={600} variant="h6">
                                    <b className="text-primary">Last updated at: </b>
                                    {lastUpdated}
                                </Typography>
                                <Tooltip title="Refresh" placement="top">
                                    <IconButton
                                        onClick={handleManualRefresh}
                                        disabled={isFetchingDeployments}
                                        data-cy="handle-manual-refresh"
                                    >
                                        <RefreshIcon
                                            className={
                                                isFetchingDeployments ? 'disabled-refresh-icon-color' : 'text-primary'
                                            }
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Divider sx={{ mb: 1 }} />
                            <Box sx={{ height: 'calc(100vh - 254px)', overflowY: 'auto' }}>
                                <Fallback
                                    isLoading={isFetchingDeployments}
                                    isDataLength={Boolean(currentDeployments?.data?.length)}
                                    noDataMsg="No deployments found!"
                                    component={
                                        <DeploymentsListComponent
                                            deploymentData={currentDeployments}
                                            selectedDeployment={selectedDeployment}
                                            setSelectedDeployment={setSelectedDeployment}
                                        />
                                    }
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack flexBasis={'100%'} sx={{ pl: 3 }} className="grid-pt-0">
                    <Card className="center-loader" sx={{ height: 'auto' }} data-cy="update-details-card">
                        <CardContent
                            className="p-0"
                            sx={{ height: '100%', overflowY: 'auto' }}
                            data-cy="update-details-card-content"
                        >
                            <Stack
                                flexDirection={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                p={2}
                                sx={{ height: '67.6px' }}
                            >
                                <Box className="d-flex align-items-center">
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        {' '}
                                        <Typography
                                            className="text-primary"
                                            variant="h6"
                                            fontSize={'16px'}
                                            fontWeight={600}
                                            data-cy="update-header"
                                        >
                                            Update
                                        </Typography>
                                    </Stack>
                                </Box>
                                {selectedDeployment && (
                                    <Box className="d-flex" data-cy="retry-cancel-deployment-wrapper">
                                        <Button
                                            startIcon={<ReplayIcon />}
                                            variant="outlined"
                                            size="medium"
                                            className="btn-outline-primary-rounded"
                                            onClick={handleRetryDeploymentHandler}
                                            disabled={
                                                !statisticsData?.data?.devicesCompletedFailedCount ||
                                                isFetchingRetryDeployment
                                            }
                                            data-cy="current-update-retry-device"
                                        >
                                            {isFetchingRetryDeployment ? 'Processing..' : 'Retry failed devices'}
                                        </Button>
                                        <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                                        <Button
                                            startIcon={<CloseIcon />}
                                            variant="outlined"
                                            size="medium"
                                            className="btn-outline-error-rounded"
                                            onClick={handleDeleteBtnOpen}
                                            data-cy="current-update-cancel-deployment"
                                        >
                                            Cancel deployment
                                        </Button>
                                    </Box>
                                )}
                            </Stack>
                            <Divider />
                            <Stack
                                minHeight={'335px'}
                                justifyContent={'center'}
                                data-cy="update-deployment-details-container"
                            >
                                <Fallback
                                    isLoading={isFetchingDeployments}
                                    isDataLength={Boolean(selectedDeployment)}
                                    noDataMsg="No deployments found!"
                                    component={<SelectedDeploymentDetails selectedDeployment={selectedDeployment} />}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} mt={'24px'}>
                        <Box sx={{ width: '49%' }}>
                            <Card
                                sx={{ m: 0 }}
                                className="custom-card custom-card-height-view"
                                data-cy="target-devices-card"
                            >
                                <Stack
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    p={2}
                                >
                                    <Typography
                                        variant="h6"
                                        fontSize={'16px'}
                                        fontWeight={600}
                                        className="text-primary"
                                        data-cy="target-device-header"
                                    >
                                        Target devices
                                    </Typography>
                                    <Button
                                        sx={{ p: 0 }}
                                        variant="text"
                                        disabled={!targetDeviceList || targetDeviceList?.data?.length < 3}
                                        size="medium"
                                        color="primary"
                                        onClick={(): void =>
                                            navigate(
                                                `/updateList/${props?.groupId}/${selectedDeployment?.deviceClassId}/${selectedDeployment?.deploymentId}`
                                            )
                                        }
                                        data-cy="view-more-target-devices-button"
                                    >
                                        View more
                                    </Button>
                                </Stack>
                                <Divider />
                                <CardContent
                                    sx={{ overflowY: 'auto', pb: '16px !important' }}
                                    className="update-compliance-center-loader"
                                    data-cy="target-devices-card-content"
                                >
                                    <Fallback
                                        isLoading={isFetchingDevices}
                                        isDataLength={Boolean(targetDeviceList?.data?.length)}
                                        component={
                                            <TargetDeviceList
                                                targetDeviceList={targetDeviceList}
                                                groupId={props.groupId}
                                            />
                                        }
                                        noDataMsg="No Target Devices"
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                        <Box sx={{ width: '49%' }}>
                            <Card
                                sx={{ m: 0 }}
                                className="custom-card custom-card-height-view"
                                data-cy="device-statics-card"
                            >
                                <Stack className="card-header" data-cy="device-statics-header">
                                    <Typography variant="h6">Device statistics for update deployment</Typography>
                                </Stack>
                                <CardContent
                                    sx={{ overflowY: 'auto', pb: '16px !important' }}
                                    className="update-compliance-center-loader custom-highchart-height"
                                    data-cy="device-statics-card-content"
                                >
                                    <Stack className="current-updates-auto-circular-progressbar">
                                        <Fallback
                                            isLoading={isFetchingStatistics}
                                            isDataLength={Boolean(statisticsData)}
                                            noDataMsg="No statistics found!"
                                            component={
                                                <Box>
                                                    <StatisticsChartComponent
                                                        data={statisticsChartData}
                                                        legendOptions={customLegendOptions}
                                                    />
                                                </Box>
                                            }
                                        />
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <CancelDeploymentModal
                data={statisticsData}
                handleCloseModal={handleDeleteBtnClose}
                isModalOpen={isDeleteBtnOpen}
                isLoading={isFetchingStatistics}
                refetchData={refetchStatisticsData}
                deleteKey={deleteKey}
                setDeleteKey={setDeleteKey}
                cancelDeployment={handleCancelDeployment}
                isSendingResponse={cancellingDeployment}
            />
        </Box>
    );
};
