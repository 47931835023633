import { api } from '../../redux-config/api/baseApi';
const groupApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getGroupDetails: builder.query<any, any>({
            query: () => ({
                url: 'du/v1/groups',
            }),
            providesTags: ['SummaryGroup', 'Profile', 'AddToGroups', 'DeleteGroups'],
        }),
        getDeviceByGroupName: builder.query<any, any>({
            query: (groupId) => ({
                url: `du/v1/groups/${groupId}/devices`,
            }),
        }),
        getDeviceStaticsByGroupName: builder.query<any, any>({
            query: (groupId) => ({
                url: `du/v1/groups/${groupId}/details`,
                method: 'GET',
            }),
        }),
        getGroupedColumnChart: builder.query<any, any>({
            query: () => ({
                url: 'du/v1/groups/compliance',
            }),
            providesTags: ['AddToGroups', 'DeleteGroups'],
        }),
        getDonutChartForUpdatesAvailable: builder.query<any, any>({
            query: (groupId) => ({
                url: `du/v1/groups/${groupId}/update/compliance`,
                method: 'GET',
            }),
        }),
        getBestAvailableUpdates: builder.query<any, any>({
            query: (groupId) => ({
                url: `du/v1/groups/${groupId}/update/available`,
            }),
        }),
        getDeviceDeploymentDetails: builder.query<any, any>({
            query: (deviceId) => ({
                url: `du/v1/deployments/${deviceId}/diagnostic/status`,
            }),
        }),
        getDeploymentHistoryByGroupId: builder.query<any, any>({
            query: ({ groupId }) => ({
                url: `du/v1/deployments/${groupId}/history`,
            }),
            providesTags: ['DeleteDeployments', 'CancelDeployment'],
        }),
        getCurrentDeploymentList: builder.query<any, any>({
            query: (params) => ({
                url: `du/v1/deployments/current`,
                params,
            }),
            providesTags: ['CreateDeployment', 'CancelDeployment'],
        }),
        getCurrentDeploymentDevices: builder.query<any, any>({
            query: ({ groupId, deviceClassId, deploymentId }) => ({
                url: `du/v1/deployments/${groupId}/classId/${deviceClassId}/deployment/${deploymentId}/devices`,
            }),
            providesTags: ['CreateDeployment'],
        }),
        getCurrentDeploymentStatistics: builder.query<any, any>({
            query: ({ groupId, deviceClassId, deploymentId }) => ({
                url: `du/v1/deployments/${groupId}/classId/${deviceClassId}/deployment/${deploymentId}/statistics`,
            }),
            providesTags: ['CancelDeployment', 'CreateDeployment'],
        }),
        updateAssigneeGroupCSV: builder.mutation<any, any>({
            query: ({ groupNameForDevices, uploadedFile }) => {
                const formData = new FormData();
                formData.append('file', uploadedFile);
                return {
                    url: `du/v1/groups/${groupNameForDevices}/assign`,
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['AddToGroups'] : []),
        }),
        getDeploymentViewDevices: builder.query<any, any>({
            query: ({ groupId, deviceClassId, deploymentId }) => ({
                url: `du/v1/deployments/${groupId}/classId/${deviceClassId}/deployment/${deploymentId}/devices`,
            }),
        }),
        getTargetDevicesForDeployment: builder.query<any, any>({
            query: ({ groupId, deviceClassId }) => ({
                url: `/du/v1/deployments/${groupId}/classId/${deviceClassId}/target/devices`,
            }),
        }),
        deleteGroups: builder.mutation<any, any>({
            query: (data) => ({
                url: 'du/v1/groups',
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeleteGroups'] : []),
        }),
        deleteDeployments: builder.mutation<any, any>({
            query: ({ groupId, selectedIds }) => ({
                url: `du/v1/deployments/${groupId}/deployments`,
                method: 'DELETE',
                body: selectedIds,
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeleteDeployments'] : []),
        }),
        downloadDeviceCSVByGroupName: builder.query<any, any>({
            query: (params) => ({
                url: `du/v1/devices/download`,
                method: 'GET',
                params,
            }),
        }),
        downloadDeviceDeploymentCSV: builder.query<any, any>({
            query: ({ groupId, deviceClassId, deploymentId }) => ({
                url: `du/v1/deployments/${groupId}/deviceClassId/${deviceClassId}/deployments/${deploymentId}/devices/download`,
                method: 'GET',
            }),
        }),
        downloadDeploymentHistoryCSV: builder.query<any, any>({
            query: (groupId) => ({
                url: `du/v1/deployments/${groupId}/history/download`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetGroupDetailsQuery,
    useGetDeviceByGroupNameQuery,
    useGetDeviceStaticsByGroupNameQuery,
    useGetGroupedColumnChartQuery,
    useGetDonutChartForUpdatesAvailableQuery,
    useGetBestAvailableUpdatesQuery,
    useGetDeviceDeploymentDetailsQuery,
    useGetDeploymentHistoryByGroupIdQuery,
    useGetCurrentDeploymentListQuery,
    useGetCurrentDeploymentDevicesQuery,
    useGetCurrentDeploymentStatisticsQuery,
    useUpdateAssigneeGroupCSVMutation,
    useGetDeploymentViewDevicesQuery,
    useGetTargetDevicesForDeploymentQuery,
    useDeleteGroupsMutation,
    useDeleteDeploymentsMutation,
    useLazyDownloadDeviceCSVByGroupNameQuery,
    useLazyDownloadDeviceDeploymentCSVQuery,
    useLazyDownloadDeploymentHistoryCSVQuery,
} = groupApi;
