/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { AppBar, Spacer, UserMenu } from '@brightlayer-ui/react-components';
import {
    Typography,
    Toolbar,
    Avatar,
    Stack,
    IconButton,
    Box,
    Button,
    ListItemText,
    Divider,
    Icon,
    CircularProgress,
} from '@mui/material';
import Logo from '../images/eaton.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { ExitToApp, Person, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useUpdateAdopterIotHubDataMutation, useLazyGetAdopterIotHubListQuery } from '../features/profile/profileApi';
import Tooltip from '@mui/material/Tooltip';
import { initials } from '../commonUtils';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Collapse from '@mui/material/Collapse';
import { IotHub } from '../types';
import { useSelector } from 'react-redux';

export const HeaderMenu = (props: any): React.JSX.Element => {
    /*Additional hooks*/
    const { oktaAuth } = useOktaAuth();
    const navigate = useNavigate();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    /*Helper-Utility functions*/
    const handleUserLogout = React.useCallback(async (): Promise<void> => {
        await oktaAuth.signOut();
    }, []);
    const pathName = window.location.pathname;

    /*States*/
    const [checkedIotHub, setCheckedIotHub] = React.useState<string>();

    const [checkedAdopter, setCheckedAdopter] = React.useState<string>();

    const [iotHubActiveClass, setIotHubActiveClass] = React.useState<string>();

    const [isDropDownActive, setIsDropDownActive] = React.useState(false);

    const [openCollapse, setOpenCollapse] = React.useState<string[]>([]);

    /*API Calls*/

    const [
        getAdopterIotHubList,
        {
            data: adopterIotHubListData,
            isFetching: isAdopterIotHubDataFetching,
            isError: isErrorForIotHubAdopterData,
            isSuccess: isSuccessForAdopterIotHub,
        },
    ]: any = useLazyGetAdopterIotHubListQuery();

    const adopterDataLength: number = adopterIotHubListData?.data?.length;

    //Default selected Adopter for new user
    const adopterList =
        (profileProps.iotHubName === null || profileProps.adopterName === null) &&
        adopterIotHubListData?.data?.map((adopter: any) => ({ adopterName: adopter?.name, adopterId: adopter?.id }));

    //Default selected iotHub for new user
    const iotHubList = adopterIotHubListData?.data?.flatMap((item: any) =>
        item?.iotHubs?.map((hub: any) => ({ iotHubName: hub?.name, iotHubId: hub?.id }))
    );

    // API call to save selected adopter and Iot Hub id.
    const [updateAdopterIotHubData, { isLoading: isLoadingForSwitchAdopterIotHub }] =
        useUpdateAdopterIotHubDataMutation();

    //Credentials for user profile details
    const name = profileProps.firstName?.concat(profileProps?.lastName) ?? 'N/A';
    const nameCredentials = initials(profileProps?.email);

    /*Functions*/

    const handleClickCollapse = (adopterId: string): void => {
        const isOpen = openCollapse.includes(adopterId);
        setOpenCollapse((prevState) => {
            if (isOpen) {
                return prevState?.filter((id) => id !== adopterId);
            }
            return [...prevState, adopterId];
        });
    };

    //Close menu items handler

    const handleClose = (): void => {
        if (profileProps.adopterId !== null || profileProps.iotHubId !== null) {
            setCheckedAdopter(profileProps?.adopterId);
            setCheckedIotHub(profileProps?.iotHubId);
            setIotHubActiveClass(profileProps?.iotHubId);
            setOpenCollapse([profileProps?.adopterId]);
        } else if (profileProps.adopterId === null || profileProps.iotHubId === null) {
            navigate('/newUserFallback');
        }
        setIsDropDownActive(false);
    };

    const closeIconDisableVariable =
        (profileProps.adopterName === null || profileProps.iotHubName === null) && Boolean(adopterDataLength);

    //Adopter dropdown (select iotHub) click handler

    const iotHubOnClickHandler = (iotHub: IotHub, adopter: any): void => {
        setCheckedAdopter(adopter?.id);
        setCheckedIotHub(iotHub?.id);
        setIotHubActiveClass(iotHub?.id);
    };

    //Save Button disable handler

    const saveButtonDisableHandler = (): boolean =>
        (profileProps.iotHubId === checkedIotHub && profileProps.adopterId === checkedAdopter) ||
        isAdopterIotHubDataFetching ||
        isErrorForIotHubAdopterData ||
        isLoadingForSwitchAdopterIotHub ||
        !checkedIotHub ||
        !iotHubActiveClass;

    // Function to save selected adopter and iotHub and call the switch API.

    const iotHubAdopterSaveHandler = () => async () => {
        const data = {
            adopterId: checkedAdopter,
            iotHubId: checkedIotHub,
        };
        const resultData: any = await updateAdopterIotHubData(data);

        if (resultData?.data?.success) {
            setIsDropDownActive(false);
            setOpenCollapse([profileProps.adopterId]);
            navigate('/summary');
        } else {
            setIsDropDownActive(false);
            setCheckedAdopter(profileProps?.adopterId);
            setCheckedIotHub(profileProps?.iotHubId);
            navigate('/noProfileData', { state: { errorMessageForSwitch: resultData, errorForSwitchCall: true } });
        }
    };

    //Click handler for dropdown.

    const handleClickOpen = (): void => {
        getAdopterIotHubList(undefined);
        setIsDropDownActive(!isDropDownActive);
    };

    //Function for Adopter loading and failure conditions

    const adopterEdgeCasesHandling = (): React.JSX.Element => {
        if (isAdopterIotHubDataFetching) {
            return <CircularProgress thickness={5} size={50} />;
        } else if (isErrorForIotHubAdopterData) {
            return (
                <Typography variant="h6" textAlign={'center'} className="f-14" sx={{ paddingX: 2, maxWidth: '350px' }}>
                    Sorry something went wrong please try again later.
                </Typography>
            );
        }
        return (
            <Typography variant="h6" className="f-14" sx={{ paddingX: 2, maxWidth: '350px' }}>
                User <b>{profileProps?.email || ''}</b> is not authorized for configured adopters.
            </Typography>
        );
    };

    /*Effects*/

    React.useEffect(() => {
        if (props.isAppAuthenticated && (profileProps.iotHubId !== null || profileProps.adopterId !== null)) {
            setCheckedIotHub(profileProps?.iotHubId);
            setCheckedAdopter(profileProps?.adopterId);
            setIotHubActiveClass(profileProps?.iotHubId);
            setOpenCollapse([profileProps?.adopterId]);
        }
        //for new user
        else if (profileProps.iotHubName === null || profileProps.adopterName === null) {
            setIsDropDownActive(true);
            getAdopterIotHubList(undefined);
        }
    }, [props.isAppAuthenticated, profileProps]);

    //Effect when new user logs in

    React.useEffect(() => {
        if (profileProps.iotHubName === null || profileProps.adopterName === null) {
            setCheckedAdopter(adopterList?.[0]?.adopterId);
            setCheckedIotHub(iotHubList?.[0]?.iotHubId);
            setOpenCollapse([adopterList?.[0]?.adopterId]);
        }
    }, [isSuccessForAdopterIotHub]);

    return (
        <>
            {pathName !== '/profile' &&
                !pathName.includes('/groupOverviewDevice') &&
                !pathName.includes('/deviceTabs') &&
                !pathName.includes('/updateList') &&
                !pathName.includes('/deploymentView') &&
                !pathName.includes('/overviewList') &&
                !pathName.includes('/groupsTab') && (
                    <AppBar position={'sticky'} variant="collapsed" sx={{ position: 'fixed', top: '0' }}>
                        <Toolbar>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <Box data-cy="logo-wrapper">
                                    <img src={Logo} alt="logo-img" height={'11px'} width={'40px'} />
                                </Box>
                                <Box ml={'44px'} data-cy="dropdown-content-wrapper">
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        <Stack
                                            className="cursor-pointer"
                                            flexDirection={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            onClick={handleClickOpen}
                                            data-cy="adopter-iotHub-dropdown"
                                        >
                                            {' '}
                                            <Box>
                                                <Typography
                                                    variant={'body1'}
                                                    color="white"
                                                    fontSize={'20px'}
                                                    fontWeight={600}
                                                    mr={'10px'}
                                                    data-cy="selected-adopter"
                                                >
                                                    {profileProps.adopterName || '--'}
                                                </Typography>
                                                <Typography
                                                    variant={'body1'}
                                                    color="white"
                                                    fontSize={'14px'}
                                                    fontWeight={400}
                                                    data-cy="selected-iotHub"
                                                >
                                                    {profileProps.iotHubName || '--'}
                                                </Typography>
                                            </Box>
                                            <Box data-cy="dropdown-icon-wrapper">
                                                <Icon>
                                                    <ArrowDropDownIcon />
                                                </Icon>
                                            </Box>
                                        </Stack>
                                        <Menu
                                            id="basic-menu"
                                            open={isDropDownActive}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            className="select-iot-hub-menu"
                                            data-cy="dropdown-menu-content"
                                        >
                                            <Stack
                                                flexDirection={'row'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                className="menu-header"
                                            >
                                                <Typography sx={{ fontWeight: 680 }}>
                                                    Switch IoTHub from Adopters
                                                </Typography>
                                                <Tooltip
                                                    title={
                                                        closeIconDisableVariable
                                                            ? 'Please first select and then save an iotHub.'
                                                            : ''
                                                    }
                                                    placement="right"
                                                    arrow
                                                >
                                                    <span>
                                                        <IconButton
                                                            onClick={(): void => handleClose()}
                                                            disabled={closeIconDisableVariable}
                                                            data-cy="close-dropdown"
                                                            disableRipple
                                                        >
                                                            <CloseIcon className="cursor-pointer" />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Stack>
                                            <Divider />
                                            <Box
                                                className={`menu-body ${
                                                    isErrorForIotHubAdopterData ||
                                                    isAdopterIotHubDataFetching ||
                                                    adopterDataLength === 0
                                                        ? 'menu-body-empty'
                                                        : ''
                                                }`}
                                                data-cy="adopter-iotHub-listing-content"
                                            >
                                                {isAdopterIotHubDataFetching ||
                                                isErrorForIotHubAdopterData ||
                                                adopterIotHubListData?.data?.length === 0
                                                    ? adopterEdgeCasesHandling()
                                                    : adopterIotHubListData?.data?.length > 0 &&
                                                      adopterIotHubListData?.data?.map((adopter: any) => (
                                                          <React.Fragment key={adopter.id}>
                                                              <MenuItem
                                                                  className={`f-14 ${
                                                                      profileProps?.adopterId === adopter?.id ||
                                                                      adopterList?.[0]?.adopterId === adopter?.id
                                                                          ? 'menu-active-color'
                                                                          : ''
                                                                  }`}
                                                                  onClick={(): void => handleClickCollapse(adopter?.id)}
                                                              >
                                                                  {adopter?.name}
                                                                  <ListItemText
                                                                      className="f12"
                                                                      primary={
                                                                          <Typography ml={1}>
                                                                              {` (${adopter?.iotHubs?.length} IoTHubs)`}
                                                                          </Typography>
                                                                      }
                                                                  />
                                                                  {openCollapse.includes(adopter.id) ? (
                                                                      <ExpandLess />
                                                                  ) : (
                                                                      <ExpandMore />
                                                                  )}
                                                              </MenuItem>
                                                              <Collapse
                                                                  in={openCollapse.includes(adopter.id)}
                                                                  unmountOnExit
                                                              >
                                                                  <Box className="nested-lists">
                                                                      <MenuList>
                                                                          {adopter?.iotHubs?.map((iotHub: IotHub) => (
                                                                              <MenuItem
                                                                                  key={iotHub?.id}
                                                                                  className={`menu-item-pl-6 ${
                                                                                      checkedIotHub === iotHub?.id
                                                                                          ? 'menu-item-active-color'
                                                                                          : ''
                                                                                  }`}
                                                                                  onClick={(): void =>
                                                                                      iotHubOnClickHandler(
                                                                                          iotHub,
                                                                                          adopter
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  <ListItemText
                                                                                      className="f12"
                                                                                      primary={iotHub?.name}
                                                                                  />
                                                                                  {checkedIotHub === iotHub?.id &&
                                                                                      iotHubActiveClass ===
                                                                                          iotHub?.id && (
                                                                                          <DoneIcon color="primary" />
                                                                                      )}
                                                                              </MenuItem>
                                                                          ))}
                                                                      </MenuList>
                                                                  </Box>
                                                              </Collapse>
                                                          </React.Fragment>
                                                      ))}
                                            </Box>
                                            <Divider />
                                            <Stack
                                                flexDirection={'row'}
                                                justifyContent={'end'}
                                                paddingX={2}
                                                paddingY={'6px'}
                                            >
                                                <Tooltip
                                                    title={
                                                        closeIconDisableVariable && !checkedIotHub
                                                            ? 'Please select and save an iotHub.'
                                                            : ''
                                                    }
                                                    placement="right"
                                                    arrow
                                                >
                                                    <span>
                                                        <Button
                                                            variant="contained"
                                                            sx={{ py: '3px' }}
                                                            onClick={iotHubAdopterSaveHandler()}
                                                            autoFocus
                                                            disabled={saveButtonDisableHandler()}
                                                            startIcon={
                                                                isLoadingForSwitchAdopterIotHub && (
                                                                    <CircularProgress
                                                                        className="circular-upload-btn"
                                                                        sx={{ color: '#fff' }}
                                                                    />
                                                                )
                                                            }
                                                            data-cy="switch-adopter-button"
                                                            disableRipple
                                                        >
                                                            Save
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Stack>
                                        </Menu>
                                    </Stack>
                                </Box>
                            </Stack>
                            <Spacer />
                            <UserMenu
                                data-cy="profile-menu-icon"
                                id="profile-menu"
                                MenuProps={{
                                    style: {
                                        top: '16px',
                                    },
                                }}
                                avatar={
                                    <Tooltip
                                        title={name}
                                        placement="top"
                                        disableHoverListener
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Avatar>{nameCredentials?.toUpperCase()}</Avatar>
                                    </Tooltip>
                                }
                                menuGroups={[
                                    {
                                        items: [
                                            {
                                                title: 'My Profile',
                                                icon: <Person />,
                                                onClick: () => navigate('/profile'),
                                            },
                                            {
                                                title: 'Log Out',
                                                icon: <ExitToApp />,
                                                onClick: handleUserLogout as never,
                                            },
                                        ],
                                    },
                                ]}
                                menuTitle={name}
                                menuSubtitle={profileProps?.email}
                            />
                        </Toolbar>
                    </AppBar>
                )}
        </>
    );
};
