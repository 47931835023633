import React from 'react';
import { Stack, Typography } from '@mui/material';
import { DeviceDetailsByGroupName } from '../types/Summary';

export const installedUpdateDetailsCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
    <>
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" fontSize={14} flexBasis={'40%'}>
                Provider :
            </Typography>
            <Typography variant="body2" fontSize={14} fontWeight={600} pl={1} flexBasis={'60%'}>
                {groups?.installedUpdate?.provider ?? '--'}
            </Typography>
        </Stack>
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" fontSize={14} flexBasis={'40%'}>
                Name :
            </Typography>
            <Typography variant="body2" fontSize={14} fontWeight={600} pl={1} flexBasis={'60%'}>
                {groups?.installedUpdate?.name ?? '--'}
            </Typography>
        </Stack>
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" fontSize={14} flexBasis={'40%'}>
                Version :
            </Typography>
            <Typography variant="body2" fontSize={14} fontWeight={600} pl={1} flexBasis={'60%'}>
                {groups?.installedUpdate?.version ?? '--'}
            </Typography>
        </Stack>
    </>
);

export const lastAttemptedUpdateDetailsCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
    <>
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" fontSize={14} flexBasis={'30%'}>
                Provider :{' '}
            </Typography>
            <Typography variant="body2" fontSize={14} fontWeight={500} pl={2} flexBasis={'70%'}>
                {groups?.lastAttemptedUpdate?.provider ?? '--'}
            </Typography>
        </Stack>
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" fontSize={14} flexBasis={'30%'}>
                Name :{' '}
            </Typography>
            <Typography variant="body2" fontSize={14} fontWeight={500} pl={2} flexBasis={'70%'}>
                {groups?.lastAttemptedUpdate?.name ?? '--'}
            </Typography>
        </Stack>
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography variant="body1" fontSize={14} flexBasis={'30%'}>
                Version :{' '}
            </Typography>
            <Typography variant="body2" fontSize={14} fontWeight={500} pl={2} flexBasis={'70%'}>
                {groups?.lastAttemptedUpdate?.version ?? '--'}
            </Typography>
        </Stack>
    </>
);
