import React, { useCallback, useEffect, useState } from 'react';
import { getAllRoutes } from './Router/Routes';
import OktaConfig from './OktaConfig';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import SecureAuth from './components/SecureAuth';
import CustomLoginComponent from './components/CustomLogin';
import { useSelector } from 'react-redux';
import OpenSnackbar from './components/Snackbar';
import { isValidToken } from './commonUtils';
import { setSnackbarState } from './features/common/commonSlice';
import { useAppDispatch } from './redux-config/store';

type AppConfigType = {
    API_URL: string;
    OKTA_APPLICATION_ID: string;
    OKTA_ISSUER_URL: string;
};

declare global {
    interface Window {
        APP_CONFIG: AppConfigType;
    }
}

const App = (): React.JSX.Element => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const snackbarProps = useSelector((state: any) => state.commonSlices.snackbar);
    const oktaAuth = new OktaAuth(OktaConfig);
    const token = localStorage.getItem('okta-token-storage');

    /*States*/
    const [isAppAuthenticated, setIsAppAuthenticated] = useState<boolean>(false);

    /*Functions*/
    const restoreOriginalUri = useCallback((): void => {
        window.location.replace(toRelativeUrl('/', window.location.origin));
    }, []);

    const customAuthHandler = useCallback((): void => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
        if (!previousAuthState?.isAuthenticated) {
            triggerLogin();
        }
    }, [oktaAuth]);

    const getWrapperClassName = (): string => {
        if (location.pathname === '/login') {
            return 'login-content-body';
        }
        return 'content-body-wrapper';
    };

    const triggerLogin = (): void => {
        window.location.replace('/login');
    };

    const handleUserLogout = React.useCallback(async (): Promise<void> => {
        await oktaAuth.signOut();
    }, []);

    useEffect(() => {
        if (snackbarProps?.error401) {
            handleUserLogout().catch(() => {
                dispatch(setSnackbarState({ open: true, message: 'Failed to logout user', isErrorAlert: true }));
            });
        }
    }, [snackbarProps?.error401]);

    return (
        <div className={getWrapperClassName()}>
            <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
                <Routes>
                    <Route
                        path="/login"
                        element={isValidToken(token) ? <Navigate to={'/'} replace={true} /> : <CustomLoginComponent />}
                    />
                    <Route path="/login/callback" element={<LoginCallback />} />
                    <Route
                        path="*"
                        element={
                            <SecureAuth
                                isToken={isValidToken(token)}
                                onAppLoaded={(): void => setIsAppAuthenticated(true)}
                            />
                        }
                    >
                        {isAppAuthenticated && getAllRoutes()}
                    </Route>
                </Routes>
                <OpenSnackbar
                    isOpen={snackbarProps?.open}
                    message={snackbarProps?.message}
                    isErrorAlert={snackbarProps?.isErrorAlert}
                />
            </Security>
        </div>
    );
};

export default App;
