import { Typography, Box, Divider, TextField, Stack } from '@mui/material';
import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import TagBox from '../../components/TagBox';

export const AddGroupModal3 = ({
    groupNameForDevices,
    payloadForDevices,
    setPayloadForDevices,
    groupOperationType,
}: any): React.JSX.Element => {
    const handleTagsChange = (tags: string[]): void => {
        setPayloadForDevices(tags);
    };

    const getSelectedDevice = (): React.JSX.Element => (
        <TagBox tags={payloadForDevices} onTagsChange={handleTagsChange} />
    );
    return (
        <>
            <Divider />
            <DialogContent className="modal-body update-group-modal3-height">
                {groupOperationType?.id === 'addNewGroup' && (
                    <Box p={3}>
                        <TextField
                            id="filled-basic"
                            label="Group Name"
                            variant="filled"
                            className="w-100"
                            disabled
                            value={groupNameForDevices || '--'}
                        />
                    </Box>
                )}
                <Typography variant="body2" fontWeight={600} className="text-right" p={3} pb={0}>
                    Selected Devices
                </Typography>
                <Stack px={2}>
                    <Stack
                        flexDirection={'row'}
                        flexWrap={'wrap'}
                        marginY={payloadForDevices?.length === 1 ? 1 : 0}
                        maxHeight={'200px'}
                        sx={{ overflowY: 'auto', my: 1 }}
                    >
                        {getSelectedDevice()}
                    </Stack>
                    {payloadForDevices?.length > 1 && (
                        <Typography
                            variant="body1"
                            fontSize={'16px'}
                            marginTop={2}
                            sx={{ border: '2px solid #80bde0', borderRadius: '4px', padding: 1, marginBottom: 1 }}
                        >
                            <b>Note:</b> A Job will be scheduled for adding multiple devices in Group. This may take few
                            minutes to complete.
                        </Typography>
                    )}
                </Stack>
            </DialogContent>
            <Divider />
        </>
    );
};
