import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, Divider, List, ListItem, ListItemText, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import { CustomErrorHandling } from '../../components/CustomErrorHandling';
import { openInNewTab } from '../../commonUtils';

type Props = {
    data: object[];
    isFetching: boolean;
    selectedUpdateData: object;
    isErrorTargetDevice?: boolean;
    isLoading?: boolean;
};

export const DeploymentModal1 = ({
    data,
    selectedUpdateData,
    isErrorTargetDevice,
    isLoading,
}: Props): React.JSX.Element => {
    const [handleSearch, filteredData] = useSearchFilter(data);

    return (
        <>
            <Divider />
            <DialogContent className="modal-body custom-loader-wrapper-firmware-height">
                <Box m={3} className="border deployment-modal-list-min-height">
                    {!isLoading && !isErrorTargetDevice ? (
                        Object.entries(selectedUpdateData ?? {}).map(([key, value]: string[]) => (
                            <Stack
                                px={2}
                                mt={1}
                                sx={{ pb: '5px' }}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                key={key}
                            >
                                <Box>
                                    <Typography variant="h6" className="right-text">
                                        {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h6" fontSize={'16px'} fontWeight={600}>
                                        {value}
                                    </Typography>
                                </Box>
                            </Stack>
                        ))
                    ) : (
                        <CustomErrorHandling
                            responseData={Object.keys(selectedUpdateData ?? {})?.length}
                            isError={isErrorTargetDevice}
                        />
                    )}
                </Box>

                <Box className="m-24 border">
                    <Typography variant="h6" p={2} fontSize={'14px'} fontWeight={600}>
                        Target Devices
                    </Typography>
                    <Divider />
                    <Box className="target-devices-pos-relative table-header-search-box">
                        <TextField
                            id="outlined-basic"
                            placeholder="Search.."
                            variant="outlined"
                            className="w-100"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                handleSearch(event, data, 'deviceId')
                            }
                        />
                        <SearchIcon className="search-icon-wrap" />
                    </Box>
                    <Divider />

                    <List className="custom-overflow-height">
                        {!data?.length ? (
                            <CustomErrorHandling responseData={data?.length} isError={isErrorTargetDevice} />
                        ) : (
                            filteredData?.map((item: any) => (
                                <ListItem className="m-16 border" sx={{ width: 'auto' }} key={item?.deviceId}>
                                    <Stack
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        className="w-100"
                                    >
                                        <Stack
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                            justifyContent={'space-between'}
                                        >
                                            <ListItemText className="f-14 fw-400">{item?.deviceId}</ListItemText>
                                        </Stack>
                                        <Button
                                            variant="text"
                                            size="medium"
                                            onClick={(): void => openInNewTab(`/summary/deviceTabs/${item?.deviceId}`)}
                                        >
                                            View
                                        </Button>
                                    </Stack>
                                </ListItem>
                            ))
                        )}
                    </List>
                </Box>
            </DialogContent>
            <Divider />
        </>
    );
};
