import {
    Stack,
    Typography,
    TableRow,
    TableCell,
    Box,
    Checkbox,
    Collapse,
    Table,
    TableHead,
    Button,
    TableSortLabel,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertDateStringToTimestamp, dateTimeObjToString, getModalHeaders, sortData } from '../../commonUtils';
import { useCreateDeploymentMutation } from '../../features/common/commonApis';
import { setSnackbarState } from '../../features/common/commonSlice';
import { DeploymentModal1 } from '../../features/modal/DeploymentModal1';
import { DeploymentModal2 } from '../../features/modal/DeploymentModal2';
import { DeploymentModal3 } from '../../features/modal/DeploymentModal3';
import { StepperModal } from '../../features/modal/StepperModal';
import FirmwareDetailsModal from '../../features/modal/firmwareDetailsModal';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux-config/store';
import { useGetFirmwareBestUpdateMutation, useLazyGetFirmwareDetailsQuery } from '../Firmware/firmwareApis';
import { useGetTargetDevicesForDeploymentQuery } from './groupApi';
import { useGetSubgroupsMutation, useGetUpdateComplianceDataMutation } from './summaryApi';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { v4 as uuidv4 } from 'uuid';
import { FiberManualRecord } from '@mui/icons-material';
import { SortType } from './Summary';
import { Row } from '../../types/GroupsRow';
import SubgroupTable from './SubgroupTable';

const now = new Date();
const immediateDeployment = now.getTime();

export const SummaryRow = (props: {
    row: Row;
    checkedGroups: string[];
    setCheckedGroups: React.Dispatch<React.SetStateAction<string[]>>;
}): React.JSX.Element => {
    /*Additional Hooks*/
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    /*States*/
    const [open, setOpen] = useState(false);
    const [openDeploymentModal, setOpenDeploymentModal] = useState<boolean>(false);
    const [groupId, setGroupId] = useState<string>();
    const [deviceClassSubGroupId, setDeviceClassSubGroupId] = useState<string>();
    const [subGroupId, setSubGroupId] = useState<string>();
    const [subGroupModalActive, setSubGroupModalActive] = useState<boolean>();
    const [openSubGroupDeploymentModal, setOpenSubGroupDeploymentModal] = useState<boolean>();
    const [selectedUpdateId, setSelectedUpdateId] = useState('');
    const [deploymentDateTime, setDeploymentDateTime] = useState<string>('');
    const [dateTimePickerValue, setDateTimePickerValue] = useState(null);
    const [deploymentId, setDeploymentId] = useState<string>('');
    const [isScheduledDeployment, setIsScheduledDeployment] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<string>('');
    const [updatesForSubGroup, setUpdatesForSubGroup] = useState<any>('');
    const [openFirmwareDetailsModal, setOpenFirmwareDetailsModal] = useState<boolean>(false);
    const [sortType, setSortType] = useState<SortType>({});

    /*Api Calls*/
    const [getSubgroups, { data: subgroupData, isLoading: subgroupDataLoading, isError: subGroupApiError }] =
        useGetSubgroupsMutation();
    const [subRowsData, setSubRowsData] = useState(subgroupData?.data);
    const [getUpdateComplianceData, { data: updateComplianceData }] = useGetUpdateComplianceDataMutation();
    const [
        getFirmwareBestUpdate,
        { data: bestUpdateData, isLoading: isBestUpdateFetching, isError: isErrorBestUpdate },
    ] = useGetFirmwareBestUpdateMutation();
    const [createDeployment, { isLoading: isCreatingDeployment }] = useCreateDeploymentMutation();
    const [getFirmwareDetails, { data: firmwareDetails, isFetching: firmwareDetailsFetching }] =
        useLazyGetFirmwareDetailsQuery();

    /*Variables*/
    const { row } = props;
    const { checkedGroups } = props;
    const { setCheckedGroups } = props;
    const totalCount =
        row?.progressBarData?.onLatestUpdateDeviceCount +
        row?.progressBarData?.newUpdatesAvailableDeviceCount +
        row?.progressBarData?.updatesInProgressDeviceCount;

    const isValidScheduleDeployment =
        deploymentDateTime !== '' ? convertDateStringToTimestamp(deploymentDateTime) > immediateDeployment : null;

    const gid = groupId ?? subGroupId;
    const isDisableDeployment = isCreatingDeployment || (isScheduledDeployment && !isValidScheduleDeployment);
    const deploymentPayload = (): object => ({
        deploymentId,
        groupId: gid,
        startDateTime: isScheduledDeployment ? convertDateStringToTimestamp(deploymentDateTime) : immediateDeployment,
        updateId: selectedUpdateData?.[0]?.updateId ?? updatesForSubGroup,
    });
    const selectedUpdateData = bestUpdateData?.data?.filter(
        (item: { deviceClassId: string }) => item?.deviceClassId === selectedUpdateId
    );

    const {
        data: targetDeviceList,
        isFetching: targetDevicesListFetching,
        isError: isErrorTargetDevice,
    } = useGetTargetDevicesForDeploymentQuery(
        {
            groupId: gid,
            deviceClassId: deviceClassSubGroupId ?? selectedUpdateData?.[0]?.deviceClassId,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !selectedUpdateData?.[0]?.deviceClassId && !deviceClassSubGroupId,
        }
    );

    /*Handlers*/
    const onFormChange = (id: string): void => {
        setSelectedUpdateId(id);
    };

    const handleDateTimePicker = (selectedDateTimeObj: React.SetStateAction<null>): void => {
        const dateTime = dateTimeObjToString(selectedDateTimeObj);
        setDeploymentDateTime(dateTime);
        setDateTimePickerValue(selectedDateTimeObj);
    };

    const handleDeploymentDateTime = (isScheduled: boolean): void => {
        setIsScheduledDeployment(isScheduled);
    };

    const deploymentGroupHandler = async (id: string): Promise<void> => {
        const groupDeployId: string = id.replaceAll('$', '');
        setGroupId(groupDeployId);
        const groupIdPayload = {
            groupId: groupDeployId,
        };
        setOpenDeploymentModal(true);
        setOpenSubGroupDeploymentModal(false);
        setDeploymentId(uuidv4());
        await getFirmwareBestUpdate(groupIdPayload);
    };

    const handleCloseOpen = (): void => {
        setOpenDeploymentModal(false);
        setOpenSubGroupDeploymentModal(false);
        setDeploymentId('');
        setIsScheduledDeployment(false);
        setDeploymentDateTime('');
        setSelectedUpdateId('');
        setDateTimePickerValue(null);
        setCurrentStep('');
    };

    const submitHandler = async (): Promise<void> => {
        await createDeployment(deploymentPayload()).then((res: any) => {
            if (!res?.error) {
                dispatch(setSnackbarState({ open: true, message: 'Deployment Created Successfully' }));
            }
        });
    };

    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    const handleFirmwareDetailsModal = async (): Promise<void> => {
        setOpenFirmwareDetailsModal(true);

        await getFirmwareDetails({
            provider: selectedUpdateData?.[0]?.updateId?.provider ?? updatesForSubGroup?.provider,
            name: selectedUpdateData?.[0]?.updateId?.name ?? updatesForSubGroup?.name,
            version: selectedUpdateData?.[0]?.updateId?.version ?? updatesForSubGroup?.version,
        });
    };

    const handleDeploymentModalStepDisable = (): boolean => {
        if (currentStep === 'deploymentmodal3' && selectedUpdateId === '') return true;
        if (currentStep === 'deploymentmodal2' && isScheduledDeployment && deploymentDateTime === '') return true;
        return false;
    };

    const handleCloseFirmwareDetailsModal = (): void => {
        setOpenFirmwareDetailsModal(false);
    };

    const groupNavigationHandler = (groupName: string, path: string, extraParams?: unknown): void => {
        const modifiedGroupName: string = groupName.replaceAll('$', '');
        if (extraParams) {
            navigate(`/summary/groupsTab/${modifiedGroupName}/${path}`, { state: extraParams });
        } else {
            navigate(`/summary/groupsTab/${modifiedGroupName}/${path}`);
        }
    };

    const subGroupDeploymentHandler = (deviceClassId: React.SetStateAction<string | undefined>, update: any): void => {
        setDeviceClassSubGroupId(deviceClassId);
        setOpenSubGroupDeploymentModal(true);
        setOpenDeploymentModal(false);
        setSubGroupModalActive(true);
        setDeploymentId(uuidv4());
        setUpdatesForSubGroup(update);
    };

    const expandRow = async (subgroupId: string): Promise<void> => {
        setOpen(!open);
        const subGroupDeployId: string = subgroupId.replaceAll('$', '');
        setSubGroupId(subGroupDeployId);
        await getSubgroups({ groupId: row?.update }).then(async (res: any) => {
            const subgroupIdsArray: string[] = [];
            res?.data?.data.map((subGroup: { deviceClassId: string }) => {
                subgroupIdsArray.push(subGroup.deviceClassId);
            });
            if (!res.error) {
                await getUpdateComplianceData({ groupId: row?.update, subgroupIds: subgroupIdsArray });
            }
        });
    };

    //stepper modal content
    const groupDeploymentStepper = [
        {
            key: 'deploymentmodal3',
            header: 'New Updates',
            // customHeader: 'v 1.2',
            title: profileProps?.iotHubName,
            subtitle: groupId,
            component: (
                <DeploymentModal3
                    data={bestUpdateData?.data}
                    onFormChange={onFormChange}
                    selectedUpdateId={selectedUpdateId}
                    isErrorBestUpdate={isErrorBestUpdate}
                    onInfoClick={handleFirmwareDetailsModal}
                />
            ),
        },
        {
            key: 'deploymentmodal1',
            header: 'New Updates',
            // customHeader: 'v 1.2',
            title: profileProps?.iotHubName,
            subtitle: groupId,
            component: (
                <DeploymentModal1
                    data={targetDeviceList?.data}
                    isFetching={targetDevicesListFetching}
                    selectedUpdateData={selectedUpdateData?.[0]?.updateId}
                    isErrorTargetDevice={isErrorTargetDevice}
                    isLoading={isBestUpdateFetching}
                />
            ),
        },
        {
            key: 'deploymentmodal2',
            header: 'New Updates',
            // customHeader: 'v 1.2',
            title: profileProps?.iotHubName,
            subtitle: groupId,
            component: (
                <DeploymentModal2
                    onDateTimePickerChange={handleDateTimePicker}
                    deploymentDateTime={deploymentDateTime}
                    groupName={groupId}
                    deploymentId={deploymentId}
                    handleDeploymentDateTime={handleDeploymentDateTime}
                    isScheduledDeployment={isScheduledDeployment}
                    dateTimePickerValue={dateTimePickerValue}
                    isValidDeployment={isValidScheduleDeployment}
                />
            ),
        },
    ];

    const subGroupDeploymentStepper = [
        {
            key: 'deploymentmodal1',
            header: 'New Updates',
            // customHeader: 'v 1.2',
            title: profileProps?.iotHubName,
            subtitle: subGroupModalActive ? subGroupId : groupId,
            component: (
                <DeploymentModal1
                    data={targetDeviceList?.data}
                    isFetching={targetDevicesListFetching}
                    selectedUpdateData={updatesForSubGroup}
                    isErrorTargetDevice={false}
                    isLoading={false}
                />
            ),
        },
        {
            key: 'deploymentmodal2',
            header: 'New Updates',
            // customHeader: 'v 1.2',
            title: profileProps?.iotHubName,
            subtitle: gid,
            component: (
                <DeploymentModal2
                    onDateTimePickerChange={handleDateTimePicker}
                    deploymentDateTime={deploymentDateTime}
                    groupName={gid}
                    deploymentId={deploymentId}
                    handleDeploymentDateTime={handleDeploymentDateTime}
                    isScheduledDeployment={isScheduledDeployment}
                    dateTimePickerValue={dateTimePickerValue}
                    isValidDeployment={isValidScheduleDeployment}
                />
            ),
        },
    ];

    // Function to handle individual checkboxes
    const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string): void => {
        const checkedGroupsClone = checkedGroups.slice();
        if (e.target.checked) {
            //If the checkbox is checked then push it inside the checkedGroupsClone array
            checkedGroupsClone.push(id);
        } else {
            const uncheckedIndex = checkedGroupsClone.indexOf(id);
            if (uncheckedIndex !== -1) {
                checkedGroupsClone.splice(uncheckedIndex, 1);
            }
        }
        //Set the selected groups to checked groups
        setCheckedGroups(checkedGroupsClone);
    };

    // Function to check if an individual checkbox is checked
    const isChecked = (id: string, deploymentLength: number): boolean => {
        if (deploymentLength !== 0) {
            return false;
        }
        return checkedGroups.includes(id);
    };

    const handleDataSort = (key: string, type: string): void => {
        const sortedData = sortData(subRowsData, key, type);
        setSubRowsData(sortedData);
        setSortType(type === 'desc' ? { [key]: 'asc' } : { [key]: 'desc' });
    };

    /*Effects*/
    useEffect(() => {
        const filteredSubRows = subgroupData?.data?.filter((subRow: any) => subRow.deviceCount);
        setSubRowsData(filteredSubRows);
    }, [subgroupData?.data]);

    return (
        <React.Fragment>
            <TableRow>
                <Tooltip
                    title={
                        row?.deployments > 0 ? 'This Group has an active deployment. So, this can not be deleted.' : ''
                    }
                >
                    <TableCell padding={'checkbox'}>
                        <Checkbox
                            disabled={row?.deployments > 0 || row?.device > 0}
                            checked={isChecked(row?.update, row?.deployments)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => checkboxHandler(e, row?.update)}
                        />
                    </TableCell>
                </Tooltip>
                <TableCell scope="row" data-cy="group-tabs-navigation" className="active-link-on-hover">
                    <Typography
                        variant="body2"
                        className="text-primary text-clickable"
                        onClick={(): void => groupNavigationHandler(row?.update, 'overview')}
                    >
                        {row?.update}
                    </Typography>
                </TableCell>
                <TableCell
                    sx={{ textAlign: 'right', pr: '14.7%' }}
                    data-cy="group-device-count"
                    className="active-link-on-hover"
                >
                    <Typography
                        variant="body2"
                        className="text-primary text-clickable"
                        onClick={(): void =>
                            navigate('/deviceTable', { state: { devicesStatus: `${row?.update}`.replaceAll('$', '') } })
                        }
                    >
                        {row?.device ?? '--'}
                    </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', paddingTop: '5px' }}>
                    {/* progressbar styles starts */}
                    <Stack>
                        <Stack flexDirection={'row'} alignItems={'center'} className="value">
                            <Typography
                                variant="h6"
                                sx={{
                                    width: `${Math.ceil(
                                        (row?.progressBarData?.newUpdatesAvailableDeviceCount / totalCount) * 100
                                    )}%`,
                                }}
                                className="value-text"
                            >
                                {row?.progressBarData?.newUpdatesAvailableDeviceCount || ''}
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    width: `${Math.ceil(
                                        (row?.progressBarData?.updatesInProgressDeviceCount / totalCount) * 100
                                    )}%`,
                                }}
                                className="value-text"
                            >
                                {row?.progressBarData?.updatesInProgressDeviceCount || ''}
                            </Typography>

                            <Typography
                                variant="h6"
                                sx={{
                                    width: `${Math.ceil(
                                        (row?.progressBarData?.onLatestUpdateDeviceCount / totalCount) * 100
                                    )}%`,
                                }}
                                className="value-text"
                            >
                                {row?.progressBarData?.onLatestUpdateDeviceCount || ''}
                            </Typography>
                        </Stack>
                        {row?.progressBarData ? (
                            <>
                                <Box
                                    className="progress-bar"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(): void => groupNavigationHandler(row?.update, 'current-update')}
                                >
                                    <Tooltip title="Outdated">
                                        <Box
                                            className="brown"
                                            sx={{
                                                width: `${Math.ceil(
                                                    (row?.progressBarData?.newUpdatesAvailableDeviceCount /
                                                        totalCount) *
                                                        100
                                                )}%`,
                                            }}
                                        ></Box>
                                    </Tooltip>
                                    <Tooltip title="Updates in progress">
                                        <Box
                                            className="mustard"
                                            sx={{
                                                width: `${Math.ceil(
                                                    (row?.progressBarData?.updatesInProgressDeviceCount / totalCount) *
                                                        100
                                                )}%`,
                                            }}
                                        ></Box>
                                    </Tooltip>
                                    <Tooltip title="On Latest">
                                        <Box
                                            className="green"
                                            sx={{
                                                width: `${Math.ceil(
                                                    (row?.progressBarData?.onLatestUpdateDeviceCount / totalCount) * 100
                                                )}%`,
                                            }}
                                        ></Box>
                                    </Tooltip>
                                </Box>
                                <Typography align="center" variant="inherit">
                                    {!row?.device && '--'}
                                </Typography>
                            </>
                        ) : (
                            <Typography align="center" variant="inherit">
                                Loading...
                            </Typography>
                        )}
                    </Stack>
                    {/* progressbar styles ends*/}
                </TableCell>
                <TableCell>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Tooltip
                            title={!row?.isDeployable ? 'No update is available to deploy.' : `${row?.status}`}
                            placement="top"
                        >
                            <span>
                                <Button
                                    variant="outlined"
                                    sx={{ cursor: 'pointer', borderRadius: '30px', mr: 1 }}
                                    onClick={(): Promise<void> => deploymentGroupHandler(row?.update)}
                                    disabled={!row?.isDeployable}
                                    data-cy="deployment-button"
                                >
                                    Deploy
                                </Button>
                            </span>
                        </Tooltip>
                        <Button
                            variant={open ? 'contained' : 'outlined'}
                            className="btn-rounded"
                            sx={{
                                cursor: 'pointer',
                                borderRadius: '50%',
                                mr: 1,
                                minWidth: 'auto !important',
                                padding: '6px !important',
                            }}
                            data-cy="expand-groups-row-dropdown"
                            onClick={(): Promise<void> => expandRow(row?.update)}
                        >
                            {' '}
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit className="box-border-added">
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={'15%'}>
                                            Model
                                            <TableSortLabel
                                                direction={sortType['deviceModelName']}
                                                active={Object.hasOwn(sortType, 'deviceModelName')}
                                                onClick={(): void =>
                                                    handleDataSort('deviceModelName', sortType['deviceModelName'])
                                                }
                                                disabled={!subRowsData}
                                            ></TableSortLabel>
                                        </TableCell>
                                        <TableCell width={'16.8%'} sx={{ textAlign: 'right', paddingRight: '8px' }}>
                                            # of Devices
                                            <TableSortLabel
                                                direction={sortType['deviceCount']}
                                                active={Object.hasOwn(sortType, 'deviceCount')}
                                                onClick={(): void =>
                                                    handleDataSort('deviceCount', sortType['deviceCount'])
                                                }
                                                disabled={!subRowsData}
                                            ></TableSortLabel>
                                        </TableCell>
                                        <TableCell width={'15.2%'} sx={{ textAlign: 'right' }}>
                                            Latest Version
                                        </TableCell>
                                        <TableCell width={'47%'} sx={{ textAlign: 'center' }}>
                                            <Stack
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                            >
                                                <span className="badge-wrapper">
                                                    <FiberManualRecord
                                                        fontSize="small"
                                                        className="not-compliance-text-color"
                                                    />{' '}
                                                    Outdated
                                                </span>
                                                <span className="badge-wrapper">
                                                    <FiberManualRecord
                                                        fontSize="small"
                                                        className="inprogress-text-color"
                                                    />{' '}
                                                    Updates in progress
                                                </span>
                                                <span className="badge-wrapper">
                                                    <FiberManualRecord
                                                        fontSize="small"
                                                        className="compliance-text-color"
                                                    />{' '}
                                                    On Latest
                                                </span>
                                            </Stack>
                                        </TableCell>
                                        <TableCell width={'6%'}></TableCell>
                                    </TableRow>
                                </TableHead>
                                {SubgroupTable(
                                    row,
                                    updateComplianceData,
                                    subgroupDataLoading,
                                    subRowsData,
                                    subGroupApiError,
                                    subGroupDeploymentHandler,
                                    groupNavigationHandler
                                )}
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <StepperModal
                modalContent={groupDeploymentStepper}
                closeModal={(): void => handleCloseOpen()}
                isOpen={openDeploymentModal}
                submitHandler={submitHandler}
                getCurrentStep={getCurrentStep}
                submitButton={isScheduledDeployment ? 'Schedule Deployment' : 'Deploy'}
                isDisabled={handleDeploymentModalStepDisable() || isDisableDeployment}
                isSendingResponse={isCreatingDeployment}
                className="common-modal-view"
            />
            <StepperModal
                modalContent={subGroupDeploymentStepper}
                closeModal={(): void => handleCloseOpen()}
                isOpen={openSubGroupDeploymentModal}
                submitHandler={submitHandler}
                submitButton={isScheduledDeployment ? 'Schedule Deployment' : 'Deploy'}
                isDisabled={isDisableDeployment}
                isSendingResponse={isCreatingDeployment}
                className="common-modal-view"
            />

            {/* Firmware details modal */}
            <FirmwareDetailsModal
                data={firmwareDetails?.data}
                handleOpenModal={openFirmwareDetailsModal}
                handleCloseModal={handleCloseFirmwareDetailsModal}
                modalHeaders={getModalHeaders}
                isLoading={firmwareDetailsFetching}
            />

            {/* snackbar */}
        </React.Fragment>
    );
};
