import React, { useEffect } from 'react';

type DebouncedFunc<T extends any[]> = (...args: T) => void;

/**
 * The `useDebounce` function is a custom hook in TypeScript that debounces a given function and
 * returns the debounced function along with the debounced value.
 * @param func - The `func` parameter is the function that you want to debounce. It can accept any
 * number of arguments of any type.
 * @param {number} [milliseconds] - The `milliseconds` parameter is an optional number that specifies
 * the delay in milliseconds before the debounced function is executed. If not provided, it defaults to
 * 500 milliseconds.
 * @param {any} [initialValue] - The `initialValue` parameter is an optional value that represents the
 * initial value of the debounced function. It is used to set the initial value of the `debouncedValue`
 * state variable. If no initial value is provided, the `debouncedValue` will be `undefined` initially.
 * @returns The function `useDebounce` returns an array with two elements. The first element is the
 * debounced function `debouncedFunc`, which is a function that will execute the provided `func` after
 * a specified delay. The second element is the `debouncedValue`, which is the most recent value passed
 * to the debounced function.
 */
export const useDebounce = <T extends any[]>(
    milliseconds?: number,
    initialValue?: any
): [DebouncedFunc<T>, T | undefined] => {
    const time = milliseconds ?? 500;
    const timer = React.useRef<NodeJS.Timeout>();
    const [debouncedValue, setDebouncedValue] = React.useState<T | undefined>(undefined);

    useEffect(() => {
        const debouncedTimer = setTimeout(() => setDebouncedValue(initialValue), milliseconds ?? 500);

        return () => {
            clearTimeout(debouncedTimer);
        };
    }, [initialValue, milliseconds]);

    const debouncedFunc = (func: any, ...args: T): any => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            if (func) func(...args);
        }, time);
    };

    return [debouncedFunc as any, debouncedValue];
};
