import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { DeploymentViewData } from '../../types';

type Props = {
    deploymentData: DeploymentViewData;
    formattedDateTime: string;
};

export const DeploymentViewList = (props: Props): React.JSX.Element => (
    <List
        sx={{
            border: '1px solid rgba(66, 78, 84, 0.12)',
            borderRadius: '4px',
            padding: '16px',
        }}
    >
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Provider</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {props?.deploymentData?.provider}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Name</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>{props?.deploymentData?.name}</ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Version</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {props?.deploymentData?.version}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Descriptive label</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {props?.deploymentData?.friendlyName ?? '--'}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Start time</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {props?.formattedDateTime || 'N/A'}
            </ListItemText>
        </ListItem>
        <ListItem className="d-flex justify-content-between align-items-center">
            <ListItemText>Cloud rollback</ListItemText>
            <ListItemText sx={{ display: 'flex', justifyContent: 'end' }}>
                {props?.deploymentData?.isCloudInitiatedRollback ? 'Yes' : 'No'}
            </ListItemText>
        </ListItem>
    </List>
);
