import React from 'react';
import { Configuration } from '@brightlayer-ui/icons-mui';
import { EmptyState } from '@brightlayer-ui/react-components';
const NewUserBackground = (): React.JSX.Element => (
    <EmptyState
        icon={<Configuration fontSize={'inherit'} />}
        title={''}
        description={'Please select and save an IotHub associated with an adopter from the dropdown list.'}
    />
);

export default NewUserBackground;
