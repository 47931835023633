import { CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

type Props = {
    isLoading: boolean;
    isDataLength: boolean;
    noDataMsg?: string;
    component: React.JSX.Element;
};

const Fallback = (props: Props): React.JSX.Element => {
    const Component = props?.component;
    const handleFallback = (): React.JSX.Element => {
        if (props?.isLoading) {
            return (
                <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                    <CircularProgress thickness={5} size={50} />
                </Stack>
            );
        } else if (!props?.isDataLength) {
            return (
                <Stack height={'100%'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">{props?.noDataMsg ?? 'No Data Found'}</Typography>
                </Stack>
            );
        }
        return Component;
    };

    return handleFallback();
};

export default Fallback;
