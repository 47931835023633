import { Avatar, Tooltip, Typography } from '@mui/material';

import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import React from 'react';

export const DeviceConnectionStatus = ({ deviceStatus }: any): React.JSX.Element => {
    switch (deviceStatus) {
        case 'Connected':
            return (
                <Tooltip title="Connected" placement="left-start">
                    <Avatar className="table-avatar-icon device-online-color">
                        <CloudDoneIcon />
                    </Avatar>
                </Tooltip>
            );
        case 'Disconnected':
            return (
                <Tooltip title="Disconnected" placement="left-start">
                    <Avatar className="table-avatar-icon device-offline-color">
                        <CloudOffIcon />
                    </Avatar>
                </Tooltip>
            );
        default:
            return (
                <Typography variant="h6" className="right-text">
                    {'--'}
                </Typography>
            );
    }
};
