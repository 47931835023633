import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../redux-config/store';
import { setSnackbarState } from '../../features/common/commonSlice';

//MUI imports
import {
    Typography,
    Box,
    Card,
    CardContent,
    Button,
    List,
    ListItem,
    Stack,
    Divider,
    CircularProgress,
} from '@mui/material';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { Devices } from '@mui/icons-material';

//APIs imports
import {
    useGetDeviceByGroupNameQuery,
    useGetDeviceStaticsByGroupNameQuery,
    useGetDonutChartForUpdatesAvailableQuery,
    useGetTargetDevicesForDeploymentQuery,
    useLazyDownloadDeviceCSVByGroupNameQuery,
} from './groupApi';
import { useCreateDeploymentMutation } from '../../features/common/commonApis';
import { useGetFirmwareBestUpdateMutation, useLazyGetFirmwareDetailsQuery } from '../Firmware/firmwareApis';

//Components imports
import { DeploymentModal1 } from '../../features/modal/DeploymentModal1';
import { DeploymentModal2 } from '../../features/modal/DeploymentModal2';
import { StepperModal } from '../../features/modal/StepperModal';
import { DonutChart } from '../../components/DonutChart';
import { DeploymentModal3 } from '../../features/modal/DeploymentModal3';
import FirmwareDetailsModal from '../../features/modal/firmwareDetailsModal';
import CustomTable from '../../components/CustomTable';
import { CustomWaitingState } from '../../components/CustomWaitingState';
import {
    installedUpdateDetailsCell,
    lastAttemptedUpdateDetailsCell,
} from '../../components/InstalledUpdateDetailsCell';
import { ChartConstants } from '../../components/Constant/ChartConstants';

import { DonutChartSkeleton, DeviceDetailsByGroupName, GroupName, GroupDetails } from '../../types';
import {
    convertDateStringToTimestamp,
    getModalHeaders,
    downloadFileFromLink,
    dateTimeObjToString,
    getLocalTimeStamp,
} from '../../commonUtils';
import { v4 as uuidv4 } from 'uuid';

/* The below code is using the `window.scrollTo` method to scroll the window to the top of the page
with a smooth animation effect. The `top` property is set to 0 to scroll to the top of the page, and
the `behavior` property is set to 'smooth' to enable smooth scrolling. */
window.scrollTo({
    top: 0,
    behavior: 'smooth',
});

/* Chart Props*/
const customLegendOptions = {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: -50,
    y: 70,
};

const subtitleOptions = {
    x: -126,
    y: 10,
};

/* The code below snippet is written in TypeScript and React. It creates a new Date object representing
the current date and time. It then retrieves the timestamp of the current date and time using the
`getTime()` method. */
const now = new Date();
const immediateDeployment = now.getTime();

/*Main Component*/
export const GroupOverview = (props: GroupName): React.JSX.Element => {
    /*Additional Hooks*/
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { container } = useParams();

    /*States*/
    const [openDeploymentModal, setOpenDeploymentModal] = useState(false);
    const [createDeployment, { isLoading: isCreatingDeployment }] = useCreateDeploymentMutation();
    const [selectedUpdateId, setSelectedUpdateId] = useState('');
    const [customWaitingState, setCustomWaitingState] = useState<boolean>(false);
    const [dateTimePickerValue, setDateTimePickerValue] = useState();
    const [deploymentDateTime, setDeploymentDateTime] = useState<string>('');
    const [deploymentId, setDeploymentId] = useState<string>('');
    const [isScheduledDeployment, setIsScheduledDeployment] = useState<boolean>(false);
    const [openProviderDetailModal, setOpenProviderDetailModal] = useState(false);
    const [currentStep, setCurrentStep] = useState<string>('');

    /*Api Calls*/
    const {
        data: deviceListData,
        isFetching: isDeviceListFetched,
        isError: isDeviceListFetchedError,
    } = useGetDeviceByGroupNameQuery(props.groupName, {
        refetchOnMountOrArgChange: true,
    });
    const [getFirmwareDetails, { data: firmwareDetails, isFetching: firmwareDetailsFetching }] =
        useLazyGetFirmwareDetailsQuery();

    // API call to get device statics by group name.
    const { data: groupDetails } = useGetDeviceStaticsByGroupNameQuery(props?.groupName, {
        refetchOnMountOrArgChange: true,
    });
    //API call for compliance update by groupId.
    const { data: getDonutChartForUpdatesAvailable, isFetching: isDonutChartForUpdatesAvailableFetched } =
        useGetDonutChartForUpdatesAvailableQuery(props.groupName);
    const [getFirmwareBestUpdate, { data: updatesData }] = useGetFirmwareBestUpdateMutation();
    const selectedUpdateData = updatesData?.data?.filter(
        (item: { deviceClassId: string }) => item?.deviceClassId === selectedUpdateId
    );
    const {
        data: targetDeviceList,
        isFetching: targetDevicesListFetching,
        isError: isErrorForTargetDevice,
    } = useGetTargetDevicesForDeploymentQuery(
        {
            groupId: props?.groupName,
            deviceClassId: selectedUpdateData?.[0]?.deviceClassId,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !selectedUpdateData?.[0]?.deviceClassId,
        }
    );
    const [
        downloadDeviceCSV,
        { currentData: deviceCSVData, isSuccess: deviceCSVDownloadSuccess, isError: deviceCSVDownloadError },
    ] = useLazyDownloadDeviceCSVByGroupNameQuery();

    /*Variables*/
    const groupNameDetails: GroupDetails = groupDetails;
    const getDeviceDetailsByGroupName = deviceListData?.data;
    const isValidScheduleDeployment =
        deploymentDateTime !== '' ? convertDateStringToTimestamp(deploymentDateTime) > immediateDeployment : true;

    /* The code below is defining a data structure for a donut chart.
    It is creating a `DonutChartSkeleton` object named `donutChartDataForUpdatesAvailable` with
    properties such as `name`, `totalDeviceCount`, and `data`. The `data` property is an array of
    objects representing different categories in the donut chart, each with a `name`, `y` value,
    `color`, and `legendTooltip`. The values for `y`, `color`, and `legendTooltip` are being
    retrieved from a `getDonutChartForUpdatesAvailable */
    const donutChartDataForUpdatesAvailable: DonutChartSkeleton = {
        name: 'Data',
        totalDeviceCount: getDonutChartForUpdatesAvailable?.data?.totalDeviceCount,
        data: [
            {
                name: ChartConstants.CHART_COMPLIANCE_LEGEND_UPDATE_IN_PROGRESS,
                y: getDonutChartForUpdatesAvailable?.data?.updatesInProgressDeviceCount,
                color: '#F2D34E',
                legendTooltip: ChartConstants.CHART_COMPLIANCE_TOOLTIP_UPDATE_IN_PROGRESS,
            },
            {
                name: ChartConstants.CHART_COMPLIANCE_LEGEND_UPDATE_AVAILABLE,
                y: getDonutChartForUpdatesAvailable?.data?.newUpdatesAvailableDeviceCount,
                color: '#F68B42',
                legendTooltip: ChartConstants.CHART_COMPLIANCE_TOOLTIP_UPDATE_AVAILABLE,
            },
            {
                name: ChartConstants.CHART_COMPLIANCE_LEGEND_UPDATE_LATEST_UPDATE,
                y: getDonutChartForUpdatesAvailable?.data?.onLatestUpdateDeviceCount,
                color: '#57C141',
                legendTooltip: ChartConstants.CHART_COMPLIANCE_TOOLTIP_UPDATE_LATEST_UPDATE,
            },
        ],
    };

    /* Handlers*/

    /* The code below snippet consists of functions related to handling deployment, form changes, date/time
     * pickers, deployment payload creation, submission, CSV download, and firmware details modal opening
     * in a TypeScript React application.
     */
    const handleClickDeployment = async (): Promise<void> => {
        const deployPayload = {
            groupId: groupNameDetails?.data?.groupId ?? props?.groupName,
        };
        setOpenDeploymentModal(true);
        setDeploymentId(uuidv4());
        await getFirmwareBestUpdate(deployPayload);
    };

    const handleCloseDeployment = (): void => {
        setOpenDeploymentModal(false);
        setDeploymentId('');
        setIsScheduledDeployment(false);
        setDeploymentDateTime('');
        setSelectedUpdateId('');
    };

    const onFormChange = (id: string): void => {
        setSelectedUpdateId(id);
    };

    const handleDateTimePicker = (selectedDateTimeObj: React.SetStateAction<undefined>): void => {
        const dateTime = dateTimeObjToString(selectedDateTimeObj);
        setDeploymentDateTime(dateTime);
        setDateTimePickerValue(selectedDateTimeObj);
    };

    const handleDeploymentDateTime = (isScheduled: boolean): void => {
        setIsScheduledDeployment(isScheduled);
    };

    const deploymentPayload = (): object => ({
        deploymentId,
        groupId: groupNameDetails?.data?.groupId,
        startDateTime: isScheduledDeployment ? convertDateStringToTimestamp(deploymentDateTime) : immediateDeployment,
        updateId: selectedUpdateData?.[0]?.updateId,
    });

    const submitHandler = async (): Promise<void> => {
        await createDeployment(deploymentPayload())
            .then((res: any) => {
                if (!res?.error) {
                    dispatch(setSnackbarState({ open: true, message: 'Deployment Created Successfully!' }));
                }
            })
            .catch((res) => {
                console.error(res?.error?.data?.errorMessage?.[0]);
            });
    };

    const downloadCSVHandler = async (): Promise<void> => {
        setCustomWaitingState(true);
        await downloadDeviceCSV({ groupId: props?.groupName });
    };

    const handleFirmwareDetailsModal = async (): Promise<void> => {
        setOpenProviderDetailModal(true);
        const { provider, name, version } = selectedUpdateData[0].updateId;
        await getFirmwareDetails({ provider, name, version });
    };

    const classHandlerForDeploymentStatus = (deployStatus: string | undefined): string => {
        switch (deployStatus) {
            case 'Succeeded':
                return 'succeeded-tag';
            case 'Canceled':
            case 'Failed':
                return 'cancel-chip';
            case 'InProgress':
                return 'in-progress-chip';
            default:
                return 'bg-white text-black';
        }
    };

    const customClassHandlerForDeviceList = (): string => {
        if (getDeviceDetailsByGroupName?.length === 0 || isDeviceListFetchedError) return 'group-overview-list-height';
        return 'group-overview-single-data-height';
    };

    /*Stepper */

    /* The code below defines an array named `stepper` which contains objects representing different
   steps in a stepper component. Each object in the array has properties like `key`, `header`,
   `title`, `subtitle`, and `component`. The `component` property contains JSX elements representing
   different deployment modals (`DeploymentModal1`, `DeploymentModal2`, `DeploymentModal3`) with
   various props being passed to them. These modals seem to be related to displaying and managing
   new updates for a specific IoT device group. The code suggests a multi-step process for handling
   deployment updates, with each step having different */
    const stepper = [
        {
            key: 'deploymentmodal3',
            header: 'New Updates',
            title: props?.profileData?.iotHubName,
            subtitle: groupNameDetails?.data?.groupId,
            component: (
                <DeploymentModal3
                    data={updatesData?.data}
                    onFormChange={onFormChange}
                    selectedUpdateId={selectedUpdateId}
                    onInfoClick={handleFirmwareDetailsModal}
                />
            ),
        },
        {
            key: 'deploymentmodal1',
            header: 'New Updates',
            title: props?.profileData?.iotHubName,
            subtitle: groupNameDetails?.data?.groupId,
            component: (
                <DeploymentModal1
                    data={targetDeviceList?.data}
                    isFetching={targetDevicesListFetching}
                    selectedUpdateData={selectedUpdateData?.[0]?.updateId}
                    isErrorTargetDevice={isErrorForTargetDevice}
                    isLoading={targetDevicesListFetching}
                />
            ),
        },
        {
            key: 'deploymentmodal2',
            header: 'New Updates',
            title: props?.profileData?.iotHubName,
            subtitle: groupNameDetails?.data?.groupId,
            component: (
                <DeploymentModal2
                    onDateTimePickerChange={handleDateTimePicker}
                    deploymentDateTime={deploymentDateTime}
                    groupName={groupNameDetails?.data?.groupId}
                    deploymentId={deploymentId}
                    handleDeploymentDateTime={handleDeploymentDateTime}
                    isScheduledDeployment={isScheduledDeployment}
                    dateTimePickerValue={dateTimePickerValue}
                    isValidDeployment={isValidScheduleDeployment}
                />
            ),
        },
    ];

    /*Stepper Functions

     * The function `getCurrentStep` sets the current step to the provided value.
     * @param {string} step - The parameter "step" is a string that represents the current step.
     */
    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    /**
     * The function `handleDeploymentModalStepDisable` returns true if certain conditions are met,
     * otherwise it returns false.
     * @returns The function `handleDeploymentModalStepDisable` returns a boolean value.
     */
    const handleDeploymentModalStepDisable = (): boolean => {
        if (currentStep === 'deploymentmodal3' && selectedUpdateId === '') return true;
        if (currentStep === 'deploymentmodal2' && isScheduledDeployment && deploymentDateTime === '') return true;
        return false;
    };

    const handleProviderDetailClose = (): void => {
        setOpenProviderDetailModal(false);
    };

    /*Table Cell Handlers*/
    /**
     * The below functions are used to render different cells in a table for displaying device details in
     * a React TypeScript application.
     * @param {DeviceDetailsByGroupName} groups - The functions `groupNameCell`, `deploymentStatusCell`,
     * `lastAttemptedUpdateDetailsCell`, and `onLatestUpdateCell` are designed to render different parts of a
     * device group's details in a UI component. The `groups` parameter represents an object containing
     * details about a device group, such as `
     */
    const groupNameCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
        <Typography
            variant="body2"
            className="text-primary text-clickable"
            onClick={(): void =>
                navigate(`/${container}/groupOverviewDevice/${props.groupName}/${groups?.deviceId}`, {
                    state: { groupName: props?.groupName },
                })
            }
        >
            {groups?.deviceId}
        </Typography>
    );

    const deploymentStatusCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
        <ListItemTag
            className={classHandlerForDeploymentStatus(groups?.deploymentStatus)}
            label={groups?.deploymentStatus === null ? '--' : `${groups?.deploymentStatus}`}
        />
    );

    const onLatestUpdateCell = (groups: DeviceDetailsByGroupName): React.JSX.Element => (
        <ListItemTag
            label={groups?.onLatestUpdate ? 'Yes' : 'No'}
            className={groups?.onLatestUpdate ? 'succeeded-tag' : 'cancel-chip'}
        />
    );

    /*Table Headers*/
    /* The below code is defining an array of column objects using React's useMemo hook. Each column
   object represents a column in a table and includes properties such as header (column title),
   accessor (data key), cell (function to render cell content), isDebounce (boolean flag for
   debouncing), and width (column width). The useMemo hook is used to memoize the columns array so
   that it is only recalculated when the dependencies, in this case the getDeviceDetailsByGroupName
   function, change. */

    const columns = React.useMemo(
        () => [
            {
                header: 'Device Id',
                accessor: 'deviceId',
                cell: groupNameCell,
                isDebounce: true,
                width: '20%',
            },
            {
                header: 'Deployment status',
                isDebounce: true,
                cell: deploymentStatusCell,
                width: '20%',
            },
            {
                header: 'Installed update',
                isDebounce: true,
                cell: installedUpdateDetailsCell,
                width: '20%',
            },
            {
                header: 'Last attempted update',
                isDebounce: true,
                cell: lastAttemptedUpdateDetailsCell,
                width: '20%',
            },
            {
                header: 'On latest update',
                isDebounce: true,
                cell: onLatestUpdateCell,
                width: '20%',
            },
        ],
        [getDeviceDetailsByGroupName]
    );

    /*Effects*/
    useEffect(() => {
        if (deviceCSVDownloadSuccess && deviceCSVData?.data) {
            downloadFileFromLink(deviceCSVData?.data, 'deviceCSV');
            setCustomWaitingState(false);
        } else if (deviceCSVDownloadError) {
            setCustomWaitingState(false);
        }
    }, [deviceCSVDownloadSuccess, deviceCSVDownloadError, deviceCSVData]);

    return (
        <>
            <Box p={'24px 24px 22px 114px'}>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Stack flexBasis={'49%'}>
                        <Card sx={{ m: 0, height: '100%' }} className="custom-card">
                            <Box className="card-header">
                                <Stack flexDirection={'row'} alignItems={'center'} data-cy="group-details-header">
                                    <Typography variant="h6">Details</Typography>
                                </Stack>
                            </Box>
                            <CardContent className="p-0">
                                <List data-cy="group-details-list">
                                    <ListItem sx={{ justifyContent: 'space-between', padding: '16px' }}>
                                        <Typography fontSize={'16px'} fontWeight={600} className="text-content">
                                            Group Name
                                        </Typography>
                                        <Typography
                                            textAlign={'end'}
                                            fontSize={'16px'}
                                            fontWeight={400}
                                            className="text-content"
                                        >
                                            {groupNameDetails?.data?.groupId || '--'}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                    <ListItem sx={{ justifyContent: 'space-between', padding: '16px' }}>
                                        <Typography fontSize={'16px'} fontWeight={600} className="text-content">
                                            Group Type
                                        </Typography>
                                        <Typography
                                            fontSize={'16px'}
                                            fontWeight={400}
                                            textAlign={'end'}
                                            className="text-content"
                                        >
                                            {groupNameDetails?.data?.groupType || 'IoT Hub tag based group'}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                    <ListItem sx={{ justifyContent: 'space-between', padding: '16px' }}>
                                        <Typography fontSize={'16px'} fontWeight={600} className="text-content">
                                            Created
                                        </Typography>
                                        <Typography
                                            textAlign={'end'}
                                            fontSize={'16px'}
                                            fontWeight={400}
                                            className="text-content"
                                        >
                                            {groupNameDetails?.data?.createdAt !== null
                                                ? getLocalTimeStamp(groupNameDetails?.data?.createdAt)
                                                : '--'}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                    <ListItem sx={{ justifyContent: 'space-between', padding: '16px' }}>
                                        <Typography fontSize={'16px'} fontWeight={600} className="text-content">
                                            Status
                                        </Typography>
                                        <Stack flexDirection={'row'} alignItems={'center'}>
                                            <Typography textAlign={'end'}>
                                                {groupNameDetails?.data?.status || '--'}
                                            </Typography>
                                        </Stack>
                                    </ListItem>
                                    <Stack flexDirection={'row'} justifyContent={'flex-end'} m={2}>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            sx={{ borderRadius: '50px' }}
                                            onClick={handleClickDeployment}
                                            disabled={!groupNameDetails?.data?.isDeployable}
                                            data-cy="update-group-details"
                                        >
                                            Deploy
                                        </Button>
                                    </Stack>
                                </List>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack flexBasis={'49%'}>
                        <Card sx={{ m: 0, height: '100%' }} className="custom-card group-overview-card-height">
                            <Box className="card-header">
                                <Stack flexDirection={'row'} alignItems={'center'} data-cy="update-compliance-chart">
                                    <Typography variant="h6">Update Compliance for Group</Typography>
                                </Stack>
                            </Box>
                            <CardContent
                                sx={{ height: '100%' }}
                                className="update-compliance-center-loader custom-highchart-height"
                            >
                                <Stack
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    height={'100%'}
                                >
                                    <Box className="chart" sx={{ height: '100%' }}>
                                        {isDonutChartForUpdatesAvailableFetched ? (
                                            <CircularProgress
                                                sx={{
                                                    position: 'absolute',
                                                    top: '35%',
                                                    left: '48%',
                                                }}
                                                thickness={5}
                                                size={50}
                                            />
                                        ) : (
                                            <DonutChart
                                                data={donutChartDataForUpdatesAvailable}
                                                size={'90%'}
                                                additionalLegendOptions={customLegendOptions}
                                                subtitleOptions={subtitleOptions}
                                            />
                                        )}
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Stack>
                <Card className="mt-24" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        data-cy="device-details-header"
                    >
                        <Box sx={{ borderBottom: '0 !important', paddingLeft: '16px' }}>
                            <Typography variant="h6" fontSize={'16px'} fontWeight={600} className="text-primary">
                                Device List ({getDeviceDetailsByGroupName?.length ?? 0})
                            </Typography>
                        </Box>
                        <Stack flexDirection={'row'} alignItems={'center'} m={2}>
                            <Button
                                variant="text"
                                size="medium"
                                onClick={(): void => navigate(`/${container}/overviewList/${props?.groupName}`)}
                                disabled={getDeviceDetailsByGroupName?.length < 3 || isDeviceListFetched}
                                data-cy="view-more-device-details"
                            >
                                View more
                            </Button>
                            <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                disabled={!getDeviceDetailsByGroupName?.length}
                                onClick={downloadCSVHandler}
                                data-cy="export-device-list-as-CSV"
                            >
                                Export as CSV
                            </Button>
                        </Stack>
                    </Stack>
                    <Divider />
                    <CardContent
                        className="card-content group-overview-list-height"
                        sx={{ p: 0 }}
                        data-cy="device-list-content"
                    >
                        <Divider />
                        <CustomTable
                            total={getDeviceDetailsByGroupName?.length}
                            keyToTraverse="deviceId"
                            data={getDeviceDetailsByGroupName?.slice(0, 2)}
                            wrapperClass={customClassHandlerForDeviceList()}
                            headers={columns}
                            containerClass="custom-data-table"
                            isLoading={isDeviceListFetched}
                            noDataFoundTitle={
                                !getDeviceDetailsByGroupName || isDeviceListFetchedError
                                    ? 'Something went wrong !! No device(s) found'
                                    : 'No device(s) found'
                            }
                            noDataFoundIcon={<Devices fontSize="inherit" />}
                        />
                    </CardContent>
                </Card>
                {getDeviceDetailsByGroupName?.length && !isDeviceListFetched ? (
                    <Stack
                        bgcolor={'white'}
                        border={'1px solid rgba(66, 78, 84, 0.12)'}
                        borderRadius={'4px'}
                        sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 0, mx: '-1px' }}
                    >
                        <Button
                            fullWidth
                            variant="text"
                            disabled={getDeviceDetailsByGroupName?.length < 3}
                            size="medium"
                            color="primary"
                            data-cy="view-full-list-button"
                            sx={{
                                cursor: 'pointer',
                                background: 'none',
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                            onClick={(): void => navigate(`/${container}/overviewList/${props?.groupName}`)}
                        >
                            ...View full list
                        </Button>
                    </Stack>
                ) : null}
            </Box>

            <StepperModal
                modalContent={stepper}
                closeModal={handleCloseDeployment}
                isOpen={openDeploymentModal}
                submitHandler={submitHandler}
                submitButton={isScheduledDeployment ? 'Schedule Deployment' : 'Deploy'}
                getCurrentStep={getCurrentStep}
                isDisabled={handleDeploymentModalStepDisable() || isCreatingDeployment}
                isSendingResponse={isCreatingDeployment}
                className="common-modal-view"
            />
            <FirmwareDetailsModal
                data={firmwareDetails?.data}
                handleOpenModal={openProviderDetailModal}
                handleCloseModal={handleProviderDetailClose}
                modalHeaders={getModalHeaders}
                isLoading={firmwareDetailsFetching}
            />
            {customWaitingState && <CustomWaitingState />}
        </>
    );
};
