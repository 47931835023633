import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { BackdropLoader } from '../assets/loader/BackdropLoader';

const PageNotFound: React.FC = () => {
    const location = useLocation();
    if (location.pathname === '/') return <BackdropLoader />;
    return (
        <div style={{ textAlign: 'center', marginTop: '42vh', padding: '0 20px' }}>
            <Typography variant="h4" gutterBottom>
                404 - Page Not Found
            </Typography>
            <Typography variant="h6" gutterBottom>
                The page you are looking for does not exist.
            </Typography>
            <div style={{ marginTop: '20px' }}>
                <Button component={Link} to="/summary" variant="contained" color="primary">
                    Go to Home
                </Button>
            </div>
        </div>
    );
};

export default PageNotFound;
