import { CancelOutlined, Search } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import React from 'react';

type GlobalSearchProps = {
    searchKey: string | undefined;
    setSearchKey: React.Dispatch<React.SetStateAction<string | undefined>>;
    handleGlobalSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const GlobalSearch = ({ searchKey, setSearchKey, handleGlobalSearch }: GlobalSearchProps): React.JSX.Element => (
    <Box>
        <TextField
            id="searchKey"
            placeholder="Search..."
            onChange={handleGlobalSearch}
            value={searchKey ?? ''}
            InputProps={{
                startAdornment: <Search className="search-icon" id="search" sx={{ mr: '5px', color: '#727e84' }} />,
                endAdornment: searchKey ? (
                    <CancelOutlined
                        className="cancel-icon"
                        sx={{
                            color: '#727e84',
                            cursor: 'pointer',
                        }}
                        onClick={(): void => setSearchKey('')}
                    />
                ) : (
                    ''
                ),
            }}
            variant="outlined"
            className="w-100"
        />
    </Box>
);
