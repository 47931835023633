import { api } from '../../redux-config/api/baseApi';

const commonApis = api.injectEndpoints({
    endpoints: (builder) => ({
        createDeployment: builder.mutation({
            query: (body) => ({
                url: '/du/v1/deployments/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['CreateDeployment'] : []),
        }),
        cancelDeployment: builder.mutation({
            query: (body) => ({
                url: '/du/v1/deployments/cancel',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error): any => (!error ? ['CancelDeployment'] : []),
        }),
        retryDeployment: builder.mutation({
            query: (body) => ({
                url: '/du/v1/deployments/retry',
                method: 'POST',
                body,
            }),
        }),
        getDeviceStats: builder.query({
            query: () => ({
                url: '/du/v1/summary/device',
            }),
            providesTags: ['Profile', 'SummaryGroup'],
        }),
        getIotHubStats: builder.query<any, any>({
            query: () => ({
                url: '/du/v1/iotHub/statistics',
            }),
            providesTags: ['Profile', 'SummaryGroup'],
        }),
        getFiltersList: builder.query<any, any>({
            query: () => ({
                url: '/du/v1/devices/dropdown',
                method: 'GET',
            }),
            providesTags: ['Profile'],
        }),
        getUnknownDeviceCount: builder.query<any, any>({
            query: () => ({
                url: '/du/v1/summary/adu/counter',
            }),
            providesTags: ['Profile', 'SummaryGroup'],
        }),
        getSitesList: builder.query<any, any>({
            query: () => ({
                url: '/du/v1/devices/sites',
                method: 'GET',
            }),
            providesTags: ['Profile'],
        }),
    }),
});

export const {
    useGetIotHubStatsQuery,
    useCreateDeploymentMutation,
    useCancelDeploymentMutation,
    useRetryDeploymentMutation,
    useGetDeviceStatsQuery,
    useLazyGetFiltersListQuery,
    useGetUnknownDeviceCountQuery,
    useLazyGetSitesListQuery,
} = commonApis;

export const {
    endpoints: { getDeviceStats, getIotHubStats, getFiltersList, getUnknownDeviceCount, getSitesList },
} = commonApis;
