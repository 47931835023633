import React, { useRef } from 'react';
import { handleValidReqJson } from '../commonUtils';

/**
 * The type `HandleFileUploadDownloadResult` represents the result of a file upload or download
 * operation, including progress, valid properties, uploaded file JSON, downloaded blob, and uploaded
 * file name.
 * @property {number} progress - A number representing the progress of the file upload or download. It
 * can range from 0 to 100, where 0 indicates no progress and 100 indicates the completion of the
 * upload or download.
 * @property {ValidProps} validProps - validProps is a variable of type ValidProps. It contains valid
 * properties for the file being uploaded or downloaded.
 * @property {object | null} uploadFileJson - The `uploadFileJson` property is an object that
 * represents the JSON data of the uploaded file. It can be null if no file has been uploaded yet.
 * @property {Blob | null} downloadBlob - The `downloadBlob` property is of type `Blob` and represents
 * the downloaded file as a binary large object. A `Blob` is a file-like object that can be used to
 * represent data in a format that can be easily transferred over the network. It can contain any type
 * of data, such
 * @property {string} uploadFileName - The name of the file being uploaded.
 */
export type HandleFileUploadDownloadResult = {
    progress?: number;
    validProps?: ValidProps;
    uploadFileJson?: object | null;
    uploadFileName?: string;
    uploadedFile: any[];
    fileSize?: number | null;
    inputFileRef?: any;
};

/**
 * The ValidProps type represents an object with an errorMessage property of type string and an isValid
 * property of type boolean.
 * @property {string} errorMessage - A string that represents an error message.
 * @property {boolean} isValid - A boolean value indicating whether the data is valid or not.
 */
type ValidProps = {
    errorMessage: string;
    isValid: boolean;
};

/**
 * The `useHandleFileUpload` function is a custom React hook that handles file upload and download
 * functionality, including parsing JSON files and tracking progress.
 * @returns The function `useHandleFileUpload` returns an array with three elements:
 */

export function useHandleFileUpload(): [
    handleFileUploadDownload: (
        event: React.ChangeEvent<HTMLInputElement>,
        fileType: string,
        multiple?: boolean
    ) => void,
    result: HandleFileUploadDownloadResult,
    resetValues: () => void,
    handleRemoveFile: (index: number) => void
] {
    const initialProgress = 0;
    const initialValidProps = { isValid: false, errorMessage: '' };
    const initialUploadFileJson = {};
    const initialUploadFileName = '';
    const initialUploadedFile: object[] = [];
    const initialFileSize = null;

    //ref use to clear the input value in case of file remove
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [progress, setProgress] = React.useState(initialProgress);
    const [validProps, setValidProps] = React.useState(initialValidProps);
    const [uploadFileJson, setUploadFileJson] = React.useState(initialUploadFileJson);
    const [uploadFileName, setUploadFileName] = React.useState<string>(initialUploadFileName);
    const [uploadedFile, setUploadedFile] = React.useState<any[]>(initialUploadedFile);
    const [fileSize, setFileSize] = React.useState<number | null>(initialFileSize);

    /**
     * The function `handleFileUploadDownload` is used to handle file uploads and downloads in a React
     * application, specifically for handling JSON files.
     * @param event - The event parameter is of type React.ChangeEvent<HTMLInputElement>. It represents
     * the event that is triggered when the file input element changes.
     */
    const handleFileUploadDownload = (
        event: React.ChangeEvent<HTMLInputElement>,
        fileType: string,
        multiple?: boolean
    ): void => {
        const fileInput: any = event?.target;
        let file = fileInput?.files?.[0];
        const fileName = file?.name as string;
        const fileSizeInBytes = file?.size;
        setFileSize(fileSizeInBytes);
        setUploadFileName(fileName);

        // handle multiple files
        if (multiple) {
            file = Array.from(fileInput?.files);
            setUploadedFile((prevSelectedFiles: any) => [...prevSelectedFiles, ...file]);
        } else {
            setUploadedFile(file);
        }
        const reader = new FileReader();
        reader.onload = (e): void => {
            const contents = e?.target?.result as string;
            const percent = (e?.loaded / e?.total) * 100;
            try {
                if (fileType === 'json') {
                    const fileNameArray = fileName.split('.');
                    if (fileNameArray[fileNameArray.length - 2] !== 'importmanifest')
                        return setValidProps({
                            errorMessage: 'Invalid File Name *File name must end with .importmanifest.json',
                            isValid: false,
                        });
                    const jsonObject = JSON.parse(contents);
                    setUploadFileJson(jsonObject);
                    setValidProps(handleValidReqJson(jsonObject));
                }
                setProgress(percent);
            } catch (error) {
                setValidProps({ errorMessage: 'Invalid File Type *required JSON ', isValid: false });
            }
        };
        if (Array.isArray(file)) {
            file.forEach((element) => reader.readAsText(element));
        } else if (file) {
            reader.readAsText(file);
        }
    };

    const handleRemoveFile = (index: number): void => {
        const files = [...uploadedFile];
        files.splice(index, 1);
        setUploadedFile(files);
        if (inputFileRef.current) inputFileRef.current.value = '';
    };

    /**
     * The function "resetValues" resets a set of variables to their initial values.
     */
    const resetValues = (): void => {
        setProgress(initialProgress);
        setValidProps(initialValidProps);
        setUploadFileJson(initialUploadFileJson);
        setUploadFileName(initialUploadFileName);
        setUploadedFile(initialUploadedFile);
        setFileSize(initialFileSize);
    };

    /* The code is creating an object `result` of type `HandleFileUploadDownloadResult` that contains
    the values of the variables `progress`, `validProps`, `uploadFileJson`, `uploadFileName`,
    `uploadedFile`, and `fileSize`. This object represents the current state of the file
    upload/download process. The object is then returned as part of an array along with the
    `handleFileUploadDownload` function and the `resetValues` function. */
    const result: HandleFileUploadDownloadResult = {
        progress,
        validProps,
        uploadFileJson,
        uploadFileName,
        uploadedFile,
        fileSize,
        inputFileRef,
    };

    return [handleFileUploadDownload, result, resetValues, handleRemoveFile];
}
