import React, { memo } from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, Box, Divider, FormHelperText, Stack, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InfoIcon from '@mui/icons-material/Info';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export const DeploymentModal2 = memo(
    ({
        onDateTimePickerChange,
        deploymentDateTime,
        groupName,
        deploymentId,
        handleDeploymentDateTime,
        isScheduledDeployment,
        dateTimePickerValue,
        isValidDeployment,
    }: {
        onDateTimePickerChange?: (selectedDateTimeObj: any) => void;
        deploymentDateTime?: any;
        groupName?: string;
        deploymentId?: string;
        handleDeploymentDateTime?: (isScheduled: boolean) => void;
        isScheduledDeployment?: boolean;
        dateTimePickerValue: any;
        isValidDeployment: boolean | null;
    }): React.JSX.Element => {
        const now = dayjs();
        const formattedDate = now.format('YYYY-MM-DD HH:mm:ss');
        const formattedValue = dateTimePickerValue ? dayjs(dateTimePickerValue.format('YYYY-MM-DD HH:mm:ss')) : null;

        return (
            <>
                <Divider />
                <DialogContent
                    className="modal-body custom-loader-wrapper-firmware-height"
                    data-cy="deploy-modal-content"
                >
                    <Box p={3} data-cy="deployment-modal-subHeader-details">
                        <Typography variant="h6" fontSize={'16px'} fontWeight={400} className="text-content">
                            Group Name- <b>{groupName}</b>
                        </Typography>
                        <Typography variant="h6" fontSize={'16px'} fontWeight={400} className="text-content">
                            Deployment ID- <b>{deploymentId}</b>
                        </Typography>
                    </Box>
                    <FormControl className="p-24">
                        <FormLabel id="demo-radio-buttons-group-label">
                            Specify when this deployment should start
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={isScheduledDeployment ? 'scheduleDeployment' : 'startDeployment'}
                            name="radio-buttons-group"
                            className="mt-16"
                        >
                            <FormControlLabel
                                value="startDeployment"
                                control={<Radio />}
                                label="Start immediately"
                                onClick={(): void => {
                                    if (handleDeploymentDateTime) handleDeploymentDateTime(false);
                                }}
                                data-cy="deploy-updates-immediately"
                            />
                            <FormControlLabel
                                value="scheduleDeployment"
                                control={<Radio />}
                                label="Start at a scheduled date and time"
                                onClick={(): void => {
                                    if (handleDeploymentDateTime) handleDeploymentDateTime(true);
                                }}
                                data-cy="deploy-scheduled-updates"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Divider />
                    {isScheduledDeployment && (
                        <>
                            <Box className="custom-calender-width">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DateTimePicker
                                            label="Start date (local)"
                                            onChange={onDateTimePickerChange}
                                            minDateTime={dayjs(formattedDate)}
                                            value={formattedValue}
                                            ampm={false}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <Typography fontSize={'12px'} fontWeight={400} variant="h6" pl={3} pt={1}>
                                    MM/DD/YYYY
                                </Typography>
                            </Box>
                            {deploymentDateTime && isValidDeployment && (
                                <Stack flexDirection={'row'} justifyContent={'start'} m={2}>
                                    <Avatar className="table-avatar-icon theme-primary avatar-light-bg-color">
                                        <InfoIcon />
                                    </Avatar>
                                    <Box pl={3}>
                                        <Typography
                                            variant="h6"
                                            fontSize={'16px'}
                                            fontWeight={400}
                                            className="text-content"
                                        >
                                            Deployment will begin at
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            fontSize={'16px'}
                                            fontWeight={400}
                                            className="text-content"
                                        >
                                            {deploymentDateTime}
                                        </Typography>
                                    </Box>
                                </Stack>
                            )}
                            {deploymentDateTime && !isValidDeployment && (
                                <FormHelperText sx={{ color: '#ca3c3d', padding: '20px' }}>
                                    *Please select a valid date and time
                                </FormHelperText>
                            )}
                        </>
                    )}
                </DialogContent>
            </>
        );
    }
);
