import { api } from '../../redux-config/api/baseApi';

const deviceApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDeviceList: builder.query<any, any>({
            query: (body) => ({
                url: 'du/v1/devices',
                body,
                method: 'POST',
            }),
            providesTags: ['AddToGroups'],
        }),
        getDeviceListById: builder.query<any, any>({
            query: (deviceId) => ({
                url: `du/v1/devices/${deviceId}`,
                method: 'GET',
            }),
            providesTags: ['deviceId'],
        }),
        updateAssigneeGroup: builder.mutation<any, any>({
            query: (data) => ({
                url: 'du/v1/groups/assign',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (_result, error): any => (!error ? ['devices', 'deviceId', 'AddToGroups'] : []),
        }),
        getDeviceDeploymentStatus: builder.query<any, any>({
            query: ({ deviceId }: { deviceId: string }) => ({
                url: `du/v1/deployments/${deviceId}/deployment/status`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetDeviceListQuery,
    useGetDeviceListByIdQuery,
    useUpdateAssigneeGroupMutation,
    useGetDeviceDeploymentStatusQuery,
} = deviceApi;

export const {
    endpoints: { getDeviceList },
} = deviceApi;
