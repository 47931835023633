import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetProfileDetailsQuery } from '../../features/profile/profileApi';
import { Typography, Box, Tabs, Tab, Toolbar, Stack } from '@mui/material';
import { AppBar } from '@brightlayer-ui/react-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GroupOverview } from './GroupOverview';
import { DeploymentHistory } from './DeploymentHistory';
import { useSelector } from 'react-redux';
import { CurrentUpdates } from './CurrentUpdates';

export const GroupsTab = (): React.JSX.Element => {
    const navigate = useNavigate();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);
    const { groupName, selectedTab, container } = useParams();

    const { data: getProfileDetails } = useGetProfileDetailsQuery(undefined);

    const selectedTabHeaders = (): string => {
        switch (selectedTab) {
            case 'overview':
                return 'Group Overview';
            case 'current-update':
                return 'Group Current Updates';
            default:
                return 'Group Deployments';
        }
    };

    const handleTabValue = (): number => {
        switch (selectedTab) {
            case 'overview':
                return 0;
            case 'current-update':
                return 1;
            default:
                return 2;
        }
    };

    const groupsTab = [
        {
            id: 'overview',
            label: 'Overview',
            isActive: selectedTab === 'overview',
        },
        {
            id: 'current-update',
            label: 'Current Updates',
            isActive: selectedTab === 'current-update',
        },
        {
            id: 'history',
            label: 'Deployment History',
            isActive: selectedTab === 'history',
        },
    ];

    const selectedTabComponent = (): React.JSX.Element => {
        switch (selectedTab) {
            case 'history':
                return <DeploymentHistory groupId={groupName} />;
            case 'current-update':
                return <CurrentUpdates groupId={groupName} profileData={getProfileDetails?.data} />;
            default:
                return <GroupOverview groupName={groupName} profileData={getProfileDetails?.data} />;
        }
    };

    /* The `useEffect` hook in the provided code snippet is checking the value of `selectedTab` and
    determining whether it matches any of the expected values ('overview', 'current-update', 'history').
    If the value of `selectedTab` does not match any of these expected values, it navigates the user to
    the '/PageNotFound' route. Otherwise, if the value of `selectedTab` is valid, it scrolls the window
    to the top (position 0, 0). This logic ensures that the user is redirected to the appropriate page
    based on the selected tab or scrolls to the top of the page if the selected tab is valid. */
    useEffect(() => {
        if (selectedTab && !['overview', 'current-update', 'history'].includes(selectedTab)) {
            navigate('/PageNotFound');
        } else {
            window.scrollTo(0, 0);
        }
    }, [selectedTab]);

    return (
        <>
            <Box className="content-container detail-page-container" sx={{ height: 'auto' }}>
                <AppBar className="detail-page-header appbar-index6">
                    <Toolbar className="detail-page-toolbar">
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <ArrowBackIcon
                                className="back-icon cursor-pointer"
                                onClick={(): void => navigate(`/${container}`)}
                            />
                            <Box>
                                <Typography variant={'h6'} fontSize={'20px'} fontWeight={600} className="text-content">
                                    {selectedTabHeaders()}
                                </Typography>
                                <Typography
                                    variant={'body1'}
                                    fontSize={'16px'}
                                    fontWeight={400}
                                    className="text-content"
                                >
                                    {profileProps?.adopterName || '--'} <b>&gt;</b> {profileProps?.iotHubName || '--'}{' '}
                                    <b>&gt;</b> Groups <b>&gt;</b> {groupName}
                                </Typography>
                            </Box>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box
                className="detail-page-header"
                sx={{ zIndex: '0', height: '58px !important', position: 'fixed', top: '60px' }}
            >
                <Tabs className="custom-tab custom-tabs-style" value={handleTabValue()}>
                    {groupsTab.map((tab) => (
                        <Tab
                            key={tab.id}
                            onClick={(): void => navigate(`/${container}/groupsTab/${groupName}/${tab.id}`)}
                            label={tab.label}
                            className={`tab-item ${tab.isActive ? 'active' : ''}`}
                            sx={{ py: 0, px: 4 }}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box className="custom-card" sx={{ m: 0, boxShadow: 'none !important' }}>
                <Box>{selectedTabComponent()}</Box>
            </Box>
        </>
    );
};
