import React from 'react';
import { Card, CardContent, Stack, Box, Typography, Divider, Tooltip, Link } from '@mui/material';
import { FiberManualRecord, HelpOutline } from '@mui/icons-material';
import { ChartConstants } from './Constant/ChartConstants';
import { useNavigate } from 'react-router-dom';

type DeviceData = {
    totalDeviceCount: number;
    onLatestUpdateDeviceCount: number;
    newUpdatesAvailableDeviceCount: number;
    updatesInProgressDeviceCount: number;
    aduEnabledConnectedDevices: number;
    aduEnabledDisconnectedDevices: number;
};

type HubData = {
    id: string;
    name: string;
    disabledDeviceCount: number;
    enabledDeviceCount: number;
    totalDeviceCount: number;
    connectedDeviceCount: number;
    disConnectedDeviceCount: number;
};

type UnknownDeviceData = {
    totalDeviceInUnknownStateCount: number;
    defaultGroupDevicesCount: number;
};

export const CountersComponent = (props: {
    deviceData?: DeviceData;
    hubData?: HubData;
    unknownDeviceData?: UnknownDeviceData;
    setDevicesStatus?: (status: string) => void;
}): React.JSX.Element => {
    const navigate = useNavigate();
    const handleCounterClick = (status: string): void => {
        if (props?.setDevicesStatus) {
            props.setDevicesStatus(status);
        } else {
            navigate('/deviceTable', { state: { devicesStatus: status } });
        }
    };

    const subCountBoxElementHandler = (
        value: number | undefined,
        label: string,
        boxClass: string,
        customColorClass: string,
        tooltip?: string | null,
        handleClickParam?: string
    ): React.JSX.Element => (
        <Box className={boxClass}>
            <Typography className="counter-subheader custom-hover-link">
                {handleClickParam ? (
                    <Link onClick={(): void => handleCounterClick(handleClickParam)}>{value ?? '--'}</Link>
                ) : (
                    `${value ?? '--'}`
                )}
            </Typography>
            <Tooltip title={tooltip} arrow>
                <Stack flexDirection={'row'} alignItems={'center'} className="inner-space-bottom-4">
                    <FiberManualRecord fontSize="small" className={customColorClass} />
                    <Typography className="counter-color-sizes custom-hover-link">
                        {handleClickParam ? (
                            <Link onClick={(): void => handleCounterClick(handleClickParam)}>{label}</Link>
                        ) : (
                            `${label}`
                        )}
                    </Typography>
                </Stack>
            </Tooltip>
        </Box>
    );

    return (
        <Card className="w-100 new-counter-content">
            <CardContent sx={{ p: '24px !important' }}>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Box className="inner-space-8">
                        <Typography className="counter-header custom-hover-link">
                            <Link sx={{ cursor: 'pointer' }} onClick={(): void => handleCounterClick('notAduDevices')}>
                                Total : {props?.hubData?.totalDeviceCount ?? '--'}
                            </Link>
                        </Typography>
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            {subCountBoxElementHandler(
                                props?.hubData?.connectedDeviceCount,
                                'Connected',
                                'inner-space-right-16',
                                'connected-color',
                                null,
                                'notAduConnected'
                            )}
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                            {subCountBoxElementHandler(
                                props?.hubData?.disConnectedDeviceCount,
                                'Disconnected',
                                'inner-space-left-16',
                                'disconnected-color',
                                null,
                                'notAduDisconnected'
                            )}
                        </Stack>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                    <Box className="inner-space-8">
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography className="counter-header custom-hover-link">
                                {' '}
                                <Link onClick={(): void => handleCounterClick('aduDevices')}>
                                    ADU Ready (Connection Status)
                                    <Tooltip title={ChartConstants?.ADU_READY_TOOLTIP} arrow>
                                        <HelpOutline className="empty-counter-icon" />
                                    </Tooltip>
                                    : {props?.deviceData?.totalDeviceCount ?? '--'}
                                </Link>
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            {subCountBoxElementHandler(
                                props?.deviceData?.aduEnabledConnectedDevices,
                                'Connected',
                                'inner-space-right-16',
                                'connected-color',
                                null,
                                'aduConnectedDevices'
                            )}
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                            {subCountBoxElementHandler(
                                props?.deviceData?.aduEnabledDisconnectedDevices,
                                'Disconnected',
                                'inner-space-left-16',
                                'disconnected-color',
                                null,
                                'aduDisconnectedDevices'
                            )}
                        </Stack>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                    <Box className="inner-space-8">
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography className="counter-header">
                                {' '}
                                ADU Ready (Update Status)
                                <Tooltip title={ChartConstants?.ADU_READY_TOOLTIP} arrow>
                                    <HelpOutline className="empty-counter-icon" />
                                </Tooltip>
                                : {props?.deviceData?.totalDeviceCount ?? '--'}
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            {subCountBoxElementHandler(
                                props?.deviceData?.newUpdatesAvailableDeviceCount,
                                'Outdated',
                                'inner-space-right-16',
                                'not-compliance-text-color',
                                ChartConstants.CHART_COMPLIANCE_TOOLTIP_UPDATE_AVAILABLE
                            )}
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                            {subCountBoxElementHandler(
                                props?.deviceData?.updatesInProgressDeviceCount,
                                'Updates In Progress',
                                'inner-space-right-16 inner-space-left-16',
                                'inprogress-text-color',
                                ChartConstants.CHART_COMPLIANCE_TOOLTIP_UPDATE_IN_PROGRESS
                            )}
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                            {subCountBoxElementHandler(
                                props?.deviceData?.onLatestUpdateDeviceCount,
                                'On Latest',
                                'inner-space-left-16',
                                'compliance-text-color',
                                ChartConstants.CHART_COMPLIANCE_TOOLTIP_UPDATE_LATEST_UPDATE
                            )}
                        </Stack>
                    </Box>
                    <Divider orientation="vertical" variant="middle" flexItem sx={{ borderRightWidth: 2 }} />
                    <Box className="inner-space-8">
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography className="counter-header">Not ADU Initiated</Typography>
                            <Tooltip title={ChartConstants?.NOT_ADU_INITIATED_TOOLTIP} arrow>
                                <HelpOutline className="empty-counter-icon" />
                            </Tooltip>
                        </Stack>
                        <Typography className="counter-subheader">
                            {props?.unknownDeviceData?.totalDeviceInUnknownStateCount ?? '--'}
                        </Typography>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};
