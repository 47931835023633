import React, { useState, useEffect } from 'react';

/**
 * The `useSearchFilter` function is a custom hook in TypeScript that takes an array of objects and
 * returns a function to handle search filtering and the filtered data.
 * @param {object[]} data - An array of objects that will be used as the initial data for filtering.
 * @returns The function `useSearchFilter` returns an array with two elements. The first element is a
 * function `handleSearch` that takes three parameters: `event` of type
 * `React.ChangeEvent<HTMLInputElement>`, `data` of type `object[]`, and `targetKey` of type `string`.
 * The second element is `filteredData` of type `object[]`.
 */
export const useSearchFilter = (
    data: object[],
    searchRef?: any
): [
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>, data: object[], targetKey: string) => void,
    filteredData: object[],
    searchValue: string
] => {
    const [filteredData, setFilteredData] = useState<object[]>(data);
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {
        setSearchValue('');
        setFilteredData(data); // set initial value of filteredData once data is available
    }, [data, searchRef]);

    /**
     * @param {string} targetKey - The `targetKey` parameter is a string that represents the key in the
     * `dataToBeFiltered` objects that you want to filter on.
     */
    const handleSearch = (
        event: React.ChangeEvent<HTMLInputElement>,
        dataToBeFiltered: object[],
        targetKey: string
    ): void => {
        setSearchValue(event.target.value);
        if (event.target.value === '') {
            setFilteredData(data); // set filteredData to original data when search is cleared
        } else {
            setFilteredData(dataToBeFiltered?.filter((item: any) => item[targetKey].includes(event.target.value)));
        }
        event.stopPropagation();
    };

    return [handleSearch, filteredData, searchValue];
};
