import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AutocompleteRenderInputParams } from '@mui/material';

const MIN_TAG_LENGTH = 3;
const MAX_TAG_LENGTH = 200;
const RESERVED_VALUES = ['uncategorized', 'default'];

type Props = {
    params: AutocompleteRenderInputParams;
    setErrorMessage: any;
    errorMessage: string | null;
    setSelectedAssigneeValue: any;
    selectedAssigneeValue: any;
};
const CustomTextField = ({
    params,
    setErrorMessage,
    errorMessage,
    setSelectedAssigneeValue,
    selectedAssigneeValue,
}: Props): React.JSX.Element => {
    const validateInput = (value: string): void => {
        /* This block of code is a validation logic for the input value in the text field.*/
        if (RESERVED_VALUES.includes(value.trim().toLowerCase())) {
            setErrorMessage('*Group name cannot be "Uncategorized" or "default".');
            return;
        }
        if (value.length < MIN_TAG_LENGTH) {
            setErrorMessage(`*Group name can't be less than ${MIN_TAG_LENGTH} characters.`);
            return;
        }
        if (value.length > MAX_TAG_LENGTH) {
            setErrorMessage(`*Group name can't exceed ${MAX_TAG_LENGTH} characters.`);
            return;
        }
        if (!/^[a-zA-Z0-9._-]*$/.test(value)) {
            setErrorMessage(
                '*Group name can only contain alphanumeric characters and special characters _(underscore), -(hyphen), .(period)'
            );
            return;
        }
        setErrorMessage(null);
    };

    useEffect(() => {
        //validate input on input change
        if (selectedAssigneeValue) validateInput(selectedAssigneeValue);
    }, [selectedAssigneeValue]);

    return (
        <div>
            <TextField
                {...params}
                label="Assign Group"
                value={selectedAssigneeValue}
                onChange={(e): void => setSelectedAssigneeValue(e.target.value)}
                error={!!errorMessage}
                helperText={errorMessage}
                InputProps={{
                    ...params.InputProps,
                    type: 'search',
                }}
                data-cy="select-group-name"
            />
        </div>
    );
};

export default CustomTextField;
