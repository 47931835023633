import React from 'react';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    tags: string[];
    onTagsChange: (tags: string[]) => void;
};

const TagBox: React.FC<Props> = ({ tags, onTagsChange }) => {
    const [showWarningText, setShowWarningText] = React.useState(false);
    const removeTag = (index: number): void => {
        const updatedTags = [...tags];
        if (updatedTags.length > 1) {
            updatedTags.splice(index, 1);
            onTagsChange(updatedTags);
        } else {
            setShowWarningText(true);
        }
    };

    return (
        <>
            <div className="tag-box">
                {tags.map((tag, index) => (
                    <Chip
                        sx={{ margin: 1 }}
                        label={tag}
                        variant="outlined"
                        onDelete={(): void => removeTag(index)}
                        key={uuidv4()}
                    />
                ))}
            </div>
            {showWarningText && (
                <FormHelperText sx={{ color: '#ca3c3d' }}>*At least one device need to be selected</FormHelperText>
            )}
        </>
    );
};

export default TagBox;
