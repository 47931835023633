import {
    Typography,
    Box,
    Divider,
    TextField,
    Stack,
    Chip,
    FormHelperText,
    TableContainer,
    Link,
    Paper,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { downloadFileFromLink } from '../../commonUtils';
import CheckIcon from '@mui/icons-material/Check';
import { getDeviceList } from '../../pages/DeviceUpdate/deviceApi';
import DialogContent from '@mui/material/DialogContent';
import {
    getFilterLabel,
    handlePaginationPayload,
    initialPaginationPayload,
} from '../../pages/DeviceUpdate/DeviceTable';
import CustomTable from '../../components/CustomTable';
import { v4 as uuidv4 } from 'uuid';
import { getFiltersList, getSitesList } from '../common/commonApis';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import { useLoadMoreData } from '../../hooks/useLoadMoreData';
import { Devices } from '@mui/icons-material';
import { useGetGroupDetailsQuery } from '../../pages/Summary/groupApi';
import useDeviceTableHeaders from '../../pages/DeviceUpdate/DeviceTableHeaders';
import DeviceTableCustomFilters from '../../pages/DeviceUpdate/DeviceTableCustomFilters';

export const AddGroupModal2 = (props: any): React.JSX.Element => {
    /*Additional Hooks */
    const tableRef = React.useRef<any>();

    /*States */
    const [paginationPayload, setPaginationPayload] = useState(initialPaginationPayload);
    const [isSiteMenuOpen, setIsSiteMenuOpen] = useState<null | HTMLElement>(null);
    const [siteFilterObj, setSiteFilterObj] = useState<{ siteId?: string[] }>({});

    //API call for device list
    const [getDeviceListQuery, { data, isError, isFetching }]: any = getDeviceList.useLazyQuery();
    const [getFiltersListQuery, { currentData: filtersData }]: any = getFiltersList.useLazyQuery();
    const [getSitesListQuery, { currentData: sitesData, isFetching: isFetchingSites }]: any =
        getSitesList.useLazyQuery();
    const [handleSearch, filteredSearchData, searchValue] = useSearchFilter(sitesData?.data?.site);

    const { dataToShow, handleLoadMore }: { dataToShow: object[]; handleLoadMore: () => void } =
        useLoadMoreData(filteredSearchData);

    //API call to get assignee group list.
    const groupListData = useGetGroupDetailsQuery(undefined);

    const isOpen = Boolean(isSiteMenuOpen);
    const handleSiteFilterOpen = (event: React.MouseEvent<HTMLElement>): any => {
        setIsSiteMenuOpen(event.currentTarget);
    };
    const handleSiteFilterClose = (): any => {
        setIsSiteMenuOpen(null);
    };

    const handleSelectedSite = (id: string, event: any): void => {
        if (event.target.checked) {
            setSiteFilterObj((prevState) => {
                const updatedSiteId = [...(prevState.siteId ?? []), id];
                return { ...prevState, siteId: updatedSiteId };
            });
        } else {
            setSiteFilterObj((prevState) => {
                const updatedSiteId = (prevState.siteId ?? []).filter((siteId: string) => siteId !== id);
                return { ...prevState, siteId: updatedSiteId };
            });
        }
    };

    /**
     * The `checkboxSelectHandler` function filters devices belonging to a default group, checks if any
     * selected device is in the default group, and sets an error message accordingly if trying to
     * delete devices from the default group.
     * @param {string[]} checkBoxIdsArr - The `checkBoxIdsArr` parameter is an array of strings that
     * contains the IDs of the checkboxes that have been selected by the user.
     */
    const checkboxSelectHandler = (checkBoxIdsArr: string[]): void => {
        const isDefaultGrpDevice = data?.data?.records.filter((device: any) => device.groupName === 'default');
        const isDisable = isDefaultGrpDevice?.some((device: { deviceId: string }) =>
            checkBoxIdsArr.includes(device.deviceId)
        );
        if (props.groupOperationType.id === 'deleteFromGroup') {
            props?.setDevicesToDelete({
                isError: isDisable,
                errorMessage: isDisable ? '*Devices are not allowed to remove from default group.' : '',
            });
        }
        props?.setPayloadForDevices(checkBoxIdsArr);
    };

    //Below are function and states for handling of csv/excel file.
    const fileType = props?.uploadedFile?.type;

    const fileValidation = (): boolean => {
        if (
            fileType === 'text/csv' ||
            fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            props?.setFileValidation(true);
            return true;
        }
        props?.setFileValidation(false);
        return false;
    };

    const handleFilterRemove = (key: string): void => {
        if (key === 'siteId') setSiteFilterObj({});
        tableRef?.current?.resetFilters(key, true);
    };

    let deviceHeaders = useDeviceTableHeaders(filtersData, groupListData);
    deviceHeaders = deviceHeaders.filter((col) => col.header !== 'Last Reported At' && col.header !== 'Change Group');

    const handleFilterChange = (tableFilters: any, sortedData: any): void => {
        if (!tableFilters?.siteId?.length) {
            delete tableFilters.siteId;
        }
        handlePaginationPayload(tableFilters, paginationPayload, sortedData, setPaginationPayload);
    };

    const getWrapperClassForTable = (): string => {
        switch (true) {
            case data?.data?.records?.length === 0:
                return 'device-table-white-space custom-device-table-responsive-for-add-group-modal no-device-found-center custom-device-table-responsive-for-add-group-modal-filters add-group-filter-height';
            case Object.keys(paginationPayload.filters).length > 0:
                return 'custom-device-table-responsive-for-add-group-modal-filters add-group-filter-height';
            default:
                return 'device-table-white-space custom-device-table-responsive-for-add-group-modal';
        }
    };

    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const handleGroupFilter = (): void => {
        setSiteFilterObj({});
        tableRef?.current?.resetFilters('clearAll');
        setPaginationPayload({ ...initialPaginationPayload, isAduEnabled: !paginationPayload.isAduEnabled });
    };

    /*Effects */
    useEffect(() => {
        switch (props?.deviceUploadType) {
            case 'uploadDevice':
                props?.setPayloadForDevices([]);
                break;
            case 'selectDevice':
                props?.resetValues();
                break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        getFiltersListQuery();
        getSitesListQuery();
    }, []);

    useEffect(() => {
        if (props?.deviceUploadType === 'selectDevice') getDeviceListQuery(paginationPayload);
    }, [paginationPayload]);

    return (
        <>
            <Divider />
            {props?.deviceUploadType === 'uploadDevice' ? (
                <DialogContent className="modal-body">
                    {props?.groupOperationType.id === 'addNewGroup' && (
                        <Box p={3}>
                            <TextField
                                id="filled-basic"
                                label="Group Name"
                                variant="filled"
                                className="w-100"
                                value={props?.groupNameForDevices}
                                disabled
                            />
                        </Box>
                    )}
                    <Divider />
                    <Box p={3}>
                        {props?.fileSize && !fileValidation() && (
                            <FormHelperText sx={{ color: '#ca3c3d', mb: 1 }}>
                                *Please upload valid .csv or .xlsx file.
                            </FormHelperText>
                        )}
                        <Typography variant="body2" className="right-text">
                            Upload excel file
                        </Typography>
                        <Box component={'form'} className="form-w-100">
                            <input
                                type="file"
                                className="custom-input mb-0 modal-file-select-padding"
                                onChange={(e): void => props?.handleFileUploadDownload(e, 'any')}
                            />
                        </Box>
                        <Typography variant="body2" mt={2} className="right-text">
                            Download sample{' '}
                            <Link
                                id="link"
                                color="primary"
                                className="primary-text"
                                onClick={(): void =>
                                    downloadFileFromLink(`${window.location.origin}/deviceList.csv`, 'deviceList.csv')
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                DeviceList.csv
                            </Link>
                        </Typography>

                        <Stack flexDirection={'row'} alignItems={'center'} marginTop={2}>
                            {props?.validProps?.errorMessage && <ErrorIcon className="custom-error-icon" />}
                            <Typography variant="body2" className="custom-error-text">
                                {props?.validProps?.errorMessage}
                            </Typography>
                        </Stack>
                        {props?.fileSize && props?.uploadFileName && fileValidation() && (
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} py={3}>
                                <Box display={'flex'} alignItems={'center'}>
                                    {props?.uploadFileName ? (
                                        <CheckIcon className={'custom-icon-color'} />
                                    ) : (
                                        <CloseIcon className={'custom-icon-close-color'} />
                                    )}
                                    <Chip
                                        label={props?.uploadFileName || '--'}
                                        variant="outlined"
                                        sx={{ marginLeft: '16px' }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant="body2" className="modal-paragraph">
                                        {props?.fileSize} kb.
                                    </Typography>
                                </Box>
                            </Stack>
                        )}
                    </Box>
                </DialogContent>
            ) : (
                <DialogContent className="modal-body">
                    <Box px={3} paddingY={1}>
                        {props?.payloadForDevices?.length < 1 && (
                            <FormHelperText sx={{ color: '#ca3c3d' }}>
                                *Please select at least one device.
                            </FormHelperText>
                        )}
                        {props?.devicesToDelete.isError && (
                            <FormHelperText sx={{ color: '#ca3c3d' }}>
                                {props?.devicesToDelete.errorMessage}
                            </FormHelperText>
                        )}
                        <DeviceTableCustomFilters
                            handleClick={handleSiteFilterOpen}
                            open={isOpen}
                            handleClose={handleSiteFilterClose}
                            handleSearch={handleSearch}
                            sitesData={sitesData}
                            searchValue={searchValue}
                            dataToShow={dataToShow}
                            additionalFilters={siteFilterObj}
                            handleSiteFilter={handleSelectedSite}
                            filteredSearchData={filteredSearchData}
                            isFetchingSites={isFetchingSites}
                            handleLoadMore={handleLoadMore}
                            paginationPayload={paginationPayload}
                            handleGroupFilter={handleGroupFilter}
                            isMenuOpen={isSiteMenuOpen}
                        />
                        <Stack />
                        <Divider />
                        <Stack direction="row" spacing={1} className="chip-spacing">
                            {Object.keys(paginationPayload.filters).map((key: string) => (
                                <Chip
                                    key={uuidv4()}
                                    label={getFilterLabel(key, deviceHeaders)}
                                    onDelete={(): void => handleFilterRemove(key)}
                                />
                            ))}
                        </Stack>
                        <Paper sx={{ border: '1px solid #f2f7f7' }}>
                            <TableContainer>
                                <CustomTable
                                    ref={tableRef}
                                    isPagination={true}
                                    controlledPageSize={100}
                                    total={data?.data?.total}
                                    keyToTraverse="deviceId"
                                    handleFilterChange={handleFilterChange}
                                    handlePageChange={handlePaginationChange}
                                    data={data?.data?.records}
                                    headers={deviceHeaders}
                                    containerClass="custom-data-table"
                                    wrapperClass={getWrapperClassForTable()}
                                    isLoading={(!data?.data?.records && !isError) || isFetching}
                                    noDataFoundTitle={isError ? 'Something went wrong' : 'No devices found'}
                                    handleCheckboxSelect={checkboxSelectHandler}
                                    keyToCheckForDisabledCheckbox="connectionState"
                                    valueToCheckForDisabledCheckbox="Disconnected"
                                    customClass="add-group-modal-emptyStateWrapper"
                                    additionalFilterValue={siteFilterObj}
                                    defaultPageSize={25}
                                    noDataFoundIcon={<Devices fontSize="inherit" />}
                                ></CustomTable>
                            </TableContainer>
                        </Paper>
                    </Box>
                </DialogContent>
            )}
            <Divider />
        </>
    );
};
