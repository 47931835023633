import React, { useState, useRef, useMemo, useEffect } from 'react';
import CustomTable from '../../components/CustomTable';
import Stack from '@mui/material/Stack';
import {
    Box,
    Button,
    Chip,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Divider,
    CardContent,
    TableContainer,
} from '@mui/material';
import { Description } from '@mui/icons-material';
import { useGetAuditLogsQuery, useLazyDownloadAuditLogsQuery } from './auditApis';
import { RefetchConfigOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { useFilteredValues, useDebounce } from '../../hooks';
import { AuditRequestObj } from '../../types';
import ReactJson from 'react-json-view';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import { v4 as uuidv4 } from 'uuid';
import { downloadFileFromLink, getLocalTimeStamp } from '../../commonUtils';
import { CustomWaitingState } from '../../components/CustomWaitingState';
import { GlobalSearch } from '../../components/GlobalSearch';

export const AuditLogs = (): React.JSX.Element => {
    /*Additional Hooks */
    const dispatch = useAppDispatch();
    const tableRef = useRef<any>();
    const [paginationPayload, setPaginationPayload] = useState({ page: 0, size: 25, filters: {} });

    /*States */
    const [requestModal, setRequestModal] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const [auditResponse, setAuditResponse] = useState<any>({});
    const [auditRequest, setAuditRequest] = useState<any>({});
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
    const [customWaitingState, setCustomWaitingState] = useState<boolean>(false);

    /*Table Handlers */

    /**
     * The function `getInvokedAtCell` returns a JSX element displaying the formatted local date and time
     * of an audit event or 'N/A' if not available.
     * @param {any} audit - The `audit` parameter seems to be an object containing information related to
     * an audit record. The function `getInvokedAtCell` takes this `audit` object as input and extracts the
     * `createdAt` property from it to display the formatted date and time in the user's local timezone
     * using Moment.js
     * @returns A JSX element containing a Typography component with the formatted date and time from the
     * `createdAt` property of the `audit` object, or 'N/A' if the date is not available.
     */
    const getInvokedAtCell = (audit: any): React.JSX.Element => (
        <Typography variant={'body2'}>{getLocalTimeStamp(audit?.createdAt) ?? 'N/A'}</Typography>
    );

    /**
     * The function returns a JSX element representing a button with an onClick event handler.
     * @param {any} audit - The parameter "audit" is of type "any", which means it can be any type of
     * data.
     */
    const getRequestCell = (audit: any): React.JSX.Element => (
        <Button variant={'outlined'} onClick={(): void => requestModalHandler(true, JSON.parse(audit?.request))}>
            {' '}
            Request
        </Button>
    );

    /**
     * The function returns a JSX element that renders a button with an onClick event handler.
     * @param {any} audit - The parameter `audit` is of type `any`, which means it can be any type of
     * value.
     */
    const getResponseCell = (audit: any): React.JSX.Element => (
        <Button variant={'outlined'} onClick={(): void => responseModalHandler(true, JSON.parse(audit?.response))}>
            Response
        </Button>
    );

    /* The above code is using the `useDebounce` hook to debounce the `searchKey` value. It is not using
   the first and second elements of the returned array, so they are being ignored by using the comma
   operator. The debounced value is stored in the `debouncedValue` variable. */
    const [, debouncedValue] = useDebounce(undefined, searchKey);

    /**
     * The function `handleGlobalSearch` updates the search key based on the value of an input field.
     * @param {any} e - The parameter `e` is of type `any`, which means it can be any type of value. In
     * this case, it is likely an event object that is passed to the function when it is called.
     */
    const handleGlobalSearch = (e: any): void => {
        const searchValue = e.target.value || '';
        const regex = /^(?!\s)(\s*\S+\s*)*$/; // regex to avoid spaces
        const isValidValue = regex.test(searchValue);
        if (isValidValue || searchValue === '') setSearchKey(searchValue); // Added condition for non value string
    };

    /**
     * The function `responseModalHandler` sets the `auditResponse` state with the provided data and
     * sets the `responseModal` state to the provided action.
     * @param {boolean} action - The `action` parameter is a boolean value that determines whether to
     * show or hide the response modal. If `action` is `true`, the response modal will be shown. If
     * `action` is `false`, the response modal will be hidden.
     * @param {string} data - The `data` parameter is a string that represents the data to be set in
     * the `auditResponse` state.
     */
    const responseModalHandler = (action: boolean, data: string): void => {
        setAuditResponse({ data });
        setResponseModal(action);
    };

    /**
     * The function `requestModalHandler` sets the `AuditRequest` data and toggles the `RequestModal`
     * state.
     * @param {boolean} action - The "action" parameter is a boolean value that determines whether to
     * show or hide the request modal. If "action" is true, the request modal will be shown. If
     * "action" is false, the request modal will be hidden.
     * @param {AuditRequestObj} data - The `data` parameter is of type `AuditRequestObj`.
     */
    const requestModalHandler = (action: boolean, data: AuditRequestObj): void => {
        setAuditRequest(data);
        setRequestModal(action);
    };

    /**
     * The above code defines two functions, handleFilterChange and handlePaginationChange, which
     * update the paginationPayload state based on the provided filters and pagination values.
     * @param {any} filters - The `filters` parameter is an object that represents the filters applied
     * to the data. It can contain various properties depending on the specific filtering requirements,
     * such as search keywords, date ranges, or any other criteria used to filter the data.
     */

    const handleFilterChange = (filters: any, sortedData: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(paginationPayload.filters)) {
            setPaginationPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: filters,
            }));
        } else if (Object.keys(sortedData)?.length) {
            setPaginationPayload((prev: any) => ({
                ...prev,
                page: 0,
                sort: sortedData,
            }));
        }
    };

    /**
     * The function `handlePaginationChange` updates the pagination payload with new page and size values.
     * @param {number} page - The `page` parameter represents the page number that the user wants to
     * navigate to in the pagination component.
     * @param {number} size - The `size` parameter in the `handlePaginationChange` function represents the
     * number of items to be displayed per page in a paginated list or table. It determines how many items
     * will be shown on each page when the pagination is changed.
     */
    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    /**
     * The function `getFilterLabel` retrieves the header label of a column based on its accessor key.
     * @param {string} key - The `key` parameter is a string that is used to find a matching `accessor`
     * property in the `columns` array.
     * @returns The `getFilterLabel` function returns the header value of the column object that has an
     * accessor matching the provided key. If a matching column is found, it returns the header value of
     * that column; otherwise, it returns `undefined`.
     */
    const getFilterLabel = (key: string): string | undefined => {
        const filteredColumn = columns.find((obj) => obj.accessor === key);
        return filteredColumn?.header;
    };

    /**
     * The function `getWrapperClassForTable` returns a CSS class based on certain conditions related to
     * data length, error status, and table filters.
     * @returns The function `getWrapperClassForTable` returns a string value based on the conditions
     * inside the switch statement. The return value depends on the following conditions:
     */
    const getWrapperClassForTable = (): string => {
        switch (true) {
            case allAuditLogs?.data?.length === 0 || isError:
                return 'device-table-white-space custom-audit-table-responsive no-device-found-center';
            case Object.keys(tableFilters).length > 0:
                return 'custom-audit-table-responsive-with-filters audit-logs-filter-height';
            default:
                return 'device-table-white-space custom-audit-table-responsive';
        }
    };

    /*Download CSV Handler*/
    /**
     * The function `downloadCSVHandler` sets a custom waiting state and then downloads audit logs in CSV
     * format.
     */
    const downloadCSVHandler = async (): Promise<void> => {
        setCustomWaitingState(true);
        await downloadAuditLogsCSV({});
    };

    /*Table columns and filters*/
    const columns = useMemo(
        () => [
            {
                header: 'Event Name',
                accessor: 'event',
                isDebounce: true,
                isFilterable: true,
                width: '15%',
                filterRegex: /^(?!\s)(\s*\S+\s*)*$/,
            },
            {
                header: 'Invoked By',
                accessor: 'invokeBy',
                isDebounce: true,
                isFilterable: true,
                width: '15%',
                filterRegex: /^(?!\s)(\s*\S+\s*)*$/,
            },
            {
                header: 'Email',
                accessor: 'email',
                isDebounce: true,
                isFilterable: true,
                width: '20%',
                filterRegex: /^(?!\s)(\s*\S+\s*)*$/,
            },
            {
                header: 'Invoked At',
                accessor: 'createdAt',
                cell: getInvokedAtCell,
                isSortable: true,
                width: '15%',
            },
            {
                header: 'Status Code',
                accessor: 'httpStatusCode',
                isDebounce: true,
                isFilterable: true,
                width: '15%',
                filterRegex: '^[0-9]+$',
                filterWarningMessage: 'Only numbers are allowed for status code!',
            },
            {
                header: 'Request',
                cell: getRequestCell,
                width: '10%',
            },
            {
                header: 'Response',
                cell: getResponseCell,
                width: '10%',
            },
        ],
        []
    );
    const [tableFilters] = useFilteredValues({ allFilters: columns, triggeredFilters: paginationPayload?.filters });

    /*API calls */
    const [downloadAuditLogsCSV, { currentData: auditLogsCSVData, isSuccess, isError }] =
        useLazyDownloadAuditLogsQuery();

    const {
        data: allAuditLogs,
        isLoading,
        isFetching,
    } = useGetAuditLogsQuery<{ data: any; refetch: RefetchConfigOptions; isLoading: boolean; isFetching: boolean }>(
        { ...paginationPayload, filters: tableFilters },
        { refetchOnMountOrArgChange: true }
    );

    /*Effects */

    /* The below code is using the `useEffect` hook in a TypeScript React component. It is setting the
    `searchKey` property of the `payload` state variable to the `debouncedValue` whenever the
    `debouncedValue` changes. The `debouncedValue` is likely a value that has been debounced using a
    debounce function, which means it will only update after a certain delay or when a certain
    condition is met. */

    useEffect(() => {
        setPaginationPayload((prev: any) => {
            const prevClone = { ...prev };
            if (!debouncedValue) delete prevClone.searchKey;
            return {
                ...prevClone,
                ...(debouncedValue && { searchKey: debouncedValue }),
                page: 0,
            };
        });
    }, [debouncedValue]);

    useEffect(() => {
        if (isSuccess && auditLogsCSVData?.data) {
            downloadFileFromLink(auditLogsCSVData?.data, 'auditLogsCSV');
            setCustomWaitingState(false);
        } else if (isSuccess && auditLogsCSVData?.data === null) {
            setCustomWaitingState(false);
            dispatch(setSnackbarState({ open: true, message: 'Something went wrong, please try again later!' }));
        } else if (isError) {
            setCustomWaitingState(false);
        }
    }, [isSuccess, isError, auditLogsCSVData]);

    return (
        <>
            <Box className="main-content-wrapper">
                <Card className="custom-card" sx={{ m: 0 }}>
                    {/* <Box className={classes.containerWrapper}>*/}
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        className="card-header"
                    >
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography
                                variant="body2"
                                component={'span'}
                                fontSize={'16px'}
                                fontWeight={600}
                                className="text-primary"
                            >
                                Audit Logs
                            </Typography>
                        </Stack>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={!allAuditLogs?.data?.total}
                            onClick={downloadCSVHandler}
                        >
                            Export as CSV
                        </Button>
                    </Stack>
                    <Divider />
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        className="pos-relative table-header-search-box"
                    >
                        <GlobalSearch
                            handleGlobalSearch={handleGlobalSearch}
                            searchKey={searchKey}
                            setSearchKey={setSearchKey}
                        />
                    </Stack>
                    <Divider />
                    <Stack direction="row" spacing={1} className="chip-spacing">
                        {Object.keys(paginationPayload?.filters).map((key: string) => (
                            <Chip
                                key={uuidv4()}
                                label={getFilterLabel(key)}
                                onDelete={(): void => tableRef?.current?.resetFilters(key, true)}
                            />
                        ))}
                    </Stack>
                    <CardContent className="card-content">
                        <TableContainer>
                            <CustomTable
                                ref={tableRef}
                                isPagination={true}
                                controlledPageSize={paginationPayload?.page}
                                total={allAuditLogs?.data?.total}
                                keyToTraverse="id"
                                handleFilterChange={handleFilterChange}
                                handlePageChange={handlePaginationChange}
                                data={allAuditLogs?.data?.records}
                                headers={columns}
                                containerClass="custom-data-table"
                                wrapperClass={getWrapperClassForTable()}
                                isLoading={isLoading || isFetching}
                                noDataFoundTitle={
                                    !allAuditLogs ? 'Something went wrong !! No logs found' : 'No Logs found'
                                }
                                defaultPageSize={25}
                                noDataFoundIcon={<Description fontSize="inherit" />}
                            ></CustomTable>
                        </TableContainer>
                    </CardContent>
                    <Dialog
                        fullWidth
                        open={requestModal}
                        onClose={(): void => setRequestModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="common-modal-view"
                    >
                        <DialogTitle id="alert-dialog-title" className="modal-header">
                            <Typography variant={'h6'}> {'Request'}</Typography>
                        </DialogTitle>
                        <Divider />
                        <DialogContent className="modal-body">
                            <Box className="custom-json-view">
                                <ReactJson src={auditRequest} enableClipboard={false} theme="monokai" />
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions className="modal-footer">
                            <Button variant={'text'} onClick={(): void => setRequestModal(false)}>
                                OKAY
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullWidth
                        open={responseModal}
                        onClose={(): void => setResponseModal(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="common-modal-view"
                    >
                        <DialogTitle id="alert-dialog-title" className="modal-header">
                            <Typography variant={'h6'}> {'Response'}</Typography>
                        </DialogTitle>
                        <Divider />
                        <DialogContent className="modal-body">
                            <Box className="custom-json-view">
                                <ReactJson src={auditResponse} enableClipboard={false} theme="monokai" />
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions className="modal-footer">
                            <Button variant={'text'} onClick={(): void => setResponseModal(false)}>
                                OKAY
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            </Box>
            {customWaitingState && <CustomWaitingState />}
        </>
    );
};
