import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useAppDispatch } from '../redux-config/store';
import { setSnackbarState } from '../features/common/commonSlice';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    isOpen: boolean;
    message: string;
    isErrorAlert?: boolean;
};

const OpenSnackbar = (props: Props): React.JSX.Element => {
    const dispatch = useAppDispatch();
    const handleClose = (): void => {
        dispatch(setSnackbarState({ open: false }));
    };
    return (
        <Snackbar
            open={props?.isOpen}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Alert
                key={uuidv4()}
                severity={props?.isErrorAlert ? 'error' : 'success'}
                variant="filled"
                sx={{ width: '100%', color: '#fdf7f7', backgroundColor: props?.isErrorAlert ? '#bd2e2f' : '#1d2529' }}
                onClose={handleClose}
            >
                {props?.message}
            </Alert>
        </Snackbar>
    );
};

export default OpenSnackbar;
