type Constants = {
    CHART_COMPLIANCE_LEGEND_UPDATE_IN_PROGRESS?: string;
    CHART_COMPLIANCE_LEGEND_UPDATE_AVAILABLE?: string;
    CHART_COMPLIANCE_LEGEND_UPDATE_LATEST_UPDATE?: string;
    CHART_COMPLIANCE_TOOLTIP_UPDATE_IN_PROGRESS?: string;
    CHART_COMPLIANCE_TOOLTIP_UPDATE_AVAILABLE?: string;
    CHART_COMPLIANCE_TOOLTIP_UPDATE_LATEST_UPDATE?: string;
    ADU_READY_TOOLTIP?: string;
    NOT_ADU_INITIATED_TOOLTIP?: string;
};

export const ChartConstants: Constants = {
    CHART_COMPLIANCE_LEGEND_UPDATE_IN_PROGRESS: 'Updates in progress',
    CHART_COMPLIANCE_LEGEND_UPDATE_AVAILABLE: 'New updates available',
    CHART_COMPLIANCE_LEGEND_UPDATE_LATEST_UPDATE: 'On latest update',
    CHART_COMPLIANCE_TOOLTIP_UPDATE_IN_PROGRESS:
        'An active deployment is in the process of delivering the highest compatible version update to the device.',
    CHART_COMPLIANCE_TOOLTIP_UPDATE_AVAILABLE:
        "A device hasn't yet installed the highest compatible version update and isn't in an active deployment for that update",
    CHART_COMPLIANCE_TOOLTIP_UPDATE_LATEST_UPDATE:
        'The device has installed the highest compatible version update published to Device Update.',
    ADU_READY_TOOLTIP: 'Number of devices with active device update agent (ADU agent).',
    NOT_ADU_INITIATED_TOOLTIP: `Number of devices without device update agent (ADU agent) support.`,
};
