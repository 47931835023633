import { Box, Typography, CircularProgress } from '@mui/material';
import React from 'react';

type Props = {
    responseData: number;
    isError: boolean | undefined;
    customClass?: string;
};

export const CustomErrorHandling = (props: Props): React.JSX.Element => {
    switch (true) {
        case props?.responseData < 1:
            return (
                <Box className="no-data-found-wrapper">
                    <Typography variant="h6">No data found.</Typography>
                </Box>
            );
        case props?.isError:
            return (
                <Box className="no-data-found-wrapper">
                    <Typography variant="h6">
                        Sorry, something went wrong. We are working on it and get it fixed as soon as we can.
                    </Typography>
                </Box>
            );
        default:
            return (
                <Box className={props?.customClass ?? 'center-loader'}>
                    <CircularProgress thickness={5} size={50} />
                </Box>
            );
    }
};
