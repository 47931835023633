import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { SideNav } from '../components/SideNav';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../redux-config/store';
import { setCredentials } from '../features/auth/authSlice';
import { useGetProfileDetailsQuery } from '../features/profile/profileApi';
import { BackdropLoader } from '../assets/loader/BackdropLoader';
import { HeaderMenu } from './HeaderMenu';
import { setProfileState, setRecallProfile } from '../features/common/commonSlice';
import { useSelector } from 'react-redux';

const SecureAuth = ({ isToken, onAppLoaded }: { isToken: boolean; onAppLoaded: () => void }): React.JSX.Element => {
    /*Additional hooks*/
    const location = useLocation();
    const { oktaAuth, authState } = useOktaAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);
    const recallProfileProps = useSelector((state: any) => state.commonSlices.recallProfile);

    /*Variables*/
    const pathName = location?.pathname;

    /*States*/
    const [isAppAuthenticated, setIsAppAuthenticated] = useState(false);

    /*Api Calls*/
    const {
        data,
        isError: isErrorForProfileDetails,
        error,
        refetch: refetchProfileCall,
        isFetching: isFetchingProfileDetails,
    } = useGetProfileDetailsQuery(undefined, {
        skip: !isAppAuthenticated,
    });

    /*Effects*/
    useEffect(() => {
        dispatch(
            setProfileState({
                ...profileProps,
                email: data?.data?.email,
                firstName: data?.data?.firstName,
                lastName: data?.data?.lastName,
                adopterName: data?.data?.adopterName,
                adopterId: data?.data?.selectedAdopter,
                iotHubName: data?.data?.iotHubName,
                iotHubId: data?.data?.selectedIotHub,
                phoneNumber: data?.data?.phoneNumber,
            })
        );
        if (
            data?.data?.selectedAdopter &&
            data?.data?.selectedIotHub &&
            (pathName === '/summary' || pathName === '/' || pathName === '/noProfileData')
        ) {
            navigate('/summary');
        } else {
            navigate(`${pathName}`);
            if (data?.data?.selectedAdopter === null || data?.data?.selectedIotHub === null)
                navigate('/newUserBackground');
        }
    }, [data?.data]);

    useEffect(() => {
        if (isErrorForProfileDetails) {
            navigate('/noProfileData', { state: { errorState: isErrorForProfileDetails, errorToDisplay: error } });
        }
    }, [isErrorForProfileDetails]);

    useEffect(() => {
        if (!authState) {
            return;
        }
        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            navigate('/login');
        } else {
            // condition is for new user if no adopter/iotHub is selected then do not navigate to homepage(summary)
            if (location.pathname === '/' && data?.data?.selectedAdopter && data?.data?.selectedIotHub)
                navigate('/summary');
            dispatch(setCredentials({ token: authState?.accessToken?.accessToken ?? '' }));
            onAppLoaded();
            setIsAppAuthenticated(true);
        }
    }, [!!authState, authState?.isAuthenticated, location.pathname]);

    useEffect(() => {
        const fetchProfile = async (): Promise<void> => {
            await refetchProfileCall().then((res: any) => {
                if (res?.error) {
                    console.error(res?.error?.data?.error);
                }
                dispatch(setRecallProfile(false));
            });
        };
        if (recallProfileProps) {
            fetchProfile().catch(() => console.error('Error in fetchData!'));
        }
    }, [recallProfileProps]);

    return (
        <>
            {isFetchingProfileDetails && <BackdropLoader isOpen={isFetchingProfileDetails} />}
            {isToken && (
                <>
                    <HeaderMenu isAppAuthenticated={isAppAuthenticated} />
                    <SideNav isErrorForProfileDetails={isErrorForProfileDetails} />
                    <Outlet />
                </>
            )}
        </>
    );
};
export default SecureAuth;
