import { api } from '../../redux-config/api/baseApi';

const commonApis = api.injectEndpoints({
    endpoints: (builder) => ({
        getSummaryGroups: builder.query({
            query: () => ({
                url: '/du/v1/summary/groups',
                method: 'GET',
            }),
            providesTags: ['SummaryGroup', 'DeleteGroups', 'Profile', 'AddToGroups'],
        }),
        getSubgroups: builder.mutation({
            query: (body) => ({
                url: '/du/v1/summary/subgroups',
                method: 'POST',
                body,
            }),
        }),
        getUpdateComplianceData: builder.mutation({
            query: (body) => ({
                url: '/du/v1/summary/subgroups/updateCompliance',
                method: 'POST',
                body,
            }),
        }),
        getTargetDevicesForDeployment: builder.query<any, any>({
            query: ({ groupId, deviceClassId }) => ({
                url: `/du/v1/deployments/${groupId}/classId/${deviceClassId}/target/devices`,
                method: 'GET',
            }),
            providesTags: ['SummaryGroup'],
        }),
        getGroupComplianceData: builder.mutation({
            query: (body) => ({
                url: '/du/v1/summary/groups/updateCompliance',
                method: 'POST',
                body,
            }),
        }),
        updateAssigneeGroupCSV: builder.mutation<any, any>({
            query: ({ groupNameForDevices, uploadedFile }) => {
                const formData = new FormData();
                formData.append('file', uploadedFile);
                return {
                    url: `du/v1/groups/${groupNameForDevices}/assign`,
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['SummaryGroup'] : []),
        }),
        deleteGroups: builder.mutation<any, any>({
            query: (data) => ({
                url: 'du/v1/groups',
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: (result, error): any => (!error ? ['DeleteGroups'] : []),
        }),
        unAssigneeDevices: builder.mutation<any, any>({
            query: (data) => ({
                url: 'du/v1/groups/unAssign',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error): any => (!error ? ['devices', 'deviceId', 'AddToGroups'] : []),
        }),

        deleteDevicesCSV: builder.mutation<any, any>({
            query: ({ uploadedFile }) => {
                const formData = new FormData();
                formData.append('file', uploadedFile);
                return {
                    url: `du/v1/groups/unAssign/csv`,
                    method: 'POST',
                    body: formData,
                };
            },
            invalidatesTags: (result, error): any => (!error ? ['SummaryGroup'] : []),
        }),
    }),
});

export const {
    useGetSummaryGroupsQuery,
    useGetSubgroupsMutation,
    useGetUpdateComplianceDataMutation,
    useGetTargetDevicesForDeploymentQuery,
    useGetGroupComplianceDataMutation,
    useUpdateAssigneeGroupCSVMutation,
    useDeleteGroupsMutation,
    useUnAssigneeDevicesMutation,
    useDeleteDevicesCSVMutation,
} = commonApis;
