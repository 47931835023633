import {
    Dialog,
    DialogTitle,
    Typography,
    Divider,
    DialogContent,
    List,
    ListItem,
    Stack,
    DialogActions,
    Button,
    Box,
    CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import CustomTable from '../../components/CustomTable';
import { getLocalTimeStamp } from '../../commonUtils';
import { FirmwareDetails } from '../../types';
import { EmptyObj } from '../../types/Common';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReactJson from 'react-json-view';
import { FullScreenJsonView } from './FullScreenJsonView';

interface TabPanelProps {
    children?: React.ReactNode;
}

const CustomTabPanel = (props: TabPanelProps): React.JSX.Element => {
    const { children } = props;

    return (
        <div>
            <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
            </Box>
        </div>
    );
};

type Props = {
    data: FirmwareDetails | EmptyObj;
    handleOpenModal: boolean;
    handleCloseModal: () => void;
    modalHeaders: (data: object[]) => Array<{ header?: string; accessor?: string }>;
    isLoading?: boolean;
};

const FirmwareDetailsModal = ({
    data,
    handleOpenModal,
    handleCloseModal,
    modalHeaders,
    isLoading,
}: Props): React.JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<string>('generalInfoTab');
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

    const getSelectedTabDetails = (): React.JSX.Element => {
        if (selectedTab === 'generalInfoTab') {
            return (
                <>
                    <List className="detail-list provider-detail-list" sx={{ pt: 0 }}>
                        <ListItem className="justify-content-between" sx={{ px: 0, pt: 0 }}>
                            <Typography className="list-sub-header align-items-center" variant="body1">
                                Name{' '}
                            </Typography>
                            <Typography fontWeight={600} variant="body1">
                                {data?.updateId?.name}
                            </Typography>
                        </ListItem>
                        <ListItem className="justify-content-between" sx={{ px: 0 }}>
                            <Typography className="list-sub-header align-items-center" variant="body1">
                                Created at
                            </Typography>
                            <Stack flexDirection={'row'} m={0}>
                                <Typography mx={'5px'} fontWeight={700} variant="body1">
                                    {getLocalTimeStamp(data?.createdDateTime)}
                                </Typography>
                            </Stack>
                        </ListItem>
                        <ListItem className="justify-content-between" sx={{ px: 0 }}>
                            <Typography className="list-sub-header align-items-center" variant="body1">
                                Updated at
                            </Typography>
                            <Stack flexDirection={'row'} m={0}>
                                <Typography mx={'5px'} fontWeight={700} variant="body1">
                                    {getLocalTimeStamp(data?.importedDateTime)}
                                </Typography>
                            </Stack>
                        </ListItem>
                        <ListItem className="justify-content-between" sx={{ px: 0 }}>
                            <Typography className="list-sub-header align-items-center" variant="body1">
                                Description
                            </Typography>
                            <Typography fontWeight={600} variant="body1">
                                {data?.friendlyName}
                            </Typography>
                        </ListItem>
                    </List>
                    <Divider />
                    <Typography variant="h6" fontSize={'14px'} fontWeight={400} className="text-content" py={2}>
                        Compatible Device Classes <b>{`${data?.compatibility?.length}`}</b>
                    </Typography>
                    <List sx={{ border: '1px solid rgba(66, 78, 84, 0.12)', p: 0 }} className="custom-list">
                        <CustomTable
                            total={data?.compatibility?.length}
                            keyToTraverse="id"
                            data={data?.compatibility}
                            headers={modalHeaders(data?.compatibility ?? [])}
                            containerClass="custom-data-table"
                            isLoading={false}
                            noDataFoundTitle={
                                !data?.compatibility ? 'Something went wrong !! No logs found' : 'No Logs found'
                            }
                        />
                    </List>
                </>
            );
        }
        return (
            <>
                <Box className="custom-json-view custom-json-view-height">
                    <ReactJson src={data} enableClipboard={false} theme="monokai" />
                </Box>
                <Stack flexDirection={'row'} justifyContent={'flex-end'} pt={1}>
                    <Button variant="text" onClick={(): void => setFullScreenMode(true)}>
                        Toggle Fullscreen...
                    </Button>
                </Stack>
            </>
        );
    };

    const closeFirmwareDetailsModal = (): void => {
        setSelectedTab('generalInfoTab');
        handleCloseModal();
    };

    return (
        <>
            {/* Provider Detail Modal */}
            <Dialog
                open={handleOpenModal}
                onClose={closeFirmwareDetailsModal}
                fullWidth={true}
                maxWidth="sm"
                className="form-wrapper common-modal-view"
            >
                <>
                    <DialogTitle id="alert-dialog-title" className="form-header" sx={{ pb: 0 }}>
                        <Typography variant="body1" className="header-text">
                            Firmware Details : {data?.updateId?.name}
                        </Typography>
                    </DialogTitle>
                    <Divider />
                    <DialogContent className="tabs-modal-wrapper">
                        {/* Tab section */}
                        <Box sx={{ width: '100%', p: '0px !important' }}>
                            <Box>
                                <Tabs className="tab-content" aria-label="basic tabs example">
                                    <Tab
                                        label="Overview"
                                        id="generalInfoTab"
                                        onClick={(): void => setSelectedTab('generalInfoTab')}
                                        className={selectedTab === 'generalInfoTab' ? 'tab-selected' : ''}
                                    />
                                    <Tab
                                        label="View Manifest"
                                        id="manifestJsonTab"
                                        onClick={(): void => setSelectedTab('manifestJsonTab')}
                                        className={selectedTab === 'manifestJsonTab' ? 'tab-selected' : ''}
                                    />
                                </Tabs>
                            </Box>
                            <CustomTabPanel>
                                <Box className="custom-loader-wrapper custom-loader-wrapper-firmware-view-height">
                                    {isLoading ? (
                                        <Box className="custom-loader-spin">
                                            <CircularProgress thickness={5} size={50} />
                                        </Box>
                                    ) : (
                                        getSelectedTabDetails()
                                    )}
                                </Box>
                            </CustomTabPanel>
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions className="form-footer d-flex justify-content-between">
                        <Button
                            sx={{ textTransform: 'capitalize' }}
                            variant="outlined"
                            onClick={closeFirmwareDetailsModal}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
            {/* End Provider Detail Modal */}
            <FullScreenJsonView
                srcJSON={data}
                handleOpen={fullScreenMode}
                handleClose={(): void => setFullScreenMode(false)}
            />
        </>
    );
};

export default FirmwareDetailsModal;
