import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';

//MUI imports
import {
    Typography,
    Box,
    Card,
    CardContent,
    TextField,
    TableContainer,
    Divider,
    Stack,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    Button,
    CircularProgress,
} from '@mui/material';
import { Spacer } from '@brightlayer-ui/react-components';
import Autocomplete from '@mui/material/Autocomplete';
import { Devices } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';

//APIs imports
import { useGetDeviceListQuery, useUpdateAssigneeGroupMutation } from './deviceApi';
import { useGetGroupDetailsQuery } from '../Summary/groupApi';
import {
    getFiltersList,
    getIotHubStats,
    getDeviceStats,
    getUnknownDeviceCount,
    getSitesList,
} from '../../features/common/commonApis';

//Components imports
import TagBox from '../../components/TagBox';
import CustomTable from '../../components/CustomTable';
import { CountersComponent } from '../../components/CountersComponent';
import DeviceTableCustomFilters from './DeviceTableCustomFilters';

//Custom Hooks imports
import { useSearchFilter } from '../../hooks/useSearchFilter';
import { useLoadMoreData } from '../../hooks/useLoadMoreData';
import useDeviceTableHeaders from './DeviceTableHeaders';
import { useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import { GroupId } from '../../types';
import { PaginationPayload } from '../../types/Common';
import CustomTextField from '../../components/CustomTextField';

/*Types*/
export type Filters = {
    [key: string]: any;
};

interface DevicePaginationPayload extends PaginationPayload {
    isAduEnabled: boolean;
}

/*Utility Functions*/
export const initialPaginationPayload = {
    page: 0,
    size: 25,
    isAduEnabled: true,
    filters: {},
};

export const handlePaginationPayload = (
    filters: any,
    payload: DevicePaginationPayload,
    sortedData: {
        key: string;
        sortType: string;
    },
    setPaginationPayload: (payload: any) => void
): void => {
    if (JSON.stringify(filters) !== JSON.stringify(payload.filters)) {
        setPaginationPayload((prev: any) => ({
            ...prev,
            page: 0,
            filters: filters,
        }));
    } else if (Object.keys(sortedData)?.length) {
        setPaginationPayload((prev: any) => ({
            ...prev,
            page: 0,
            sort: sortedData,
        }));
    }
};

export const getFilterLabel = (key: string, columns: any[]): string | undefined => {
    if (key === 'siteId') return 'Site';
    const filteredColumn = columns.find((obj: { accessor: string }) => obj.accessor === key);
    return filteredColumn?.header;
};

/*Main Component*/
export const DeviceTable = (): React.JSX.Element => {
    /*Additional Hooks*/
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);
    const tableRefForDevices = useRef<any>();

    /*States*/
    const [selectedIdsArr, setSelectedIdsArr] = useState<string[]>([]);
    const [isAddToGrpModalOpen, setIsAddToGrpModalOpen] = useState(false);
    const [selectedIdsArrClone, setSelectedIdsArrClone] = useState<string[]>([]);
    const [isMenuOpen, setIsMenuOpen] = React.useState<null | HTMLElement>(null);
    const [additionalFilters, setAdditionalFilters] = useState<{ siteId?: string[]; connectionState?: string[] }>({});
    const [popupModal, setPopupModal] = useState<{ isOpen: boolean; groupName: string }>({
        isOpen: false,
        groupName: '',
    });
    const [isTagBoxActive, setIsTagBoxActive] = useState<boolean>(false);
    const [devicesStatus, setDevicesStatus] = useState<string>(location?.state?.devicesStatus);
    const [paginationPayload, setPaginationPayload] = useState<DevicePaginationPayload>(initialPaginationPayload);
    const [selectedAssigneeValue, setSelectedAssigneeValue] = useState<string>('');
    const [assigneeDeviceId, setAssigneeDeviceId] = useState<string | null>(null);
    const [selectedGroupName, setSelectedGroupName] = useState<string | undefined>('');
    // assign group modal
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [resetSearchFilters, setResetSearchFilters] = useState({});

    /*Api Calls*/
    //device List API call.
    const { data, isError, isFetching } = useGetDeviceListQuery(paginationPayload, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });
    const [getFiltersListQuery, { currentData: filtersData }]: any = getFiltersList.useLazyQuery();
    const [getSitesListQuery, { currentData: sitesData, isFetching: isFetchingSites }]: any =
        getSitesList.useLazyQuery();
    const [getIotHubStatsQuery, { currentData: hubStats }]: any = getIotHubStats.useLazyQuery();
    const [getDeviceStatsQuery, { currentData: deviceStats }]: any = getDeviceStats.useLazyQuery();
    const [getUnknownDeviceCountQuery, { currentData: unknownDeviceStats }]: any = getUnknownDeviceCount.useLazyQuery();
    const assigneeGroupListData = useGetGroupDetailsQuery(undefined);
    const [updateAssigneeGroup, result] = useUpdateAssigneeGroupMutation();
    const { isLoading: isLoadingAssignGroup } = result;

    /*Custom Hooks*/
    const [handleSearch, filteredSearchData, searchValue] = useSearchFilter(sitesData?.data?.site);
    const { dataToShow, handleLoadMore }: { dataToShow: object[]; handleLoadMore: () => void } =
        useLoadMoreData(filteredSearchData);

    /*Variables*/
    const open = Boolean(isMenuOpen);

    /* The code below defines a variable `assigneeGroupList` which is an array of strings extracted from the `assigneeGroupListData` object.
    The data is filtered to exclude items with a `groupId` of '$default' and then mapped to extract only the `groupId` values. */
    const assigneeGroupList: string[] = assigneeGroupListData?.data?.data
        ?.filter((item: GroupId) => item?.groupId !== '$default')
        .map((item: GroupId) => item?.groupId);
    const isLoaderAssignGroupModal = assigneeGroupListData?.isLoading;
    const isFetchingAssignGroupModal = assigneeGroupListData?.isFetching;

    /*Counter Click Handlers*/
    /**
     * The function `counterClickHandler` updates pagination and additional filters based on the status of
     * device connections.
     */
    const counterClickHandler = (): void => {
        const status = devicesStatus;
        switch (status) {
            case 'notAduConnected':
                setPaginationPayload((prev) => ({
                    ...prev,
                    page: 0,
                    isAduEnabled: false,
                }));
                setAdditionalFilters((prev) => ({ ...prev, connectionState: ['Connected'] }));
                break;
            case 'notAduDisconnected':
                setPaginationPayload((prev) => ({
                    ...prev,
                    page: 0,
                    isAduEnabled: false,
                }));
                setAdditionalFilters((prev) => ({ ...prev, connectionState: ['Disconnected'] }));
                break;
            case 'notAduDevices':
                handleGroupFilter(false);
                break;
            case 'aduDevices':
                handleGroupFilter(true);
                break;
            case 'aduConnectedDevices':
                setPaginationPayload((prev) => ({
                    ...prev,
                    page: 0,
                    isAduEnabled: true,
                }));
                setAdditionalFilters((prev) => ({ ...prev, connectionState: ['Connected'] }));
                break;
            case 'aduDisconnectedDevices':
                setPaginationPayload((prev) => ({
                    ...prev,
                    page: 0,
                    isAduEnabled: true,
                }));
                setAdditionalFilters((prev) => ({ ...prev, connectionState: ['Disconnected'] }));
                break;

            default:
                setPaginationPayload((prev) => ({
                    ...prev,
                    page: 0,
                    isAduEnabled: true,
                }));
                setAdditionalFilters((prev) => ({ ...prev, groupName: [status] }));
                break;
        }
        setDevicesStatus('');
    };

    /*Table Handlers*/
    /**
     * The code snippet contains various functions related to handling pagination, filters, site filtering,
     * group navigation, modals, checkboxes, and table display in a TypeScript React application.
     * @param {number} page - The `page` parameter in the `handlePaginationChange` function represents the
     * page number that is being selected for pagination. It is used to update the pagination payload with
     * the new page number.
     * @param {number} size - The `size` parameter in the `handlePaginationChange` function represents the
     * number of items per page in a paginated table or list. It determines how many items will be
     * displayed on each page when paginating through a large dataset. The user can specify the size to
     * control the pagination behavior and the
     */
    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev) => ({ ...prev, page: page, size: size }));
    };

    const handleFilterChange = (
        tableFilters: { siteId?: any[] },
        sortedData: {
            key: string;
            sortType: string;
        }
    ): void => {
        if (!tableFilters?.siteId?.length) {
            delete tableFilters.siteId;
        }
        handlePaginationPayload(tableFilters, paginationPayload, sortedData, setPaginationPayload);
    };

    const handleSiteFilter = (id: string, event: React.ChangeEvent<any>): void => {
        if (!event.target.checked) {
            setAdditionalFilters((prevState) => {
                const updatedSiteId = (prevState.siteId ?? []).filter((siteId: string) => siteId !== id);
                return { ...prevState, siteId: updatedSiteId };
            });
        } else {
            setAdditionalFilters((prevState) => {
                const updatedSiteId = [...(prevState.siteId ?? []), id];
                return { ...prevState, siteId: updatedSiteId };
            });
        }
    };

    const handleFilterRemove = (key: string): void => {
        if (key === 'siteId') {
            setAdditionalFilters((prev) => {
                delete prev.siteId;
                return { ...prev };
            });
        } else if (key === 'connectionState') {
            setAdditionalFilters((prev) => {
                delete prev.connectionState;
                return { ...prev };
            });
        }
        tableRefForDevices?.current?.resetFilters(key, true);
        setResetSearchFilters((prev) => ({ ...prev, [key]: true }));
    };

    const navigateToGroup = (groupName: string): void => {
        /* The below code is checking if a group with a specific
       `groupId` exists in the `assigneeGroupListData` data. If the group is found based on the
       `groupName` or if `groupName` is 'default', it navigates to a specific URL using
       `navigate()`. If the group is not found, it sets a popup modal with the group name using
       `setPopupModal()`. */
        const isGroupPresent = assigneeGroupListData?.data?.data.find(
            (group: { groupId: string }) => group.groupId === groupName || groupName === 'default'
        );
        if (isGroupPresent?.groupId) {
            navigate(`/deviceTable/groupsTab/${groupName}/overview`);
        } else {
            setPopupModal({ isOpen: true, groupName });
        }
    };
    const handleGroupOpen = (deviceId: string | null, deviceGroupName: string | undefined): void => {
        setOpenModal(true);
        setAssigneeDeviceId(deviceId);
        setSelectedGroupName(deviceGroupName);
        setIsTagBoxActive(false);
    };

    //Device Headers Hooks
    const deviceListColumns = useDeviceTableHeaders(
        filtersData,
        assigneeGroupListData,
        navigateToGroup,
        handleGroupOpen,
        resetSearchFilters
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setIsMenuOpen(event.currentTarget);
    };
    const handleClose = (): void => {
        setIsMenuOpen(null);
    };

    const handlePopupClose = (): void => {
        setPopupModal({ isOpen: false, groupName: '' });
    };

    const handleGroupClose = (): void => {
        setOpenModal(false);
        setIsAddToGrpModalOpen(false);
        setSelectedAssigneeValue('');
    };

    //Function to handle selected assignee group.
    const handleSelectAssignee = (_event: React.ChangeEvent<any>, newValue: string | null): void => {
        if (newValue !== null) setSelectedAssigneeValue(newValue);
    };

    //Function to handle assignee group data.
    const handleAssigneeGroup = async (): Promise<void> => {
        const requestDataForAssigneeGroup = {
            deviceIds: assigneeDeviceId !== null ? [assigneeDeviceId] : selectedIdsArrClone,
            groupId: selectedAssigneeValue,
        };
        const response: any = await updateAssigneeGroup(requestDataForAssigneeGroup);

        if (response?.data?.success) {
            handleGroupClose();
            dispatch(setSnackbarState({ open: true, message: 'Device added to group successfully !' }));
        }
    };

    const handleCheckboxSelect = (checkBoxIdsArr: string[]): void => {
        setSelectedIdsArr(checkBoxIdsArr);
        setSelectedIdsArrClone(checkBoxIdsArr);
    };

    const handleGroupFilter = (isAduEnabled: boolean): void => {
        tableRefForDevices?.current?.resetFilters('clearAll');
        setAdditionalFilters({});
        setPaginationPayload({
            ...initialPaginationPayload,
            isAduEnabled,
        });
    };

    const handleAddToGroupModal = (): void => {
        setIsAddToGrpModalOpen(true);
        setSelectedIdsArrClone(selectedIdsArr);
        setIsTagBoxActive(true);
    };

    const handleTagsChange = (tags: string[]): void => {
        setSelectedIdsArrClone(tags);
    };

    const getWrapperClassForTable = (): string => {
        if (data?.data?.total === 0 || isError) {
            return 'device-table-white-space custom-device-table-responsive device-table-no-data-found-height';
        } else if (Object.keys(paginationPayload.filters).length > 0) {
            return 'custom-device-table-responsive-height-for-filters device-table-filter-height';
        }
        return 'device-table-white-space custom-device-table-responsive device-table-no-data-found-height';
    };

    const assignButtonDisableHandler = (): boolean =>
        selectedAssigneeValue === selectedGroupName ||
        isLoadingAssignGroup ||
        isFetchingAssignGroupModal ||
        selectedAssigneeValue === '' ||
        Boolean(errorMessage);

    /*Effects*/
    /* The above code is a `useEffect` hook in a TypeScript React component. It runs a function
   `counterClickHandler()` when either `devicesStatus` or `paginationPayload.isAduEnabled` changes.
   This effect is triggered when the component mounts or when either of the dependencies change. */
    useEffect(() => {
        if (devicesStatus) {
            counterClickHandler();
        }
    }, [devicesStatus, paginationPayload.isAduEnabled]);

    useEffect(() => {
        getIotHubStatsQuery();
        getDeviceStatsQuery();
        getUnknownDeviceCountQuery();
        getFiltersListQuery();
        getSitesListQuery();
    }, []);

    return (
        <Box className="main-content-wrapper">
            <CountersComponent
                deviceData={deviceStats?.data}
                hubData={hubStats?.data}
                unknownDeviceData={unknownDeviceStats?.data}
                setDevicesStatus={setDevicesStatus}
            />
            <Card sx={{ m: 0 }} className="custom-card mt-24">
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className="card-header"
                >
                    <Box>
                        <Typography
                            variant="body2"
                            component={'span'}
                            fontSize={'16px'}
                            fontWeight={600}
                            className="text-primary"
                        >
                            Devices registered on : {profileProps?.iotHubName || '--'}
                            {` (Showing ${data?.data?.records?.length ?? 0} of ${data?.data?.total ?? 0})`}
                        </Typography>
                    </Box>
                    <Box>
                        {selectedIdsArr.length > 0 && (
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                disabled={selectedIdsArr.length === 0}
                                onClick={handleAddToGroupModal}
                                data-cy="add-to-group-button"
                            >
                                Add to group
                            </Button>
                        )}
                    </Box>
                </Stack>
                <Divider />
                <DeviceTableCustomFilters
                    handleClick={handleClick}
                    open={open}
                    handleClose={handleClose}
                    handleSearch={handleSearch}
                    sitesData={sitesData}
                    searchValue={searchValue}
                    dataToShow={dataToShow}
                    additionalFilters={additionalFilters}
                    handleSiteFilter={handleSiteFilter}
                    filteredSearchData={filteredSearchData}
                    isFetchingSites={isFetchingSites}
                    handleLoadMore={handleLoadMore}
                    paginationPayload={paginationPayload}
                    handleGroupFilter={handleGroupFilter}
                    isMenuOpen={isMenuOpen}
                />
                <Divider />
                <Stack direction="row" spacing={1} className="chip-spacing">
                    {Object.keys(paginationPayload.filters).map((key: string) => (
                        <Chip
                            key={uuidv4()}
                            label={getFilterLabel(key, deviceListColumns)}
                            onDelete={(): void => handleFilterRemove(key)}
                        />
                    ))}
                </Stack>
                <CardContent className="card-content">
                    <TableContainer>
                        <CustomTable
                            ref={tableRefForDevices}
                            isPagination={true}
                            controlledPageSize={paginationPayload?.page}
                            total={data?.data?.total}
                            keyToTraverse="deviceId"
                            handleFilterChange={handleFilterChange}
                            handlePageChange={handlePaginationChange}
                            data={data?.data?.records}
                            headers={deviceListColumns}
                            containerClass="custom-data-table"
                            wrapperClass={getWrapperClassForTable()}
                            isLoading={isFetching || isFetchingAssignGroupModal}
                            noDataFoundTitle={isError ? 'Something went wrong' : 'No devices found'}
                            noDataFoundIcon={<Devices fontSize="inherit" />}
                            handleCheckboxSelect={handleCheckboxSelect}
                            keyToCheckForDisabledCheckbox="connectionState"
                            valueToCheckForDisabledCheckbox="Disconnected"
                            additionalFilterValue={additionalFilters}
                            setAdditionalFilterValue={setAdditionalFilters}
                            defaultPageSize={25}
                        ></CustomTable>
                    </TableContainer>
                    <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'35%'}>
                        {/* assign group modal starts */}
                        <Dialog
                            open={openModal || isAddToGrpModalOpen}
                            onClose={handleGroupClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className="modal-wrapper common-modal-view"
                        >
                            <DialogTitle id="alert-dialog-title" className="modal-header">
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    <Typography variant="h6">Assign Group</Typography>
                                </Stack>
                                <FormHelperText>Assign a group to selected device(s).</FormHelperText>
                            </DialogTitle>
                            <Divider />
                            <DialogContent>
                                {isLoaderAssignGroupModal || isFetchingAssignGroupModal ? (
                                    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                                        <CircularProgress thickness={5} size={50} />
                                    </Stack>
                                ) : (
                                    <DialogContentText id="alert-dialog-description" className="modal-body">
                                        {isAddToGrpModalOpen ? (
                                            <Stack maxHeight={'200px'} sx={{ overflowY: 'auto' }}>
                                                <TagBox tags={selectedIdsArrClone} onTagsChange={handleTagsChange} />
                                            </Stack>
                                        ) : (
                                            <TextField
                                                id="filled-basic"
                                                label="Device ID"
                                                variant="filled"
                                                value={assigneeDeviceId}
                                                sx={{ width: '100%' }}
                                                disabled
                                            />
                                        )}
                                        <Spacer margin={'24px'} />
                                        <Autocomplete
                                            freeSolo
                                            id="free-solo-2-demo"
                                            disableClearable
                                            value={selectedAssigneeValue}
                                            onChange={handleSelectAssignee}
                                            options={assigneeGroupList?.map((option) => option) || []}
                                            renderInput={(params): React.JSX.Element => (
                                                <CustomTextField
                                                    params={params}
                                                    setSelectedAssigneeValue={setSelectedAssigneeValue}
                                                    selectedAssigneeValue={selectedAssigneeValue}
                                                    setErrorMessage={setErrorMessage}
                                                    errorMessage={errorMessage}
                                                />
                                            )}
                                        />
                                        {selectedIdsArrClone?.length > 1 && isTagBoxActive && (
                                            <Typography
                                                variant="body1"
                                                fontSize={'16px'}
                                                marginTop={2}
                                                sx={{
                                                    border: '2px solid #80bde0',
                                                    borderRadius: '4px',
                                                    padding: 1,
                                                    marginBottom: 1,
                                                }}
                                            >
                                                <b>Note:</b> A Job will be scheduled for adding multiple devices in
                                                Group. This may take few minutes to complete.
                                            </Typography>
                                        )}
                                    </DialogContentText>
                                )}
                            </DialogContent>
                            <Divider />
                            <DialogActions className="modal-footer">
                                <Button onClick={handleGroupClose} variant="outlined" size="medium">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(): Promise<void> => handleAssigneeGroup()}
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    autoFocus
                                    startIcon={
                                        isLoadingAssignGroup && (
                                            <CircularProgress className="circular-upload-btn" sx={{ color: '#fff' }} />
                                        )
                                    }
                                    disabled={assignButtonDisableHandler()}
                                >
                                    Assign
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* assign group modal ends */}

                        {/* popup modal starts */}
                        <Dialog
                            open={popupModal.isOpen}
                            onClose={handlePopupClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className="modal-wrapper common-modal-view"
                        >
                            <DialogTitle id="alert-dialog-title" className="modal-header">
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    <WarningIcon className="custom-icon-warning-color" />
                                    <Typography variant="h6" pl={1}>
                                        Alert
                                    </Typography>
                                </Stack>
                            </DialogTitle>

                            <Divider />
                            <DialogContent>
                                Device has been tagged with GroupName -{' '}
                                <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
                                    {popupModal.groupName}
                                </Typography>
                                .<br />
                                It is not part of the Update Group yet as the device is not ADU enabled. Please make
                                sure an ADU agent is running on the device.
                            </DialogContent>
                            <Divider />
                            <DialogActions className="modal-footer">
                                <Button onClick={handlePopupClose} variant="outlined">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};
