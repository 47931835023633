import React, { useState } from 'react';
import { Button, Chip, DialogContentText, DialogContent, Divider, Typography, Box, Stack } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import ReactJson from 'react-json-view';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';
import { FullScreenJsonView } from './FullScreenJsonView';
import { FirmwareModalProps } from '../../types';

export const FirmwareModal2 = React.memo((props: FirmwareModalProps): React.JSX.Element => {
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
    return (
        <>
            <Divider />
            <DialogContent className="form-content modal-body">
                <DialogContentText id="alert-dialog-description" className="form-content-text modal-body">
                    <Typography variant="body2">Firmware manifest file</Typography>
                    <Box component={'form'} className="form-w-100">
                        <Box>
                            <input
                                title=""
                                type="file"
                                accept=".json"
                                className="custom-input mb-0 modal-file-select-padding "
                                onChange={(e): void => props?.handleFileUploadDownload(e, 'json')}
                            />
                        </Box>
                    </Box>
                    <>
                        {props.uploadFileName && (
                            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pt={2}>
                                <Stack flexDirection={'row'} alignItems={'center'}>
                                    {props?.progress === 100 ? (
                                        <CheckIcon className="custom-icon-color" />
                                    ) : (
                                        <WarningIcon className="custom-icon-warning-color" />
                                    )}
                                    <Tooltip title={props?.uploadFileName} placement="bottom-end">
                                        <Chip
                                            label={
                                                props?.uploadFileName?.length > 50
                                                    ? `${props?.uploadFileName.substring(0, 48)}...`
                                                    : props?.uploadFileName
                                            }
                                            variant="outlined"
                                            // onDelete={handleDelete}
                                            sx={{ marginLeft: '16px' }}
                                        />
                                    </Tooltip>
                                </Stack>
                                <Box>
                                    {props?.fileSize && (
                                        <Typography variant="body2" className="modal-paragraph">
                                            {props?.fileSize > 1024
                                                ? `${(props?.fileSize / 1024).toFixed(2)} kB`
                                                : `${props?.fileSize} bytes`}
                                        </Typography>
                                    )}
                                </Box>
                            </Stack>
                        )}
                    </>
                    <Stack flexDirection={'row'} alignItems={'center'} marginTop={2}>
                        {props?.validProps?.errorMessage && <ErrorIcon className="custom-error-icon" />}
                        <Typography variant="body2" className="custom-error-text">
                            {props?.validProps?.errorMessage}
                        </Typography>
                    </Stack>

                    {/* <FormHelperText sx={{ color: '#ca3c3d' }}>{validProps?.errorMessage}</FormHelperText> */}
                    <Box className="custom-text-editor" mt={2}>
                        <Box className="custom-json-view">
                            {props.uploadFileJson && props.progress === 100 && (
                                <>
                                    <ReactJson
                                        src={props.uploadFileJson}
                                        enableClipboard={false}
                                        collapseStringsAfterLength={5}
                                        theme="monokai"
                                    />
                                    <Stack flexDirection={'row'} justifyContent={'flex-end'} pt={1}>
                                        <Button variant="text" onClick={(): void => setFullScreenMode(true)}>
                                            Toggle Fullscreen...
                                        </Button>
                                    </Stack>
                                </>
                            )}
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <Divider />
            <FullScreenJsonView
                srcJSON={props.uploadFileJson}
                handleOpen={fullScreenMode}
                handleClose={(): void => setFullScreenMode(false)}
            />
        </>
    );
});
