import React, { useEffect } from 'react';
import { useDebounce } from './useDebounce';

type ReturnProps = [
    tableFilters: {
        [key: string]: string | string[];
    }
];

type AcceptedProps = {
    allFilters: Filter[];
    triggeredFilters: {
        [key: string]: string | string[];
    };
    timer?: number;
};

type Filter = {
    [key: string]: any;
};

/**
 * The `useFilteredValues` function is a custom React hook that manages and updates table filters based
 * on triggered filters.
 * @param {AcceptedProps}  - - `allFilters`: An array of all available filters.
 * @returns The function `useFilteredValues` returns an array containing the `tableFilters` state.
 */
export const useFilteredValues = ({ allFilters, triggeredFilters, timer }: AcceptedProps): ReturnProps => {
    const [tableFilters, setTableFilters] = React.useState<ReturnProps[0]>(triggeredFilters);

    const [debouncedFilterChange] = useDebounce(timer ?? 600);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this case,
it is used to trigger the `handleFilterChange` function whenever the `triggeredFilters` prop
changes. */
    useEffect(() => {
        handleFilterChange();
    }, [triggeredFilters]);

    /**
     * The function `handleFilterChange` iterates through all filters and updates the `tableFilters`
     * state based on the `triggeredFilters` state.
     */
    const handleFilterChange = (): void => {
        if (!triggeredFilters || Object.keys(triggeredFilters).length === 0) {
            debouncedFilterChange(() => {
                setTableFilters({});
            });
            return;
        }

        Object.keys(triggeredFilters).forEach((appliedFilter) => {
            const filterHeader = allFilters.find(
                (filter) => filter?.accessor === appliedFilter || filter?.filterKey === appliedFilter
            );

            if (!filterHeader || JSON.stringify(triggeredFilters) === JSON.stringify(tableFilters)) {
                return;
            }

            if (filterHeader.isDebounce) {
                debouncedFilterChange(() => {
                    setTableFilters(triggeredFilters);
                });
                return;
            }

            debouncedFilterChange(() => {
                setTableFilters((prevFilters) => ({
                    ...prevFilters,
                    [appliedFilter]: triggeredFilters[appliedFilter],
                }));
            });
        });

        const hasRemovedFilters = Object.keys(tableFilters).some(
            (filterKey) => !Object.hasOwn(triggeredFilters, filterKey)
        );

        if (hasRemovedFilters) {
            debouncedFilterChange(() => {
                setTableFilters(triggeredFilters);
            });
        }
    };
    return [tableFilters];
};
