/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
    Box,
    Typography,
    Card,
    Chip,
    Stack,
    Button,
    Divider,
    CardContent,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    Link,
    Tooltip,
} from '@mui/material';
import { AutoDeleteOutlined, AssignmentReturned } from '@mui/icons-material';
import CustomTable from '../../components/CustomTable';
import { useGetFirmwaresQuery, useUploadFirmwareDataMutation, useDeleteFirmwareMutation } from './firmwareApis';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { FirmwareModal1 } from '../../features/modal/FirmwareModal1';
import { FirmwareModal2 } from '../../features/modal/FirmwareModal2';
import FirmwareModal3 from '../../features/modal/FirmwareModal3';
import { StepperModal } from '../../features/modal/StepperModal';
import { useHandleFileUpload } from '../../hooks/useHandleFileUpload';
import FirmwareDetailsModal from '../../features/modal/firmwareDetailsModal';
import { getModalHeaders, downloadFile, handleClientSidePaginationSorting, getLocalTimeStamp } from '../../commonUtils';
import Checkbox from '@mui/material/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import manifest from '../../manifest.json';
import { NOTE_MESSAGE_FIRMWARE_DELETE, SEARCH_PARAMS } from '../../components/Constant/FirmwareConstants';
import { Firmware, FirmwareDetails } from '../../types';
import { EmptyObj, PaginationPayload } from '../../types/Common';
import { GlobalSearch } from '../../components/GlobalSearch';

/*Constants*/
const initialFirmwarePayload: Firmware = {
    name: '',
    description: '',
    manifest: [null],
    manifestFileName: '',
    file: [null],
};
const blob = new Blob([JSON.stringify(manifest)]);

const FirmwareOverview = (): React.JSX.Element => {
    /*Additional Hooks*/
    const dispatch = useAppDispatch();
    const tableRef = useRef<any>();

    /*Custom Hooks*/
    const [
        handleModal2FileUpload,
        {
            progress: modal2Progress,
            validProps: modal2ValidProps,
            uploadFileJson: modal2UploadFileJson,
            uploadedFile: modal2UploadFile,
            uploadFileName: modal2UploadFileName,
            fileSize: modal2FileSize,
        },
        resetModal2Values,
    ] = useHandleFileUpload();

    const [
        handleModal3FileUpload,
        {
            progress: modal3Progress,
            uploadedFile: modal3UploadFile,
            uploadFileName: modal3UploadFileName,
            fileSize: modal3FileSize,
            inputFileRef,
        },
        resetModal3Values,
        handleRemoveFile,
    ] = useHandleFileUpload();

    /*States*/
    const [currentStep, setCurrentStep] = useState<string>('basicDetails');
    const [firmwarePayload, setFirmwarePayload] = useState<Firmware>(initialFirmwarePayload);
    const [selectedFirmwares, setSelectedFirmwares] = useState<object[]>([]);
    const [filteredData, setFilteredData] = useState<FirmwareDetails[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [paginationPayload, setPaginationPayload] = useState<PaginationPayload>({
        page: 0,
        size: 10,
        filters: {},
        sort: { key: '', sortType: '' },
    });
    const [searchKey, setSearchKey] = useState<string | undefined>(undefined);
    const [openFirmware, setOpenFirmware] = useState(false);
    const [openProviderDetailModal, setOpenProviderDetailModal] = useState(false);
    const [firmwareDetails, setFirmwareDetails] = useState<FirmwareDetails | EmptyObj>({});
    // delete firmware modal
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    /*Api Calls*/
    const [uploadFirmwareData, { isLoading: isUploadingFirmware }] = useUploadFirmwareDataMutation();
    const [deleteFirmware, { isLoading: isDeletingFirmware }] = useDeleteFirmwareMutation();
    const {
        currentData: allFirmwares,
        isLoading,
        isFetching,
    } = useGetFirmwaresQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });

    /*Modal Handlers*/
    /**
     * The code below snippet contains several functions related to handling modals, checkboxes, and file
     * uploads in a TypeScript React application.
     */
    const handleProviderDetailClose = (): void => {
        setOpenProviderDetailModal(false);
    };
    const handleFirmwareModal = (current: boolean): void => {
        setOpenFirmware(current);
        if (current === false) {
            setFirmwarePayload(initialFirmwarePayload);
            resetModal2Values();
            resetModal3Values();
        }
    };

    const handleDeleteBtnOpen = (): void => {
        setIsDeleteModalOpen(true);
    };

    /**
     * The function checks if there are any duplicate file names in an array of uploaded file names and
     * returns an error message if duplicates are found.
     * @returns An object is being returned with two properties: "errorMessage" and "isValid".
     */
    const checkDuplicateFile = (): {
        errorMessage: string;
        isValid: boolean;
    } => {
        const modal3UploadFileNames = modal3UploadFile.map((file: any) => file?.name);
        const uploadedFilesNameArray = [...modal3UploadFileNames, modal2UploadFileName];
        const hasDuplicate = new Set(uploadedFilesNameArray).size !== uploadedFilesNameArray.length;
        return {
            errorMessage: 'File already uploaded.',
            isValid: !hasDuplicate,
        };
    };

    const hasDuplicateFile = useMemo(() => checkDuplicateFile(), [modal3UploadFile]);

    const detailModalHandler = (action: boolean, data: FirmwareDetails): void => {
        setFirmwareDetails(data);
        setOpenProviderDetailModal(action);
    };

    const handleCheckboxSelect = (checkedFirmwares: string[]): void => {
        const selectedFirmwareUpdateIds: object[] = [];
        for (const id of checkedFirmwares) {
            const firmware = allFirmwares?.data?.records?.find((item: { id: string }) => item.id === id);
            if (firmware) {
                selectedFirmwareUpdateIds.push(firmware.updateId);
            }
        }
        setSelectedFirmwares(selectedFirmwareUpdateIds);
    };

    /*Modals Content*/
    /* The code below is defining an array called `modalContent` using the `React.useMemo` hook. This
     * array contains objects that represent different steps or sections of a modal for uploading
     * firmware. Each object has properties such as `key`, `header`, `title`, and `component`. The `key`
     * property is used for identifying each step, the `header` property is the title displayed at the
     * top of each step, the `title` property is a description or additional information for each step,
     * and the `component` property is the React component to be rendered for each step. */
    const modalContent = useMemo(
        () => [
            {
                key: 'basicDetails',
                header: 'Upload Firmware - Basic Details',
                title: 'Enter basic details to create a firmware.',
                component: <FirmwareModal1 payload={firmwarePayload} setPayload={setFirmwarePayload} />,
            },
            {
                key: 'manifestFile',
                header: 'Upload Firmware - manifest.json file',
                title: (
                    <>
                        An import manifest is a JSON file that defines important information about the update that you
                        are importing. Download sample file:{' '}
                        <Link
                            id="link"
                            color="primary"
                            className="primary-text"
                            onClick={(): void => downloadFile(blob, 'sample.importmanifest.json')}
                            style={{ cursor: 'pointer' }}
                        >
                            sample.importmanifest.json
                        </Link>
                        <br />
                        For more details, refer to:{' '}
                        <Link
                            href="https://learn.microsoft.com/en-us/azure/iot-hub-device-update/import-schema"
                            color="primary"
                            className="primary-text"
                            style={{ cursor: 'pointer' }}
                            target="_blank"
                            rel="noopener"
                        >
                            Microsoft Azure Docs
                        </Link>
                    </>
                ),
                component: (
                    <FirmwareModal2
                        handleFileUploadDownload={handleModal2FileUpload}
                        progress={modal2Progress}
                        validProps={modal2ValidProps}
                        uploadFileJson={modal2UploadFileJson}
                        uploadFileName={modal2UploadFileName}
                        fileSize={modal2FileSize}
                        uploadedFile={modal2UploadFile}
                    />
                ),
            },
            {
                key: 'firmwareFile',
                header: 'Upload Firmware - Firmware file',
                title: 'Firmware is a microcode or program that is embedded into the memory of hardware devices to help them operate',
                component: (
                    <FirmwareModal3
                        handleFileUploadDownload={handleModal3FileUpload}
                        progress={modal3Progress}
                        uploadFileName={modal3UploadFileName}
                        fileSize={modal3FileSize}
                        uploadedFile={modal3UploadFile}
                        validProps={hasDuplicateFile}
                        handleRemoveFile={handleRemoveFile}
                        inputFileRef={inputFileRef}
                    />
                ),
            },
        ],
        [firmwarePayload, handleModal2FileUpload, handleModal3FileUpload]
    );

    /*Modal Step Handlers*/
    /**
     * The code below defines several functions in TypeScript React that determine if a step in a
     * process should be disabled based on certain conditions.
     * @param {string} step - The `step` parameter is a string that represents the current step in a
     * process.
     */
    const getCurrentStep = (step: string): void => {
        setCurrentStep(step);
    };

    const firmwareModalStepDisabled = (): boolean => {
        if (!firmwarePayload?.file?.length || modal3Progress !== 100 || !hasDuplicateFile?.isValid) {
            return true;
        }
        return false;
    };
    const handleStepDisable = (): boolean => {
        switch (currentStep) {
            case 'basicDetails':
                return Boolean(!firmwarePayload?.name?.trim().length || !firmwarePayload?.description?.trim().length);
            case 'manifestFile':
                return Boolean(!modal2ValidProps?.isValid || modal2Progress !== 100);
            case 'firmwareFile':
                return firmwareModalStepDisabled();
            default:
                return false;
        }
    };

    /*Promises*/
    /**
     * The code below defines two async functions, `submitHandler` and `handleDeleteBtnClose`, which
     * handle the submission of firmware data and the deletion of firmware respectively, and display
     * success messages or log error messages accordingly.
     */
    const submitHandler = async (): Promise<void> => {
        await uploadFirmwareData(firmwarePayload)
            .then((res: any) => {
                if (!res?.error) {
                    dispatch(setSnackbarState({ open: true, message: 'Firmware Uploaded Successfully' }));
                }
            })
            .catch((res) => {
                setOpenFirmware(false);
                console.error(res?.error?.data?.errorMessage?.[0]);
            });
    };
    const handleDeleteBtnClose = async (): Promise<any> => {
        await deleteFirmware(selectedFirmwares)
            .then((res: any) => {
                if (!res?.error) {
                    setIsDeleteModalOpen(false);
                    dispatch(setSnackbarState({ open: true, message: 'Deleted Successfully' }));
                }
            })
            .catch((res) => {
                setIsDeleteModalOpen(false);
                console.error(res?.error?.data?.errorMessage?.[0]);
            });
    };

    /*Table Handlers*/
    /**
     * The function `handleGlobalSearch` updates the search key and pagination payload when a change
     * event occurs in an input element.
     * @param e - React.ChangeEvent<HTMLInputElement> - This is the type of the event object that is
     * passed to the function. It represents a change event on an input element in a React component.
     */
    const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchKey(e.target.value);
        setPaginationPayload({
            ...paginationPayload,
            page: 0,
        });
    };

    /**
     * The function `handlePaginationChange` updates the pagination payload with the new page and size
     * values.
     * @param {number} page - The `page` parameter represents the current page number in the pagination.
     * It indicates which page of data is currently being displayed.
     * @param {number} size - The "size" parameter represents the number of items to be displayed per
     * page in a paginated list or table.
     */
    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: PaginationPayload) => ({ ...prev, page: page, size: size }));
    };

    /*Table Cells*/
    /**
     * The code below defines several functions that return JSX elements for different cells in a
     * table, each displaying different details of a firmware object.
     * @param {FirmwareDetails} firmware - The `firmware` parameter is of type `FirmwareDetails`.
     */
    const getDetailsCell = (firmware: FirmwareDetails): React.JSX.Element => (
        <Button variant={'outlined'} onClick={(): void => detailModalHandler(true, firmware)}>
            Details
        </Button>
    );
    const getProviderCell = (firmware: FirmwareDetails): React.JSX.Element => (
        <Typography>{firmware.updateId.provider}</Typography>
    );
    const getFirmwareCell = (firmware: FirmwareDetails): React.JSX.Element => (
        <Typography>{firmware.updateId.name}</Typography>
    );
    const getVersionCell = (firmware: FirmwareDetails): React.JSX.Element => (
        <Typography>{firmware.updateId.version}</Typography>
    );
    const getDescriptionCell = (firmware: FirmwareDetails): React.JSX.Element => (
        <Tooltip title={firmware?.friendlyName?.length > 20 ? firmware?.friendlyName : ''} placement="bottom-start">
            <Typography>{firmware?.friendlyName}</Typography>
        </Tooltip>
    );
    const getImportedAtCell = (firmware: FirmwareDetails): React.JSX.Element => (
        <Typography>{getLocalTimeStamp(firmware?.importedDateTime) || '--'}</Typography>
    );

    /*Table Columns*/
    /* The code below is defining an array of columns for a table. Each column object in the array has
     * properties such as header (the column header text), cell (the content to be displayed in the
     * column cells), width (the width of the column), and isSelectable (a boolean indicating whether
     * the column is selectable). The useMemo hook is used to memoize the columns array so that it is
     * only recomputed when its dependencies change. */
    const columns = useMemo(
        () => [
            {
                header: '',
                isSelectable: true,
                cell: <Checkbox />,
            },
            {
                header: 'Provider',
                cell: getProviderCell,
                width: '15%',
            },
            {
                header: 'Firmware Name',
                cell: getFirmwareCell,
                width: '15%',
            },
            {
                header: 'Version',
                cell: getVersionCell,
                width: '15%',
            },
            {
                header: 'Description',
                cell: getDescriptionCell,
                width: '20%',
            },
            {
                header: 'Imported At',
                cell: getImportedAtCell,
                width: '20%',
            },
            {
                header: 'Actions',
                cell: getDetailsCell,
                width: '15%',
            },
        ],
        []
    );

    /*Effects*/
    useEffect(() => {
        const payloadClone = JSON.parse(JSON.stringify(firmwarePayload));
        if (modal2UploadFileJson) {
            payloadClone.manifest = modal2UploadFileJson;
        }
        if (modal3UploadFile) {
            payloadClone.file = modal3UploadFile;
        }
        if (modal2UploadFileName) {
            payloadClone.manifestFileName = modal2UploadFileName;
        }
        setFirmwarePayload(payloadClone);
    }, [modal2UploadFileJson, modal3UploadFile, modal2UploadFileName]);

    useEffect(() => {
        if (!isFetching && !isLoading) {
            const clonedData = allFirmwares?.data?.records?.slice(
                paginationPayload.page * paginationPayload.size,
                Number(paginationPayload.page * paginationPayload.size) + Number(paginationPayload.size)
            );
            setFilteredData(clonedData);
            setTotalRecords(allFirmwares?.data?.records?.length);
        }
    }, [allFirmwares]);

    useEffect(() => {
        if (allFirmwares?.data?.records?.length) {
            const [paginatedData, filteredTotal]: [FirmwareDetails[], number] = handleClientSidePaginationSorting(
                allFirmwares?.data?.records,
                paginationPayload,
                searchKey,
                SEARCH_PARAMS
            ) as [FirmwareDetails[], number];
            setFilteredData(paginatedData);
            setTotalRecords(filteredTotal);
        }
    }, [paginationPayload, searchKey]);

    return (
        <>
            <Box className="main-content-wrapper">
                <Card sx={{ m: 0 }} className="custom-card">
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        padding={2}
                        className="card-header"
                    >
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography variant="h6" color="primary">
                                Firmware Overview
                            </Typography>
                        </Stack>
                        <Box>
                            {!selectedFirmwares.length ? (
                                <Button
                                    startIcon={<FileUploadIcon />}
                                    onClick={(): void => handleFirmwareModal(true)}
                                    variant="contained"
                                    size="medium"
                                >
                                    Upload Firmware
                                </Button>
                            ) : (
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteBtnOpen}
                                    variant="contained"
                                    size="medium"
                                    className="error-btn"
                                >
                                    Delete
                                </Button>
                            )}
                        </Box>

                        {/* delete btn modal starts */}
                        <Dialog
                            open={isDeleteModalOpen}
                            onClose={handleDeleteBtnClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className="modal-wrapper common-modal-view"
                        >
                            <DialogTitle id="alert-dialog-title" className="modal-header">
                                {'Delete Firmware(s)?'}
                            </DialogTitle>
                            <Divider />
                            <DialogContent className="modal-body">
                                <DialogContentText id="alert-dialog-description" sx={{ p: 3 }}>
                                    {'This action cannot be undone.'}
                                </DialogContentText>
                            </DialogContent>
                            <Stack px={3} pb={2}>
                                <Typography
                                    variant="body1"
                                    fontSize={'16px'}
                                    sx={{
                                        border: '2px solid #80bde0',
                                        borderRadius: '4px',
                                        padding: 1,
                                        marginBottom: 1,
                                        backgroundColor: '#fbfbfb',
                                    }}
                                >
                                    <b>Note:</b> {NOTE_MESSAGE_FIRMWARE_DELETE}
                                </Typography>
                            </Stack>
                            <Divider />
                            <DialogActions className="modal-footer">
                                <Button onClick={(): void => setIsDeleteModalOpen(false)} variant="outlined">
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleDeleteBtnClose}
                                    className="error-btn"
                                    variant="contained"
                                    autoFocus
                                    disabled={isDeletingFirmware}
                                    startIcon={isDeletingFirmware ? <AutoDeleteOutlined /> : ''}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* delete btn modal ends */}
                    </Stack>
                    <Divider />
                    <CardContent className="card-content">
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            className="pos-relative table-header-search-box"
                        >
                            <GlobalSearch
                                handleGlobalSearch={handleGlobalSearch}
                                searchKey={searchKey}
                                setSearchKey={setSearchKey}
                            />
                        </Stack>
                        <Divider />
                        <Stack direction="row" spacing={1} className="chip-spacing">
                            {Object.keys(paginationPayload?.filters).map((key: string) => (
                                <Chip
                                    key={uuidv4()}
                                    label={key}
                                    onDelete={(): void => tableRef?.current?.resetFilters(key, true)}
                                />
                            ))}
                        </Stack>
                        <CustomTable
                            ref={tableRef}
                            isPagination={true}
                            controlledPageSize={paginationPayload?.page}
                            total={totalRecords}
                            keyToTraverse="id"
                            handlePageChange={handlePaginationChange}
                            data={filteredData
                                ?.slice()
                                .sort((x: any, y: any) => y?.importedDateTime - x?.importedDateTime)}
                            headers={columns}
                            containerClass="custom-data-table"
                            isLoading={isLoading || isFetching}
                            noDataFoundTitle={!filteredData ? 'Something went wrong !! No logs found' : 'No Logs found'}
                            handleCheckboxSelect={handleCheckboxSelect}
                            wrapperClass={
                                'device-table-white-space firmware-overview-table-responsive-height no-device-found-center'
                            }
                            noDataFoundIcon={<AssignmentReturned fontSize="inherit" />}
                        />
                    </CardContent>
                </Card>
            </Box>
            <FirmwareDetailsModal
                data={firmwareDetails}
                handleCloseModal={handleProviderDetailClose}
                handleOpenModal={openProviderDetailModal}
                modalHeaders={getModalHeaders}
            />

            <StepperModal
                modalContent={modalContent}
                closeModal={(): void => handleFirmwareModal(false)}
                isOpen={openFirmware}
                submitHandler={submitHandler}
                getCurrentStep={getCurrentStep}
                submitButton={isUploadingFirmware ? 'Uploading...' : 'Upload'}
                isDisabled={handleStepDisable() || isUploadingFirmware}
                isSendingResponse={isUploadingFirmware}
                className={'common-modal-view'}
            />
        </>
    );
};

export default FirmwareOverview;
