import moment from 'moment';
import { LegendOptions } from './types/HighCharts';
import { ClientSidePaginationReturnType, PaginationPayload } from './types/Common';

export const legendOptions: LegendOptions = {
    enabled: true,
    useHTML: true,
    labelFormatter: function () {
        // If legendTooltip is set, put it into title attr
        if (this?.options?.legendTooltip || this.options?.y) {
            return `<div title="${this.options.legendTooltip}">${this.name} ${
                this.options.y || this.options.y === 0 ? `: <b>${this.options.y}</b>` : ''
            } </div>`;
        }
        return `<div>${this.name}</div>`;
    },
};

/**
 * The function `createNumArray` creates an array of numbers from 1 to the specified length.
 * @param {number} length - The `length` parameter is a number that represents the desired length of
 * the array to be created.
 * @returns The function `createNumArray` returns an array of numbers.
 */
export const createNumArray = (length: number): number[] => {
    const data: number[] = [];
    for (let i = 0; i < length; i++) {
        data.push(i + 1);
    }
    return data;
};

export function downloadFile(blob: Blob | null, fileName: string): void {
    const link = document.createElement('a');
    if (blob) link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
}
export const downloadFileFromLink = (link: string, filename: string): void => {
    const element = document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

/**
 * The function `handleValidReqJson` checks if a given object has all the required keys and if the
 * description key has a maximum length of 512 characters.
 * @param object - An object that contains a "description" key with a string value.
 * @returns an object with two properties: "isValid" and "errorMessage".
 */
export function handleValidReqJson(object: { description: string }): { isValid: boolean; errorMessage: string } {
    const requiredKeys = ['updateId', 'compatibility', 'instructions'];
    if (object?.description?.length > 512) {
        return {
            isValid: false,
            errorMessage: 'Description cannot be more than 512 characters',
        };
    } else if (requiredKeys.every((key) => Object.keys(object).includes(key))) {
        return {
            isValid: true,
            errorMessage: '',
        };
    }
    return {
        isValid: false,
        errorMessage: "missing key 'updateId' or 'compatibility' or 'instructions'",
    };
}

export function convertDateStringToTimestamp(dateString: string | Date): number {
    const timestamp = new Date(dateString).getTime();
    return timestamp;
}

export const getLocalTimeStamp = (timestamp: any): string => {
    const localDateTime = moment.utc(timestamp).local();
    return localDateTime.format('L LTS');
};

export const getModalHeaders = (data: object[]): Array<{ header?: string; accessor?: string }> => {
    if (data?.length) {
        const customColumns = [];
        for (const key in data[0]) {
            const modifiedHeader = key.charAt(0).toUpperCase() + key.slice(1);
            customColumns.push({ header: modifiedHeader, accessor: key });
        }
        return customColumns;
    }
    return [];
};

export const copyToClipboard = (text: string): Promise<void> =>
    new Promise((resolve, reject) => {
        navigator.clipboard
            .writeText(text ?? '')
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    });

export function createHeadersArray(numHeaders: number, isSelectable: boolean): object[] {
    return Array.from({ length: numHeaders }, (_, i) => ({
        header: `Header${i}`,
        ...(isSelectable && i === 0 && { isSelectable: true }),
    }));
}

export const customLegendOptions = {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    x: -16,
    y: 4,
    itemMarginTop: 3, // Decrease top margin
    itemMarginBottom: 3, // Decrease bottom margin
};

export const subtitleOptions = {
    x: -86,
    y: 10,
};

export const initials = (str: string): string =>
    str
        ?.split('@')[0]
        .split(/(?=[A-Z])/)
        .map((word: any) => word[0])
        .join('');

export const openInNewTab = (path: string): void => {
    window.open(path, '_blank');
};

export const sortData = (data: any[], key: string, type: string): any[] => {
    const sortedData = [...data];
    if (type === 'desc') {
        sortedData.sort((b: any, a: any) => {
            if (typeof a?.[key] === 'string' && typeof b?.[key] === 'string') {
                return a?.[key].localeCompare(b?.[key], 'en', { sensitivity: 'base', numeric: true });
            }
            return a?.[key] - b?.[key];
        });
    } else {
        sortedData.sort((a: any, b: any) => {
            if (typeof a?.[key] === 'string' && typeof b?.[key] === 'string') {
                return a?.[key].localeCompare(b?.[key], 'en', { sensitivity: 'base', numeric: true });
            }
            return a?.[key] - b?.[key];
        });
    }
    return sortedData;
};

export const handleClientSidePaginationSorting = (
    records: object[],
    paginationPayload: PaginationPayload,
    searchKey?: string | undefined,
    searchParams?: string[]
): ClientSidePaginationReturnType => {
    let sortedDataClone = records?.slice();
    if (searchKey && searchKey.trim() !== '') {
        sortedDataClone = records?.filter((obj) =>
            searchParams?.some((key) => {
                const params = key.split('.');
                const valueToCheck = params.reduce((acc: any, k) => (acc?.[k] ? acc[k] : null), obj);
                return valueToCheck?.toString().toLowerCase().includes(searchKey.toLowerCase());
            })
        );
    }
    if ('sort' in paginationPayload && typeof paginationPayload?.sort?.key === 'string') {
        sortedDataClone = sortData(
            sortedDataClone,
            paginationPayload?.sort?.key,
            paginationPayload?.sort?.sortType.toLowerCase()
        );
    }
    const startIndex = paginationPayload.page * paginationPayload.size;
    const endIndex = startIndex + Number(paginationPayload.size);
    const paginatedData = sortedDataClone?.slice(startIndex, endIndex);
    const filteredTotal = sortedDataClone?.length;
    return [paginatedData, filteredTotal];
};

export const getTimeDifference = (timestamp: number | undefined): string => {
    if (!timestamp) {
        return '';
    }

    const currentTime = Date.now();
    const timeDifference = currentTime - timestamp;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const formatTimeUnit = (value: number, unit: string): string => `${value} ${unit}${value !== 1 ? 's' : ''}`;

    if (days > 0) {
        return `${formatTimeUnit(days, 'day')} ${formatTimeUnit(hours % 24, 'hr')} ${formatTimeUnit(
            minutes % 60,
            'min'
        )} ${formatTimeUnit(seconds % 60, 'sec')} ago`;
    } else if (hours > 0) {
        return `${formatTimeUnit(hours, 'hour')} ${formatTimeUnit(minutes % 60, 'min')} ${formatTimeUnit(
            seconds % 60,
            'sec'
        )} ago`;
    } else if (minutes > 0) {
        return `${formatTimeUnit(minutes, 'minute')} ${formatTimeUnit(seconds % 60, 'second')} ago`;
    }
    return `${formatTimeUnit(seconds, 'second')} ago`;
};

export function isValidToken(jsonString: string | null): boolean {
    try {
        const jsonObj = jsonString && JSON.parse(jsonString);
        if (jsonObj.idToken) return true;
        return false;
    } catch (e) {
        return false;
    }
}

export const dateTimeObjToString = (selectedDateTimeObj: any): string => {
    const dateString = selectedDateTimeObj?.$d.toDateString() as string;
    const timeString = selectedDateTimeObj?.$d.toTimeString() as string;
    return `${dateString} ${timeString}`;
};
