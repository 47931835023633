import { api } from '../../redux-config/api/baseApi';

const firmwareApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFirmwares: builder.query<any, any>({
            query: () => ({
                url: 'du/v1/firmwares/updates',
                method: 'GET',
            }),
            providesTags: ['DeleteFirmwares'],
        }),

        getFirmwareHistory: builder.query<any, any>({
            query: () => ({
                url: '/du/v1/firmwares/import/history',
                method: 'GET',
            }),
        }),
        getFirmwareBestUpdate: builder.mutation<any, any>({
            query: (body) => ({
                url: '/du/v1/firmwares/best/update',
                method: 'POST',
                body,
            }),
        }),

        getFirmwareDetails: builder.query<any, any>({
            query: (params) => ({
                url: 'du/v1/firmwares',
                method: 'GET',
                params,
            }),
        }),
        // getFirmwareUpdates: builder.query<any, any>({
        //     query: ({ groupId, deviceClassId }) => ({
        //         url: `/du/v1/firmwares/${groupId}/classId/${deviceClassId}/available/update`,
        //         method: 'GET',
        //     }),
        // }),
        uploadFirmwareData: builder.mutation<any, any>({
            query: ({ name, description, manifest, file, manifestFileName }) => {
                const formData = new FormData();
                formData.append('manifest', btoa(JSON.stringify(manifest)));
                formData.append('manifestFileName', manifestFileName);
                formData.append('name', name);
                formData.append('description', description);
                for (const element of file) {
                    formData.append('files', element);
                }
                return {
                    url: `/du/v1/firmwares/upload`,
                    body: formData,
                    method: 'POST',
                    formData: true,
                };
            },
        }),
        deleteFirmware: builder.mutation<any, any>({
            query: (payload) => ({
                url: '/du/v1/firmwares',
                body: { firmwares: payload },
                method: 'DELETE',
            }),
            invalidatesTags: (_result, error) => (!error ? ['DeleteFirmwares'] : []),
        }),
        downloadFirmwareImportHistoryCSV: builder.query<any, any>({
            query: () => ({
                url: `du/v1/firmwares/import/history/download`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetFirmwaresQuery,
    useGetFirmwareHistoryQuery,
    useGetFirmwareBestUpdateMutation,
    useLazyGetFirmwareDetailsQuery,
    // useGetFirmwareUpdatesQuery,
    useUploadFirmwareDataMutation,
    useDeleteFirmwareMutation,
    useGetFirmwareDetailsQuery,
    useLazyDownloadFirmwareImportHistoryCSVQuery,
} = firmwareApi;
