import React from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { DialogContent, Stack } from '@mui/material';

export const CustomWaitingState = (): React.JSX.Element => (
    <Dialog className=" full-page-modal" open={true}>
        <DialogContent className=" bg-full-page-modal ">
            <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
                <Stack>
                    <Typography variant="body1" fontWeight={'600'} className=" text-white ">
                        Please wait while report is being fetched...
                    </Typography>
                </Stack>
            </Stack>
        </DialogContent>
    </Dialog>
);
