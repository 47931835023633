import React, { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Button,
    DialogActions,
    DialogTitle,
    Divider,
    IconButton,
    MobileStepper,
    Stack,
    Typography,
    CircularProgress,
    Tooltip,
} from '@mui/material';
import { ListItemTag } from '@brightlayer-ui/react-components';
import CloseIcon from '@mui/icons-material/Close';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

/* The code is defining a functional component called `StepperModal` using React and TypeScript. It is
a modal component that displays a series of steps using a stepper. The component receives props,
including `isOpen` (a boolean indicating whether the modal is open), `modalContent` (an array of
objects representing each step in the modal), `getCurrentStep` (a function to get the current step),
`submitHandler` (a function to handle form submission), `closeModal` (a function to close the
modal), and `isDisabled` (a boolean indicating whether the next button should be disabled). */
export const StepperModal = memo((props: any): React.JSX.Element => {
    const [currentStep, setCurrentStep] = React.useState(props?.modalContent[0]?.key);

    /**
     * The function `getCurrentStepIndex` returns the index of the current step in the `modalContent`
     * array.
     */
    const getCurrentStepIndex = (): number => props?.modalContent?.findIndex((step: any) => step?.key === currentStep);

    /* The `React.useEffect` hook is used to perform side effects in a functional component. In this
    case, it is used to call the `getCurrentStep` function from the props whenever the `currentStep`
    state variable changes. */
    React.useEffect(() => {
        if (props?.getCurrentStep) props?.getCurrentStep(currentStep);
    }, [currentStep]);
    React.useEffect(() => {
        if (!props.isOpen) setCurrentStep(props?.modalContent[0]?.key);
    }, [props.isOpen]);

    /**
     * The function `gotoNextStep` updates the current step in a modal and performs additional actions
     * if it is the last step.
     */
    const gotoNextStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (props?.modalContent?.length - 1 > currentStepIndex) {
            setCurrentStep(props?.modalContent[currentStepIndex + 1]?.key);
        } else {
            props.submitHandler().finally(() => {
                props.closeModal();
            });
        }
    };

    /**
     * The function `gotoBackStep` is used to navigate to the previous step in a modal, and if there is
     * no previous step, it closes the modal.
     */
    const gotoBackStep = (): void => {
        const currentStepIndex = getCurrentStepIndex();
        if (currentStepIndex > 0) {
            setCurrentStep(props?.modalContent[currentStepIndex - 1]?.key);
        } else {
            props.closeModal();
        }
    };

    /**
     * The function `getContent` returns the component from `props.modalContent` based on the current step
     * index.
     */
    const getContent = (): React.JSX.Element => props?.modalContent[getCurrentStepIndex()]?.component;

    /**
     * The function returns the label for a back action button based on the current step index.
     * @returns The function `getBackActionButtonLabel` returns a string value. If the
     * `currentStepIndex` is 0, it returns the string 'Cancel'. Otherwise, it returns the string
     * 'Back'.
     */
    const getBackActionButtonLabel = (): string => {
        const currentStepIndex = getCurrentStepIndex();
        return currentStepIndex === 0 ? 'Cancel' : 'Back';
    };

    /**
     * The function `getNextActionButtonLabel` returns the label for the next action button based on
     * the current step index.
     * @returns The function `getNextActionButtonLabel` returns a string value. The returned value will
     * be either 'Finish' or 'Next' depending on the condition inside the function.
     */
    const getNextActionButtonLabel = (): string => {
        const currentStepIndex = getCurrentStepIndex();
        return props?.modalContent?.length - 1 === currentStepIndex ? props?.submitButton : 'Next';
    };

    //Function to close the modal
    const closeModal = (): void => {
        props.closeModal();
    };

    return (
        <Dialog
            open={props?.isOpen}
            aria-describedby="alert-dialog-slide-description"
            className={`form-wrapper modal-wrapper ${props.className}`}
        >
            <DialogTitle id="alert-dialog-title" className="form-header" sx={{ position: 'relative' }}>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Typography variant="body1" className="header-text">
                            {props?.modalContent[getCurrentStepIndex()]?.header}
                        </Typography>
                        <Box sx={{ paddingBottom: '20px' }}></Box>
                    </Stack>
                    {props?.modalContent[getCurrentStepIndex()]?.customHeader && (
                        <ListItemTag
                            label={props?.modalContent[getCurrentStepIndex()]?.customHeader}
                            className="custom-version-box"
                        />
                    )}
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Tooltip title={currentStep.includes('deploymentmodal') && 'IoTHub name'} arrow placement="top">
                        <Typography variant="subtitle2" className="sub-header-text">
                            {props?.modalContent[getCurrentStepIndex()]?.title}
                        </Typography>
                    </Tooltip>
                    <Tooltip title={currentStep.includes('deploymentmodal') && 'Group name'} arrow placement="top">
                        <Typography variant="subtitle2" className="sub-header-text">
                            {props?.modalContent[getCurrentStepIndex()]?.subtitle}
                        </Typography>
                    </Tooltip>
                </Stack>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {getContent()}
            <Divider />
            <DialogActions className="form-footer justify-content-between">
                {props?.additionalButton && (
                    <>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                                variant="outlined"
                                startIcon={<RemoveRedEyeIcon />}
                                sx={{ borderRadius: '50px' }}
                                onClick={props?.additionalButton?.onClick}
                                disabled={props?.additionalButton?.disabled}
                            >
                                View
                            </Button>
                        </Stack>
                        <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                    </>
                )}

                {
                    <MobileStepper
                        style={{ width: '100%', background: 'transparent', boxShadow: 'none', padding: '0' }}
                        steps={props?.modalContent?.length}
                        activeStep={getCurrentStepIndex()}
                        variant="dots"
                        position="static"
                        backButton={
                            <Button variant="outlined" onClick={gotoBackStep}>
                                {getBackActionButtonLabel()}
                            </Button>
                        }
                        nextButton={
                            <Stack direction={'row'} spacing={2}>
                                {props?.modalContent[getCurrentStepIndex()]?.customPositiveBtn}
                                <Button
                                    variant="contained"
                                    onClick={gotoNextStep}
                                    disabled={props?.isDisabled}
                                    startIcon={
                                        props?.isSendingResponse ? (
                                            <CircularProgress className="circular-upload-btn" sx={{ color: '#fff' }} />
                                        ) : (
                                            props?.submitButtonIcon
                                        )
                                    }
                                >
                                    {getNextActionButtonLabel()}
                                </Button>
                            </Stack>
                        }
                    />
                }
            </DialogActions>
        </Dialog>
    );
});
