import { api } from '../../redux-config/api/baseApi';

const auditApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAuditLogs: builder.query<any, any>({
            query: (body: any) => ({
                url: 'audit/v1/audits',
                body,
                method: 'POST',
            }),
        }),
        downloadAuditLogs: builder.query<any, any>({
            query: () => ({
                url: 'audit/v1/audits',
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetAuditLogsQuery, useLazyDownloadAuditLogsQuery } = auditApi;
