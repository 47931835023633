/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { memo } from 'react';
import {
    MenuItem,
    TablePagination,
    IconButton,
    Typography,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableCell,
    Checkbox,
    InputAdornment,
    FilledInput,
    FormControl,
    TableSortLabel,
    Stack,
    TableBody,
    Button,
    Box,
    Divider,
    Paper,
    FormControlLabel,
    TextField,
} from '@mui/material';
import { Header } from '../types';
import { TableSkeleton } from '../components/TableSkeleton';
import { EmptyState } from '@brightlayer-ui/react-components';
import { Add, ArrowDropDown } from '@mui/icons-material';
import { useIsMount, usePagination, useSelectedIds } from '../hooks';
import { TablePaginationActions } from './TablePaginationActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { setSnackbarState } from '../features/common/commonSlice';
import { useAppDispatch } from '../redux-config/store';

type CustomTableProps = {
    controlledPageSize?: any;
    isSuccess?: boolean;
    wrapperClass?: string;
    containerClass?: string;
    customClass?: string;
    isPagination?: boolean;
    isError?: boolean;
    data: any[];
    headers: any[];
    keyToTraverse: string;
    isLoading: boolean;
    noDataFoundIcon?: React.JSX.Element;
    noDataFoundTitle?: string;
    noDataFoundButtonText?: string;
    noDataFoundButtonAction?: () => void;
    handleCheckboxSelect?: (arg0: string[]) => void;
    handleFilterChange?: (arg0: any, arg1: any) => void;
    handlePageChange?: (arg0: number, arg1: number) => void;
    total: number;
    noDataFoundDescription?: string | React.JSX.Element;
    keyToCheckForDisabledCheckbox?: string;
    valueToCheckForDisabledCheckbox?: string;
    defaultPageSize?: number;
    additionalFilterValue?: object;
    setAdditionalFilterValue?: any;
};

const CustomTable = React.forwardRef((props: CustomTableProps, ref) => {
    const dispatch = useAppDispatch();
    const [filteredValues, setFilteredValues] = React.useState<any>({});
    const [sortedData, setSortedData] = React.useState<any>({});
    const [openFilterDropDown, setOpenFilterDropDown] = React.useState<any>({});
    const [pageSize, setPageSize] = React.useState<number>(props?.defaultPageSize ?? 10);
    const [isAllCheckboxSelected, setIsAllCheckboxSelected] = React.useState<boolean>(false);

    /* The below code is using the `usePagination` hook to manage pagination functionality in a
    TypeScript React component. */
    const {
        currentPage,
        goToLastPage,
        goToNextPage,
        goToFirstPage,
        goToPrevPage,
        setPage,
        totalPages,
        setCurrentPage,
    } = usePagination({
        pageSize: pageSize,
        totalItems: props?.total,
    });

    const [selectedIds, setSelectedIds, selectionHandler] = useSelectedIds();

    const isMount = useIsMount();

    /* The code below is using the `useEffect` hook in React to update the `filteredValues` state
    whenever the `additionalFilterValue` prop changes. It is using the optional chaining operator
    (`?.`) to safely access the `additionalFilterValue` prop. The `setFilteredValues` function is
    used to update the state by merging the previous state (`prevState`) with the new
    `additionalFilters` value. */
    React.useEffect(() => {
        const additionalFilters = props?.additionalFilterValue;
        setFilteredValues((prevState: any) => ({ ...prevState, ...additionalFilters }));
    }, [props?.additionalFilterValue]);

    /* The below code is using the `useImperativeHandle` hook from React to expose certain functions to
    the parent component through a ref. */
    React.useImperativeHandle(ref, () => ({
        resetCheckboxes: (): void => setSelectedIds([]),
        resetFilters: (key: string, type?: any): void => resetFilters(key, type),
        dropDownHandler: (key: string, type?: any): void => dropDownHandler(key, type),
    }));

    /* The below code is a React useEffect hook that is triggered when the component mounts. It checks
    if the `props.headers` array exists and has a length greater than 0. If it does, it iterates
    over each `header` object in the array. If the `header` object has a property `isFilterable`
    that is truthy, it sets the state of `openFilterDropDown` using the `setOpenFilterDropDown`
    function. The state is updated by spreading the previous state (`prev`) and setting the value of
    the `header.accessor` property to `false`. */
    React.useEffect(() => {
        if (props?.headers?.length) {
            props.headers.forEach((header: Header) => {
                if (header?.isFilterable) {
                    setOpenFilterDropDown((prev: any) =>
                        header.accessor ? { ...prev, [header?.accessor]: false } : prev
                    );
                }
            });
        }
    }, []);

    /* The below code is using the `useEffect` hook from React to execute a function whenever the
    `selectedIds` variable changes. The function being executed is
    `props.handleCheckboxSelect(selectedIds)`, which is passed as a prop to the component. This code
    is likely used to handle the selection of checkboxes and update the selectedIds state in the
    parent component. */
    React.useEffect(() => {
        if (props.handleCheckboxSelect) props.handleCheckboxSelect(selectedIds);
    }, [selectedIds]);

    /* The below code is using the `useEffect` hook from React to execute a function whenever the
    dependencies (`filteredValues`, `currentPage`, `pageSize`, `sortedData`) change. */
    React.useEffect(() => {
        if ((filteredValues || sortedData) && props.handleFilterChange) {
            const filters = { ...filteredValues };
            props.handleFilterChange(filters, sortedData);
        }
    }, [filteredValues, sortedData]);

    React.useEffect(() => {
        if ((currentPage || pageSize) && props.handlePageChange) {
            props.handlePageChange(currentPage, pageSize);
        }
    }, [currentPage, pageSize]);

    React.useEffect(() => {
        if (!isMount && props.controlledPageSize !== currentPage) {
            setCurrentPage(props.controlledPageSize);
        }
    }, [props.controlledPageSize]);

    /**
     * The function `filterPayloadHandler` handles filtering based on the provided id, value, and
     * filterRegex.
     * @param {any} id - The `id` parameter is used to identify the specific filter being applied. It
     * could be a unique identifier or a key that represents the filter.
     * @param {any} value - The `value` parameter represents the input value that is being filtered.
     * @param {any} filterRegex - The `filterRegex` parameter is a regular expression used to validate
     * the `value` parameter. It is used to check if the `value` matches a specific pattern or format.
     */
    const filterPayloadHandler = (id: any, value: any, filterRegex: any, filterWarningMessage: any): void => {
        if (id !== '') {
            if (value === '') {
                resetFilters(id);
            } else if (filterRegex) {
                const regex = new RegExp(filterRegex);
                if (regex.test(value)) {
                    setFilteredValues((prev: any) => ({ ...prev, [id]: value }));
                } else if (filterWarningMessage) {
                    dispatch(setSnackbarState({ open: true, message: filterWarningMessage }));
                }
            } else {
                setFilteredValues((prev: any) => ({ ...prev, [id]: value }));
            }
        }

        // Include additional filter value in the filter payload
        setFilteredValues((prev: any) => ({ ...prev, ...props?.additionalFilterValue }));
    };

    /**
     * The function `dropDownHandler` toggles the state of a dropdown menu based on a given key and
     * type.
     * @param {string | undefined} key - The `key` parameter is a string or undefined. It is used to
     * identify the specific dropdown that needs to be toggled or opened.
     * @param {string} [type] - The `type` parameter is an optional string that specifies the action to
     * be performed on the dropdown. If the `type` is set to `'remove'`, it will call the
     * `resetFilters` function with the `key` as an argument.
     */
    const dropDownHandler = (key: string | undefined, type?: string): void => {
        if (key) {
            if (Object.keys(openFilterDropDown).includes(key)) {
                if (type && type === 'remove') {
                    resetFilters(key);
                }
                if (type === 'close') {
                    setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: false }));
                    return;
                }
                setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: !prev[key] }));
            } else setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: true }));
        }
    };

    /**
     * The function `handleSelectAll` is used to handle the selection of all items in a list.
     * @param {any} e - The parameter `e` is an event object that is passed to the `handleSelectAll`
     * function. It is typically an event object that is triggered when a checkbox is checked or
     * unchecked.
     */
    const handleSelectAll = (): void => {
        setIsAllCheckboxSelected((prevIsAllSelected) => !prevIsAllSelected);
        let IdsArr: string[] = [];
        if (selectedIds?.length >= 0 && !isAllCheckboxSelected) {
            IdsArr = props?.data
                ?.filter((subData) => {
                    if (
                        (props?.keyToCheckForDisabledCheckbox &&
                            subData[props?.keyToCheckForDisabledCheckbox] !== props?.valueToCheckForDisabledCheckbox) ||
                        (!props?.keyToCheckForDisabledCheckbox && selectedIds?.length !== props?.data.length)
                    ) {
                        return true;
                    }
                    return false;
                })
                .map((subData) => subData?.[props?.keyToTraverse]);
        }
        setSelectedIds(IdsArr);
    };

    /**
     * The function `handleSorting` is used to toggle between ascending and descending sorting based on
     * a given key.
     * @param {any} key - The `key` parameter is used to specify the property or key by which the data
     * should be sorted.
     */
    const handleSorting = (key: any): void => {
        let str = 'DESC';
        if (key === sortedData?.key) {
            str = 'ASC';
            if (sortedData?.sortType === 'ASC') {
                str = 'DESC';
            }
        }

        setSortedData((prev: any) => ({ ...prev, key: key, sortType: str }));
    };

    /**
     * The function `handleSetFilteredValues` updates the `filteredValues` state by removing a specific
     * value from a specific filter, and also removes the filter if it becomes empty or if the value is
     * 'all'.
     * @param {string} id - The `id` parameter is a string that represents the identifier of a filter.
     * @param {any} value - The `value` parameter is the value that needs to be removed from the
     * `filteredValues` object.
     */
    const handleSetFilteredValues = (id: string, value: any): void => {
        const filtersClone = JSON.parse(JSON.stringify(filteredValues));
        if (filtersClone?.[id]) {
            const removableIndex = filtersClone[id]?.indexOf(value);
            filtersClone[id].splice(removableIndex, 1);
        }
        if ((filtersClone && !filtersClone[id].length) || value === 'all') {
            delete filtersClone[id];
        }
        setFilteredValues(filtersClone);
        if (Object.keys(props?.additionalFilterValue ?? {}).includes(id) && props?.setAdditionalFilterValue)
            props?.setAdditionalFilterValue(filtersClone);
    };

    /**
     * The function handles the change event of a checkbox and updates the filtered values based on the
     * checkbox's state.
     * @param e - The `e` parameter is of type `React.ChangeEvent<HTMLInputElement>`, which represents
     * the event object generated when the checkbox is changed. It contains information about the
     * checkbox element and its new state.
     * @param {any} id - The `id` parameter is used to identify the checkbox element. It is typically a
     * unique identifier or key associated with the checkbox.
     * @param {any} value - The `value` parameter represents the value of the checkbox that was
     * clicked.
     * @param {any} [allValues] - The `allValues` parameter is an optional parameter that represents an
     * array of all possible values for the checkbox group.
     */
    const checkboxPayloadHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: any,
        value: any,
        allValues?: any
    ): void => {
        if (!e.target.checked) {
            handleSetFilteredValues(id, value);
        } else if (value === 'all') {
            setFilteredValues((prev: any) => ({ ...prev, [id]: allValues?.filter((val: any) => val !== 'all') }));
        } else {
            setFilteredValues((prev: any) => ({
                ...prev,
                [id]: prev[id] ? prev[id]?.concat([value]) : [value],
            }));
        }
    };

    /**
     * The function checks if a specific value is already checked in a filtered list.
     * @param {string} id - The `id` parameter is a string that represents the identifier of an item.
     * @param {any} accessor - The `accessor` parameter is a variable that represents the property or
     * key of an object. It is used to access a specific value within the object.
     * @param {any} value - The `value` parameter is the total number of items that should be checked.
     * @returns a boolean value. It returns `true` if the conditions inside the if statements are met,
     * and `false` otherwise.
     */
    const alreadyCheckedHandler = (id: string, accessor: any, value: any): any => {
        if (filteredValues && accessor) {
            if (id === 'all') {
                if (filteredValues[accessor]?.length === value - 1) return true;
            }
            if (filteredValues?.[accessor]?.includes(id)) return true;
            return false;
        }
    };

    /**
     * The function `resetFilters` is used to remove filters from a set of filtered values in a React
     * application.
     * @param {string} key - The key parameter is a string that represents the key of the filter to be
     * reset.
     * @param {any} [type] - The `type` parameter is an optional parameter of type `any`.
     */
    const resetFilters = (key: string, type?: any): void => {
        const filtersClone = JSON.parse(JSON.stringify(filteredValues));

        if (key === 'clearAll') {
            setFilteredValues({});
            return setOpenFilterDropDown({});
        }
        if (type) {
            setOpenFilterDropDown((prev: any) => ({ ...prev, [key]: false }));
        }
        if (key === 'globalSearch') {
            type.forEach((filter: any) => {
                delete filtersClone[filter];
                setOpenFilterDropDown((prev: any) => ({ ...prev, [filter]: false }));
            });
        } else {
            delete filtersClone[key];
        }
        setFilteredValues(filtersClone);
        if (Object.keys(props?.additionalFilterValue ?? {}).includes(key) && props?.setAdditionalFilterValue)
            props?.setAdditionalFilterValue(filtersClone);
    };

    /**
     * The function handleChangeRowsPerPage updates the page size based on the value selected by the
     * user.
     * @param {any} e - The parameter "e" is an event object that is passed to the function when the
     * event occurs. In this case, it is likely an event object that is triggered when the user changes
     * the value of a dropdown or input field.
     */
    const handleChangeRowsPerPage = (e: any): void => {
        setPageSize(parseInt(e.target.value));
        setCurrentPage(0);
    };

    /* The below code is defining a function called `getTableBodyCellContent` that takes two
    parameters: `header` and `dataObj`. */
    const getTableBodyCellContent = (header: any, dataObj: any): React.JSX.Element => {
        if (header?.isSelectable) {
            if (props.keyToCheckForDisabledCheckbox && props?.valueToCheckForDisabledCheckbox) {
                return (
                    <Checkbox
                        color="primary"
                        checked={
                            selectedIds.includes(dataObj?.[props?.keyToTraverse]) &&
                            dataObj?.[props?.keyToCheckForDisabledCheckbox] !== props?.valueToCheckForDisabledCheckbox
                        }
                        onChange={selectionHandler(dataObj?.[props?.keyToTraverse])}
                        disabled={
                            dataObj?.[props?.keyToCheckForDisabledCheckbox] === props?.valueToCheckForDisabledCheckbox
                        }
                    />
                );
            }
            return (
                <Checkbox
                    color="primary"
                    checked={selectedIds.includes(dataObj?.[props?.keyToTraverse])}
                    onChange={selectionHandler(dataObj?.[props?.keyToTraverse])}
                />
            );
        } else if (header?.cell) {
            return header?.cell(dataObj);
        }
        return (
            <Typography variant={header?.typeVariant ?? 'body1'}>
                {dataObj?.[header?.accessor || ''] || 'N/A'}
            </Typography>
        );
    };

    /**
     * The function `getTableBodyCellClass` returns the class name for a table body cell based on the
     * provided header and data.
     * @param {Header} header - The `header` parameter is an object that represents the header of a
     * table column. It contains information about the column, such as its title and cell class.
     * @param {any} data - The `data` parameter is of type `any`, which means it can accept any data
     * type. It is used as an argument in the `header.cellClass` function, if it exists.
     * @returns The function `getTableBodyCellClass` returns a string.
     */
    const getTableBodyCellClass = (header: Header, data: any): string => {
        if (header?.cellClass) {
            if (typeof header.cellClass === 'string') {
                return header.cellClass;
            }
            return header?.cellClass(data);
        }
        return 'tableBodyCell';
    };

    /**
     * The handleClickAway function sets all values in the openFilterDropDown state to false.
     */
    const handleClickAway = (): void => {
        const newFilterDropDown: any = JSON.parse(JSON.stringify(openFilterDropDown));
        for (const key in newFilterDropDown) {
            newFilterDropDown[key] = false;
        }
        setOpenFilterDropDown(newFilterDropDown);
    };

    const containerRef: any = React.useRef(null);

    /* The below code is implementing a click outside functionality in a React component using the
    useEffect hook. It adds an event listener to the document that listens for click events. When a
    click event occurs, it checks if the clicked element is outside the containerRef.current
    element. If it is outside, it calls the handleClickAway function. The event listener is removed
    when the component is unmounted. */
    React.useEffect(() => {
        const handleClick = (event: any): void => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleClickAway();
            }
        };
        document.addEventListener('click', handleClick);
        return (): void => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    /**
     * The function returns a JSX element for rendering table pagination actions.
     */
    const getTablePaginationAction = (): React.JSX.Element => (
        <TablePaginationActions
            totalPages={totalPages}
            page={currentPage}
            goToLastPage={goToLastPage}
            goToNextPage={goToNextPage}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
        />
    );

    /* The below code is a TypeScript React function called `getHeaderFilterBody`. It takes a `header`
    parameter of type `any` and returns a JSX element. */
    const getHeaderFilterBody = (header: any): React.JSX.Element => {
        if (header?.filterOptions?.length) {
            return header?.filterOptions?.map((option: any) => (
                <div key={option?.id}>
                    {option?.id === 'all' ? (
                        <>
                            <Box className="custom-table-padding custom-menu-wrapper">
                                <MenuItem value={option?.id}>
                                    <FormControlLabel
                                        className="form-label-ml-0"
                                        control={
                                            <Checkbox
                                                id="check"
                                                checked={alreadyCheckedHandler(
                                                    option?.id,
                                                    header?.accessor,
                                                    header?.filterOptions?.length
                                                )}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                    checkboxPayloadHandler(
                                                        e,
                                                        header?.accessor,
                                                        option?.id,
                                                        header?.filterOptions?.map((opt: any) => opt.id)
                                                    )
                                                }
                                            />
                                        }
                                        label={option?.label}
                                    ></FormControlLabel>
                                </MenuItem>
                            </Box>
                            <Divider />
                        </>
                    ) : (
                        <Box className="custom-table-padding">
                            <MenuItem value={option?.id} key={option?.id} divider={option?.id === 'all'}>
                                <FormControlLabel
                                    className="form-label-ml-0"
                                    control={
                                        <Checkbox
                                            id="check"
                                            checked={alreadyCheckedHandler(
                                                option?.id,
                                                header?.accessor,
                                                header?.filterOptions?.length
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                                checkboxPayloadHandler(
                                                    e,
                                                    header?.accessor,
                                                    option?.id,
                                                    header?.filterOptions?.map((opt: any) => opt.id)
                                                )
                                            }
                                        />
                                    }
                                    label={option?.label}
                                />
                            </MenuItem>
                        </Box>
                    )}
                </div>
            ));
        } else if (header?.headerOptions) {
            return header?.headerOptions();
        }
        return (
            <FilledInput
                size="small"
                placeholder="Search"
                className="device-custom-search-icon"
                id={header?.accessor}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    filterPayloadHandler(e.target.id, e.target.value, header?.filterRegex, header?.filterWarningMessage)
                }
                value={filteredValues[header?.accessor] ?? ''}
                hiddenLabel={true}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            onClick={(): void => dropDownHandler(header?.accessor, 'remove')}
                        >
                            {filteredValues[header?.accessor] ? <HighlightOffIcon /> : <SearchIcon />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        );
    };

    return (
        <Paper>
            <TableContainer className={props.wrapperClass ?? ''}>
                <Table stickyHeader aria-label="sticky table" className="custom-data-table" sx={{ height: '100%' }}>
                    <TableHead className="device-custom-search-icon-position">
                        <TableRow ref={containerRef}>
                            {props.headers?.map((header: Header) => (
                                <TableCell
                                    key={header?.header}
                                    className={`tableHeadCell checkbox-max-width ${header?.minWidth} `}
                                    width={header?.width}
                                >
                                    {header?.isSelectable ? (
                                        <Checkbox
                                            color="primary"
                                            inputProps={{
                                                'aria-label': 'select all desserts',
                                            }}
                                            checked={
                                                Boolean(selectedIds?.length) &&
                                                selectedIds?.length === props?.data?.length
                                            }
                                            indeterminate={
                                                Boolean(selectedIds?.length) &&
                                                Boolean(selectedIds?.length < props?.data?.length)
                                            }
                                            disabled={!props?.data?.length}
                                            onChange={handleSelectAll}
                                        />
                                    ) : (
                                        <Stack direction="row">
                                            {header?.header}
                                            {header?.isSortable && (
                                                <TableSortLabel
                                                    direction={
                                                        sortedData?.key === header?.accessor ||
                                                        sortedData?.key === header?.sortKey
                                                            ? sortedData?.sortType?.toLowerCase()
                                                            : 'desc'
                                                    }
                                                    active={
                                                        sortedData?.key === header?.accessor ||
                                                        Boolean(header?.sortKey && sortedData?.key === header?.sortKey)
                                                    }
                                                    onClick={(): void =>
                                                        handleSorting(header?.sortKey ?? header?.accessor)
                                                    }
                                                >
                                                    <Box component="span"></Box>
                                                </TableSortLabel>
                                            )}

                                            {header?.isFilterable && (
                                                <>
                                                    <ArrowDropDown
                                                        id="filterDropdown"
                                                        onClick={(): void => dropDownHandler(header?.accessor)}
                                                    />
                                                    {header?.accessor && openFilterDropDown?.[header?.accessor] && (
                                                        <Box className="menu-content-items">
                                                            <FormControl
                                                                variant="filled"
                                                                className={`menu-wrapper ${header?.wrapperClass}`}
                                                                size="small"
                                                                defaultValue="Small"
                                                                {...((header?.filterOptions ||
                                                                    header?.headerOptions) && {
                                                                    sx: {
                                                                        ...(header?.headerSx && header?.headerSx()),
                                                                        padding: '0 !important',
                                                                    },
                                                                })}
                                                            >
                                                                {header?.isSearchForSelectBox && (
                                                                    <Box className="custom-menu-header">
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            placeholder="Search.."
                                                                            variant="outlined"
                                                                            className="w-100"
                                                                            onChange={(
                                                                                event: React.ChangeEvent<HTMLInputElement>
                                                                            ): void => {
                                                                                if (header?.filterSearchCell)
                                                                                    header?.filterSearchCell(event);
                                                                            }}
                                                                            value={header.filterSearchValue}
                                                                        />
                                                                        <SearchIcon className="search-icon-wrap" />
                                                                    </Box>
                                                                )}
                                                                {header?.filterOptions?.length ? (
                                                                    <Box className="menu-content">
                                                                        {getHeaderFilterBody(header)}
                                                                    </Box>
                                                                ) : (
                                                                    <> {getHeaderFilterBody(header)}</>
                                                                )}
                                                            </FormControl>
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                            {header?.extraOptions && header?.extraOptions()}
                                        </Stack>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {props.isLoading && <TableSkeleton headers={props.headers} />}
                    {!props?.isLoading && Boolean(props?.data?.length) && (
                        <TableBody>
                            {props?.data?.map((dataObj: any) => (
                                <TableRow sx={{ position: 'relative' }} key={dataObj?.[props?.keyToTraverse]}>
                                    {props.headers?.map(
                                        (header: Header): React.JSX.Element => (
                                            <TableCell
                                                {...(header?.sx && {
                                                    sx: { ':before': header?.sx(dataObj) },
                                                })}
                                                key={header.header}
                                                className={getTableBodyCellClass(header, dataObj)}
                                            >
                                                {getTableBodyCellContent(header, dataObj)}
                                            </TableCell>
                                        )
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                    {!props.isLoading && Boolean(!props?.data?.length) && (
                        <TableBody sx={{ position: 'relative' }}>
                            <EmptyState
                                sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%)' }}
                                className={`${props.customClass ?? 'emptyStateWrapper'}`}
                                icon={props?.noDataFoundIcon}
                                title={props?.noDataFoundTitle}
                                {...(props?.noDataFoundDescription && {
                                    description: props.noDataFoundDescription,
                                })}
                                actions={
                                    props?.noDataFoundButtonAction && (
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            startIcon={<Add />}
                                            onClick={props?.noDataFoundButtonAction}
                                        >
                                            {props?.noDataFoundButtonText ?? ''}
                                        </Button>
                                    )
                                }
                            />
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {props?.isPagination && (
                <TablePagination
                    sx={{ borderTop: '1px solid #f2f2f2' }}
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    colSpan={8}
                    count={props?.total ?? 0}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    labelRowsPerPage="Items per page"
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'items per page',
                        },
                        native: true,
                    }}
                    onPageChange={setPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={getTablePaginationAction}
                />
            )}
        </Paper>
    );
});

export default memo(CustomTable);
