import React from 'react';
import { Route } from 'react-router-dom';
import { DeviceTable } from '../pages/DeviceUpdate/DeviceTable';
import { DeviceDetailTabs } from '../pages/DeviceUpdate/DeviceDetailTabs';
import { FirmwareTabs } from '../pages/Firmware/FirmwareTabs';
import { OverviewList } from '../pages/Summary/OverviewList';
import { UpdateList } from '../pages/Summary/UpdateList';
import { DeploymentView } from '../pages/Summary/DeploymentView';
import { AuditLogs } from '../pages/Audit/AuditLogs';
import { GroupsTab } from '../pages/Summary/GroupsTab';
import { MyProfile } from '../components/MyProfile';
import { GroupOverviewDevice } from '../pages/Summary/GroupOverviewDevice';
import { Summary } from '../pages/Summary/Summary';
import PageNotFound from '../components/PageNotFound';
import { NoProfileData } from '../components/NoProfileData';
import NewUserFallback from '../components/NewUserFallback';
import NewUserBackground from '../components/NewUserBackground';

export const getAllRoutes = (): React.JSX.Element => (
    <>
        <Route path="noProfileData" element={<NoProfileData />} />
        <Route path="deviceTable" element={<DeviceTable />} />
        <Route path=":container/:subContainer?/deviceTabs/:groupId?/:deviceId" element={<DeviceDetailTabs />} />
        <Route path="firmwareTabs/:selectedTab" element={<FirmwareTabs />} />
        <Route path=":container/overviewList/:groupName" element={<OverviewList />} />
        <Route path="updateList/:groupId/:deviceClassId/:deploymentId" element={<UpdateList />} />
        <Route path=":container/:groupName/deploymentView/:status" element={<DeploymentView />} />
        <Route path="auditLogs" element={<AuditLogs />} />
        <Route path=":container/groupsTab/:groupName/:selectedTab" element={<GroupsTab />} />
        <Route path="profile" element={<MyProfile />} />
        <Route path=":container/groupOverviewDevice/:groupName/:deviceId" element={<GroupOverviewDevice />} />
        <Route path="summary" element={<Summary />} />
        <Route path="newUserFallback" element={<NewUserFallback />} />
        <Route path="newUserBackground" element={<NewUserBackground />} />
        <Route path="PageNotFound'" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
    </>
);
