import React, { useEffect } from 'react';
import OktaConfig from '../OktaConfig';
import OktaAuth from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

/**
 * The below code is a custom login component in TypeScript React that uses the Okta Sign-In Widget for
 * authentication and handles CORS errors.
 * @param {any}  - - `setCorsErrorModalOpen`: A function that sets the state of whether the CORS error
 * modal is open or not.
 * @returns The `CustomLoginComponent` function returns a JSX element.
 */
const CustomLoginComponent = (): React.JSX.Element => {
    const navigate = useNavigate();

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
    is used to initialize and configure the Okta Sign-In Widget and handle any errors that occur
    during the authentication process. */
    useEffect((): any => {
        /* The code is creating a new instance of the OktaSignIn class and configuring it with various
        options. */
        const authClient = new OktaAuth(OktaConfig);
        if (authClient.isLoginRedirect()) {
            // Parse token from redirect url
            authClient.token
                .parseFromUrl()
                .then((data) => {
                    const { idToken }: any = data.tokens;
                    // Store parsed token in Token Manager
                    authClient.tokenManager.add('idToken', idToken);
                    navigate('/');
                })
                .catch((error) => {
                    console.error('error: ', error);
                });
        } else {
            // Attempt to retrieve ID Token from Token Manager
            authClient.tokenManager
                .get('idToken')
                .then((idToken) => {
                    if (idToken) {
                        navigate('/');
                    } else {
                        // You're not logged in, you need a sessionToken
                        return authClient.token.getWithRedirect({
                            responseType: 'id_token',
                        });
                    }
                })
                .catch((error) => {
                    console.error('error: ', error);
                });
        }
    }, []);

    return <></>;
};

export default CustomLoginComponent;
