import { Checkbox, Avatar, Stack, Typography, IconButton, Chip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { copyToClipboard, getLocalTimeStamp, sortData } from '../../commonUtils';
import { setSnackbarState } from '../../features/common/commonSlice';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import EditIcon from '@mui/icons-material/Edit';
import { Device, GroupId } from '../../types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux-config/store';
import { useSearchFilter } from '../../hooks/useSearchFilter';

const useDeviceTableHeaders = (
    filtersData: any,
    assigneeGroupListData: any,
    navigateToGroup?: { (groupName: string): void; (arg0: string): void },
    handleGroupOpen?: {
        (deviceId: string | null, deviceGroupName: string | undefined): void;
        (arg0: string, arg1: string): void;
    },
    resetSearchFilters?: { productType?: any; modelNumber?: any; groupName?: any } | undefined
): any[] => {
    //Hooks
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    //States
    const [groupNamesList, setGroupNamesList] = useState<any>();

    //Custom Hooks
    const [handleProductTypeSearch, filteredProductTypeSearchData, productTypeSearchValue] = useSearchFilter(
        filtersData?.data?.productType,
        resetSearchFilters?.productType
    );
    const [handleModelNumberSearch, filteredModelNumberSearchData, modelNumberSearchValue] = useSearchFilter(
        filtersData?.data?.modelNumber,
        resetSearchFilters?.modelNumber
    );
    const [handleGroupNameSearch, filteredGroupNameSearchData, groupNameSearchValue] = useSearchFilter(
        groupNamesList,
        resetSearchFilters?.groupName
    );

    //onClick event handler to navigate to deviceTabs for particular device id.
    const getDeviceByIdHandler = (deviceId: string | null): void => {
        navigate(`/deviceTable/deviceTabs/${deviceId}`);
    };

    const groupNameFilterList = (): void => {
        const groupsList = assigneeGroupListData?.data?.data
            ? sortData(assigneeGroupListData?.data?.data, 'groupId', 'asc').map((item: GroupId) => ({
                  id: item?.groupId === '$default' ? 'default' : item?.groupId,
                  label: item?.groupId === '$default' ? 'default' : item?.groupId,
              }))
            : [];

        setGroupNamesList(groupsList);
    };

    //Function to handle checkbox selection for devices
    const getCheckBoxForDevices = (): React.JSX.Element => <Checkbox />;

    //Function to get device status
    const getDeviceStatus = (device: Device): React.JSX.Element =>
        device?.connectionState === 'Disconnected' ? (
            <Avatar className="table-avatar-icon device-offline-color">
                <CloudOffIcon />
            </Avatar>
        ) : (
            <Avatar className="table-avatar-icon device-online-color">
                <CloudDoneIcon />
            </Avatar>
        );

    //Function to navigate to deviceTabs for particular device id.
    const getDeviceIdCell = (device: Device): React.JSX.Element => (
        //get device details.
        <Stack flexDirection={'row'} alignItems={'center'}>
            <Typography
                variant="body2"
                className="text-primary text-clickable active-link"
                sx={{ wordBreak: 'break-all' }}
                onClick={(): void => getDeviceByIdHandler(device?.deviceId)}
            >
                {device?.deviceId ?? '--'}
            </Typography>
            <IconButton
                sx={{ pl: 1 }}
                onClick={async (): Promise<void> => {
                    try {
                        await copyToClipboard(device?.deviceId);
                        dispatch(
                            setSnackbarState({
                                open: true,
                                message: 'Copied to clipboard!',
                            })
                        );
                    } catch (error) {
                        console.error('Error copying to clipboard:', error);
                    }
                }}
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </Stack>
    );

    //Function to get last reported time for devices
    const getLastReportedAtCell = (device: Device): React.JSX.Element => {
        const userLocalDateTime =
            device?.lastActivityTime !== null ? getLocalTimeStamp(device?.lastActivityTime) : '--';
        return (
            <Typography variant={'body2'}>
                {Math.sign(device?.lastActivityTime as number) <= 0 ? '--' : userLocalDateTime}
            </Typography>
        );
    };

    const getDeviceProductTypeCell = (device: Device): React.JSX.Element => (
        <Typography variant={'body2'}>{device?.productType ?? '--'}</Typography>
    );
    const getModelNumberCell = (device: Device): React.JSX.Element => (
        <Typography variant={'body2'}>{device?.modelNumber ?? '--'}</Typography>
    );

    //Function to get groupName
    const groupNameDetails = (device: Device): React.JSX.Element => (
        <Tooltip placement="top" arrow describeChild title={device?.groupName ?? ''}>
            <Chip
                variant="outlined"
                size="small"
                label={device?.groupName ?? '-'}
                className="custom-chip f-11"
                sx={{
                    Radius: '4px',
                }}
                disabled={!device?.groupName}
                onClick={(): void => {
                    if (navigateToGroup) navigateToGroup(device?.groupName);
                }}
            />
        </Tooltip>
    );

    //Function to edit group details
    const editGroupDetails = (device: Device): React.JSX.Element => (
        <IconButton
            onClick={(): void => {
                if (handleGroupOpen) handleGroupOpen(device?.deviceId, device?.groupName);
            }}
            disabled={device?.connectionState !== 'Connected'}
        >
            <EditIcon className={device?.connectionState === 'Connected' ? 'primary-color' : ''} />
        </IconButton>
    );

    //Functions to handle search for checkbox filter values
    const handleModelNumberSearchCell = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleModelNumberSearch(event, filtersData?.data?.modelNumber, 'label');
    };

    const handleDeviceProductTypeSearchCell = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleProductTypeSearch(event, filtersData?.data?.productType, 'label');
    };
    const handleGroupNameSearchCell = (event: React.ChangeEvent<HTMLInputElement>): void => {
        handleGroupNameSearch(event, groupNamesList, 'label');
    };

    //Columns for device list details
    const deviceListColumns = useMemo(
        () => [
            {
                header: '',
                isSelectable: true,
                cell: getCheckBoxForDevices,
            },
            {
                header: 'Status',
                accessor: 'connectionState',
                isDebounce: true,
                cell: getDeviceStatus,
                width: '196px',
                minWidth: 'custom-width-170px',
                isFilterable: true,
                filterOptions: filtersData?.data?.connectionState,
            },
            {
                header: 'Device Id',
                accessor: 'deviceId',
                isDebounce: true,
                isFilterable: true,
                cell: getDeviceIdCell,
                width: '30%',
            },
            {
                header: 'Device Product Type',
                accessor: 'productType',
                cell: getDeviceProductTypeCell,
                width: '20%',
                isFilterable: true,
                filterOptions: [{ id: 'all', label: 'All' }, ...(filteredProductTypeSearchData ?? [])],
                isSearchForSelectBox: true,
                filterSearchCell: handleDeviceProductTypeSearchCell,
                filterSearchValue: productTypeSearchValue,
            },
            {
                header: 'Model Number',
                accessor: 'modelNumber',
                cell: getModelNumberCell,
                width: '15%',
                isFilterable: true,
                filterOptions: [{ id: 'all', label: 'All' }, ...(filteredModelNumberSearchData ?? [])],
                isSearchForSelectBox: true,
                filterSearchCell: handleModelNumberSearchCell,
                filterSearchValue: modelNumberSearchValue,
            },
            {
                header: 'Last Reported At',
                accessor: 'lastActivityTime',
                cell: getLastReportedAtCell,
                isSortable: true,
                width: '12%',
            },
            {
                header: 'Group Name',
                accessor: 'groupName',
                isFilterable: true,
                cell: groupNameDetails,
                width: '17%',
                filterOptions: [{ id: 'all', label: 'All' }, ...(filteredGroupNameSearchData ?? [])],
                isSearchForSelectBox: true,
                filterSearchCell: handleGroupNameSearchCell,
                filterSearchValue: groupNameSearchValue,
            },
            {
                header: 'Change Group',
                cell: editGroupDetails,
                width: '5%',
            },
        ],
        [
            filtersData,
            assigneeGroupListData,
            filteredProductTypeSearchData,
            filteredModelNumberSearchData,
            filteredGroupNameSearchData,
            productTypeSearchValue,
        ]
    );

    /*Effects */
    /* The `useEffect` hook in the code snippet is used to call the `groupNameFilterList` function when the
    `assigneeGroupListData` dependency changes. */
    useEffect(() => {
        groupNameFilterList();
    }, [assigneeGroupListData]);

    return deviceListColumns;
};

export default useDeviceTableHeaders;
