import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SnackbarState, ProfileState } from './commonTypes';

const commonSlice = createSlice({
    name: 'commonSlices',
    initialState: {
        snackbar: {
            open: false,
            error401: false,
        },
        profile: {
            email: '',
            firstName: '',
            lastName: '',
            adopterName: '',
            adopterId: '',
            iotHubName: '',
            iotHubId: '',
            phoneNumber: '',
        },
        recallProfile: false,
    },
    reducers: {
        setSnackbarState: (state: { snackbar: { open: boolean } }, action: PayloadAction<SnackbarState>) => {
            state.snackbar = action.payload;
        },
        setProfileState: (
            state: {
                profile: ProfileState;
            },
            action: PayloadAction<ProfileState>
        ) => {
            state.profile = action.payload;
        },
        setRecallProfile: (
            state: {
                recallProfile: boolean;
            },
            action: PayloadAction<boolean>
        ) => {
            state.recallProfile = action.payload;
        },
    },
});

export const { setSnackbarState, setProfileState, setRecallProfile } = commonSlice.actions;

export default commonSlice;
