import { TableRow, TableCell, Typography, TableBody, Button } from '@mui/material';
import { Stack, Box } from '@mui/system';
import React from 'react';
import { createHeadersArray } from '../../commonUtils';
import { TableSkeleton } from '../../components/TableSkeleton';
import { GroupComplianceData } from '../../types';
import { Row } from '../../types/GroupsRow';
import Tooltip from '@mui/material/Tooltip';

/*Subgroup Table Component*/
const SubgroupTable = (
    row: Row,
    updateComplianceData: { data: { [x: string]: GroupComplianceData } },
    subgroupDataLoading: boolean,
    subRowsData: any[],
    subGroupApiError: boolean,
    subGroupDeploymentHandler: (deviceClassId: any, update: any) => void,
    groupNavigationHandler: (groupName: string, path: string, extraParams?: { subGroupDeviceClassId: string }) => void
): React.JSX.Element => {
    if (subgroupDataLoading) {
        return <TableSkeleton headers={createHeadersArray(4, false)} row={3} />;
    } else if (subGroupApiError) {
        return (
            <TableRow>
                <TableCell colSpan={7}>
                    <Stack width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                        <Typography variant="h6" fontWeight={600} py={3}>
                            Sorry, something went wrong. We are working on it and get it fixed as soon as we can.
                        </Typography>
                    </Stack>
                </TableCell>
            </TableRow>
        );
    }
    return (
        <TableBody data-cy="subGroup-table">
            {subRowsData?.map((subgroupRow: any) => (
                <TableRow key={subgroupRow.deviceClassId}>
                    <TableCell scope="row">{subgroupRow.deviceModelName}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                        <Typography>{subgroupRow?.deviceCount ?? '--'}</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                        <Typography>{subgroupRow.bestUpdateAvailable ?? '--'}</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', paddingTop: '2px' }}>
                        {/* progressbar styles starts */}
                        <Stack>
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                className="value"
                            >
                                <>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: `${Math.ceil(
                                                (updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                    ?.newUpdatesAvailableDeviceCount /
                                                    updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                        ?.totalDeviceCount) *
                                                    100
                                            )}%`,
                                            margin: '0px !important',
                                        }}
                                        className="value-text"
                                    >
                                        {updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                            ?.newUpdatesAvailableDeviceCount || ''}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: `${Math.ceil(
                                                (updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                    ?.updatesInProgressDeviceCount /
                                                    updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                        ?.totalDeviceCount) *
                                                    100
                                            )}%`,
                                            margin: '0px !important',
                                        }}
                                        className="value-text"
                                    >
                                        {updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                            ?.updatesInProgressDeviceCount || ''}
                                    </Typography>

                                    <Typography
                                        variant="h6"
                                        sx={{
                                            width: `${Math.ceil(
                                                (updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                    ?.onLatestUpdateDeviceCount /
                                                    updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                        ?.totalDeviceCount) *
                                                    100
                                            )}%`,
                                            margin: '0px !important',
                                        }}
                                        className="value-text"
                                    >
                                        {updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                            ?.onLatestUpdateDeviceCount || ''}
                                    </Typography>
                                </>
                            </Stack>

                            {updateComplianceData?.data ? (
                                <>
                                    <Box
                                        className="progress-bar"
                                        sx={{ margin: '0px !important', cursor: 'pointer' }}
                                        onClick={(): void =>
                                            groupNavigationHandler(row?.update, 'current-update', {
                                                subGroupDeviceClassId: subgroupRow?.deviceClassId,
                                            })
                                        }
                                    >
                                        <Tooltip title="Outdated">
                                            <Box
                                                className="brown"
                                                sx={{
                                                    width: `${Math.ceil(
                                                        (updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                            ?.newUpdatesAvailableDeviceCount /
                                                            updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                                ?.totalDeviceCount) *
                                                            100
                                                    )}%`,
                                                    margin: '0px !important',
                                                }}
                                            ></Box>
                                        </Tooltip>
                                        <Tooltip title="Updates in progress">
                                            <Box
                                                className="mustard"
                                                sx={{
                                                    width: `${Math.ceil(
                                                        (updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                            ?.updatesInProgressDeviceCount /
                                                            updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                                ?.totalDeviceCount) *
                                                            100
                                                    )}%`,
                                                    margin: '0px !important',
                                                }}
                                            ></Box>
                                        </Tooltip>
                                        <Tooltip title="On Latest">
                                            <Box
                                                className="green"
                                                sx={{
                                                    width: `${Math.ceil(
                                                        (updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                            ?.onLatestUpdateDeviceCount /
                                                            updateComplianceData?.data?.[subgroupRow.deviceClassId]
                                                                ?.totalDeviceCount) *
                                                            100
                                                    )}%`,
                                                    margin: '0px !important',
                                                }}
                                            ></Box>
                                        </Tooltip>
                                    </Box>
                                    <Typography align="center" variant="inherit">
                                        {!subgroupRow?.deviceCount && '--'}
                                    </Typography>
                                </>
                            ) : (
                                <Typography align="center" variant="inherit">
                                    Loading...
                                </Typography>
                            )}
                        </Stack>
                        {/* progressbar styles ends*/}
                    </TableCell>
                    <TableCell>
                        <Tooltip
                            title={
                                !subgroupRow?.deviceCount || !subgroupRow?.isDeployable
                                    ? 'No update is available to deploy.'
                                    : ''
                            }
                            placement="top"
                        >
                            <span>
                                <Button
                                    variant="outlined"
                                    className={
                                        !subgroupRow?.isDeployable || !subgroupRow?.deviceCount
                                            ? 'disabled-image-icon'
                                            : 'default-image-icon '
                                    }
                                    sx={{ cursor: 'pointer', borderRadius: '30px', mr: 1 }}
                                    onClick={(): void =>
                                        subGroupDeploymentHandler(subgroupRow?.deviceClassId, subgroupRow?.updateId)
                                    }
                                    disabled={!subgroupRow?.deviceCount || !subgroupRow?.isDeployable}
                                >
                                    Deploy
                                </Button>
                            </span>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

export default SubgroupTable;
