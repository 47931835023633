import React, { useMemo, useEffect, useRef, useState } from 'react';
import { ListItemTag } from '@brightlayer-ui/react-components';
import {
    Typography,
    Box,
    Card,
    CardContent,
    Button,
    Divider,
    TableContainer,
    Grid,
    AppBar,
    Toolbar,
    Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomTable from '../../components/CustomTable';
import {
    useGetCurrentDeploymentStatisticsQuery,
    useGetDeploymentViewDevicesQuery,
    useLazyDownloadDeviceDeploymentCSVQuery,
} from './groupApi';
import { useSelector } from 'react-redux';
import { useCancelDeploymentMutation, useRetryDeploymentMutation } from '../../features/common/commonApis';
import { downloadFileFromLink, getLocalTimeStamp } from '../../commonUtils';
import CancelDeploymentModal from '../../features/modal/CancelDeploymentModal';
import { CustomWaitingState } from '../../components/CustomWaitingState';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import { BackdropLoader } from '../../assets/loader/BackdropLoader';
import { DeployedDeviceType, DeploymentViewData } from '../../types';
import { AssignmentReturned } from '@mui/icons-material';
import { DeploymentViewList } from './DeploymentViewList';

export const DeploymentView = (): React.JSX.Element => {
    /*Additional Hooks*/
    const tableRefForDevices = useRef<any>();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { container } = useParams();
    const navigate = useNavigate();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    /*States */
    const [paginationPayload, setPaginationPayload] = useState({ page: 0, size: 10, filters: {} });
    const [isDeleteBtnOpen, setIsDeleteBtnOpen] = useState(false);
    const [deleteKey, setDeleteKey] = useState<string>('');
    const [customWaitingState, setCustomWaitingState] = useState<boolean>(false);

    /*Variables used in API calls*/
    const data = location?.state?.data;
    if (data) {
        localStorage.setItem('deploymentData', JSON.stringify(data));
    }
    const storedDataString = localStorage.getItem('deploymentData');
    const deploymentData: DeploymentViewData = storedDataString ? JSON.parse(storedDataString) : null;
    const groupId = deploymentData?.groupId?.replaceAll('$', '');
    const deviceClassId = deploymentData?.deviceClassSubgroups?.[0];
    const deploymentId = deploymentData?.deploymentId;

    /*API calls*/
    const [cancelDeployment, { isLoading: isCancelDeploymentLoading }] = useCancelDeploymentMutation();

    const {
        data: statisticsData,
        isFetching: isFetchingStatistics,
        refetch: refetchStatisticsData,
    } = useGetCurrentDeploymentStatisticsQuery(
        {
            groupId: groupId,
            deviceClassId: deviceClassId,
            deploymentId: deploymentId,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !(deviceClassId && deploymentId),
        }
    );

    const {
        data: deploymentViewDevices,
        isLoading,
        isFetching,
    } = useGetDeploymentViewDevicesQuery({ groupId, deviceClassId, deploymentId });

    const [downloadDeploymentCSV, { currentData: deploymentCSVData, isSuccess, isError }] =
        useLazyDownloadDeviceDeploymentCSVQuery();

    const [retryDeployment, { isLoading: isFetchingRetryDeploymentView }] = useRetryDeploymentMutation();

    /*Variables */
    const formattedDateTime = getLocalTimeStamp(deploymentData?.startDateTime);
    const selectedAdopter = profileProps?.adopterName as string;
    const selectedIotHubName = profileProps?.iotHubName as string;
    const dataForDeployedDevices = deploymentViewDevices?.data;

    /*Functions for table cell*/

    /**
     * The function returns a JSX element representing a button with an onClick event handler.
     * @param {DeployedDeviceType} deployedDevice - The parameter "deployedDevice" is of type "any", which means it can be any type of
     * data.
     */
    const getDeviceIdCell = (deployedDevice: DeployedDeviceType): React.JSX.Element => (
        <Typography>{deployedDevice?.deviceId}</Typography>
    );

    const getDeviceStatus = (deployedDevice: DeployedDeviceType): React.JSX.Element => (
        <ListItemTag
            className={customClassNameHandler(deployedDevice?.deviceState)}
            label={deployedDevice?.deviceState === null ? '--' : `${deployedDevice?.deviceState}`}
        />
    );

    const getRetryCount = (deployedDevice: DeployedDeviceType): React.JSX.Element => (
        <Typography>{deployedDevice?.retryCount}</Typography>
    );

    const getNewDeploymentStatus = (deployedDevice: DeployedDeviceType): React.JSX.Element => (
        <ListItemTag
            className={deployedDevice?.movedOnToNewDeployment ? 'succeeded-tag' : 'cancel-chip'}
            label={deployedDevice?.movedOnToNewDeployment ? 'Yes' : 'No'}
        />
    );

    /*Table Columns */
    const deploymentDeviceListColumns = useMemo(
        () => [
            {
                header: 'Name',
                // isSelectable: true,
                cell: getDeviceIdCell,
                width: '25%',
            },
            {
                header: 'Deployment status',
                isDebounce: true,
                //isFilterable:true,
                cell: getDeviceStatus,
                width: '25%',
            },
            {
                header: 'Retry count',
                // isDebounce: true,
                //isFilterable:true,
                cell: getRetryCount,
                width: '25%',
            },
            {
                header: 'Moved to new deployment',
                cell: getNewDeploymentStatus,
                width: '25%',
            },
        ],
        []
    );

    /*Table Handlers */
    const handleFilterChange = (filters: any): void => {
        if (JSON.stringify(filters) !== JSON.stringify(paginationPayload.filters)) {
            setPaginationPayload((prev: any) => ({
                ...prev,
                page: 0,
                filters: filters,
            }));
        }
    };

    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: any) => ({ ...prev, page: page, size: size }));
    };

    const customClassNameHandler = (deployStatus: string): string => {
        switch (deployStatus) {
            case 'Succeeded':
                return 'succeeded-tag';
            case 'Canceled':
            case 'Failed':
                return 'cancel-chip';
            case 'InProgress':
                return 'in-progress-chip';
            default:
                return 'bg-white text-black';
        }
    };

    const handleLabelClassName = (): string => {
        switch (statisticsData?.data?.deploymentState) {
            case 'Active': {
                return 'succeeded-tag';
            }
            case 'Inactive': {
                return 'inactive-chip';
            }
            default:
                return 'cancel-chip';
        }
    };

    const getWrapperClassForTable = (): string => {
        switch (true) {
            case dataForDeployedDevices?.length === 0 || isError:
                return 'device-table-white-space deployment-view-table-responsive no-device-found-center';
            case dataForDeployedDevices?.length < 3:
                return 'device-table-white-space deployment-view-table-responsive';
            default:
                return 'device-table-white-space deployment-view-table-responsive';
        }
    };

    /*Delete deployments handler */

    /**
     * The above functions handle canceling a deployment, opening a delete button, and closing a delete
     * button in a TypeScript React application.
     */
    const cancelDeploymentPayload = (): object => ({
        groupId,
        deviceClassId,
        deploymentId,
    });

    const handleCancelDeployment = async (): Promise<void> => {
        if (deleteKey) {
            await cancelDeployment(cancelDeploymentPayload())
                .then((res: any) => {
                    if (!res?.error) {
                        dispatch(setSnackbarState({ open: true, message: 'Deployment Cancelled Successfully!' }));
                    }
                })
                .catch((res: any) => {
                    console.error(res?.error?.data?.errorMessage?.[0]);
                });
            setIsDeleteBtnOpen(false);
        }
    };

    const handleDeleteBtnOpen = (): void => {
        setIsDeleteBtnOpen(true);
    };

    const handleDeleteBtnClose = (): void => {
        setIsDeleteBtnOpen(false);
    };

    /**
     * The function `retryDeploymentViewHandler` retries a deployment and displays a success message if
     * successful.
     */
    const retryDeploymentViewHandler = async (): Promise<void> => {
        await retryDeployment(cancelDeploymentPayload())
            .then((res: any) => {
                if (!res?.error) {
                    dispatch(setSnackbarState({ open: true, message: 'Retry Deployment Successful!' }));
                }
            })
            .catch((res) => {
                console.error(res?.error?.data?.errorMessage?.[0]);
            });
    };

    /*Download CSV handler */
    const downloadCSVHandler = async (): Promise<void> => {
        setCustomWaitingState(true);
        await downloadDeploymentCSV({ groupId, deviceClassId, deploymentId });
    };

    /*Effects */

    /* The above code is using the `useEffect` hook in a React component to handle side effects. It checks
    if `isSuccess` is true and `deploymentCSVData` has data. If both conditions are met, it calls the
    `downloadFileFromLink` function with the data from `deploymentCSVData` and sets a custom waiting
    state to false. If there is an error (`isError` is true), it also sets the custom waiting state to
    false. The `useEffect` hook will run whenever `isSuccess`, `isError`, or `deploymentCSVData` change. */

    useEffect(() => {
        if (isSuccess && deploymentCSVData?.data) {
            downloadFileFromLink(deploymentCSVData?.data, 'deploymentDevicesCSV');
            setCustomWaitingState(false);
        } else if (isError) {
            setCustomWaitingState(false);
        }
    }, [isSuccess, isError, deploymentCSVData]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} mt={'80px'} mx={3}>
                    <AppBar position="fixed" className="detail-page-header bg-white appbar-index6">
                        <Toolbar className="detail-page-toolbar">
                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <ArrowBackIcon
                                    className="back-icon cursor-pointer text-content"
                                    onClick={(): void => navigate(`/${container}/groupsTab/${groupId}/history`)}
                                    sx={{ marginRight: '16px' }}
                                />
                                <Box>
                                    <Typography variant={'h6'} className="text-content">
                                        Deployment Details
                                    </Typography>
                                    <Typography variant={'body1'} className="text-content">
                                        {selectedAdopter} &gt; {selectedIotHubName} &gt; Updates &gt;{' '}
                                        {deploymentData?.deploymentId}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Toolbar>
                    </AppBar>
                </Grid>
                {isFetching || isFetchingStatistics ? (
                    <BackdropLoader isOpen={isFetching || isFetchingStatistics} />
                ) : (
                    <Grid item xs={12}>
                        <Box sx={{ p: '0px 24px 24px 116px' }}>
                            <Card sx={{ mb: 3 }}>
                                <CardContent className="p-0">
                                    <Stack
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        p={2}
                                    >
                                        <Box className="d-flex align-items-center">
                                            <Stack flexDirection={'row'} alignItems={'center'}>
                                                <Typography
                                                    className="text-primary"
                                                    variant="h6"
                                                    fontSize={'16px'}
                                                    fontWeight={600}
                                                    data-cy="deployment-view-header"
                                                >
                                                    Update details
                                                </Typography>
                                            </Stack>
                                            <ListItemTag
                                                label={statisticsData?.data?.deploymentState}
                                                className={handleLabelClassName()}
                                                sx={{ marginLeft: 2 }}
                                            />
                                        </Box>
                                        {statisticsData?.data?.deploymentState === 'Active' && (
                                            <Box className="d-flex">
                                                <Button
                                                    startIcon={<ReplayIcon />}
                                                    variant="outlined"
                                                    size="medium"
                                                    className="btn-outline-primary-rounded"
                                                    onClick={retryDeploymentViewHandler}
                                                    disabled={true}
                                                    data-cy="retry-device"
                                                >
                                                    {isFetchingRetryDeploymentView
                                                        ? 'Processing..'
                                                        : 'Retry failed devices'}
                                                </Button>
                                                <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                                                <Button
                                                    startIcon={<CloseIcon />}
                                                    variant="outlined"
                                                    size="medium"
                                                    className="btn-outline-error-rounded"
                                                    onClick={handleDeleteBtnOpen}
                                                    data-cy="cancel-device"
                                                >
                                                    Cancel deployment
                                                </Button>
                                            </Box>
                                        )}
                                    </Stack>
                                    <Divider />
                                    <Box p={2} data-cy="deployment-view-details">
                                        <DeploymentViewList
                                            deploymentData={deploymentData}
                                            formattedDateTime={formattedDateTime}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                            <Card data-cy="deployment-details-table">
                                <CardContent className="p-0">
                                    <Stack
                                        flexDirection={'row'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        p={2}
                                        className="bg-white"
                                    >
                                        <Stack flexDirection={'row'} alignItems={'center'}>
                                            <Stack flexDirection={'row'} alignItems={'center'}>
                                                <Typography
                                                    variant="h6"
                                                    fontSize={'16px'}
                                                    fontWeight={600}
                                                    className="text-primary"
                                                >
                                                    Device list <b>({dataForDeployedDevices?.length})</b>
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            disabled={!dataForDeployedDevices?.length}
                                            onClick={downloadCSVHandler}
                                        >
                                            Export as CSV
                                        </Button>
                                    </Stack>
                                    <Divider />
                                    <TableContainer>
                                        <CustomTable
                                            ref={tableRefForDevices}
                                            isPagination={true}
                                            controlledPageSize={paginationPayload?.page}
                                            total={dataForDeployedDevices?.length}
                                            keyToTraverse="deviceId"
                                            handleFilterChange={handleFilterChange}
                                            handlePageChange={handlePaginationChange}
                                            data={dataForDeployedDevices}
                                            headers={deploymentDeviceListColumns}
                                            containerClass="custom-data-table"
                                            wrapperClass={getWrapperClassForTable()}
                                            isLoading={isLoading || isFetching}
                                            noDataFoundTitle={
                                                !dataForDeployedDevices
                                                    ? 'Something went wrong !! No devices found'
                                                    : 'No devices found'
                                            }
                                            noDataFoundIcon={<AssignmentReturned fontSize="inherit" />}
                                        ></CustomTable>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                )}
            </Grid>
            <CancelDeploymentModal
                data={statisticsData}
                handleCloseModal={handleDeleteBtnClose}
                isModalOpen={isDeleteBtnOpen}
                isLoading={isFetchingStatistics}
                refetchData={refetchStatisticsData}
                deleteKey={deleteKey}
                setDeleteKey={setDeleteKey}
                cancelDeployment={handleCancelDeployment}
                isSendingResponse={isCancelDeploymentLoading}
            />
            {customWaitingState && <CustomWaitingState />}
        </>
    );
};
