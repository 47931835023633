import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { setSnackbarState } from '../../features/common/commonSlice';

export const baseQuery = fetchBaseQuery({
    baseUrl: window.APP_CONFIG.API_URL,
    credentials: 'include',
    prepareHeaders: (headers: any, { getState }: any) => {
        const token = getState()?.auth?.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithInterceptor: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args: any,
    api: any,
    extraOptions: any
) => {
    const result: any = await baseQuery(args, api, extraOptions);
    const errorMessage = result?.error?.data?.errorMessage?.[0] || result?.error?.data?.error;
    if (
        result?.error?.status === 500 ||
        result?.error?.status === 403 ||
        result?.error?.status === 404 ||
        result?.error?.status === 400 ||
        result?.error?.status === 503
    ) {
        api.dispatch(
            setSnackbarState({
                open: true,
                message: errorMessage,
                isErrorAlert: true,
            })
        );
    }
    if (result?.error?.status === 401) {
        api.dispatch(
            setSnackbarState({
                open: true,
                message: errorMessage,
                isErrorAlert: true,
                error401: true,
            })
        );
    }
    return result;
};

export const api = createApi({
    tagTypes: [
        'devices',
        'Profile',
        'deviceId',
        'AddToGroups',
        'DeleteGroups',
        'DeleteDeployments',
        'DeleteFirmwares',
        'SummaryGroup',
        'CancelDeployment',
        'CreateDeployment',
    ],
    reducerPath: 'api',
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({}),
});
