/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { DialogContentText, DialogContent, Divider, Typography, Box, Stack, Chip } from '@mui/material';
import uploadIcon from '../../images/uploading.svg';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import { NOTE_MESSAGE_FIRMWARE_UPLOAD } from '../../components/Constant/FirmwareConstants';
import { FirmwareModalProps } from '../../types';
import { v4 as uuidv4 } from 'uuid';

const FirmwareModal3 = React.memo(
    (props: FirmwareModalProps): React.JSX.Element => (
        <>
            <Divider />
            <DialogContent className="form-content modal-body">
                <DialogContentText id="alert-dialog-description" className="form-content-text modal-body">
                    <Typography variant="body2">Firmware file</Typography>
                    <Box component={'form'} className="form-w-100">
                        <Box component={'form'} className="form-w-100">
                            <Box>
                                <input
                                    ref={props?.inputFileRef}
                                    title=""
                                    multiple={true}
                                    type="file"
                                    className="custom-input mb-0 modal-file-select-padding"
                                    onChange={(e): void => props?.handleFileUploadDownload(e, 'any', true)}
                                />
                            </Box>
                        </Box>
                        <Divider />
                        {props?.progress === 100 && (
                            <>
                                {props?.uploadedFile.map(({ name, size }: any, index: number) => (
                                    <Stack
                                        key={uuidv4()}
                                        flexDirection={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        py={3}
                                    >
                                        <Stack flexDirection={'row'} alignItems={'center'}>
                                            {props?.progress === 100 ? (
                                                <CheckIcon className="custom-icon-color" />
                                            ) : (
                                                <img src={uploadIcon} className="custom-icon-color" />
                                            )}
                                            <Tooltip title={name} placement="bottom-end">
                                                <Chip
                                                    label={name?.length > 50 ? `${name.substring(0, 48)}...` : name}
                                                    variant="outlined"
                                                    onDelete={(): void => {
                                                        if (props?.handleRemoveFile) {
                                                            props?.handleRemoveFile(index);
                                                        }
                                                    }}
                                                    sx={{ marginLeft: '16px' }}
                                                />
                                            </Tooltip>
                                        </Stack>
                                        <Box>
                                            <Typography variant="body2" className="modal-paragraph">
                                                {size > 1024 ? `${Math.floor(size / 1024)} kb` : `${size} bytes`}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                ))}
                            </>
                        )}
                        <Stack flexDirection={'row'} alignItems={'center'} marginTop={2}>
                            {!props?.validProps?.isValid && (
                                <>
                                    <ErrorIcon className="custom-error-icon" />
                                    <Typography variant="body2" className="custom-error-text">
                                        {props?.validProps?.errorMessage}
                                    </Typography>
                                </>
                            )}
                        </Stack>
                        <Stack>
                            <Typography
                                variant="body1"
                                fontSize={'16px'}
                                marginTop={2}
                                sx={{
                                    border: '2px solid #80bde0',
                                    borderRadius: '4px',
                                    padding: 1,
                                    marginBottom: 1,
                                    backgroundColor: '#fbfbfb',
                                }}
                            >
                                <b>Note:</b> {NOTE_MESSAGE_FIRMWARE_UPLOAD}
                            </Typography>
                        </Stack>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <Divider />
        </>
    )
);

export default FirmwareModal3;
