import React, { useEffect } from 'react';

export const useLoadMoreData = (data: object[]): { dataToShow: object[]; handleLoadMore: () => void } => {
    /*States */
    const [dataToShow, setDataToShow] = React.useState<object[]>([]);
    const [page, setPage] = React.useState(1);

    /*Functions */
    const handleLoadMore = (): void => {
        setDataToShow(dataToShow.concat(data?.slice(page * 99, page * 99 + 99)));
        setPage(page + 1);
    };

    /*Effects */
    /* The `useEffect` hook in the provided code snippet is used to perform side effects in a functional
     component. In this specific case, the `useEffect` hook is being used to set the initial state of the
    `dataToShow` and `page` variables when the `data` prop changes. */
    useEffect(() => {
        //initial states
        setDataToShow(data?.slice(0, 99));
        setPage(1);
    }, [data]);

    return { dataToShow, handleLoadMore };
};
