const CLIENT_ID = (window as any).APP_CONFIG.OKTA_APPLICATION_ID;
const ISSUER = (window as any).APP_CONFIG.OKTA_ISSUER_URL;
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK ?? false;
// BASENAME includes trailing slash
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const USE_INTERACTION_CODE = process.env.USE_INTERACTION_CODE === 'true' || false;

export default {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
};
