import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import StarIcon from '@mui/icons-material/Star';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { CustomErrorHandling } from '../../components/CustomErrorHandling';
import InfoIcon from '@mui/icons-material/Info';

type DataItem = {
    friendlyName: string;
    id: string;
    updateId: {
        name: string;
        provider: string;
        version: string;
    };
    isBestUpdate: boolean;
    isCurrentUpdate: boolean;
};

export const DeploymentModal3 = ({
    data,
    onFormChange,
    selectedUpdateId,
    isErrorBestUpdate,
    onInfoClick,
}: {
    data?: any;
    onFormChange?: (id: string) => void;
    onInfoClick: () => Promise<void>;
    selectedUpdateId?: string;
    isErrorBestUpdate?: boolean;
}): React.JSX.Element => (
    <>
        <Divider />
        <DialogContent className="modal-body custom-loader-wrapper custom-loader-wrapper-firmware-height">
            {!data?.length ? (
                <CustomErrorHandling
                    customClass={'center-loader-wrapper'}
                    responseData={data?.length}
                    isError={isErrorBestUpdate}
                />
            ) : (
                data?.map((item: DataItem) => (
                    <FormControl className="w-100" key={item?.id}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue="female"
                            name="radio-buttons-group"
                        >
                            <InfoListItem
                                sx={{ margin: '0px !important' }}
                                className="custom-list-border"
                                title=""
                                icon={
                                    <FormControlLabel
                                        // value="bestUpdate"
                                        control={<Radio />}
                                        label=""
                                        onChange={(): void => {
                                            if (onFormChange) {
                                                onFormChange(item?.id);
                                            }
                                        }}
                                        checked={selectedUpdateId === item?.id}
                                        // defaultChecked={item?.isBestUpdate}
                                    />
                                }
                                leftComponent={
                                    <Box>
                                        <Stack flexDirection={'row'} alignItems={'center'}>
                                            <Typography variant="h6" className="left-text">
                                                Provider :{' '}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                fontSize={'16px'}
                                                fontWeight={400}
                                                className="text-content"
                                            >
                                                &nbsp; {item?.updateId?.provider}
                                            </Typography>
                                            <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 2 }} />
                                            <ListItemTag label={item?.updateId?.version} margin={0} />
                                        </Stack>
                                        <Stack flexDirection={'row'} alignItems={'center'}>
                                            <Typography variant="h6" className="left-text">
                                                Name :{' '}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                fontSize={'16px'}
                                                fontWeight={400}
                                                className="text-content"
                                            >
                                                &nbsp; {item?.updateId?.name}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                }
                                rightComponent={
                                    <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                                        <Box>
                                            {item?.isBestUpdate && (
                                                <Stack flexDirection={'row'} alignItems={'center'}>
                                                    <StarIcon fontSize="small" color="primary" />
                                                    <Typography
                                                        variant="h6"
                                                        fontSize={'14px'}
                                                        fontWeight={600}
                                                        className="text-content"
                                                        sx={{ ml: 2, mr: 2 }}
                                                    >
                                                        Best Update
                                                    </Typography>
                                                </Stack>
                                            )}
                                            {item?.isCurrentUpdate && (
                                                <Stack flexDirection={'row'} alignItems={'center'}>
                                                    <DoneAllIcon fontSize="small" color="primary" />
                                                    <Typography
                                                        variant="h6"
                                                        fontSize={'14px'}
                                                        fontWeight={600}
                                                        className="text-content"
                                                        sx={{ ml: 2 }}
                                                    >
                                                        Current Update
                                                    </Typography>
                                                </Stack>
                                            )}
                                        </Box>
                                        <Box ml={1}>
                                            <Tooltip
                                                title={
                                                    selectedUpdateId === item?.id
                                                        ? 'View'
                                                        : 'Please select update to view details'
                                                }
                                            >
                                                <span>
                                                    <IconButton
                                                        aria-label="info"
                                                        onClick={(): Promise<void> => onInfoClick()}
                                                        disabled={selectedUpdateId !== item?.id}
                                                    >
                                                        <InfoIcon
                                                            fontSize="small"
                                                            className={
                                                                selectedUpdateId === item?.id
                                                                    ? 'primary-color'
                                                                    : 'disabled-icon-color'
                                                            }
                                                        />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </Stack>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                ))
            )}
        </DialogContent>

        <Divider />
    </>
);
