import {
    Chip,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormHelperText,
    Button,
    TextField,
    Autocomplete,
    CircularProgress,
    Link,
} from '@mui/material';
import { Spacer, ListItemTag } from '@brightlayer-ui/react-components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import React, { useState } from 'react';
import {
    useGetDeviceDeploymentStatusQuery,
    useGetDeviceListByIdQuery,
    useUpdateAssigneeGroupMutation,
} from './deviceApi';
import { BackdropLoader } from '../../assets/loader/BackdropLoader';
import { useGetGroupDetailsQuery } from '../Summary/groupApi';
import { copyToClipboard, getLocalTimeStamp } from '../../commonUtils';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import { useNavigate, useParams } from 'react-router-dom';
import { DeviceConnectionStatus } from '../../components/DeviceConnectionStatus';
import { GroupId, DevicePropType } from '../../types';
import CustomTextField from '../../components/CustomTextField';

export const DeviceDetail = (props: DevicePropType): React.JSX.Element => {
    /*Additional Hooks*/
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { container } = useParams();

    /*States*/
    // assign group modal
    const [selectedAssigneeValue, setSelectedAssigneeValue] = useState<string>('');
    const [assigneeDeviceId, setAssigneeDeviceId] = useState<string | undefined>('');
    const [openModal, setOpenModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    /*Api Calls*/
    //API call to handle device details by id.
    const { data, isLoading } = useGetDeviceListByIdQuery(props.deviceId, { refetchOnMountOrArgChange: true });
    // API call to update assignee group.
    const [updateAssigneeGroup, { isLoading: isLoadingAssignGroup }] = useUpdateAssigneeGroupMutation();
    //API call to handle devices deployment status.
    const { data: getDeviceDeploymentStatus, isLoading: isLoadingForDeploymentStatus } =
        useGetDeviceDeploymentStatusQuery({
            deviceId: props?.deviceId,
            refetchOnMountOrArgChange: true,
        });
    //API call to fetch assignee groups
    const assigneeGroupListData = useGetGroupDetailsQuery(undefined, { skip: !openModal });

    /*Variables*/
    // this variable deviceDetailsByIdData contains API (device details by id ) data.
    const deviceDetailsByIdData = data?.data;
    const assigneeGroupList: string[] = assigneeGroupListData?.data?.data
        ?.filter((item: GroupId) => item?.groupId !== '$default')
        .map((item: GroupId) => item?.groupId);
    const isLoaderAssignGroupModal = assigneeGroupListData?.isLoading;
    const isFetchingAssignGroupModal = assigneeGroupListData?.isFetching;

    /*Handlers*/
    /**
     * The below code snippet contains functions related to handling assignee groups, deployment status,
     * navigation, and class name determination based on deployment status.
     * @param {string} deviceId - The `deviceId` parameter is a string that represents the unique
     * identifier of a device. It is used in various functions and methods within the code snippet
     */
    const handleGroupOpen = (deviceId: string): void => {
        setOpenModal(true);
        setAssigneeDeviceId(deviceId);
    };
    const handleGroupClose = (): void => {
        setOpenModal(false);
        setSelectedAssigneeValue('');
    };

    //Function to handle selected assignee group
    const handleSelectAssignee = (_event: React.ChangeEvent<any>, newValue: string | null): void => {
        if (newValue !== null) setSelectedAssigneeValue(newValue);
    };

    //Function to call handle Assignee group
    const handleAssigneeGroup = async (): Promise<void> => {
        const requestDataForAssigneeGroup = {
            deviceIds: [assigneeDeviceId],
            groupId: selectedAssigneeValue,
        };
        const result: any = await updateAssigneeGroup(requestDataForAssigneeGroup);
        if (result.data.success) {
            handleGroupClose();
            dispatch(setSnackbarState({ open: true, message: 'Device added to group successfully!' }));
        }
    };

    //Function for deployment status
    const onLatestUpdateHandler = (latestUpdate: boolean, type: string): string => {
        if (
            (getDeviceDeploymentStatus?.data && Object.keys(getDeviceDeploymentStatus?.data).length === 0) ||
            getDeviceDeploymentStatus?.data?.deploymentStatus === null
        ) {
            return `${type === 'installed update' ? 'NO DEPLOYMENT YET' : '--'}`;
        } else if (latestUpdate) {
            return `${type === 'installed update' ? 'LATEST' : 'Yes'}`;
        }
        return `${type === 'installed update' ? 'OUTDATED' : 'No'}`;
    };

    const deploymentNavigateHandler = (): void => {
        navigate(`/${container}/groupOverviewDevice/${deviceDetailsByIdData?.groupName}/${props?.deviceId}`);
    };

    const installedUpdatedClassNameHandler = (latestUpdate: boolean, classType: string): string => {
        if (
            (getDeviceDeploymentStatus?.data && Object.keys(getDeviceDeploymentStatus?.data).length === 0) ||
            getDeviceDeploymentStatus?.data?.deploymentStatus === null
        ) {
            return `${classType === 'installed update' ? 'failed-tag' : 'bg-white text-black'}`;
        } else if (latestUpdate) {
            return 'succeeded-tag';
        }
        return `${classType === 'installed update' ? 'outdated-chip' : 'cancel-chip'}`;
    };

    const disableHandlerForAssignButton = (): boolean =>
        selectedAssigneeValue === deviceDetailsByIdData?.groupName ||
        isLoadingAssignGroup ||
        selectedAssigneeValue === '' ||
        Boolean(errorMessage);

    return (
        <React.Fragment>
            {isLoading || isLoadingForDeploymentStatus ? (
                <BackdropLoader isOpen={isLoading || isLoadingForDeploymentStatus} />
            ) : (
                <>
                    <Paper sx={{ mb: 3 }}>
                        <List className=" list-wrapper custom-list">
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        <Typography variant="h6" className="right-text" paddingRight={2}>
                                            {deviceDetailsByIdData?.deviceId}
                                        </Typography>
                                        <IconButton
                                            onClick={async (): Promise<void> => {
                                                try {
                                                    await copyToClipboard(deviceDetailsByIdData?.deviceId);
                                                    dispatch(
                                                        setSnackbarState({
                                                            open: true,
                                                            message: 'Copied to clipboard!',
                                                        })
                                                    );
                                                } catch (error) {
                                                    console.error('Error copying to clipboard:', error);
                                                }
                                            }}
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Stack>
                                }
                            >
                                <ListItemText primary={'Device ID'} className="device-primary-text" />
                            </ListItem>
                            <Divider />
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        <DeviceConnectionStatus deviceStatus={deviceDetailsByIdData?.connectionState} />
                                    </Stack>
                                }
                            >
                                <ListItemText
                                    primary={'Last reported connection state'}
                                    className="device-primary-text"
                                />
                            </ListItem>
                            <Divider />
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <Stack flexDirection={'row'} alignItems={'center'}>
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label={`${deviceDetailsByIdData?.groupName || '--'}`}
                                            className="custom-chip"
                                            sx={{
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <IconButton
                                            onClick={(): void => handleGroupOpen(deviceDetailsByIdData?.deviceId)}
                                            disabled={deviceDetailsByIdData?.connectionState !== 'Connected'}
                                            data-cy="edit-group-icon"
                                        >
                                            <EditIcon
                                                className={
                                                    deviceDetailsByIdData?.connectionState === 'Connected'
                                                        ? 'primary-color'
                                                        : ''
                                                }
                                            />
                                        </IconButton>
                                        {/* assign group modal starts */}
                                        <Dialog
                                            open={openModal}
                                            onClose={handleGroupClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                            className="modal-wrapper common-modal-view"
                                        >
                                            <DialogTitle id="alert-dialog-title" className="modal-header">
                                                <Typography variant="h6">Assign Group</Typography>
                                                <FormHelperText>Assign a group to selected device(s).</FormHelperText>
                                            </DialogTitle>
                                            <Divider />
                                            <DialogContent>
                                                {isLoaderAssignGroupModal || isFetchingAssignGroupModal ? (
                                                    <Stack
                                                        flexDirection={'row'}
                                                        justifyContent={'center'}
                                                        alignItems={'center'}
                                                    >
                                                        <CircularProgress thickness={5} size={50} />
                                                    </Stack>
                                                ) : (
                                                    <DialogContentText
                                                        id="alert-dialog-description"
                                                        className="modal-body"
                                                    >
                                                        <TextField
                                                            id="filled-basic"
                                                            label="Device ID"
                                                            variant="filled"
                                                            value={deviceDetailsByIdData?.deviceId}
                                                            disabled
                                                            sx={{ width: '100%' }}
                                                        />
                                                        <Spacer margin={'24px'} />
                                                        <Autocomplete
                                                            freeSolo
                                                            id="free-solo-2-demo"
                                                            disableClearable
                                                            value={selectedAssigneeValue}
                                                            onChange={handleSelectAssignee}
                                                            options={
                                                                assigneeGroupList?.map((option: any) => option) || []
                                                            }
                                                            renderInput={(params): React.JSX.Element => (
                                                                <CustomTextField
                                                                    params={params}
                                                                    setSelectedAssigneeValue={setSelectedAssigneeValue}
                                                                    selectedAssigneeValue={selectedAssigneeValue}
                                                                    setErrorMessage={setErrorMessage}
                                                                    errorMessage={errorMessage}
                                                                />
                                                            )}
                                                        />
                                                    </DialogContentText>
                                                )}
                                            </DialogContent>
                                            <Divider />
                                            <DialogActions className="modal-footer">
                                                <Button onClick={handleGroupClose} variant="outlined" size="medium">
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={(): Promise<void> => handleAssigneeGroup()}
                                                    color="primary"
                                                    variant="contained"
                                                    size="medium"
                                                    autoFocus
                                                    startIcon={
                                                        isLoadingAssignGroup && (
                                                            <CircularProgress
                                                                className="circular-upload-btn"
                                                                sx={{ color: '#fff' }}
                                                            />
                                                        )
                                                    }
                                                    disabled={disableHandlerForAssignButton()}
                                                >
                                                    Assign
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                        {/* assign group modal ends */}
                                    </Stack>
                                }
                            >
                                <ListItemText primary={'Group'} className="device-primary-text" />
                            </ListItem>
                            <Divider />
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <>
                                        <Typography variant="h6" className="right-text" paddingRight={2}>
                                            {deviceDetailsByIdData?.lastActivityTime !== null
                                                ? getLocalTimeStamp(deviceDetailsByIdData?.lastActivityTime)
                                                : '--'}
                                        </Typography>
                                    </>
                                }
                            >
                                <ListItemText primary={'Last reported At'} className="device-primary-text" />
                            </ListItem>
                            <Divider />
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <>
                                        <Stack flexDirection={'row'} alignItems={'center'} className="mr-8">
                                            <ListItemTag
                                                className={installedUpdatedClassNameHandler(
                                                    getDeviceDeploymentStatus?.data?.onLatestUpdate,
                                                    'installed update'
                                                )}
                                                label={onLatestUpdateHandler(
                                                    getDeviceDeploymentStatus?.data?.onLatestUpdate,
                                                    'installed update'
                                                )}
                                            />
                                            <Typography variant="body2" paddingLeft={1}>
                                                Name:{' '}
                                                <b>
                                                    {getDeviceDeploymentStatus?.data?.installedUpdate?.updateId?.name ||
                                                        '--'}
                                                </b>
                                            </Typography>
                                            <Typography variant="body2" sx={{ mx: 2 }}>
                                                Provider:{' '}
                                                <b>
                                                    {' '}
                                                    {getDeviceDeploymentStatus?.data?.installedUpdate?.updateId
                                                        ?.provider || '--'}
                                                </b>
                                            </Typography>
                                            <Typography variant="body2">
                                                Version:{' '}
                                                <b>
                                                    {getDeviceDeploymentStatus?.data?.installedUpdate?.updateId
                                                        ?.version || '--'}
                                                </b>
                                            </Typography>
                                        </Stack>
                                    </>
                                }
                            >
                                <ListItemText primary={'Installed update'} className="device-primary-text" />
                            </ListItem>
                            <Divider />
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <>
                                        <Stack flexDirection={'row'} alignItems={'center'} className="mr-8">
                                            {getDeviceDeploymentStatus?.data?.deploymentStatus === 'Succeeded' ? (
                                                <ListItemTag className="succeeded-tag" label="Success" />
                                            ) : (
                                                <ListItemTag className="failed-tag" label="No deployment yet" />
                                            )}
                                            <Typography variant="body2" paddingLeft={1}>
                                                Name:{' '}
                                                <b>
                                                    {getDeviceDeploymentStatus?.data?.lastAttemptedUpdate?.updateId
                                                        ?.name || '--'}
                                                </b>
                                            </Typography>
                                            <Typography variant="body2" sx={{ mx: 2 }}>
                                                Provider:{' '}
                                                <b>
                                                    {' '}
                                                    {getDeviceDeploymentStatus?.data?.lastAttemptedUpdate?.updateId
                                                        ?.provider || '--'}
                                                </b>
                                            </Typography>
                                            <Typography variant="body2">
                                                Version:{' '}
                                                <b>
                                                    {getDeviceDeploymentStatus?.data?.lastAttemptedUpdate?.updateId
                                                        ?.version || '--'}
                                                </b>
                                            </Typography>
                                        </Stack>
                                    </>
                                }
                            >
                                <ListItemText primary={'Last attempted update'} className="device-primary-text" />
                            </ListItem>
                            <Divider />
                            <ListItem
                                disableGutters
                                secondaryAction={
                                    <ListItemTag
                                        label={onLatestUpdateHandler(
                                            getDeviceDeploymentStatus?.data?.onLatestUpdate,
                                            'latest update'
                                        )}
                                        className={installedUpdatedClassNameHandler(
                                            getDeviceDeploymentStatus?.data?.onLatestUpdate,
                                            'latest update'
                                        )}
                                    ></ListItemTag>
                                }
                            >
                                <ListItemText primary={'On latest update'} className="device-primary-text" />
                            </ListItem>
                            <Divider />
                            {getDeviceDeploymentStatus?.data?.deploymentStatus === 'Succeeded' && (
                                <ListItem
                                    disableGutters
                                    secondaryAction={
                                        <Stack flexDirection={'row'} justifyContent={'end'} sx={{ mt: 1 }}>
                                            <Link
                                                variant="body2"
                                                component="button"
                                                onClick={(): void => deploymentNavigateHandler()}
                                                data-cy="view-diagnostic-detail"
                                            >
                                                View Deployment Diagnostic Detail.
                                            </Link>
                                        </Stack>
                                    }
                                >
                                    <ListItemText
                                        primary={'Deployment Diagnostic Detail'}
                                        className="device-primary-text"
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </>
            )}
        </React.Fragment>
    );
};
