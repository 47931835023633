import React, { useRef, useState, useEffect } from 'react';
import {
    Typography,
    Card,
    CardContent,
    Divider,
    Stack,
    Button,
    Checkbox,
    Box,
    DialogContentText,
    CircularProgress,
} from '@mui/material';
import { CustomWaitingState } from '../../components/CustomWaitingState';
import { downloadFileFromLink, getLocalTimeStamp, handleClientSidePaginationSorting } from '../../commonUtils';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    useDeleteDeploymentsMutation,
    useGetDeploymentHistoryByGroupIdQuery,
    useLazyDownloadDeploymentHistoryCSVQuery,
} from './groupApi';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTable from '../../components/CustomTable';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { AssignmentReturned } from '@mui/icons-material';
import { DeploymentHistoryProps } from '../../types';
import { PaginationPayload } from '../../types/Common';

export const DeploymentHistory = (props: DeploymentHistoryProps): React.JSX.Element => {
    /*Additional Hooks*/
    const resetCheckboxRef: any = useRef([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { container } = useParams();

    /*States */
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [filteredDeploymentHistory, setFilteredDeploymentHistory] = useState([]);
    const [paginationPayload, setPaginationPayload] = useState<PaginationPayload>({
        page: 0,
        size: 10,
        filters: {},
        sort: { key: '', sortType: '' },
    });
    const [openCustomWaitingState, setCustomWaitingState] = useState<boolean>(false);

    /*API calls */
    const { data: deploymentHistoryData, isFetching: isDeploymentDataHistoryFetching } =
        useGetDeploymentHistoryByGroupIdQuery(
            { groupId: props?.groupId },
            {
                refetchOnMountOrArgChange: true,
            }
        );
    const [downloadDeploymentHistoryCSV, { currentData: deploymentHistoryCSVData, isSuccess, isError }] =
        useLazyDownloadDeploymentHistoryCSVQuery();
    const [deleteDeployments, { isLoading: isDeleteDeploymentsLoading }] = useDeleteDeploymentsMutation();

    /*Variables */
    const groupId = props?.groupId;
    const flattenedDataArray = deploymentHistoryData?.data?.records?.map((el: { updateId: object }): object => ({
        ...el,
        ...el.updateId,
    }));

    /*Functions for table cell */
    const getCheckBoxForDeploymentHistory = (): React.JSX.Element => <Checkbox />;

    const deploymentStatusCell = (cell: { status: string }): React.JSX.Element => (
        <ListItemTag className={handleChipClassName(cell?.status)} label={cell?.status?.toUpperCase() ?? '-'} />
    );

    const deploymentDetailCell = (cell: { status: string }): React.JSX.Element => (
        <Typography
            className="text-primary text-clickable"
            onClick={(): void => deploymentNavigationHandler(cell?.status, cell)}
        >
            View
        </Typography>
    );

    const updateCell = (cell: { provider: string; name: string }): React.JSX.Element => (
        <>
            <Stack flexDirection={'row'} alignItems={'center'}>
                <Typography variant="body1" fontSize={14}>
                    Provider -{' '}
                </Typography>
                <Typography variant="body2" fontSize={14} fontWeight={600} pl={1}>
                    {cell?.provider}
                </Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'}>
                <Typography variant="body1" fontSize={14}>
                    Name -{' '}
                </Typography>
                <Typography variant="body2" fontSize={14} fontWeight={600} pl={1}>
                    {cell?.name}
                </Typography>
            </Stack>
        </>
    );

    const startDateTimeCell = (cell: { startDateTime: number }): React.JSX.Element => (
        <Typography variant={'body2'}>{getLocalTimeStamp(cell?.startDateTime) || 'N/A'}</Typography>
    );

    /*Table Columns */
    const columns = React.useMemo(
        () => [
            {
                header: '',
                isSelectable: true,
                cell: getCheckBoxForDeploymentHistory,
            },
            {
                header: 'Update',
                accessor: '',
                cell: updateCell,
                isDebounce: true,
                width: '20%',
            },
            {
                header: 'Version',
                accessor: 'version',
                isDebounce: true,
                width: '15%',
            },
            {
                header: 'Target Devices',
                accessor: 'deviceCount',
                isDebounce: true,
                width: '15%',
            },
            {
                header: 'Start Date',
                accessor: 'startDateTime',
                cell: startDateTimeCell,
                isDebounce: true,
                width: '20%',
            },
            {
                header: 'Deployment Status',
                cell: deploymentStatusCell,
                isDebounce: true,
                width: '15%',
            },
            {
                header: 'Deployments Details',
                cell: deploymentDetailCell,
                isDebounce: true,
                width: '15%',
            },
        ],
        [deploymentHistoryData]
    );

    /*Table Handlers */
    const handleCheckboxSelect = (checkBoxIds: string[]): void => {
        setSelectedIds(checkBoxIds);
    };

    const handlePaginationChange = (page: number, size: number): void => {
        setPaginationPayload((prev: PaginationPayload) => ({ ...prev, page: page, size: size }));
    };

    const deploymentNavigationHandler = (status: string, cellData?: any): void => {
        const modifiedGroupId = props?.groupId?.replaceAll('$', '');
        navigate(`/${container}/${groupId}/deploymentView/${status?.toLowerCase()}`, {
            state: { data: cellData, groupId: modifiedGroupId },
        });
    };

    const handleChipClassName = (status: string): string => {
        switch (status) {
            case 'Active': {
                return 'succeeded-tag';
            }
            case 'Inactive': {
                return 'inactive-chip';
            }
            default:
                return 'cancel-chip';
        }
    };

    /*Delete deployments handler */
    /**
     * The function `handleDelete` is an asynchronous function that deletes deployments, closes a delete
     * modal, resets selected IDs, and resets checkboxes if a ref exists.
     */
    const handleDelete = async (): Promise<void> => {
        try {
            await deleteDeployments({ groupId, selectedIds });
            setIsDeleteOpen(false);
            setSelectedIds([]);
            if (resetCheckboxRef.current) {
                resetCheckboxRef.current.resetCheckboxes();
            }
        } catch {
            setIsDeleteOpen(false);
            setSelectedIds([]);
            if (resetCheckboxRef.current) {
                resetCheckboxRef.current.resetCheckboxes();
            }
        }
    };

    const handleDeleteBtnOpen = (): void => {
        setIsDeleteOpen(true);
    };

    const handleClose = (): void => {
        setIsDeleteOpen(false);
    };

    /*Download CSV Handler */
    const downloadCSVHandler = async (): Promise<void> => {
        setCustomWaitingState(true);
        await downloadDeploymentHistoryCSV(props?.groupId?.replaceAll('$', ''));
    };

    /*Effects */
    /* The above code is a `useEffect` hook in a TypeScript React component. It is monitoring the
    `isSuccess`, `isError`, and `deploymentHistoryCSVData` variables for changes. */

    useEffect(() => {
        if (isSuccess && deploymentHistoryCSVData?.data) {
            downloadFileFromLink(deploymentHistoryCSVData?.data, 'deploymentHistoryCSV');
            setCustomWaitingState(false);
        } else if (isSuccess && deploymentHistoryCSVData?.data === null) {
            setCustomWaitingState(false);
            dispatch(setSnackbarState({ open: true, message: 'Something went wrong, please try again later!' }));
        } else if (isError) {
            setCustomWaitingState(false);
        }
    }, [isSuccess, isError, deploymentHistoryCSVData]);

    useEffect(() => {
        if (!isDeploymentDataHistoryFetching) {
            const clonedData = flattenedDataArray?.slice(
                paginationPayload.page * paginationPayload.size,
                paginationPayload.page * paginationPayload.size + paginationPayload.size
            );
            setFilteredDeploymentHistory(clonedData);
        }
    }, [deploymentHistoryData]);

    useEffect(() => {
        if (flattenedDataArray?.length) {
            const [paginatedData]: any = handleClientSidePaginationSorting(flattenedDataArray, paginationPayload);
            setFilteredDeploymentHistory(paginatedData);
        }
    }, [paginationPayload]);

    return (
        <>
            <Box p={'24px 24px 22px 114px'}>
                <Card data-cy="deployment-history-content">
                    <CardContent className="card-content p-0">
                        <Divider />
                        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} p={2}>
                            <Typography variant="body2" fontSize={'16px'} fontWeight={600} className="text-primary">
                                Deployed updates <b>{`(${deploymentHistoryData?.data?.total || 0})`}</b>
                            </Typography>
                            <Box>
                                {selectedIds?.length > 0 ? (
                                    <Button
                                        startIcon={<DeleteIcon />}
                                        onClick={handleDeleteBtnOpen}
                                        variant="contained"
                                        size="medium"
                                        className="error-btn"
                                        sx={{ mr: 2 }}
                                    >
                                        Delete
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        disabled={!deploymentHistoryData?.data?.total}
                                        onClick={downloadCSVHandler}
                                    >
                                        Export as CSV
                                    </Button>
                                )}
                            </Box>
                        </Stack>
                        <Divider />
                        <CustomTable
                            ref={resetCheckboxRef}
                            isPagination={true}
                            total={flattenedDataArray?.length}
                            keyToTraverse="deploymentId"
                            handlePageChange={handlePaginationChange}
                            data={filteredDeploymentHistory}
                            headers={columns}
                            containerClass="custom-data-table"
                            wrapperClass="device-table-white-space deployment-history-table-responsive"
                            isLoading={isDeploymentDataHistoryFetching}
                            noDataFoundTitle={
                                !deploymentHistoryData ? 'Something went wrong !! No logs found' : 'No Logs found'
                            }
                            handleCheckboxSelect={handleCheckboxSelect}
                            keyToCheckForDisabledCheckbox="status"
                            valueToCheckForDisabledCheckbox="Active"
                            noDataFoundIcon={<AssignmentReturned fontSize="inherit" />}
                        />
                    </CardContent>
                </Card>
            </Box>

            {/* Delete modal */}
            <Dialog
                open={isDeleteOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="modal-wrapper common-modal-view"
            >
                <DialogTitle id="alert-dialog-title" className="modal-header">
                    {'Delete Deployment History(s)?'}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="modal-body">
                        {'This action cannot be undone.'}
                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions className="modal-footer">
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        className="error-btn"
                        autoFocus
                        variant="contained"
                        disabled={isDeleteDeploymentsLoading}
                        startIcon={
                            isDeleteDeploymentsLoading && (
                                <CircularProgress className="circular-upload-btn" sx={{ color: '#fff' }} />
                            )
                        }
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {openCustomWaitingState && <CustomWaitingState />}
        </>
    );
};
