import React from 'react';
import {
    AppBar,
    Box,
    Card,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDeviceDeploymentDetailsQuery } from './groupApi';
import { useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import { useGetDeviceListByIdQuery } from '../DeviceUpdate/deviceApi';
import { DeviceConnectionStatus } from '../../components/DeviceConnectionStatus';
import { ListItemTag } from '@brightlayer-ui/react-components';
import { DeviceDeploymentDetails } from '../../types';
import { BackdropLoader } from '../../assets/loader/BackdropLoader';

export const GroupOverviewDevice = (): React.JSX.Element => {
    /*Additional hooks*/
    const navigate = useNavigate();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);
    const { deviceId, container, groupName } = useParams();

    /*API calls*/
    //API call for device deployment details
    const { data: detailsData, isFetching: isDeviceDiagnosticStatusFetching } = useGetDeviceDeploymentDetailsQuery(
        deviceId,
        { refetchOnMountOrArgChange: true }
    );

    const { data, isFetching: isDeviceIdDetailsFetching } = useGetDeviceListByIdQuery(deviceId, {
        refetchOnMountOrArgChange: true,
    });

    /*Variables */
    const adopterName = profileProps?.adopterName;
    const iotHubName = profileProps?.iotHubName;
    const deviceDeploymentDetailsData: DeviceDeploymentDetails = detailsData;

    /*Functions */
    /**
     * The `backIconHandler` function navigates to different routes based on the value of the `container`
     * variable.
     */
    const backIconHandler = (): void => {
        if (container === 'deviceTable') {
            navigate(`/${container}/deviceTabs/${deviceId}`);
        } else {
            navigate(`/${container}/groupsTab/${groupName}/overview`);
        }
    };

    /**
     * The function `classHandlerForDeploymentStatus` returns a CSS class based on the deployment status
     * provided in the `deviceDeploymentDetailsData` object.
     * @returns The function `classHandlerForDeploymentStatus` returns a string value based on the
     * `deploymentStatus` property of `deviceDeploymentDetailsData.data`. The returned string is a CSS
     * class name that corresponds to the deployment status.
     */
    const classHandlerForDeploymentStatus = (): string => {
        switch (deviceDeploymentDetailsData?.data?.deploymentStatus) {
            case 'Succeeded':
                return 'succeeded-tag';
            case 'Canceled':
            case 'Failed':
                return 'cancel-chip';
            case 'InProgress':
                return 'in-progress-chip';
            default:
                return 'bg-white text-black';
        }
    };

    return (
        <>
            <AppBar position="fixed" className="bg-white" sx={{ zIndex: 5 }}>
                <Toolbar className="detail-page-toolbar">
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <ArrowBackIcon
                            data-cy="arrowBackIcon-for-overview-device"
                            className="back-icon cursor-pointer text-content"
                            onClick={(): void => backIconHandler()}
                            sx={{ marginRight: '16px' }}
                        />
                        <Box>
                            <Typography variant={'h6'} className="text-content">
                                Device Deployment Diagnostic Details
                            </Typography>
                            <Typography variant={'body1'} className="text-content">
                                {adopterName} &gt; {iotHubName} &gt; Groups &gt; {groupName} &gt; {deviceId}
                            </Typography>
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
            {isDeviceDiagnosticStatusFetching || isDeviceIdDetailsFetching ? (
                <BackdropLoader isOpen={isDeviceDiagnosticStatusFetching || isDeviceIdDetailsFetching} />
            ) : (
                <Box m={'96px 30px 30px 120px'}>
                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Card sx={{ width: '49%' }} data-cy="device-diagnostic-detail-content">
                            <Typography variant="h6" fontSize={'16px'} fontWeight={600} p={2} className="text-primary">
                                Device Deployment Diagnostic Details
                            </Typography>
                            <Divider />
                            <CardContent>
                                <List>
                                    <ListItem
                                        secondaryAction={
                                            <Typography
                                                variant="h6"
                                                fontSize={'16px'}
                                                fontWeight={400}
                                                className="text-primary text-clickable"
                                                onClick={(): void =>
                                                    navigate(
                                                        `/${container}/groupOverviewDevice/deviceTabs/${groupName}/${deviceDeploymentDetailsData?.data?.deviceId}`
                                                    )
                                                }
                                                data-cy="device-name-heading"
                                            >
                                                {deviceDeploymentDetailsData?.data?.deviceId || '--'}
                                            </Typography>
                                        }
                                    >
                                        <ListItemText primary="Device name" />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            <ListItemTag
                                                className={classHandlerForDeploymentStatus()}
                                                label={deviceDeploymentDetailsData?.data?.deploymentStatus || '--'}
                                            ></ListItemTag>
                                        }
                                    >
                                        <ListItemText primary="Deployment Status" />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            <DeviceConnectionStatus deviceStatus={data?.data?.connectionState} />
                                        }
                                    >
                                        <ListItemText primary="Last Reported Connection State" />
                                    </ListItem>
                                    <Divider />
                                    <Typography
                                        variant="h6"
                                        fontSize={'14px'}
                                        fontWeight={600}
                                        p={2}
                                        className="text-content"
                                    >
                                        Attributes
                                    </Typography>
                                    <ListItem
                                        secondaryAction={deviceDeploymentDetailsData?.data?.descriptiveLabel || '--'}
                                    >
                                        <ListItemText primary="Descriptive label" />
                                    </ListItem>
                                    <ListItem secondaryAction={deviceDeploymentDetailsData?.data?.manufacturer || '--'}>
                                        <ListItemText primary="Manufacturer" />
                                    </ListItem>
                                    <ListItem secondaryAction={deviceDeploymentDetailsData?.data?.model || '--'}>
                                        <ListItemText primary="Model" />
                                    </ListItem>
                                    <ListItem secondaryAction={deviceDeploymentDetailsData?.data?.name || '--'}>
                                        <ListItemText primary="Contract Model Name" />
                                    </ListItem>
                                    <ListItem secondaryAction={deviceDeploymentDetailsData?.data?.id || '--'}>
                                        <ListItemText primary="Contract Model ID" />
                                    </ListItem>
                                    <Divider />
                                    <Typography
                                        variant="h6"
                                        fontSize={'14px'}
                                        fontWeight={600}
                                        p={2}
                                        className="text-content"
                                    >
                                        Last Attempted Update
                                    </Typography>
                                    <ListItem secondaryAction="--">
                                        <ListItemText primary="Update" />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            deviceDeploymentDetailsData?.data?.lastInstallResult?.resultCode ?? '--'
                                        }
                                    >
                                        <ListItemText primary="Result code" />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            deviceDeploymentDetailsData?.data?.lastInstallResult?.extendedResultCode ??
                                            '--'
                                        }
                                    >
                                        <ListItemText primary="Extended result code" />
                                    </ListItem>
                                    <ListItem
                                        secondaryAction={
                                            deviceDeploymentDetailsData?.data?.lastInstallResult?.resultDetails || '--'
                                        }
                                    >
                                        <ListItemText primary="Description" />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                        <Card sx={{ width: '49%' }} data-cy="device-diagnostic-step-result">
                            <Typography variant="h6" fontSize={'14px'} fontWeight={600} p={2} className="text-primary">
                                Deployment step results
                            </Typography>
                            <Divider />
                            <CardContent>
                                <TableContainer
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        maxHeight: '700px',
                                    }}
                                >
                                    <Table stickyHeader aria-label="sticky table" className="custom-data-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Result Code</TableCell>
                                                <TableCell>Extended Result Code</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Result Details</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deviceDeploymentDetailsData?.data?.lastInstallResult?.stepResults?.map(
                                                (row) => (
                                                    <TableRow
                                                        key={uuidv4()}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Typography variant="body2">
                                                                {row?.resultCode ?? '--'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body2">
                                                                {row?.extendedResultCode ?? '--'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body2">
                                                                {row?.description ?? '--'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body2">
                                                                {row?.resultDetails ?? '--'}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Stack>
                </Box>
            )}
        </>
    );
};
