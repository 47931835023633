import React, { memo, useState } from 'react';
import { DialogContent, FormHelperText, TextField, Divider, Box } from '@mui/material';
import { Firmware } from '../../types';

type Props = { payload: Firmware; setPayload: (payload: Firmware) => void };

export const FirmwareModal1 = memo((props: Props): React.JSX.Element => {
    const [showWarningText, setShowWarningText] = useState<boolean>(false);

    /**
     * The handleChange function is used to handle changes in an input field and update the payload
     * state accordingly, while also showing a warning text if the description input exceeds 500
     * characters.
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.id === 'description' && event.target.value.length > 500) {
            setShowWarningText(true);
        } else {
            const payloadClone = JSON.parse(JSON.stringify(props?.payload));
            payloadClone[event.target.id] = event.target.value;
            props.setPayload(payloadClone);
            setShowWarningText(false);
        }
    };

    return (
        <>
            <Divider />
            <DialogContent className="form-content modal-body">
                <Box component={'form'} className="form-w-100">
                    <TextField
                        sx={{ mb: 3 }}
                        fullWidth
                        id="name"
                        label="Name"
                        value={props?.payload?.name}
                        onChange={handleChange}
                        variant="filled"
                        required
                    />
                    <TextField
                        fullWidth
                        id="description"
                        onChange={handleChange}
                        value={props?.payload?.description}
                        label="Descriptive Label"
                        variant="filled"
                        helperText={`${props?.payload?.description?.length}/500`}
                        required
                    />
                    {showWarningText && (
                        <FormHelperText sx={{ color: '#ca3c3d' }}>
                            *Description should not exceed 500 characters in length
                        </FormHelperText>
                    )}
                </Box>
            </DialogContent>
        </>
    );
});
