import React from 'react';
import { Dialog, Divider, DialogContent, Stack, DialogActions, Button, Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ReactJson from 'react-json-view';

export type Props = {
    srcJSON: any;
    handleOpen: boolean;
    handleClose: () => void;
};

export const FullScreenJsonView = ({ srcJSON, handleOpen, handleClose }: Props): React.JSX.Element => (
    <Dialog
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-json-file-full-view"
    >
        <Stack flexDirection={'row'} justifyContent={'flex-end'}>
            <IconButton>
                <Close onClick={handleClose} />
            </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
            <Box className="custom-json-view custom-json-full-view-height">
                <ReactJson src={srcJSON} enableClipboard={false} theme="monokai" />
            </Box>
        </DialogContent>
        <Divider />
        <DialogActions className="modal-footer">
            <Button variant="outlined" onClick={handleClose}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
);
