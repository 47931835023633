import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { DonutChartSkeleton, LegendOptions } from '../types/HighCharts';
import { legendOptions } from '../commonUtils';

interface TooltipOptions {
    formatter: () => string;
    point?: {
        name?: string;
        y?: number;
    };
    percentage?: number;
    backgroundColor?: string;
    outside?: boolean;
    style?: {
        color?: string;
    };
}

type ExtraLegendOptions = LegendOptions & {
    align?: string;
    verticalAlign?: string;
    layout?: string;
    x?: number;
    y?: number;
};

export const DonutChart = (props: {
    data?: DonutChartSkeleton;
    size?: string;
    innerSize?: string;
    verticalAxis?: number;
    additionalLegendOptions?: object;
    subtitleOptions?: { x?: number; y?: number };
    subtitleTooltip?: string;
    click?: any;
}): React.JSX.Element => {
    const tooltipOptions: TooltipOptions = {
        formatter: function () {
            return `${this?.point?.name} : <b>  ${this?.point?.y}  </b>  (${this?.percentage?.toFixed(1)}%)`;
        },
        backgroundColor: '#000',
        outside: true,
        style: {
            color: '#fff', // Change text color
        },
    };

    const donutChartLegendOptions: ExtraLegendOptions = {
        ...legendOptions,
        ...props?.additionalLegendOptions,
    };

    function getSubtitle(): string {
        return `
             <span class="f-12" title="${props?.subtitleTooltip}">
                 Total: <b>${props.data?.totalDeviceCount ?? '0'}</b>
             </span>`;
    }

    const options = {
        chart: {
            type: 'pie',
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        subtitle: {
            useHTML: true,
            text: props?.subtitleOptions && getSubtitle(),
            floating: true,
            verticalAlign: 'middle',
            y: props?.subtitleOptions?.y,
            x:
                props?.data?.totalDeviceCount?.toString()?.length === 4 && props?.subtitleOptions?.x
                    ? props?.subtitleOptions?.x - 5
                    : props?.subtitleOptions?.x,
        },
        plotOptions: {
            pie: {
                innerSize: props?.innerSize ?? '50%',
                size: props?.size,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                events: {
                    click: props?.click,
                },
            },
        },
        tooltip: tooltipOptions,
        legend: donutChartLegendOptions,
        series: [props?.data],
    };

    return <HighchartsReact options={options} highcharts={Highcharts} />;
};
