import {
    Paper,
    Typography,
    TableContainer,
    TableHead,
    TableBody,
    Table,
    TableRow,
    TableCell,
    Stack,
    Box,
    IconButton,
    CircularProgress,
    Button,
    InputAdornment,
    FilledInput,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Divider,
    DialogActions,
    TableSortLabel,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Add, Delete, FiberManualRecord } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import {
    useGetSummaryGroupsQuery,
    useGetGroupComplianceDataMutation,
    useUpdateAssigneeGroupCSVMutation,
    useDeleteGroupsMutation,
    useUnAssigneeDevicesMutation,
    useDeleteDevicesCSVMutation,
} from './summaryApi';
import {
    useGetDeviceStatsQuery,
    useGetIotHubStatsQuery,
    useGetUnknownDeviceCountQuery,
} from '../../features/common/commonApis';
import { TableSkeleton } from '../../components/TableSkeleton';
import { createHeadersArray, sortData } from '../../commonUtils';
import { CustomErrorHandling } from '../../components/CustomErrorHandling';
import { StepperModal } from '../../features/modal/StepperModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AddGroupModal1 } from '../../features/modal/AddGroupModal1';
import { AddGroupModal2 } from '../../features/modal/AddGroupModal2';
import { AddGroupModal3 } from '../../features/modal/AddGroupModal3';
import { useHandleFileUpload } from '../../hooks/useHandleFileUpload';
import { useUpdateAssigneeGroupMutation } from '../DeviceUpdate/deviceApi';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import SearchIcon from '@mui/icons-material/Search';
import { setSnackbarState } from '../../features/common/commonSlice';
import { useAppDispatch } from '../../redux-config/store';
import { SummaryRow } from './SummaryTable';
import { GroupComplianceData } from '../../types';
import { CountersComponent } from '../../components/CountersComponent';
import { ChartConstants } from '../../components/Constant/ChartConstants';

function createData(
    update: string,
    device: number,
    progressBarData: object,
    deployments: number,
    isDeployable: boolean,
    status: string
): object {
    return {
        update,
        device,
        progressBarData,
        deployments,
        isDeployable,
        status,
    };
}

type OperationType = {
    id: string;
    label: string;
};

export type SortType = {
    [key: string]: any;
};

type GroupComplianceDataObj = {
    [key: string]: GroupComplianceData;
};

const INITIAL_STATES = {
    devicesToDelete: {
        isError: false,
        errorMessage: '',
    },
};

export const Summary = (): React.JSX.Element => {
    const dispatch = useAppDispatch();
    //Custom hook for upload CSV add to group function.
    const [handleFileUploadDownload, { validProps, uploadFileName, fileSize, uploadedFile }, resetValues] =
        useHandleFileUpload();

    //Api Calls
    const { data: hubStats } = useGetIotHubStatsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });
    const { data: deviceStats } = useGetDeviceStatsQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });
    const { data: unknownDeviceStats } = useGetUnknownDeviceCountQuery(undefined, {
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
    });

    const {
        currentData: summaryGroupData,
        isFetching: summaryGroupDataFetching,
        isError: summaryGroupDataFetchingError,
    } = useGetSummaryGroupsQuery(undefined);

    const [getGroupComplianceData] = useGetGroupComplianceDataMutation();

    //API call for assign group (add devices to group using CSV)
    const [addDevicesToGroupsCSV, { isLoading: isAddingDevicesToGroupsCsv }] = useUpdateAssigneeGroupCSVMutation();
    //API call for assign group (add devices to group)
    const [addDevicesToGroups, { isLoading: isAddingDevicesToGroups }] = useUpdateAssigneeGroupMutation();
    //API call to delete devices
    const [deleteDevices, { isLoading: isDeletingDevices }] = useUnAssigneeDevicesMutation();
    //API call to delete devices through CSV
    const [deleteDeviceCSV, { isLoading: isDeletingDevicesCSV }] = useDeleteDevicesCSVMutation();
    //API call to delete groups
    const [deleteGroups, { isLoading: isLoadingForDeleteGroups, isSuccess: groupDeletionSuccess }] =
        useDeleteGroupsMutation();

    const [tableRowsData, setTableRowsData] = useState(summaryGroupData?.data?.groups);
    const [handleSearch, filteredData] = useSearchFilter(summaryGroupData?.data?.groups);
    const [sortType, setSortType] = useState<SortType>({});
    const [promisesFulfilled, setPromisesFulfilled] = useState(false);
    const [visibleRows, setVisibleRows] = useState(7);
    const [openAddGroupModal, setOpenAddGroupModal] = useState<boolean>(false);
    const [deviceUploadType, setDeviceUploadType] = useState<string>('selectDevice');
    const [groupOperationType, setGroupOperationType] = useState<OperationType>({
        id: 'addNewGroup',
        label: 'Add/Update Group',
    });
    const [currentStepForAddNewGroup, setCurrentStepForAddNewGroup] = useState<string>('');
    const [groupNameForDevices, setGroupNameForDevices] = useState('');
    const [devicesToDelete, setDevicesToDelete] = useState(INITIAL_STATES.devicesToDelete);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [groupComplianceData, setGroupComplianceData] = useState<GroupComplianceDataObj>({});
    //File validation for .csv and .XLSX files
    const [fileValidation, setFileValidation] = useState<boolean>(false);
    //payload for devices
    const [payloadForDevices, setPayloadForDevices] = useState([]);
    // checked icon condition
    const [checkedGroups, setCheckedGroups] = useState<string[]>([]);
    // delete btn modal
    const [isDeleteBtnOpen, setIsDeleteBtnOpen] = useState(false);

    /*Variables*/
    const requestBodyForDevices = {
        deviceIds: payloadForDevices,
        groupId: groupNameForDevices,
    };

    const requestBodyForDeviceDeletion = {
        deviceIds: payloadForDevices,
    };

    const defaultDeviceModal = [
        {
            key: 'modal1',
            header: groupOperationType.label,
            title:
                groupOperationType.id === 'addNewGroup'
                    ? 'Add devices to new or existing Group'
                    : 'Delete devices from Groups',
            component: (
                <AddGroupModal1
                    setDeviceUploadType={setDeviceUploadType}
                    groupOperationType={groupOperationType}
                    setGroupOperationType={setGroupOperationType}
                    deviceUploadType={deviceUploadType}
                    setGroupNameForDevices={setGroupNameForDevices}
                    groupNameForDevices={groupNameForDevices}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            ),
        },
        {
            key: 'modal2',
            header: groupOperationType.label,
            title:
                groupOperationType.id === 'addNewGroup'
                    ? 'Add devices to new or existing Group. Select Device(s) from below table.'
                    : 'Delete devices from Groups. Select Device(s) from below table.',
            component: (
                <AddGroupModal2
                    handleFileUploadDownload={handleFileUploadDownload}
                    validProps={validProps}
                    uploadFileName={uploadFileName}
                    fileSize={fileSize}
                    groupNameForDevices={groupNameForDevices}
                    uploadedFile={uploadedFile}
                    setFileValidation={setFileValidation}
                    fileValidation={fileValidation}
                    payloadForDevices={payloadForDevices}
                    setPayloadForDevices={setPayloadForDevices}
                    deviceUploadType={deviceUploadType}
                    groupOperationType={groupOperationType}
                    resetValues={resetValues}
                    setDevicesToDelete={setDevicesToDelete}
                    devicesToDelete={devicesToDelete}
                />
            ),
        },
        ...(deviceUploadType === 'selectDevice'
            ? [
                  {
                      key: 'modal3',
                      header: groupOperationType.label,
                      title: 'Preview and confirm all changes here.',
                      component: (
                          <AddGroupModal3
                              uploadFileName={uploadFileName}
                              groupNameForDevices={groupNameForDevices}
                              payloadForDevices={payloadForDevices}
                              setPayloadForDevices={setPayloadForDevices}
                              groupOperationType={groupOperationType}
                          />
                      ),
                  },
              ]
            : []),
    ];

    const createRows = (): any[] =>
        tableRowsData
            ?.slice(0, visibleRows)
            ?.map(
                (row: {
                    groupId: string;
                    deviceCount: number;
                    deployments: string | any[];
                    isDeployable: boolean;
                    status: string;
                }) =>
                    createData(
                        row.groupId,
                        row.deviceCount,
                        groupComplianceData?.[row.groupId],
                        row?.deployments?.length,
                        row?.isDeployable,
                        row?.status
                    )
            );

    /**
     * The function `getCurrentStep` sets the current step to the provided value.
     * @param {string} step - The parameter "step" is a string that represents the current step.
     */

    const getCurrentStepForAddNewGroups = (step: string): void => {
        if (step === 'modal1') {
            setDevicesToDelete(INITIAL_STATES.devicesToDelete);
        }
        setCurrentStepForAddNewGroup(step);
    };

    //isDisable function for group stepper

    const handleStepDisable = (): boolean => {
        if (
            groupOperationType.id === 'addNewGroup' &&
            currentStepForAddNewGroup === 'modal1' &&
            groupNameForDevices?.length < 1
        )
            return true;
        if (
            currentStepForAddNewGroup === 'modal2' &&
            payloadForDevices?.length < 1 &&
            (!uploadFileName || !fileValidation)
        )
            return true;
        if (errorMessage) return true;
        if (devicesToDelete.isError) return true;
        return false;
    };

    const handleDeleteGroup = async (): Promise<void> => {
        const requestBodyToDeleteGroups = {
            groupIds: checkedGroups,
        };
        try {
            await deleteGroups(requestBodyToDeleteGroups);
            setIsDeleteBtnOpen(false);
            setCheckedGroups([]);
        } catch {
            setIsDeleteBtnOpen(false);
        }
    };

    // onchange handler for summary screen page
    const handleSearchWithDebounce = (
        event: React.ChangeEvent<HTMLInputElement>,
        data: object[],
        targetKey: string
    ): void => {
        if (event.target.value === '') {
            setVisibleRows(7);
        }
        setTimeout(() => {
            handleSearch(event, data, targetKey);
        }, 1000);
    };

    const handleDeleteBtnOpen = (): void => {
        setIsDeleteBtnOpen(true);
    };

    const handleDeleteBtnClose = (): void => {
        setIsDeleteBtnOpen(false);
    };

    /**
     * @returns The `handleGroupsTable` function returns a JSX element based on certain conditions. If
     * `summaryGroupDataFetching` is true, it returns a `TableSkeleton`(loader) component. If
     * `summaryGroupDataFetchingError` is true, it returns a `TableBody` component with a
     * `CustomErrorHandling` component inside. If `filteredData` is an empty array, it returns a
     * `No results found
     */
    const handleGroupsTable = (): React.JSX.Element => {
        if (summaryGroupDataFetching) {
            return <TableSkeleton headers={createHeadersArray(5, true)} row={10} />;
        } else if (summaryGroupDataFetchingError) {
            return (
                <TableBody className="border-removed" sx={{ position: 'relative' }}>
                    <CustomErrorHandling
                        responseData={summaryGroupData?.data?.groups?.length}
                        isError={summaryGroupDataFetchingError}
                    />
                </TableBody>
            );
        } else if (filteredData?.length === 0) {
            return (
                <TableBody className="border-removed" sx={{ position: 'relative' }}>
                    <Box className="no-data-found-wrapper">
                        <Typography variant="h6">No results found</Typography>
                    </Box>
                </TableBody>
            );
        }
        return (
            <TableBody className="border-removed" data-cy={'summary-table-body'}>
                {createRows()?.map((row) => (
                    <SummaryRow
                        row={row}
                        key={row.update}
                        checkedGroups={checkedGroups}
                        setCheckedGroups={setCheckedGroups}
                    />
                ))}
            </TableBody>
        );
    };

    const handleLoadMoreData = (): void => {
        setVisibleRows(visibleRows + 7);
        setPromisesFulfilled(false);
    };

    const addNewGroupHandler = (): void => {
        setOpenAddGroupModal(true);
    };

    const handleCloseAddNewGroupModal = (): void => {
        setOpenAddGroupModal(false);
        setGroupOperationType({
            id: 'addNewGroup',
            label: 'Add/Update Group',
        });
        setDeviceUploadType('selectDevice');
        setGroupNameForDevices('');
        setPayloadForDevices([]);
        setFileValidation(false);
        resetValues();
        setErrorMessage(null);
    };

    const submitHandlerForAddNewGroups = async (): Promise<void> => {
        if (deviceUploadType === 'uploadDevice') {
            const { error }: any =
                groupOperationType.label === 'Add/Update Group'
                    ? await addDevicesToGroupsCSV({ groupNameForDevices, uploadedFile })
                    : await deleteDeviceCSV({ uploadedFile });
            if (!error) {
                dispatch(setSnackbarState({ open: true, message: 'Request completed successfully!' }));
            }
        } else if (
            (requestBodyForDevices?.deviceIds?.length > 0 || requestBodyForDevices?.groupId !== '') &&
            deviceUploadType !== 'uploadDevice'
        ) {
            const { error }: any =
                groupOperationType.label === 'Add/Update Group'
                    ? await addDevicesToGroups(requestBodyForDevices)
                    : await deleteDevices(requestBodyForDeviceDeletion);
            if (!error) {
                dispatch(setSnackbarState({ open: true, message: 'Request completed successfully!' }));
            }
        }
    };

    const allGroupsList = summaryGroupData?.data?.groups?.map(
        (item: { groupId: string; deployments: string | any[]; deviceCount: number }) => ({
            groupId: item?.groupId,
            deploymentLength: item?.deployments?.length,
            deviceCount: item?.deviceCount,
        })
    );

    const eachGroupDeploymentLengthGreaterThanOne = allGroupsList?.every((item: any) => item.deploymentLength);

    // Function to handle the master checkbox
    /**
     * The function `handleMasterCheckbox` toggles the selection of all checkboxes based on the master
     * checkbox state.
     * @param {any} e - The parameter `e` in the `handleMasterCheckbox` function is an event object
     * that is passed when the master checkbox is clicked. It is used to determine whether the master
     * checkbox is checked or unchecked.
     */
    const handleMasterCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e?.target?.checked) {
            // If the  checkbox is checked, select all checkboxes
            const allGroupIds = allGroupsList
                .filter(
                    (item: { deploymentLength: number; deviceCount: number }) =>
                        item?.deploymentLength === 0 && item.deviceCount === 0
                )
                .map((item: { groupId: string }) => item?.groupId);
            setCheckedGroups(allGroupIds);
        } else {
            // If the  checkbox is unchecked, clear the selected checkboxes
            setCheckedGroups([]);
        }
    };

    // Function to check if all checkboxes are checked
    /**
     * The function `isAllChecked` checks if all enabled devices are checked in a list of groups.
     * @returns The function `isAllChecked` returns a boolean value. It checks if there are any enabled
     * devices based on certain conditions, and then compares the length of `checkedGroups` with the
     * length of `enabledDevices`. If the lengths are equal, it returns `true`, otherwise it returns
     * `false`.
     */
    const isAllChecked = (): boolean => {
        const enabledDevices = allGroupsList?.filter(
            (item: { deploymentLength: number; deviceCount: number }) =>
                item?.deploymentLength === 0 && item.deviceCount === 0
        );
        if (enabledDevices?.length === 0) {
            return false;
        }
        return checkedGroups?.length === enabledDevices?.length;
    };

    const handleDataSort = (key: string, type: string): void => {
        const sortedData = sortData(summaryGroupData?.data?.groups, key, type);
        setTableRowsData(sortedData);
        setSortType(type === 'desc' ? { [key]: 'asc' } : { [key]: 'desc' });
        setVisibleRows(7);
        setPromisesFulfilled(false);
    };

    const customHeightHandlerForSummaryTable = (): string => {
        if (tableRowsData?.length === 0 || summaryGroupDataFetchingError) {
            return 'h-100';
        }
        return 'h-auto';
    };

    const mergeGroupComplianceData = (dataObj: GroupComplianceData, groupId: string): void => {
        setGroupComplianceData((prev) => ({
            ...prev,
            [groupId]: dataObj,
        }));
    };
    useEffect(() => {
        const slicedData = tableRowsData?.slice(0, visibleRows);
        if (slicedData?.length) {
            const promises: Array<Promise<void>> = [];
            slicedData.slice(-7)?.forEach((group: { groupId: string }) => {
                const promise = getGroupComplianceData({
                    groupId: group.groupId,
                }).then((res: any) => {
                    if (!res?.error) {
                        mergeGroupComplianceData(res?.data?.data?.[group.groupId], group.groupId);
                    }
                });
                promises.push(promise);
            });
            Promise.all(promises)
                .then((response) => {
                    if (response) {
                        setPromisesFulfilled(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [visibleRows, tableRowsData]);

    useEffect(() => {
        setVisibleRows(7);
        setTableRowsData(filteredData);
        setSortType({});
    }, [filteredData]);

    useEffect(() => {
        if (groupDeletionSuccess) {
            dispatch(setSnackbarState({ open: true, message: 'Request completed successfully!' }));
        }
    }, [groupDeletionSuccess]);

    return (
        <>
            <Box className="main-content-wrapper">
                <Stack
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className="custom-card"
                    sx={{ boxShadow: 'none !important', m: 0 }}
                ></Stack>
                <CountersComponent
                    deviceData={deviceStats?.data}
                    hubData={hubStats?.data}
                    unknownDeviceData={unknownDeviceStats?.data}
                />
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} py={3}>
                    <Typography className="text-primary">
                        Update Groups
                        {!summaryGroupDataFetchingError &&
                            summaryGroupData?.data?.groups?.length &&
                            ` (Showing ${
                                visibleRows > tableRowsData?.length ? tableRowsData?.length : visibleRows
                            } of ${summaryGroupData?.data?.groups?.length})`}
                    </Typography>
                    <Box>
                        {checkedGroups?.length < 1 ? (
                            <Button
                                variant={'contained'}
                                startIcon={<Add />}
                                size="medium"
                                onClick={(): void => addNewGroupHandler()}
                            >
                                Add/ Update Group
                            </Button>
                        ) : (
                            <Button
                                variant={'contained'}
                                startIcon={<Delete />}
                                size="medium"
                                className="error-btn"
                                onClick={handleDeleteBtnOpen}
                            >
                                Delete
                            </Button>
                        )}
                    </Box>
                </Stack>
                <Paper>
                    <TableContainer
                        className=""
                        component={Paper}
                        sx={{
                            maxHeight: 'calc(100vh - 218px)',
                            minHeight: '585px',
                            boxShadow: 'none !important',
                        }}
                    >
                        <Table
                            aria-label="collapsible table sticky"
                            className={customHeightHandlerForSummaryTable()}
                            data-cy="summary-table"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow className="summary-table-vertical-align">
                                    <TableCell padding={'checkbox'}>
                                        <Checkbox
                                            size="medium"
                                            color="primary"
                                            disabled={
                                                summaryGroupDataFetching || eachGroupDeploymentLengthGreaterThanOne
                                            }
                                            onChange={handleMasterCheckbox}
                                            checked={isAllChecked()}
                                        />
                                    </TableCell>
                                    <TableCell width={'15%'} className="summary-table-input-width">
                                        <Tooltip
                                            title={
                                                !promisesFulfilled &&
                                                !summaryGroupDataFetchingError &&
                                                'Please wait for the data to load.'
                                            }
                                        >
                                            <Typography fontSize={14} fontWeight={600} color={'#424e54'} pb={1}>
                                                Name
                                                <TableSortLabel
                                                    direction={sortType['groupId']}
                                                    active={Object.hasOwn(sortType, 'groupId')}
                                                    onClick={(): void => handleDataSort('groupId', sortType['groupId'])}
                                                    disabled={
                                                        !promisesFulfilled ||
                                                        !summaryGroupData?.data?.groups?.length ||
                                                        summaryGroupDataFetchingError
                                                    }
                                                ></TableSortLabel>
                                            </Typography>
                                        </Tooltip>
                                        <FilledInput
                                            size="small"
                                            placeholder="Search"
                                            data-cy="summary-search"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton edge="end">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                                                handleSearchWithDebounce(event, tableRowsData, 'groupId')
                                            }
                                        />
                                    </TableCell>
                                    <TableCell width={'30%'} sx={{ textAlign: 'right', pr: '13.9%' }}>
                                        <Tooltip
                                            title={
                                                !promisesFulfilled &&
                                                !summaryGroupDataFetchingError &&
                                                'Please wait for the data to load.'
                                            }
                                        >
                                            <Typography fontSize={14} fontWeight={600} color={'#424e54'} pb={1}>
                                                # of Devices
                                                <TableSortLabel
                                                    direction={sortType['deviceCount']}
                                                    active={Object.hasOwn(sortType, 'deviceCount')}
                                                    onClick={(): void =>
                                                        handleDataSort('deviceCount', sortType['deviceCount'])
                                                    }
                                                    disabled={
                                                        !promisesFulfilled ||
                                                        !summaryGroupData?.data?.groups?.length ||
                                                        summaryGroupDataFetchingError
                                                    }
                                                ></TableSortLabel>
                                            </Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell width={'45%'} sx={{ textAlign: 'center' }}>
                                        <Stack
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            sx={{ position: 'relative', top: '6px' }}
                                        >
                                            <span className="badge-wrapper">
                                                <FiberManualRecord
                                                    fontSize="small"
                                                    className="not-compliance-text-color"
                                                />{' '}
                                                <Tooltip
                                                    title={ChartConstants?.CHART_COMPLIANCE_TOOLTIP_UPDATE_AVAILABLE}
                                                    arrow
                                                >
                                                    <Typography fontWeight={600} fontSize="14px">
                                                        Outdated
                                                    </Typography>
                                                </Tooltip>
                                            </span>
                                            <span className="badge-wrapper">
                                                <FiberManualRecord fontSize="small" className="inprogress-text-color" />{' '}
                                                <Tooltip
                                                    title={ChartConstants?.CHART_COMPLIANCE_TOOLTIP_UPDATE_IN_PROGRESS}
                                                    arrow
                                                >
                                                    <Typography fontWeight={600} fontSize="14px">
                                                        {' '}
                                                        Updates in Progress
                                                    </Typography>
                                                </Tooltip>
                                            </span>
                                            <span className="badge-wrapper">
                                                <FiberManualRecord fontSize="small" className="compliance-text-color" />{' '}
                                                <Tooltip
                                                    title={
                                                        ChartConstants?.CHART_COMPLIANCE_TOOLTIP_UPDATE_LATEST_UPDATE
                                                    }
                                                    arrow
                                                >
                                                    <Typography fontWeight={600} fontSize="14px">
                                                        {' '}
                                                        On Latest
                                                    </Typography>
                                                </Tooltip>
                                            </span>
                                        </Stack>
                                    </TableCell>
                                    <TableCell width={'10%'}></TableCell>
                                </TableRow>
                            </TableHead>
                            {handleGroupsTable()}
                        </Table>
                    </TableContainer>
                    {summaryGroupData?.data?.groups?.length &&
                        !summaryGroupDataFetching &&
                        !summaryGroupDataFetchingError && (
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                p={1}
                                sx={{ borderTop: '1px solid rgba(66, 78, 84, 0.24)' }}
                                data-cy="summary-table-footer"
                            >
                                {filteredData?.length > visibleRows ? (
                                    <Button
                                        variant="contained"
                                        endIcon={!promisesFulfilled ? <AccessTimeIcon /> : <ExpandMoreIcon />}
                                        disabled={filteredData?.length < visibleRows || !promisesFulfilled}
                                        onClick={(): void => handleLoadMoreData()}
                                    >
                                        {!promisesFulfilled ? 'Please Wait' : 'Load more data'}
                                    </Button>
                                ) : (
                                    <Button disabled variant="outlined">
                                        No more data
                                    </Button>
                                )}
                            </Stack>
                        )}
                </Paper>
            </Box>

            <StepperModal
                className="device-table-in-modal"
                modalContent={defaultDeviceModal}
                closeModal={(): void => handleCloseAddNewGroupModal()}
                isOpen={openAddGroupModal}
                submitHandler={submitHandlerForAddNewGroups}
                getCurrentStep={getCurrentStepForAddNewGroups}
                isDisabled={
                    handleStepDisable() ||
                    isAddingDevicesToGroupsCsv ||
                    isAddingDevicesToGroups ||
                    isDeletingDevices ||
                    isDeletingDevicesCSV
                }
                submitButton={groupOperationType.label}
                isSendingResponse={
                    isAddingDevicesToGroupsCsv || isAddingDevicesToGroups || isDeletingDevices || isDeletingDevicesCSV
                }
            />

            {/* delete btn modal starts */}
            <Dialog
                open={isDeleteBtnOpen}
                onClose={handleDeleteBtnClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="modal-wrapper common-modal-view"
            >
                <DialogTitle id="alert-dialog-title" className="modal-header">
                    {'Delete Group(s)?'}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {isLoadingForDeleteGroups ? (
                        <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                            <CircularProgress thickness={5} size={50} />
                        </Stack>
                    ) : (
                        <DialogContentText id="alert-dialog-description" className="modal-body">
                            {'This action cannot be undone.'}
                        </DialogContentText>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions className="modal-footer">
                    <Button onClick={handleDeleteBtnClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteGroup}
                        className="error-btn"
                        variant="contained"
                        disabled={isLoadingForDeleteGroups}
                        autoFocus
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {/* delete btn modal ends */}
        </>
    );
};
