import React from 'react';
import { Typography, Box, Tabs, Tab, Toolbar, Stack } from '@mui/material';
import { AppBar } from '@brightlayer-ui/react-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import { DeviceDetail } from './DeviceDetail';
import { useSelector } from 'react-redux';

export const DeviceDetailTabs = (): React.JSX.Element => {
    //Hooks
    const navigate = useNavigate();
    const { deviceId, subContainer, container, groupId } = useParams();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);

    /*
     * The function `backNavigationRoute` returns different routes based on the presence of `groupId` and
     * `subContainer`.
     */
    const backNavigationRoute = (): string => {
        if (groupId) {
            return `/${container}/groupsTab/${groupId}/overview`;
        } else if (subContainer) {
            return `/${container}/groupOverviewDevice/${groupId}/${deviceId}`;
        }
        return `/${container}`;
    };

    return (
        <>
            <Box className="content-container detail-page-container" sx={{ height: 'auto' }}>
                <AppBar className="detail-page-header appbar-index6">
                    <Toolbar className="detail-page-toolbar">
                        <Stack flexDirection={'row'} alignItems={'center'}>
                            <ArrowBackIcon
                                className="back-icon text-clickable"
                                sx={{ color: '#424e54' }}
                                onClick={(): void => navigate(backNavigationRoute())}
                            />
                            <Box>
                                <Typography variant={'h6'} fontSize={'20px'} fontWeight={600} className="text-content">
                                    Device Details
                                </Typography>
                                <Typography
                                    variant={'body1'}
                                    fontSize={'16px'}
                                    fontWeight={400}
                                    className="text-content"
                                >
                                    {profileProps?.adopterName} <b>&gt;</b> {profileProps?.iotHubName} <b>&gt;</b>{' '}
                                    {deviceId}
                                </Typography>
                            </Box>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box
                className="detail-page-header"
                sx={{ zIndex: '0', height: '58px !important', position: 'fixed', top: '60px' }}
            >
                <Tabs className="custom-tab custom-tabs-style" sx={{ top: '62px', zIndex: 4 }} value={0}>
                    <Stack alignItems={'center'} flexDirection={'row'}>
                        <Tab
                            icon={<DescriptionIcon />}
                            iconPosition="start"
                            label="Details"
                            className="tab-item active"
                            sx={{ py: 0, px: 4 }}
                        />
                    </Stack>
                </Tabs>
            </Box>
            <Box className="custom-card" sx={{ mt: 4, boxShadow: 'none !important' }}>
                <DeviceDetail deviceId={deviceId} profileData={profileProps} />
            </Box>
        </>
    );
};
