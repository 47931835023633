import { Box, Button, CardContent, Divider, Stack, Typography, TextField, AppBar, Toolbar, Paper } from '@mui/material';
import React, { useMemo } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { Spacer } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { useGetCurrentDeploymentDevicesQuery } from './groupApi';
import { useSearchFilter } from '../../hooks/useSearchFilter';
import CustomTable from '../../components/CustomTable';
import { Devices } from '@mui/icons-material';

export const UpdateList = (): React.JSX.Element => {
    const navigate = useNavigate();
    const profileProps = useSelector((state: any) => state.commonSlices.profile);
    const { groupId, deploymentId, deviceClassId, container } = useParams();

    //API call to get target devices

    const { data: targetDeviceList, isFetching: isFetchingDevices } = useGetCurrentDeploymentDevicesQuery(
        {
            groupId: groupId,
            deviceClassId: deviceClassId,
            deploymentId: deploymentId,
        },
        {
            refetchOnMountOrArgChange: true,
        }
    );
    const [handleSearch, filteredData] = useSearchFilter(targetDeviceList?.data);

    const handleViewCell = ({ deviceId }: { deviceId: string }): React.JSX.Element => (
        <Button variant="text" size="medium" onClick={(): void => navigate(`/${container}/deviceTabs/${deviceId}`)}>
            View
        </Button>
    );

    const columns = useMemo(
        () => [
            {
                header: 'Devices',
                accessor: 'deviceId',
                width: '90%',
            },
            {
                header: 'Action',
                cell: handleViewCell,
            },
        ],
        []
    );
    return (
        <Paper sx={{ margin: '24px', mt: '100px', ml: '120px', padding: '16px' }}>
            <AppBar className="detail-page-header appbar-index6">
                <Toolbar className="detail-page-toolbar">
                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <ArrowBackIcon
                            className="back-icon cursor-pointer"
                            onClick={(): void => navigate(`/summary/groupsTab/${groupId}/current-update`)}
                        />
                        <Box>
                            <Typography variant={'h6'} fontSize={'20px'} fontWeight={600} className="text-content">
                                Device Update List
                            </Typography>
                            <Typography variant={'body1'} fontSize={'16px'} fontWeight={400} className="text-content">
                                {profileProps?.adopterName || '--'} <b>&gt;</b> {profileProps?.iotHubName || '--'}{' '}
                                <b>&gt;</b> Groups <b>&gt;</b> {groupId}
                            </Typography>
                        </Box>
                    </Stack>
                    <Spacer />
                </Toolbar>
            </AppBar>
            <Box>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack
                        flexDirection={'column'}
                        alignItems={'center'}
                        className="card-header"
                        sx={{ borderBottom: '0 !important' }}
                    >
                        <Typography sx={{ color: '#007bc1', fontSize: '16px', fontWeight: '600' }} variant="h6">
                            {' '}
                            {`Updates / Device List (${targetDeviceList?.data.length ?? 0})`}
                        </Typography>
                    </Stack>
                    <Button
                        variant="text"
                        size="medium"
                        onClick={(): void => navigate(`/summary/groupsTab/${groupId}/current-update`)}
                    >
                        View less
                    </Button>
                </Stack>
                <Divider />
                <Box className="pos-relative table-header-search-box">
                    <TextField
                        id="outlined-basic"
                        placeholder="Search.."
                        variant="outlined"
                        className="w-100"
                        sx={{ paddingLeft: '12px' }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            handleSearch(event, targetDeviceList?.data, 'deviceId')
                        }
                    />
                    <SearchIcon className="search-icon-wrap" />
                </Box>
                <Divider />
                <CardContent>
                    <CustomTable
                        isPagination={true}
                        data={filteredData}
                        headers={columns}
                        containerClass="custom-data-table"
                        isLoading={isFetchingDevices}
                        noDataFoundTitle={'No Devices found'}
                        keyToTraverse={'deviceId'}
                        total={targetDeviceList?.data.length ?? 0}
                        noDataFoundIcon={<Devices fontSize="inherit" />}
                    />
                </CardContent>
            </Box>
        </Paper>
    );
};
