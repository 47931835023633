import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './scss/_main.scss';
import { ThemeProvider, createTheme } from '@mui/material';
import * as BLUIThemes from '@brightlayer-ui/react-themes';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux-config/store';

const container = document.getElementById('root');
const root = createRoot((container as HTMLElement) || document.createDocumentFragment());

root.render(
    <Provider store={store}>
        <ThemeProvider theme={createTheme(BLUIThemes.blue)}>
            <CssBaseline />
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </Provider>
);
